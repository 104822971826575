import ScheduledCommsEdit, { ScheduledCommsEditProps } from "./scheduledCommsEdit";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  closeDialog
} from "../../store/areas/scheduledCommsEdit";
import ScheduledCommsEditStyles from "./scheduledCommsEditStyles";

const mapStateToProps = (state: RootState): Partial<ScheduledCommsEditProps> => ({
  requestType: state.scheduledCommsEdit.requestType,
  dialog: state.scheduledCommsEdit.dialog
});

const mapDispatchToProps = (dispatch: any) => ({
  closeDialog: () => dispatch(closeDialog())
});

const ScheduledCommsEditContainer = compose(
  withStyles(ScheduledCommsEditStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScheduledCommsEdit) as React.ComponentType<any>;
export default ScheduledCommsEditContainer;
