import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import TemplateEditorContainer from "./views/templateEditor/templateEditorContainer";
import LoaderViewContainer from "./views/loader/loaderViewContainer";
import LayoutViewContainer from "./views/layout/layoutViewContainer";
import SchedulingRuleEditorContainer from "./views/schedulingRuleEditor/schedulingRuleEditorContainer";
import SchedulingRulesListContainer from "./views/schedulingRulesList/schedulingRulesListContainer";
import TemplatesListContainer from "./views/templatesList/templatesListContainer";
import CancellationRuleEditorContainer from "./views/cancellationRuleEditor/cancellationRuleContainer";
import CancellationRulesListContainer from "./views/cancellationRulesList/cancellationRulesListContainer";
import SnippetEditorContainer from "./views/snippetEditor/snippetEditorContainer";
import SnippetsListContainer from "./views/snippetsList/snippetsListContainer";
import { AzureAD } from "react-aad-msal";
import { ExternalRouterName, RouterName } from "./enums";
import { Store } from "redux";
import ScheduledCommsListContainer from "./views/scheduledCommsList/scheduledCommsListContainer";
import SentCommsListContainer from "./views/sentCommsList/sentCommsListContainer";
import ScheduledCommsTableContainer from "./views/scheduledCommsList/scheduledCommsTable/scheduledCommsTableContainer";
import NoMatchContainer from "./views/noMatch/noMatchContainer";
import SentCommsTableContainer from "./views/sentCommsList/sentCommsTable/sentCommsTableContainer";
import { authProvider } from "./authProvider";

interface AppSecureProps {
  store: Store;
}

const AppSecure: React.FunctionComponent<AppSecureProps> = ({ store }) => {
  return (
    <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
      <Switch>
        <Route path={Object.values(RouterName)} exact>
          <LoaderViewContainer />
          <LayoutViewContainer>
            <Switch>
              <Route path={RouterName.NewTemplate} component={TemplateEditorContainer} exact />
              <Route path={RouterName.NewTemplateFromExisting} component={TemplateEditorContainer} exact />
              <Route path={RouterName.EditTemplate} component={TemplateEditorContainer} exact />
              <Route path={RouterName.Templates} component={TemplatesListContainer} exact />

              <Route path={RouterName.NewSchedulingRule} component={SchedulingRuleEditorContainer} exact />
              <Route path={RouterName.EditSchedulingRule} component={SchedulingRuleEditorContainer} exact />
              <Route path={RouterName.SchedulingRules} component={SchedulingRulesListContainer} exact />

              <Route path={RouterName.NewCancellationRule} component={CancellationRuleEditorContainer} exact />
              <Route path={RouterName.EditCancellationRule} component={CancellationRuleEditorContainer} exact />
              <Route path={RouterName.CancellationRules} component={CancellationRulesListContainer} exact />

              <Route path={RouterName.NewSnippet} component={SnippetEditorContainer} exact />
              <Route path={RouterName.NewSnippetFromExisting} component={SnippetEditorContainer} exact />
              <Route path={RouterName.EditSnippet} component={SnippetEditorContainer} exact />
              <Route path={RouterName.Snippets} component={SnippetsListContainer} exact />

              <Route path={RouterName.ScheduledComms} component={ScheduledCommsListContainer} exact />

              <Route path={RouterName.SentComms} component={SentCommsListContainer} exact />
              <Route path={RouterName.SpecificSentComms} component={SentCommsListContainer} exact />

              <Route path={RouterName.Home} component={TemplatesListContainer} exact />
            </Switch>
          </LayoutViewContainer>
        </Route>
        <Route path={ExternalRouterName.ScheduledComms} component={ScheduledCommsTableContainer} exact />
        <Route path={ExternalRouterName.SpecificSentComms} component={SentCommsTableContainer} exact />
        <Route component={NoMatchContainer} exact />
      </Switch>
    </AzureAD>
  );
};

export default AppSecure;
