import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import {
  GET_CANCELLATION_RULES_SUCCESS,
  GET_CANCELLATION_RULES,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_FILTER
} from "./actionTypes";
import {
  CancellationRule,
  CancellationRulesRequest,
  CancellationRulesTableFields,
  CancellationRulesColumnIndex,
  Filter
} from "./types";
import { Column, UpdateTableHeadersObject, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { defaultStatuses } from "../shared/values";

export interface CancellationRulesListState {
  cancellationRules?: CancellationRule[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: CancellationRulesRequest;
  status: Status[];
  filter: Filter;
}

export const defaultCancellationRuleColumns: Column[] = [
  {
    name: "name",
    label: "Rule Name",
    dataType: DataType.String
  },
  { name: "triggerName", label: "Trigger", dataType: DataType.String },
  {
    name: "templateName",
    label: "Template Name",
    dataType: DataType.String
  },
  {
    name: "lastModified",
    label: "Last Modified",
    dataType: DataType.Datetime
  },
  {
    name: "active",
    label: "Active",
    dataType: DataType.Boolean
  }
];

export const defaultFilterValues: Filter = {
  ruleName: "",
  templateName: "",
  triggers: [],
  dateModified: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All
  },
  status: ""
};

export const initialCancellationRuleQueries: CancellationRulesRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  sortAscending: true,
  sortField: CancellationRulesTableFields.Name
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: CancellationRulesColumnIndex.Name,
  sortOrder: SortOrder.asc
};

export const initialState: CancellationRulesListState = {
  cancellationRules: [],
  columns: defaultCancellationRuleColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  queries: initialCancellationRuleQueries,
  status: defaultStatuses,
  filter: defaultFilterValues
};

export const CancellationRulesListReducer: Reducer<CancellationRulesListState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_CANCELLATION_RULES:
      const { cancellationRulesRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns
      };
    case GET_CANCELLATION_RULES_SUCCESS:
      const { cancellationRules, totalRows } = action;
      return { ...state, cancellationRules, totalRows };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    default:
      return state;
  }
};

export default CancellationRulesListReducer;
