import CancellationRulesTable, { CancellationRulesTableProps } from "./cancellationRulesTable";
import { connect } from "react-redux";
import { compose } from "redux";
import cancellationRulesStyles from "../cancellationRulesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { getCancellationRules, updateTableHeaders, CancellationRulesRequest } from "../../../store/areas/cancellationRulesList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<CancellationRulesTableProps> => ({
  columns: state.cancellationRulesList.columns,
  cancellationRules: state.cancellationRulesList.cancellationRules,
  totalRows: state.cancellationRulesList.totalRows,
  itemsPerPage: state.cancellationRulesList.itemsPerPage,
  queries: state.cancellationRulesList.queries,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCancellationRules: (cancellationRulesRequest: CancellationRulesRequest) => dispatch(getCancellationRules(cancellationRulesRequest)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
});

const CancellationRulesTableContainer = compose(
  withStyles(cancellationRulesStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.CancellationRule)
)(CancellationRulesTable) as React.ComponentType<any>;

export default CancellationRulesTableContainer;
