import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppDatePickerStyles from "./appDatePickerStyle";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

export interface AppDatePickerProps {
  classes: any;
  date?: Date;
  onChange: (date?: Date) => void;
  labelText?: string;
  labelProps?: object;
  error?: boolean;
  success?: boolean;
  id?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const Item: React.FunctionComponent<AppDatePickerProps> = ({
  date,
  onChange,
  classes,
  labelText,
  labelProps,
  error,
  success,
  id,
  disableFuture,
  disablePast,
}) => {
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  return (
    <div className={classes.container}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          id={id}
          margin="none"
          value={date}
          format="dd/MM/yyyy"
          onChange={(pDate: MaterialUiPickersDate) => {
            onChange(pDate === null ? undefined : pDate);
          }}
          inputProps={{ className: classes.dateInput }}
          variant="inline"
          disableToolbar
          autoOk={true}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
        <TimePicker
          margin="none"
          id={`${id}-time`}
          value={date}
          onChange={(pDate: MaterialUiPickersDate) => {
            onChange(pDate === null ? undefined : pDate);
          }}
          inputProps={{ className: classes.dateInput }}
          variant="inline"
          autoOk={true}
        />
      </MuiPickersUtilsProvider>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </div>
  );
};

export const AppDatePicker = withStyles(AppDatePickerStyles)(Item);
