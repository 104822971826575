import { MUIColumnNames, TableRequest, DateRange } from "../shared";

export interface Snippet {
  id: string;
  name: string;
  lastModified: Date;
  active?: boolean;
}

// namespace KLP.Comms.Enums.SnippetSearchSortFields for back end mapping
export enum SnippetTableFields {
  "Name" = 1,
  "LastModified" = 2,
  "Active" = 3,
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum SnippetColumnIndex {
  "Name" = SnippetTableFields.Name - 1,
  "LastModified" = SnippetTableFields.LastModified - 1,
  "Active" = SnippetTableFields.Active - 1,
}

export const parseMUIColumnToEnum = (columnName: string): SnippetTableFields => {
  switch (columnName) {
    case MUIColumnNames.name:
      return SnippetTableFields.Name;
    case MUIColumnNames.lastModified:
      return SnippetTableFields.LastModified;
    case MUIColumnNames.active:
      return SnippetTableFields.Active;
    default:
      return SnippetTableFields.Name;
  }
};

export interface SnippetsRequest extends TableRequest {
  sortField?: SnippetTableFields;
}

export interface Filter {
  snippetName: string;
  dateCreated: DateRange;
  status: string;
}
