import SchedulingRuleCondition, { SchedulingRuleConditionProps } from "./schedulingRuleCondition";
import SchedulingRuleEditorStyles from "../schedulingRuleEditorStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/rootReducer";
import { updateSendCondition } from "../../../store/areas/schedulingRule";
import { Condition } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";
import { configureStore } from "@comms/react-query-builder/src";

const store = configureStore();

const mapStateToProps = (state: RootState): Partial<SchedulingRuleConditionProps> => ({
  fields: state.schedulingRule.schedulingRule.combinedFields,
  condition: state.schedulingRule.schedulingRule?.sendCondition,
  title: "Send Conditions",
  store,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCondition: (condition: Condition) => dispatch(updateSendCondition(condition)),
});

const SchedulingRuleSendConditionContainer = compose(
  withStyles(SchedulingRuleEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.SchedulingRule)
)(SchedulingRuleCondition) as React.ComponentType<any>;
export default SchedulingRuleSendConditionContainer;
