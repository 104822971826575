import React from "react";
import QueryBuilder from "@comms/react-query-builder";
import { Field, transformConfigField, Condition } from "../../../store/areas/shared";

export interface ScheduledCommsConditionsFilterProps {
  condition: Condition;
  triggerFields: Field[];
  store: any;
  updateFilterCondition: (condition: Condition) => void;
}

const scheduledCommsConditionsFilter: React.FunctionComponent<ScheduledCommsConditionsFilterProps> = ({
  condition,
  triggerFields,
  updateFilterCondition,
  store,
}) => {
  const builderFields = transformConfigField(triggerFields ?? []);
  const builderKey = Object.keys(builderFields).join();

  return (
    <QueryBuilder
      key={builderKey}
      fields={builderFields}
      rules={condition?.rawState?.rules}
      combinator={condition?.rawState?.combinator}
      onChange={(rules: any, combinator: any, logic: any) => updateFilterCondition({ rawState: { rules, combinator }, logic })}
      store={store}
    />
  );
};

export default scheduledCommsConditionsFilter;
