import { Reducer } from "redux";
import {
  Snippet,
  UPDATE_SNIPPET,
  INIT_SNIPPET,
  SAVE_SNIPPET,
  SAVE_SNIPPET_SUCCESS,
  Touched,
  TOUCH_SNIPPET,
  GET_SNIPPET_SUCCESS,
  UPDATE_EDITOR,
} from ".";
import { EditorState, convertToRaw } from "draft-js";

export interface SnippetState {
  snippet: Snippet;
  saved: boolean;
  touched: Touched;
}

export const initialState: SnippetState = {
  snippet: {
    id: "",
    name: "",
    bodyHtml: "",
    bodyText: "",
    rawBody: convertToRaw(
      EditorState.createEmpty().getCurrentContent()
    ),
    active: true
  },
  saved: false,
  touched: {
    name: false,
    body: false,
    isDisabled: false
  }
};

export const SnippetReducer: Reducer<SnippetState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case INIT_SNIPPET:
      return {
        ...initialState
      };
    case UPDATE_SNIPPET:
      return {
        ...state,
        snippet: action.snippet
      };
    case UPDATE_EDITOR:
      const { snippetPartial } = action;
      return {
        ...state,
        snippet: { ...state.snippet, ...snippetPartial }
      };
    case SAVE_SNIPPET:
      return {
        ...state,
        previewInfo: action.previewInfo
      };
    case SAVE_SNIPPET_SUCCESS:
      return {
        ...state,
        saved: true
      };
    case TOUCH_SNIPPET:
      return {
        ...state,
        touched: { ...state.touched, ...action.touched }
      };
    case GET_SNIPPET_SUCCESS:
      return {
        ...state,
        snippet: action.snippet
      };
    default:
      return state;
  }
};
