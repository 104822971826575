import { Theme, createStyles } from "@material-ui/core/styles";

const combinatorStyles = (theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`
    },
    buttonGroup: {
      zIndex: 2,
      position: "relative"
    }
  });

export default combinatorStyles;
