import { Field } from "../../store/areas/shared";
import { FieldType } from "../../enums";

export const overrideField: Field = {
  displayName: "Override (Email)",
  field: "",
  description: "",
  path: "Override (Email)",
  type: FieldType.Email,
  isArray: false,
  isTemplateField: false,
  isRecipientSource: true,
};

export const notificationChannelTypes = [
  { id: 1, name: "Email" },
  { id: 2, name: "UI" },
];
