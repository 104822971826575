import LoaderView, { LoaderViewProps } from "./loaderView";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import LoaderStyles from "./loaderViewStyle";
import { RootState } from "../../store/rootReducer";

export const mapStateToProps = (state: RootState): Partial<LoaderViewProps> => ({
  loading: state.loader.loading
});

const mapDispatchToProps = () => ({});

const LoaderViewContainer = compose(
  withStyles(LoaderStyles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoaderView) as React.ComponentType<any>;
export default LoaderViewContainer;
