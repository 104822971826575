/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars*/
import React, { ReactNode } from "react";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableFilterOptions } from "mui-datatables";
import { DataType, BooleanValue, SortOrder } from "../../enums";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {  withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Column } from "../../store/areas/shared";
import { CSSProperties } from "@material-ui/styles";
import { formatUTCToLocalDate } from "../../utilities/dateUtilities";
import { ROWS_PER_TABLE_PAGE } from "../../config";
import AppTableStyles from "./appTableStyle";

export interface RowMeta {
  dataIndex: number;
  rowIndex: number;
}

export interface AppTable {
  columns: Column[];
  data: any[];
  onSelect?: (items?: any[], indices?: number[], currentlySelectedRows?: number) => void;
  rowsSelected?: number[];
  selectableRows?: "multiple" | "single" | "none";
  selectableRowsHeader?: boolean;
  title: string;
  customCellNode?: (data: any) => ReactNode;
  rowsPerPage?: number;
  bodyCellStyling?: CSSProperties;
  setRowProps?: (row: any[], rowIndex: number) => object;
  makeRowsExpandable?: boolean;
  renderExpandableRow?: (rowData: string[], rowMeta: RowMeta) => ReactNode;
  filter?: boolean;
  search?: boolean;
  sort?: boolean;
  count?: number;
  serverSide?: boolean;
  pageRequested?: number;
  onColumnSortChange?: (changedColumn: string, direction: string) => any;
  onChangePage?: (currentPage: number) => void;
  onTableChange?: (action: string, tableState: any) => void;
  thinRows?: boolean;
  classes: any;
}

const TransformObjectToArray = (obj: any, columns: Column[]): any[] => {
  return columns.map(c => obj[c.name]?.toString() ?? "");
};

const Item: React.FunctionComponent<AppTable> = ({
  columns,
  data,
  onSelect,
  rowsSelected,
  title,
  selectableRows,
  customCellNode,
  rowsPerPage,
  bodyCellStyling,
  setRowProps,
  makeRowsExpandable,
  renderExpandableRow,
  filter,
  search,
  sort,
  count,
  serverSide,
  onColumnSortChange,
  onChangePage,
  onTableChange,
  pageRequested,
  selectableRowsHeader,
  thinRows,
  classes,
}) => {
  const onRowSelectionChange = (currentRowsSelected: any[], rowsSelected: any[]) => {
    if (!currentRowsSelected.length && !rowsSelected.length) {
      return onSelect!([], [], 0);
    }
    const indices = currentRowsSelected.map(el => el.dataIndex);
    const selectedData = indices.map(el => {
      return data[el];
    });
    onSelect!(selectedData, indices, rowsSelected.length);
  };

  const options: MUIDataTableOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    filter,
    search,
    sort,
    count,
    serverSide,
    rowsPerPage: rowsPerPage ? rowsPerPage : ROWS_PER_TABLE_PAGE,
    rowsPerPageOptions: [rowsPerPage ? rowsPerPage : ROWS_PER_TABLE_PAGE],
    responsive:  "standard",
    /* eslint-disable-next-line */
    customToolbarSelect: () => <></>, // to disable delete button
    onRowSelectionChange,
    selectableRows,
    selectableRowsHeader,
    rowsSelected: rowsSelected ?? [],
    setRowProps,
    expandableRows: makeRowsExpandable,
    renderExpandableRow: (rowData: string[], rowMeta: RowMeta) => renderExpandableRow!(rowData, rowMeta),
    onColumnSortChange: (changedColumn: string, direction: string) => {
      onColumnSortChange!(changedColumn, direction);
    },
    onChangePage: (currentPage: number) => {
      onChangePage!(currentPage);
    },
    onTableChange: (action: string, tableState: any) => onTableChange!(action, tableState),
  };

  const getDataTableBodyCellStyling = (): CSSProperties => {
    bodyCellStyling = thinRows ? { ...bodyCellStyling, padding: "5px 6px", wordBreak:"break-word" } : bodyCellStyling;
    return {
      "@media (max-width:960px)": {
        paddingBottom: "30px",
      },
      ...bodyCellStyling,
    };
  };

  const getButtonIconStyling = (): CSSProperties => {
    return makeRowsExpandable ? { padding: "5px 10px" } : {};
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            minHeight: "554px",
          },
        },
        MUIDataTableBodyCell: {
          root: getDataTableBodyCellStyling(),
        },
        MUIDataTableFilterList: {
          root: {
            display: "none",
          },
        },
        MUIDataTableToolbarSelect: {
          root: {
            "@media (min-width:960px)": {
              minHeight: "64px",
            },
          },
        },
        MuiIconButton: {
          root: getButtonIconStyling(),
        },
      },
    });

  const booleanCellRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: any, tableMeta: any, updateValue: any) => any
  ) => string | React.ReactNode = (value, tableMeta, updateValue) => {
    return value === "true" ? <CheckIcon color="primary" /> : <ClearIcon color="error" />;
  };

  const dateCellRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (s: any, c: any, p: any) => any
  ) => string | React.ReactNode = (value, tableMeta, updateValue) => {
    return <div>{value && formatUTCToLocalDate(value)}</div>;
  };

  const numberCellRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (s: any, c: any, p: any) => any
  ) => string | React.ReactNode = (value, tableMeta, updateValue) => {
    return <div className={classes.cellContainer}>{value}</div>;
  };

  const customCellRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (s: any, c: any, p: any) => any
  ) => string | React.ReactNode = (value, tableMeta, updateValue) => {
    if (customCellNode) {
      return customCellNode(data[tableMeta.rowIndex]);
    }
    return null;
  };

  const getMUColumns = (column: Column) => {
    const muColumn: MUIDataTableColumnDef = { ...column };
    muColumn.options = muColumn.options || {};
    muColumn.options.sort = !column.disableSort ?? true;
    muColumn.options.searchable = true;

    switch (column.dataType) {
      case DataType.Boolean:
        const filterOption: MUIDataTableFilterOptions = {
          names: [BooleanValue.Yes, BooleanValue.No],
        };
        muColumn.options.filterList = [BooleanValue.Yes];
        muColumn.options.customBodyRender = booleanCellRender;
        muColumn.options.filterOptions = filterOption;
        break;
      case DataType.Datetime:
        muColumn.options.customBodyRender = dateCellRender;
        muColumn.options.filter = false;
        break;
      case DataType.Number:
        muColumn.options.customBodyRender = numberCellRender;
        muColumn.options.filter = false;
        break;
      case DataType.Custom:
        muColumn.options.customBodyRender = customCellRender;
        muColumn.options.sort = false;
        muColumn.options.filter = false;
        break;
      default:
    }

    switch (column.sortOrder) {
      // These are inverted because we use the arrow to display flow of data, not whether it's ascending or descending
      case SortOrder.desc:
        muColumn.options.sortDirection = "asc";
        break;
      case SortOrder.asc:
        muColumn.options.sortDirection = "desc";
        break;
      default:
        muColumn.options.sortDirection = undefined;
    }
    return muColumn;
  };

  const getColumns = () => {
    if (customCellNode) {
      return [...columns, { name: "", dataType: DataType.Custom, label: "" }];
    }
    return columns;
  };

  const getData = () => {
    const value = data.map(d => TransformObjectToArray(d, columns));
    return value;
  };

  if (pageRequested !== undefined) {
    options.page = pageRequested;
  }

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable key={options.page} title={title} data={getData()} columns={getColumns().map(c => getMUColumns(c))} options={options} />
    </MuiThemeProvider>
  );
};
export const AppTable = withStyles(AppTableStyles)(Item);
