import { EditorState, convertToRaw, ContentBlock, genKey, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Suggestion } from "./types";
import { Field } from "../../store/areas/shared";
import { List } from "immutable";

export const parseStateToHTML = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  const rawContent = convertToRaw(currentContent);
  let current = draftToHtml(rawContent);
  // Default behavior is to wrap mentions in an anchor tag, which we don't want, so we're stripping it out.
  const mentionsLinkPattern = /<a href="undefined"[^>@]*>(.*?)<\/a>/g;
  let match = mentionsLinkPattern.exec(current);
  while (match != null) {
    current = current.replace(match[0], match[1]);

    // JavaScript RegExp objects are stateful when they have the global or sticky flags set (e.g. /foo/g or /foo/y).
    // They store a lastIndex from the previous match.
    // source: https://stackoverflow.com/a/11477448/1719544
    // source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
    mentionsLinkPattern.lastIndex = 0;
    match = mentionsLinkPattern.exec(current);
  }
  // Handle in line styling for images
  const imagePattern = /(<div[^>]*>)?<img.*?style=["'](?<style>.*?)["'].*?>/gi;
  const closingTag = /\/>/g;
  let imageMatch = imagePattern.exec(current);
  while (imageMatch != null) {
    let image = imageMatch[0];
    const heightAndWidth = getHeightAndWidthFromStyle(imageMatch[2]);
    image = image.replace(closingTag, heightAndWidth + "/>");
    // If second match group is empty then the image has no enclosing div tags
    if (!imageMatch[1]) {
      // Add enclosing div tags and align to center
      image = `<div style="text-align:center;">${image}</div>`;
    } else {
      let openingDivTag = imageMatch[1];
      // Replace style for images that may previously have been aligned from left or right to center
      openingDivTag = openingDivTag.replace("text-align:none", "text-align:center");
      image = image.replace(imageMatch[1], openingDivTag);
    }
    current = current.replace(imageMatch[0], image);
    imageMatch = imagePattern.exec(current);
  }
  // Also prepends all mentions with an "@" which we also want to remove
  current = current.replace(/@{{/g, "{{");
  // And now we wrap with a styled body tag to centre the e-mail
  current = `<body align="center"><table align="center" width="960">${current}</table></body>`;
  return current;
};

export const getHeightAndWidthFromStyle = (value: string) => {
  const stylePattern = /([\w-]*)\s*:\s*([^;]*)/g;
  let result = " ";
  let styleMatch = stylePattern.exec(value);
  while (styleMatch != null) {
    if (styleMatch[1] === "height" || styleMatch[1] === "width") {
      styleMatch[2] = styleMatch[2].replace("px", "");
      result += styleMatch[1] + "=" + JSON.stringify(styleMatch[2]) + " ";
    }
    styleMatch = stylePattern.exec(value);
  }
  return result;
};

export const parseStateToText = (editorState: EditorState) => {
  let current = editorState.getCurrentContent().getPlainText("\n\n");
  // As mentioned above: prepends all mentions with an "@" which we also want to remove
  current = current.replace(/@{{/g, "{{");
  return current;
};

export const createPlaceholderList = (fields: Field[]): Suggestion[] => {
  return fields.flatMap(f => {
    const suggestion: Suggestion = {
      value: `${f.path}`,
      text: f.displayName,
    };
    return suggestion;
  });
};

export const NEWLINE_REGEX = /\n/g;

export function replaceNewlines(str: string, replacement = " ") {
  return str.replace(NEWLINE_REGEX, replacement);
}

export function condenseBlocks(editorState: EditorState, blocks: ContentBlock[]) {
  blocks = blocks || editorState.getCurrentContent().getBlocksAsArray();
  let text = List();
  let characterList: any = List();

  // Gather all the text/characterList and concat them
  blocks.forEach(block => {
    // Atomic blocks should be ignored (stripped)
    if (block.getType() !== "atomic") {
      text = text.push(replaceNewlines(block.getText()));
      characterList = characterList.concat(block.getCharacterList());
    }
  });

  // Create a new content block
  const contentBlock = new ContentBlock({
    key: genKey(),
    text: text.join(""),
    type: "unstyled",
    characterList,
    depth: 0,
  });

  // Update the editor state with the compressed version
  const newContentState = ContentState.createFromBlockArray([contentBlock]);
  // Create the new state as an undoable action
  editorState = EditorState.push(editorState, newContentState, "remove-range");
  // Move the selection to the end
  return EditorState.moveFocusToEnd(editorState);
}
