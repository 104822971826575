import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { AppIcon } from "../appIcon/appIcon";

export interface ListItemProps {
  iconName: any;
  caption: any;
  to: any;
  classes: any;
}

const ListItemLink: React.FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const { iconName, caption, to, classes } = props;
  return (
    <Link to={to} className={classes.linkText}>
      <ListItem button>
        <ListItemIcon className={classes.listItem}>
          <AppIcon iconName={iconName} />
        </ListItemIcon>
        <ListItemText primary={caption} />
      </ListItem>
    </Link>
  );
};

export default ListItemLink;
