import { AnyAction } from "redux";
import {
  GET_AUGMENTERS,
  GET_AUGMENTERS_SUCCESS,
  GET_AUGMENTERS_FAIL,
  Augmenter,
  GET_TRIGGERS,
  GET_TRIGGERS_SUCCESS,
  GET_TRIGGERS_FAIL,
  TriggerDetails,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  EditorImage,
  Dictionary,
  GET_TENANTS,
  GET_TENANTS_SUCCESS,
  GET_TENANTS_FAIL,
  SET_TENANT,
  Tenant
} from ".";

export const getAugmenters = (): AnyAction => ({ type: GET_AUGMENTERS });

export const getAugmentersSuccess = (augmenters: Dictionary<Augmenter>): AnyAction => {
  return {
    type: GET_AUGMENTERS_SUCCESS,
    augmenters,
  };
};

export const getAugmentersFailure = (error: Error): AnyAction => {
  return {
    type: GET_AUGMENTERS_FAIL,
    error,
    message: "Unable to retrieve list of augmenters, please contact KLP Support",
  };
};

export const getTriggers = (): AnyAction => ({ type: GET_TRIGGERS });

export const getTriggersSuccess = (triggers: Dictionary<TriggerDetails>): AnyAction => {
  return {
    type: GET_TRIGGERS_SUCCESS,
    triggers,
  };
};

export const getTriggersFailure = (error: Error): AnyAction => {
  return {
    type: GET_TRIGGERS_FAIL,
    error,
    message: "Unable to retrieve list of triggers, please contact KLP Support",
  };
};

export const uploadImage = (image: EditorImage): AnyAction => {
  return {
    type: UPLOAD_IMAGE,
    image,
  };
};

export const uploadImageSuccess = (image: EditorImage): AnyAction => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    image,
  };
};

export const uploadImageFailure = (error: Error): AnyAction => {
  return {
    type: UPLOAD_IMAGE_FAIL,
    error,
    message: error.message || "unable to upload image, please contact IT Support",
  };
};

export const getTenants = (): AnyAction => ({ type: GET_TENANTS });

export const getTenantsSuccess = (tenants: Tenant[]): AnyAction => {
  return {
    type: GET_TENANTS_SUCCESS,
    tenants
  };
};

export const getTenantsFailure = (error: Error): AnyAction => {
  return {
    type: GET_TENANTS_FAIL,
    error,
    message: "You do not have access to any tenants"
  };
};

export const setTenant = (tenantId: string): AnyAction => {
  return {
    type: SET_TENANT,
    tenantId,
    message: "Tenant changed"
  };
};