import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import {
  GET_SCHEDULED_COMMS_SUCCESS,
  GET_SCHEDULED_COMMS,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_FILTER,
  SELECT_SCHEDULED_COMM,
  DESELECT_SCHEDULED_COMM,
  EDIT_SCHEDULED_COMMS_SUCCESS,
  UPDATE_FILTER_CONDITION,
} from "./actionTypes";
import { ScheduledComm, ScheduledCommsRequest, ScheduledCommsTableFields, ScheduledCommsColumnIndex, Filter } from "./types";
import { Column, UpdateTableHeadersObject, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE_LARGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { scheduledCommStatuses } from "./values";
import { addMonths } from "date-fns";
import { removeScheduledComms, removeScheduledCommIndices } from "./utils";
import { initialCondition } from "../shared/values";

export interface ScheduledCommsListState {
  scheduledComms?: ScheduledComm[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: ScheduledCommsRequest;
  status: Status[];
  filter: Filter;
  selectedDataIndices: number[];
  selectedScheduledComms: ScheduledComm[];
}

export const defaultScheduledCommColumns: Column[] = [
  {
    name: "templateName",
    label: "Template Name",
    dataType: DataType.String,
  },
  {
    name: "sendOn",
    label: "Scheduled Date",
    dataType: DataType.Datetime,
  },
  {
    name: "createdOn",
    label: "Created On",
    dataType: DataType.Datetime,
  },
  { name: "triggerName", label: "Trigger", dataType: DataType.String },
  {
    name: "externalSessionId",
    label: "External SessionId",
    dataType: DataType.String,
    disableSort: true,
  },
  {
    name: "status",
    label: "Status",
    dataType: DataType.String,
    disableSort: true,
  },
];

export const emptyFilter: Filter = {
  templateName: "",
  triggers: [],
  sendOnDate: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All,
  },
  createdOnDate: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All,
  },
  status: [],
  condition: initialCondition,
  triggerFields: [],
};

export const defaultFilterValues: Filter = {
  templateName: "",
  triggers: [],
  sendOnDate: {
    startDate: new Date(),
    endDate: addMonths(new Date(), 1),
    smartRange: SmartRange["Next month"],
  },
  createdOnDate: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All,
  },
  status: [],
  condition: initialCondition,
  triggerFields: [],
};

export const initialScheduledCommQueries: ScheduledCommsRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE_LARGE,
  sortAscending: true,
  sortField: ScheduledCommsTableFields.SendOn,
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: ScheduledCommsColumnIndex.SendOn,
  sortOrder: SortOrder.asc,
};

export const initialState: ScheduledCommsListState = {
  scheduledComms: [],
  columns: defaultScheduledCommColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE_LARGE,
  queries: initialScheduledCommQueries,
  status: scheduledCommStatuses,
  filter: defaultFilterValues,
  selectedDataIndices: [],
  selectedScheduledComms: [],
};

export const ScheduledCommsListReducer: Reducer<ScheduledCommsListState> = (state: ScheduledCommsListState = initialState, action: any) => {
  switch (action.type) {
    case GET_SCHEDULED_COMMS:
      const { scheduledCommsRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns,
      };
    case GET_SCHEDULED_COMMS_SUCCESS:
      const { scheduledComms, totalRows } = action;
      return {
        ...state,
        selectedDataIndices: [],
        selectedScheduledComms: [],
        scheduledComms,
        totalRows,
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      };
    case UPDATE_FILTER_CONDITION:
      return {
        ...state,
        filter: { ...state.filter, condition: action.condition },
      };
    case SELECT_SCHEDULED_COMM:
      return {
        ...state,
        selectedScheduledComms: [...state.selectedScheduledComms, ...action.selectedScheduledComm],
        selectedDataIndices: [...state.selectedDataIndices, ...action.selectedDataIndex],
      };
    case DESELECT_SCHEDULED_COMM:
      return {
        ...state,
        selectedScheduledComms: removeScheduledComms(state.selectedScheduledComms, action.selectedScheduledComm),
        selectedDataIndices: removeScheduledCommIndices(state.selectedDataIndices, action.selectedDataIndex),
      };
    case EDIT_SCHEDULED_COMMS_SUCCESS: {
      return {
        ...state,
        selectedDataIndices: [],
        selectedScheduledComms: [],
      };
    }
    default:
      return state;
  }
};

export default ScheduledCommsListReducer;
