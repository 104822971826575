import SnippetsTable, { SnippetsTableProps } from "./snippetsTable";
import { connect } from "react-redux";
import { compose } from "redux";
import snippetsStyle from "../snippetsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { SnippetsRequest, Snippet } from "../../../store/areas/snippetsList/types";
import { getSnippets, updateTableHeaders, selectSnippet, deselectSnippet } from "../../../store/areas/snippetsList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<SnippetsTableProps> => ({
  columns: state.snippetsList.columns,
  snippets: state.snippetsList.snippets,
  totalRows: state.snippetsList.totalRows,
  itemsPerPage: state.snippetsList.itemsPerPage,
  queries: state.snippetsList.queries,
  selectedSnippets: state.snippetsList.selectedSnippets,
  selectedDataIndices: state.snippetsList.selectedDataIndices,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSnippets: (snippetsRequest: SnippetsRequest) => dispatch(getSnippets(snippetsRequest)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
  selectSnippet: (snippet: Snippet, dataIndex: number) => dispatch(selectSnippet(snippet, dataIndex)),
  deselectSnippet: (snippet: Snippet, dataIndex: number) => dispatch(deselectSnippet(snippet, dataIndex)),
});

const SnippetsTableContainer = compose(
  withStyles(snippetsStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Snippet)
)(SnippetsTable) as React.ComponentType<any>;

export default SnippetsTableContainer;
