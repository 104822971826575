import { put, takeLatest, call } from "redux-saga/effects";
import {
  SAVE_TEMPLATE,
  saveTemplateSuccess,
  saveTemplateFailure,
  Template,
  GET_TEMPLATE,
  getTemplateSuccess,
  getTemplateFailure,
  QUEUE_TEMPLATE,
  queueTemplateFailure,
  queueTemplateSuccess,
  generatePayload,
  remapPayload,
  Snippet,
  getSnippetsSuccess,
  getSnippetsFailure,
  GET_SNIPPETS,
  updateTemplate,
  uploadTemplateAttachmentSuccess,
  uploadTemplateAttachmentFailure,
} from ".";
import { generateUrl, generateUrlWithQueryString, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute, RecipientSourceType, AugmenterOwner, ScheduledCommsStatus } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { NUMBER_MAX_VALUE } from "../../../config";
import { Field, Dictionary, AugmenterMappings, AugmenterInputMappings, GET_TRIGGERS_SUCCESS, getCombinedFields } from "../shared";
import { addMinutes } from "date-fns/esm";
import { store } from "../../../app";
import { RootState } from "../../rootReducer";
import { TemplateAttachmentRequest } from "./types";
import { UPLOAD_TEMPLATE_ATTACHMENT } from "./actionTypes";

function* getSnippetsSaga() {
  try {
    const url = generateUrlWithQueryString(EndpointRoute.Snippets, {
      itemsPerPage: NUMBER_MAX_VALUE,
      pageRequested: 0,
      sortAscending: true,
      sortField: 1,
      activeOnly: true,
    });
    const response = yield call(fetchSecure, url);
    const payload: { snippets: { name: string; tenantId: string; disabled: boolean }[] } = yield call([response, "json"]);

    const snippets: Snippet[] = payload.snippets.reduce((arr: Snippet[], cur) => {
      if (cur.disabled) {
        return arr;
      }
      arr.push({ name: cur.name });
      return arr;
    }, []);

    yield put(getSnippetsSuccess(snippets));
  } catch (error) {
    yield put(getSnippetsFailure(error));
  }
}

function* saveTemplateSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.Templates);
    const { template }: { template: Template } = action;
    const newMappings = template.augmenterMappings.reduce((obj: Dictionary<AugmenterInputMappings>, cur: AugmenterMappings) => {
      if (cur.augmenterOwner === AugmenterOwner.None) {
        let augmenterId = cur.augmenterId;
        if (!!cur.alias) {
          augmenterId += `:${cur.alias}`;
        }

        obj[augmenterId] = cur.mappings;
      }
      return obj;
    }, {});

    const newAttachments = template.templateAttachments.map(x => x.templateAttachmentId);

    const body = {
      id: template.id,
      name: template.name,
      triggerId: template.trigger.id,
      subject: template.subject,
      html: template.html,
      text: template.text,
      templateType: +template.templateType,
      subjectRawContent: JSON.stringify(template.subjectRawContent),
      rawContent: JSON.stringify(template.rawContent),
      disabled: !template.active,
      augmenterMappings: newMappings,
      attachments: newAttachments,
    };
    const response = yield call(fetchSecure, url, createHttpRequest(body, template.id ? "PUT" : "POST"));
    if (response.ok) {
      yield put(saveTemplateSuccess("Template saved"));
    } else {
      const error = yield call([response, "json"]);
      yield put(saveTemplateFailure(error));
    }
  } catch (error) {
    yield put(saveTemplateFailure(error));
  }
}

function* queueTemplateSaga(action: any) {
  try {
    const {
      template,
      email,
      fields,
    }: {
      template: Template;
      email: string;
      fields: Field[];
    } = action;

    const body = {
      payload: JSON.stringify(generatePayload(fields)),
      recipientSourceType: RecipientSourceType.FromRecipientSource,
      emailRecipientSource: JSON.stringify([email]),
      templateId: template.id,
      subjectPrefix: "This is a test email: ",
      Status: ScheduledCommsStatus.Pending,
      sendOn: addMinutes(new Date(), -1),
    };
    const url = generateUrl(EndpointRoute.ScheduledComms);

    const response = yield call(fetchSecure, url, createHttpRequest(body, "POST"));
    if (response.ok) {
      yield put(queueTemplateSuccess("Email has been queued"));
    } else {
      const error = yield call([response, "json"]);
      yield put(queueTemplateFailure(error));
    }
  } catch (error) {
    yield put(queueTemplateFailure(error));
  }
}

function* getTemplateSaga(action: any) {
  try {
    const { templateId }: { templateId: string } = action;
    const url = `${generateUrl(EndpointRoute.Templates)}/${templateId}`;
    const response = yield call(fetchSecure, url);
    const payload: { template: any } = yield call([response, "json"]);
    const remappedPayload: Template = remapPayload.getTemplate(payload.template);
    yield put(getTemplateSuccess(remappedPayload));
  } catch (error) {
    yield put(getTemplateFailure(error));
  }
}

function* getTriggersSuccess(action: any) {
  const state: RootState = store.getState() as RootState;

  const template = state.template.template;

  if (!template?.trigger?.id) {
    return;
  }

  const trigger = action.triggers[template.trigger.id];
  const triggerFields = trigger?.fields ?? [];

  const triggerMappings = trigger?.augmenterMappings ?? [];
  const templateMappings = template.augmenterMappings ?? [];
  const augmenterMappings = [...triggerMappings, ...templateMappings];

  const combinedFields = getCombinedFields(triggerFields, augmenterMappings);

  yield put(updateTemplate({ ...template, combinedFields }));
}

function* uploadTemplateAttachmentSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.UploadTemplateAttachment);

    const { request }: { request: TemplateAttachmentRequest } = action;

    const body = {
      name: request.templateAttachment.name,
      templateAttachmentData: request.templateAttachmentData,
    };

    const response = yield call(fetchSecure, url, createHttpRequest(body, "POST"));

    if (response.ok) {
      const payload: { templateAttachmentId: string } = yield call([response, "json"]);
      const uploadedAttachment = { ...request.templateAttachment, templateAttachmentId: payload.templateAttachmentId };
      yield put(uploadTemplateAttachmentSuccess(uploadedAttachment));
    } else {
      const error = yield call([response, "json"]);

      yield put(uploadTemplateAttachmentFailure(error));
    }
  } catch (error) {
    yield put(uploadTemplateAttachmentFailure(error));
  }
}

export function* templateWatcher() {
  yield takeLatest(GET_TEMPLATE, getTemplateSaga);
  yield takeLatest(GET_SNIPPETS, getSnippetsSaga);
  yield takeLatest(SAVE_TEMPLATE, saveTemplateSaga);
  yield takeLatest(QUEUE_TEMPLATE, queueTemplateSaga);
  yield takeLatest(GET_TRIGGERS_SUCCESS, getTriggersSuccess);
  yield takeLatest(UPLOAD_TEMPLATE_ATTACHMENT, uploadTemplateAttachmentSaga);
}
