import { format } from "date-fns-tz";

const dateString = "dd/MM/yyyy HH:mm:ss";
const dateStringWithTimeZone = "dd/MM/yyyy HH:mm:ss (zzz)";

const getOffsetInHours = (date: string) => {
  const regex = new RegExp(/GMT.*\d/);
  const asNewDate = new Date(date).toString();
  return `(${regex.exec(asNewDate)![0]})` ?? "";
};

const getDateIfValid = (date: string) => {
  const parsed = Date.parse(date);

  if (Number.isNaN(parsed)) {
    return false;
  }

  return parsed;
};

export const formatUTCToLocalDate = (date: string, includeTimezone = true) => {
  const validDate = getDateIfValid(date);

  if (!validDate) {
    return "Invalid date";
  }

  const formattedDate = format(validDate, dateString);
  const isIE = /* @cc_on!@*/ false || !!(document as any).documentMode;

  if (isIE) {
    // Note: IE does not support the Internationalization API so we have to strip out the GMT offset with regex and use that instead.
    const offset = getOffsetInHours(date);

    return includeTimezone ? `${formattedDate} ${offset}` : formattedDate;
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return includeTimezone ? format(validDate, dateStringWithTimeZone, { timeZone }) : formattedDate;
};

export const formatUTCDate = (date: string, includeTimezone = true) => {
  const validDate = getDateIfValid(date);

  if (!validDate) {
    return "Invalid date";
  }

  // needed because due to the internationalisation API JS will always try to convert to local time when parsing the date
  const utcDate = date.replace(/z/i, "");

  const formattedDate = format(new Date(utcDate), dateString);

  return includeTimezone ? `${formattedDate} (UTC)` : formattedDate;
};