import { store } from "../../../app";
import { Column, Field, TenantStatus } from "../shared";
import { FieldType, AugmenterOwner } from "../../../enums";
import { Field as BuilderField } from "@comms/react-query-builder/src/store/types";
import { FieldType as BuilderFieldType } from "@comms/react-query-builder";
import { AugmenterMappings, Dictionary, AugmenterInputMappings } from "./types";
import { RootState } from "../../rootReducer";
import { AnyAction } from "redux";

export const updateColumns = (action: any, currentColumns: Column[]) => {
  const { columnIndex, sortOrder } = action;
  const updatedColumns = currentColumns.map(
    (column: Column, index: number) =>
      index === columnIndex // Find the active column by its index
        ? (column = { ...column, sortOrder }) // then set the sort order if it matches
        : (column = { ...column, sortOrder: undefined }) // or return undefined sort value if it doesn't
  );
  return updatedColumns;
};

export const tidyFilterStatus = (filterStatus: string) => {
  switch (filterStatus) {
    case "active":
      return true;
    case "inactive":
      return false;
    default:
      return null;
  }
};

export function getCombinedFields(triggerFields: Field[], augmenterMappings: AugmenterMappings[]) {
  let fields: Field[] = [...triggerFields];
  const state = store.getState() as RootState;
  const augmenters = state.shared.augmenters;

  for (const augmenterMapping of augmenterMappings) {
    const augmenterId = augmenterMapping.augmenterId;
    const augmenter = augmenters[augmenterId];

    if (augmenter === undefined) {
      continue;
    }

    const augmenterOutputFields = mapOutputFieldsWithAlias(augmenterMapping.alias, augmenterMapping.augmenterId, augmenter.outputFields);
    fields = [...fields, ...augmenterOutputFields];
  }

  return fields;
}

export function mapOutputFieldsWithAlias(alias: string, augmenterId: string, fields: Field[]) {
  return fields.map(x => ({
    ...x,
    displayName: !!alias ? `${x.displayName} (${alias})` : x.displayName,
    path: `${alias || augmenterId}.${x.field}`,
  }));
}

export function remapAugmenterMappings(
  augmenterOwner: AugmenterOwner,
  payloadMappings: Dictionary<AugmenterInputMappings>
): AugmenterMappings[] {
  const result: AugmenterMappings[] = [];

  if (!payloadMappings) {
    return [];
  }

  for (const mappingKey in payloadMappings) {
    const keys = mappingKey.split(":").filter(x => !!x);
    result.push({
      augmenterId: keys[0],
      alias: keys[1] ?? "",
      augmenterOwner,
      mappings: payloadMappings[mappingKey],
    });
  }

  return result;
}

const getPrimitiveType = (fieldType: FieldType) => {
  switch (fieldType) {
    case FieldType.Text:
    case FieldType.Email:
    case FieldType.Url:
    case FieldType.Guid:
      return BuilderFieldType.String;
    case FieldType.Decimal:
    case FieldType.Int:
      return BuilderFieldType.Number;
    case FieldType.Date:
    case FieldType.DateTime:
      return BuilderFieldType.Date;
    case FieldType.Boolean:
      return BuilderFieldType.Boolean;
    default:
      return undefined;
  }
};

export const transformConfigField = (fields: Field[]): { [name: string]: BuilderField } => {
  return fields.reduce((acc: { [name: string]: BuilderField }, cur: Field) => {
    const fieldType = getPrimitiveType(cur.type);
    if (fieldType) {
      acc[cur.path] = { type: fieldType, name: cur.path } as BuilderField;
    }

    return acc;
  }, {});
};

export function toISOString(date?: Date) {
  return !!date ? new Date(date.toUTCString()).toISOString() : null;
}

export const tenantIsSet = (state: RootState): boolean => state.tenant.activeTenantId !== "";

export const failedToGetTenants = (state: RootState): boolean => state.tenant.status === TenantStatus.FAILED;