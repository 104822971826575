import React from "react";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppAutocompleteStyles from "./appAutocompleteStyle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

import { AppGridContainer, AppGridItem } from "..";

export interface AppAutocompleteProps {
  labelText?: string;
  labelProps?: object;
  id?: string;
  inputProps?: object;
  formControlProps?: any;
  error?: boolean;
  success?: boolean;
  touched?: boolean;
  onTouch?: () => void;
  options: string[];
  extraButton?: JSX.Element;
}
const item: React.FunctionComponent<AppAutocompleteProps> = ({
  labelText,
  id,
  inputProps,
  formControlProps,
  error,
  success,
  touched,
  onTouch,
  options,
  extraButton
}: AppAutocompleteProps) => {
  const classes = AppAutocompleteStyles();

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const onBlur = () => {
    if (!touched && onTouch) {
      onTouch();
    }
  };

  return (
    <>
      <FormControl
        disabled={true}
        error={error}
        {...formControlProps}
        className={`${formControlProps?.className} ${classes.formControl}`}
      >
        <AppGridContainer>
          <AppGridItem xs={12} sm={11} md={11}>
            <Autocomplete
              id={id}
              options={options}
              onBlur={onBlur}
              classes={{
                option: classes.option,
                root: `${marginTop} ${error ? classes.customAutocompleteError : ""}`,
                inputRoot: `${error ? classes.removeDottedBorder : ""}`
              }}
              renderInput={params => <TextField {...params} label={labelText} fullWidth />}
              {...inputProps}
            />
          </AppGridItem>
          {extraButton && (
            <AppGridItem xs={12} sm={1} md={1}>
              <div className={classes.buttonContainer}>{extraButton}</div>
            </AppGridItem>
          )}
        </AppGridContainer>
        {error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? (
          <Check className={classes.feedback + " " + classes.labelRootSuccess} />
        ) : null}
      </FormControl>
    </>
  );
};

export const AppAutocomplete = item;
