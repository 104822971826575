import ScheduledCommsFilter, { ScheduledCommsFilterProps } from "./scheduledCommsFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import ScheduledCommsListStyles from "../scheduledCommsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/styles";
import { updateFilter, Filter, applyFilter } from "../../../store/areas/scheduledCommsList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<ScheduledCommsFilterProps> => ({
  filter: state.scheduledCommsList.filter,
  triggers: state.shared.triggers,
  status: state.scheduledCommsList.status,
});

const mapDispatchToProps = (dispatch: any) => ({
  initFilter: () => {
    dispatch(applyFilter());
  },
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
});

const ScheduledCommsFilterContainer = compose(
  withStyles(ScheduledCommsListStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTenant
)(ScheduledCommsFilter) as React.ComponentType<any>;

export default ScheduledCommsFilterContainer;
