import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCopyrightStyles from "./appCopyrightStyle";
import Typography from "@material-ui/core/Typography";

export interface AppCopyrightProps {
  classes: any;
}

const Item: React.FunctionComponent<AppCopyrightProps> = (props: AppCopyrightProps) => {
  const { classes } = props;
  return (
    <div className={classes.footerText}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} KPMG LLP, a UK limited liability partnership, and a member firm
        of the KPMG network of independent member firms affiliated with KPMG International
        Cooperative, a Swiss entity. All rights reserved.
      </Typography>
    </div>
  );
};

export const AppCopyright = withStyles(AppCopyrightStyles)(Item);
