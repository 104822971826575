import SchedulingRulesFilter, { SchedulingRulesFilterProps } from "./schedulingRulesFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import SchedulingRulesListStyles from "../schedulingRulesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/styles";
import {
  updateFilter,
  Filter,
  applyFilter
} from "../../../store/areas/schedulingRulesList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<SchedulingRulesFilterProps> => ({
  filter: state.schedulingRulesList.filter,
  triggers: state.shared.triggers,
  status: state.schedulingRulesList.status
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
  initFilter: () => dispatch(applyFilter())
});

const SchedulingRulesFilterContainer = compose(
  withStyles(SchedulingRulesListStyles),
  withTenant,
  connect(mapStateToProps, mapDispatchToProps)
)(SchedulingRulesFilter) as React.ComponentType<any>;

export default SchedulingRulesFilterContainer;
