import React from "react";
import { RootState } from "../../../store/rootReducer";
import { store } from "../../../app";
import { AugmenterMappings } from "../../../store/areas/shared";
import { Template } from "../../../store/areas/template";
import AppAugmenter, { AppAugmenterProps } from "../../../components/appAugmenter/appAugmenter";

export interface TemplateAugmenterProps extends AppAugmenterProps {
  onTemplateChange: (template: Template) => void;
}

const TemplatesTable: React.FunctionComponent<TemplateAugmenterProps> = ({
  classes,
  augmenters,
  triggerFields,
  inheritedMappings,
  ownMappings,
  onTemplateChange,
  permission,
}) => {
  const onAugmenterChange = (augmenterMappings: AugmenterMappings[]) => {
    const state = store.getState() as RootState;
    onTemplateChange({ ...state.template.template, augmenterMappings });
  };

  return (
    <AppAugmenter
      classes={classes}
      augmenters={augmenters}
      triggerFields={triggerFields}
      inheritedMappings={inheritedMappings}
      ownMappings={ownMappings}
      onChange={onAugmenterChange}
      permission={permission}
    />
  );
};

export default TemplatesTable;
