import { Rule } from "./store/";
import { FieldType, CombinatorType } from "./enums";
import { operators } from "./config";
import _ from "lodash";

export const AreRulesValid = (rules: Rule[]): boolean => {
  const error = getRulesError(rules);
  return _.isEqual(error, {});
};

export const getRulesError = (rules: Rule[]): { [id: string]: string } => {
  return rules.reduce((acc: { [id: string]: string }, cur: Rule) => {
    const error = getRuleError(cur);
    if (error) {
      acc[cur.id] = error;
    } else if (IsRuleDuplicate(cur, rules)) {
      acc[cur.id] = "Condition is duplicate";
    }
    return acc;
  }, {});
};
const ruleValuesEqual = (values: any[], otherValues: any[]) => {
  return (
    JSON.stringify(values).toLowerCase() ===
    JSON.stringify(otherValues).toLowerCase()
  );
};

const IsRuleDuplicate = (rule: Rule, rules: Rule[]): boolean => {
  return rules.some(
    (r: Rule) =>
      r.id !== rule.id &&
      ruleValuesEqual(r.values, rule.values) &&
      _.isEqual(_.omit(r, ["id", "values"]), _.omit(rule, ["id", "values"]))
  );
};

export const getRuleError = (rule: Rule): string => {
  if (!rule.field || rule.field.type === FieldType.None) {
    return "Field is not specified";
  }
  if (!rule.operatorName || operators[rule.operatorName] === undefined) {
    return "Operator is not specified";
  }
  const inputs = operators[rule.operatorName].inputs[rule.field.type];
  if (inputs) {
    if (
      inputs.length !== rule.values.filter((v: any) => v !== undefined).length
    ) {
      return "Invalid or missing value";
    }
  }
  return "";
};

export const getRuleInfo = (rule: Rule): string => {
  if (
    rule.field?.type === FieldType.Date &&
    rule.values[0] &&
    rule.values[0].isToday
  ) {
    const offset = rule.values[0].offset;
    if (offset) {
      return `${rule.field.name} should be ${
        operators[rule.operatorName].labelForFormat
      }  ${Math.abs(offset)} day(s) ${offset > 0 ? "after" : "before"} today`;
    } else {
      return `${rule.field.name} should ${
        operators[rule.operatorName].labelForFormat
      }  be equal to today`;
    }
  }
  return "";
};

export const getJsonLogic = (
  rules: Rule[],
  combinator: CombinatorType
): object => {
  const errors = getRulesError(rules);
  let logicObjects: any = rules
    .filter((r: Rule) => errors[r.id] === undefined)
    .map((r: Rule) => generateRuleJsonLogic(r));
  if (logicObjects.length === 1) {
    return logicObjects[0];
  } else if (logicObjects.length > 1) {
    return (logicObjects = { [combinator]: logicObjects });
  }
  return {};
};

const generateRuleJsonLogic = (rule: Rule): object => {
  return operators[rule.operatorName].jsonLogic(rule.field.name, [
    ...rule.values,
  ]);
};
