import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppGridContainerStyles from "./appGridContainerStyle";

export interface AppGridContainerProps {
  classes: any;
}

const Item: React.FunctionComponent<AppGridContainerProps | any> = props => {
  const { children, classes, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
};

export const AppGridContainer = withStyles(AppGridContainerStyles)(Item);
