import { createStyles } from "@material-ui/core";

const SentCommsListStyles = () => createStyles({
  attachmentsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  attachment: {
    margin: "4px 8px",
    flexBasis: "30%",
  },
  attachmentName: {
    color: "#000"
  },
  attachmentExtension: {
    color: "#777",
    fontVariant: "small-caps"
  }
});

export default SentCommsListStyles;
