import { makeStyles, Theme } from "@material-ui/core/styles";

const AppAutoCompleteStyles = makeStyles((theme: Theme) => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  labelRootError: {
    color: theme.palette.error.main + " !important",
  },
  labelRootSuccess: {
    color: theme.palette.success.main + " !important",
  },
  feedback: {
    position: "absolute",
    top: "18px",
    left: "175px",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none",
  },
  formControl: {
    margin: "8px",
    position: "relative",
  },
  selectControl: {
    minWidth: "169px",
  },
}));

export default AppAutoCompleteStyles;
