import { Filter } from "./types";
import { tidyFilterStatus } from "../shared/utils";
import { RootState } from "../../rootReducer";

export const generateFilterObject = (filter: Filter): any => {
  return {
    ruleName: filter.ruleName,
    triggers: filter.triggers,
    templateName: filter.templateName,
    startDate: filter.dateModified.startDate?.toUTCString() ?? null,
    endDate: filter.dateModified.endDate?.toUTCString() ?? null,
    activeOnly: tidyFilterStatus(filter.status)
  };
};

export const getFilter = (state: RootState) => {
  return state.cancellationRulesList.filter;
};

export const getCancellationRuleRequest = (state: RootState) => {
  return state.cancellationRulesList.queries;
};
