import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppButton, AppGridContainer, AppGridItem, AppInput } from "../../../components";
import { CardColor, Size } from "../../../enums";
import { AugmenterMappings } from "../../../store/areas/shared";
import AliasDialogStyles from "./aliasDialogStyle";

export interface AliasDialogProps {
  classes?: any;
  allMappings: AugmenterMappings[];
  mappingIndex: number;
  onAliasUpdate: (augmenterMappings?: AugmenterMappings) => void;
}

const AliasDialog: React.FunctionComponent<AliasDialogProps> = ({
  classes,
  allMappings,
  mappingIndex,
  onAliasUpdate
}) => {
  const [alias, setAlias] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const currentMappings = allMappings[mappingIndex];

  const onALiasChange = (newAlias: string) => {
    let errorMessage = "";
    newAlias = newAlias.trim();
    const newAliasToLower = newAlias.toLowerCase();
    const regex = /[^a-zA-Z0-9]/gim;

    if (regex.test(newAlias)) {
      errorMessage = "A new alias can only contain characters [A-Z] and numbers [0-9].";
    } else if (newAlias !== "" && allMappings
      .some(x => x.augmenterId === currentMappings.augmenterId && x.alias.toLowerCase() === newAliasToLower)) {
      errorMessage = "An 'Additional Data Source' with the same id and alias already exists.";
    }

    setErrorMessage(errorMessage);
    setAlias(newAlias);
  };

  return (
    <>
      <AppGridContainer alignItems="baseline">
        <AppGridItem xs={10} sm={10} md={8}>
          <AppInput
            labelText="Alias"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: alias,
              onChange: (e: any) => onALiasChange(e.target.value),
            }}
          />
        </AppGridItem>
        <AppGridItem xs={2} sm={2} md={2}>
          <AppButton color={CardColor.info} onClick={() => setAlias("")} size={Size.sm}>
            Clear
          </AppButton>
        </AppGridItem>
      </AppGridContainer>
      <AppGridContainer>
        {
          !!errorMessage &&
          <AppGridItem xs={12} sm={12} md={12}>
            <span className={classes.errorMessage}>{errorMessage}</span>
          </AppGridItem>
        }
      </AppGridContainer>
      <br />
      <AppGridContainer justify="flex-end" alignItems="center">
        <AppButton
          color={CardColor.primary}
          onClick={() => onAliasUpdate({ ...currentMappings, alias })}
          size={Size.md}
          disabled={!alias || !!errorMessage}>
          Update
        </AppButton>
        <AppButton color={CardColor.rose} onClick={() => onAliasUpdate()} size={Size.md}>
          Go Back
        </AppButton>
      </AppGridContainer>
    </>
  );
};

export default withStyles(AliasDialogStyles)(AliasDialog);
