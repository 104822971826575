import { createStyles } from "@material-ui/core";

const AppGridContainerStyles = () =>
  createStyles({
    grid: {
      padding: "10px 15px !important",
      maxWidth: "inherit !important"
    },
    flex: {
      display: "flex"
    }
  });

export default AppGridContainerStyles;
