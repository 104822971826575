export const INIT_CANCELLATIONRULE = "CANCELLATIONRULE/INIT_CANCELLATIONRULE";

export const GET_CANCELLATIONRULE = "CANCELLATIONRULE/GET_CANCELLATIONRULE";
export const GET_CANCELLATIONRULE_SUCCESS = "CANCELLATIONRULE/GET_CANCELLATIONRULE_SUCCESS";
export const GET_CANCELLATIONRULE_FAIL = "CANCELLATIONRULE/GET_CANCELLATIONRULE_FAIL";

export const GET_TEMPLATES = "CANCELLATIONRULE/GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "CANCELLATIONRULE/GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAIL = "CANCELLATIONRULE/GET_TEMPLATES_FAIL";

export const SAVE_CANCELLATIONRULE = "CANCELLATIONRULE/SAVE_CANCELLATIONRULE";
export const SAVE_CANCELLATIONRULE_SUCCESS = "CANCELLATIONRULE/SAVE_CANCELLATIONRULE_SUCCESS";
export const SAVE_CANCELLATIONRULE_FAIL = "CANCELLATIONRULE/SAVE_CANCELLATIONRULE_FAIL";

export const TOUCH_CANCELLATIONRULE = "CANCELLATIONRULE/TOUCH_CANCELLATIONRULE";

export const UPDATE_CANCELLATIONRULE = "CANCELLATIONRULE/UPDATE_CANCELLATIONRULE";

export const UPDATE_CONDITION = "CANCELLATIONRULE/UPDATE_CONDITION";