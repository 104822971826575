import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getSentCommsSuccess,
  getSentCommsFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getSentComms,
  downloadSentCommSuccess,
  downloadSentCommFailure,
} from "./actions";
import { GET_SENT_COMMS, UPDATE_TABLE_HEADERS, APPLY_FILTER, DOWNLOAD_SENT_COMM } from "./actionTypes";
import { SentComm, FileType } from "./types";
import { generateUrl, generateUrlWithQueryString, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { generateFilterObject, getSentCommsRequest, getFilter } from "./utils";
import { saveAs } from "file-saver";

function* getSentCommsSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.SentComms, {
      ...action.sentCommsRequest,
      ...filterObject,
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        sentComms: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const sentComms: SentComm[] = yield payload.sentComms.map((i: any) => ({
        id: i.id,
        recipient: i.recipient,
        subject: i.subject,
        sentOn: i.sentOn,
        sent: i.isSuccess,
        downloadUri: i.blobStorageUri,
        fileType: i.templateType,
        attachments: i.attachmentNames,
      }));
      yield put(getSentCommsSuccess(sentComms, payload.totalRecordCount));
    } else {
      const error = yield call([response, "json"]);
      yield put(getSentCommsFailure(error));
    }
  } catch (error) {
    yield put(getSentCommsFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getSentCommsRequest);
  yield put(getSentComms({ ...request, pageRequested: 0 }));
}

function* downloadSentCommSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.DownloadSentComm);

    const {
      blobPath,
      fileType,
      recipient,
      sentOn,
    }: { blobPath: string; fileType: number; recipient: string; sentOn: Date } = action.request;

    const body = {
      blobStoragePath: blobPath,
    };

    const response = yield call(fetchSecure, url, createHttpRequest(body, "POST"));

    if (response.ok) {
      const payload: {
        sentComm: string;
      } = yield call([response, "json"]);
      if (fileType === FileType.HTML) {
        const blob = new Blob([payload.sentComm], { type: "html/plain;charset=utf-8" });
        saveAs(blob, `${recipient} - ${sentOn.toUTCString()}.html`);
      } else {
        const blob = new Blob([payload.sentComm], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${recipient} - ${sentOn.toUTCString()}.txt`);
      }
      yield put(downloadSentCommSuccess(payload.sentComm));
    } else {
      const error = yield call([response, "json"]);
      yield put(downloadSentCommFailure(error));
    }
  } catch (error) {
    yield put(downloadSentCommFailure(error));
  }
}

export function* SentCommsListWatcher() {
  yield takeLatest(GET_SENT_COMMS, getSentCommsSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
  yield takeLatest(DOWNLOAD_SENT_COMM, downloadSentCommSaga);
}
