import Combinator, { CombinatorProps } from './Combinator';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CombinatorStyles from './CombinatorStyles';
import { CombinatorType } from '../../enums';
import { updateCombinator } from "../../store/actions";
import { QueryBuilderActionTypes } from "../../store/actionTypes";
import { QueryBuilderState } from "../../store/reducer";

export const mapStateToProps = (state: QueryBuilderState): Partial<CombinatorProps> => ({
  ...state.combinator,
});

const mapDispatchToProps = (dispatch: Dispatch<QueryBuilderActionTypes>) => ({
  onChange: (value: CombinatorType) => dispatch(updateCombinator(value)),
});

const CombinatorContainer = compose(
  withStyles(CombinatorStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(Combinator) as React.ComponentType<any>;
export default CombinatorContainer;
