export const UPDATE_SNIPPET = "SNIPPET/UPDATE_SNIPPET";

export const UPDATE_EDITOR = "SNIPPET/UPDATE_EDITOR";

export const INIT_SNIPPET = "SNIPPET/INIT_SNIPPET";

export const SAVE_SNIPPET = "SNIPPET/SAVE_SNIPPET";
export const SAVE_SNIPPET_SUCCESS = "SNIPPET/SAVE_SNIPPET_SUCCESS";
export const SAVE_SNIPPET_FAIL = "SNIPPET/SAVE_SNIPPET_FAIL";

export const TOUCH_SNIPPET = "SNIPPET/TOUCH_SNIPPET";

export const GET_SNIPPET = "SNIPPET/GET_SNIPPET";
export const GET_SNIPPET_SUCCESS = "SNIPPET/GET_SNIPPET_SUCCESS";
export const GET_SNIPPET_FAIL = "SNIPPET/GET_SNIPPET_FAIL";
