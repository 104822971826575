/* eslint-disable react/display-name */
import React from "react";
import { Permission, ViewPermission } from "./role";
import { store } from "./app";
import { RootState } from "./store/rootReducer";
import UnauthorizedContainer from "./views/unauthorized/unauthorizedContainer";
import { View } from "./enums";

export const withAuthorization = (viewName: View) => {
  return (WrappedComponent: React.FunctionComponent<any>) => {
    return (props: any) => {
      const state: RootState = store.getState();
      const role: Permission = state.shared.account.permission;
      const permission: ViewPermission = role[viewName];
      if (permission?.read) {
        return <WrappedComponent permission={permission} {...props} />;
      }
      return <UnauthorizedContainer />;
    };
  };
};
