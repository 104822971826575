import { put, takeLatest, call } from "redux-saga/effects";
import { generateUrl, generateUrlWithQueryString, createHttpRequest } from "../../../utilities/httpUtils";
import { NUMBER_MAX_VALUE } from "../../../config";
import { EndpointRoute, AugmenterOwner } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import {
  GET_AUGMENTERS,
  Augmenter,
  getAugmentersSuccess,
  getAugmentersFailure,
  GET_TRIGGERS,
  TriggerDetails,
  getTriggersSuccess,
  getTriggersFailure,
  EditorImage,
  UPLOAD_IMAGE,
  uploadImageSuccess,
  uploadImageFailure,
  Dictionary,
  getTenantsSuccess,
  getTenantsFailure,
  GET_TENANTS
} from ".";
import { Tenant, TriggerDetailsPayload } from "./types";
import { remapAugmenterMappings } from "./utils";

function* getAugmentersSaga() {
  try {
    const url = generateUrl(EndpointRoute.Augmenters);
    const response = yield call(fetchSecure, url);
    const payload: { augmenters: Augmenter[] } = yield call([response, "json"]);

    const augmenters = payload.augmenters.reduce<Dictionary<Augmenter>>((obj, cur) => {
      obj[cur.id] = cur;
      return obj;
    }, {});

    yield put(getAugmentersSuccess(augmenters));
  } catch (error) {
    yield put(getAugmentersFailure(error));
  }
}

function* getTriggersSaga() {
  try {
    const url = generateUrlWithQueryString(EndpointRoute.Triggers, {
      itemsPerPage: NUMBER_MAX_VALUE,
      pageRequested: 0,
      sortAscending: true,
      sortField: 1,
      includeFields: true,
    });
    const response = yield call(fetchSecure, url);
    const payload: { triggers: TriggerDetailsPayload[] } = yield call([response, "json"]);

    const triggers = payload.triggers.reduce<Dictionary<TriggerDetails>>((obj, cur) => {
      obj[cur.id] = {
        id: cur.id,
        name: cur.name,
        active: !cur.disabled,
        fields: cur.fields,
        augmenterMappings: remapAugmenterMappings(AugmenterOwner.Trigger, cur.augmenterMappings),
      };
      return obj;
    }, {});

    yield put(getTriggersSuccess(triggers));
  } catch (error) {
    yield put(getTriggersFailure(error));
  }
}

function* uploadImageSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.UploadImage);

    const { image }: { image: EditorImage } = action;

    const body = {
      name: image.name,
      imageData: image.imageData,
    };

    const response = yield call(fetchSecure, url, createHttpRequest(body, "POST"));

    if (response.ok) {
      const payload: { imageUri: string } = yield call([response, "json"]);
      if (image.promiseResolver?.resolve) {
        image.promiseResolver.resolve({
          data: { link: payload.imageUri },
        });
      }
      yield put(
        uploadImageSuccess({
          ...image,
          imageData: payload.imageUri,
        })
      );
    } else {
      const error = yield call([response, "json"]);
      if (image.promiseResolver?.reject) {
        image.promiseResolver.reject(error);
      }
      yield put(uploadImageFailure(error));
    }
  } catch (error) {
    yield put(uploadImageFailure(error));
  }
}

function* getTenantsSaga() {
  try {
    const url = generateUrl(EndpointRoute.Tenants);
    const response = yield call(fetchSecure, url);
    const payload: { tenantId: string; name: string }[] = yield call([response, "json"]);
    const tenants: Tenant[] = payload.map((i: any) => ({
      id: i.tenantId,
      name: i.name
    }));

    yield put(getTenantsSuccess(tenants));
  } catch (error) {
    yield put(getTenantsFailure(error));
  }
}

export function* sharedWatcher() {
  yield takeLatest(GET_AUGMENTERS, getAugmentersSaga);
  yield takeLatest(GET_TRIGGERS, getTriggersSaga);
  yield takeLatest(UPLOAD_IMAGE, uploadImageSaga);
  yield takeLatest(GET_TENANTS, getTenantsSaga);
}
