import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { APPINSIGHTS_INSTRUMENTATIONKEY } from "./config";

const browserHistory = createBrowserHistory({ });
export const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

ai.loadAppInsights();

export const appInsights = ai.appInsights;

export default (component: any) => withAITracking(reactPlugin, component);
