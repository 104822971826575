import { createStyles } from "@material-ui/core";

const AppCopyrightStyles = () =>
  createStyles({
    footerText: {
      padding: "10px"
    }
  });

export default AppCopyrightStyles;
