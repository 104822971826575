import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppChip } from "../../../components";
import SentCommsChipStyles from "./sentCommsChipStyle";
import { RouterName } from "../../../enums";

interface SentCommsChipProps {
  history: any;
  scheduledCommId?: string;
}

const CustomChip: React.FunctionComponent<SentCommsChipProps> = ({ scheduledCommId, history }) => {

  const removeSpecificCommFilter = () => {
    history.push(`${RouterName.SentComms}`);
  };

  return scheduledCommId ?
    (
      <AppChip
        clickable={false}
        color={"primary"}
        onDelete={removeSpecificCommFilter}
        label={`You are currently viewing the emails sent out for Scheduled Comm ${scheduledCommId}. To remove this filter, close this dialogue box`}
        size={"medium"}
      />
    )
    :
    <></>;
};

export const SentCommsChip = withStyles(SentCommsChipStyles)(CustomChip);