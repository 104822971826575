import CancellationRuleCondition, { CancellationRuleConditionProps } from "./cancellationRuleCondition";
import CancellationRuleEditorStyles from "../cancellationRuleEditorStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/rootReducer";
import { updateCondition } from "../../../store/areas/cancellationRule";
import { Condition } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";
import { configureStore } from "@comms/react-query-builder/src";

const store = configureStore();

const mapStateToProps = (state: RootState): Partial<CancellationRuleConditionProps> => ({
  fields: state.cancellationRule.cancellationRule?.triggerFields,
  templateFields: state.cancellationRule.cancellationRule?.templateFields,
  condition: state.cancellationRule.cancellationRule?.condition,
  hasMultipleTemplates: state.cancellationRule.cancellationRule.hasMultipleTemplates,
  store,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCondition: (condition: Condition) => dispatch(updateCondition(condition)),
});

const CancellationRuleConditionContainer = compose(
  withStyles(CancellationRuleEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.CancellationRule)
)(CancellationRuleCondition) as React.ComponentType<any>;
export default CancellationRuleConditionContainer;
