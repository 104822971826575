import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon } from "@material-ui/core";
import AppListStyles from "./appListStyle";
import { ListData, ListItemAction } from "./types";

export interface AppListProps {
  data: ListData[];
  secondaryAction?: ListItemAction;
}

const Item: React.FunctionComponent<AppListProps> = ({ data, secondaryAction }: AppListProps) => {
  const handleSecondaryAction = (item: ListData) => {
    if (secondaryAction?.onClick) {
      return secondaryAction.onClick(item);
    }
  };

  return (
    <List>
      {data.map(item => (
        <ListItem key={item.id}>
          {item.icon && <ListItemIcon className={item.icon.style}>{item.icon.name}</ListItemIcon>}
          <ListItemText primary={item.title} secondary={item.subTitle} />
          {secondaryAction && (
            <ListItemSecondaryAction onClick={() => handleSecondaryAction(item)}>
              <IconButton className={secondaryAction.icon.style} edge="end">
                {secondaryAction.icon.name}
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export const AppList = withStyles(AppListStyles)(Item);
