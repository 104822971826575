import TemplatesList, { TemplatesListProps } from "./templatesList";
import { connect } from "react-redux";
import { compose } from "redux";
import templatesListStyle from "./templatesListStyles";
import { RootState } from "../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (state: RootState): Partial<TemplatesListProps> => ({
  selectedTemplates: state.templatesList.selectedTemplates
});

const mapDispatchToProps = () => ({});

const TemplatesListContainer = compose(
  withStyles(templatesListStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Template)
)(TemplatesList) as React.ComponentType<any>;

export default TemplatesListContainer;
