import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor
} from "../../utilities/colors";
import defaultFont from "../../utilities/font";
import { createStyles } from "@material-ui/core";

const TemplateEditorStyles = () =>
  createStyles({
    container: {
      border: "2px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: 399,
      padding: 10,
      boxShadow: `2px 3px ${primaryColor[0]}`
    },
    editor: { maxHeight: "395px", overflowY: "auto" },
    toolbar: {
      width: "100%",
      background: "red"
    },
    button: {
      margin: 8
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0px"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    labelRoot: {
      ...defaultFont,
      color: grayColor[1] + " !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset"
    },
    labelRootError: {
      color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
      color: successColor[0] + " !important"
    },
    feedback: {
      position: "absolute",
      right: "55px",
      zIndex: 2,
      display: "block",
      width: "24px",
      height: "24px",
      textAlign: "center",
      pointerEvents: "none"
    },
    defaultEditorWrapper: {
      width: "100%",
      display: "block",
      marginBottom: "25px",
      height: "400px",
      border: "2px solid black",
      boxShadow: "2px 3px #00348d",
    },
    defaultEditorWrapperError: {
      borderColor: dangerColor[0] + " !important",
      boxShadow: `2px 3px ${dangerColor[0]} !important`
    },
    defaultEditorContainer: {
      height: 300,
      padding: "5px 15px",
      borderRadius: "2px",
      "& .DraftEditor-root": {
        height: "95%"
      }
    }
  });

export default TemplateEditorStyles;
