export const GET_TEMPLATES = "TEMPLATES_LIST/GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "TEMPLATES_LIST/GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAIL = "TEMPLATES_LIST/GET_TEMPLATES_FAIL";

export const UPDATE_TABLE_HEADERS = "TEMPLATES_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "TEMPLATES_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "TEMPLATES_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const SELECT_TEMPLATE = "TEMPLATES_LIST/SELECT_TEMPLATE";
export const DESELECT_TEMPLATE = "TEMPLATES_LIST/DESELECT_TEMPLATE";

export const UPDATE_FILTER = "TEMPLATES_LIST/UPDATE_FILTER";
export const APPLY_FILTER = "TEMPLATES_LIST/APPLY_FILTER";