import React, { useEffect } from "react";
import {
  AppGridContainer,
  AppGridItem,
  AppCard,
  AppCardHeader,
  AppCardBody,
  AppCardFooter,
  AppButton,
  AppInput,
  AppTemplateEditor,
  AppSwitch,
  AppTemplateEditorOnChangeArgs,
} from "../../components";
import { CardColor, Size, RouterName, BasicColor } from "../../enums";
import { Snippet, Touched } from "../../store/areas/snippet";
import { EditorImage } from "../../store/areas/shared";
import { ViewPermission } from "../../role";

export interface SnippetEditorProps {
  classes: any;
  snippet: Snippet;
  saved: boolean;
  history: any;
  match: any;
  touched: Touched;
  editorImage: EditorImage;
  permission: ViewPermission;
  tenantId: string;
  initSnippetPage: () => void;
  updateSnippet: (snippet: Snippet) => void;
  saveSnippet: (snippet: Snippet) => void;
  saveSnippetFromExisting: (snippet: Snippet) => void;
  getSnippet: (snippetId: string) => void;
  touchSnippet: (touched: Touched) => void;
  resetState: () => void;
  updateEditor: (onChangeArgs: AppTemplateEditorOnChangeArgs) => void;
  openDialog: () => void;
  closeDialog: () => void;
  uploadImage: (image: EditorImage) => void;
}

const validSnippet = (snippet: Snippet) => {
  return snippet.name && snippet.bodyHtml && snippet.rawBody;
};

const SnippetEditor: React.FunctionComponent<SnippetEditorProps> = ({
  classes,
  initSnippetPage,
  snippet,
  updateSnippet,
  updateEditor,
  saveSnippet,
  saved,
  history,
  match,
  touched,
  touchSnippet,
  resetState,
  getSnippet,
  editorImage,
  uploadImage,
  permission,
  tenantId
}) => {
  useEffect(() => {
    initSnippetPage();
  }, [initSnippetPage]);

  useEffect(() => {
    const snippetId = match.params.snippetId ?? match.params.existingSnippetId;
    if (snippetId) {
      getSnippet(snippetId);
    }
  }, [getSnippet, match, tenantId]);

  useEffect(() => {
    if (saved) {
      resetState();
      history.push(RouterName.Snippets);
    }
  }, [saved, history, resetState]);

  const saveHandler = () => {
    if (validSnippet(snippet)) {
      saveSnippet({ ...snippet, id: match.params.snippetId });
    }

    touchSnippet({
      ...touched,
      name: true,
      body: true,
      isDisabled: true,
    });
  };

  const uploadImageHandler = (file: File, snippet: Snippet) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        uploadImage({
          ...editorImage,
          name: file.name,
          imageData: reader.result,
          promiseResolver: { resolve, reject },
        });
      };
    });
    return promise;
  };

  const getSnippetText = () =>
    !permission.write
      ? "View Snippet"
      : match.params.snippetId
        ? "Edit Snippet"
        : match.params.existingSnippetId
          ? "Create From Existing Snippet"
          : "New Snippet";

  const bodyError = touched.body && !snippet.bodyHtml;
  const bodyHeaderClasses = `${classes.editorHeader} ${bodyError ? classes.editorHeaderError : ""}`;

  const isIE = /* @cc_on!@*/ false || !!(document as any).documentMode;

  return (
    <AppGridContainer>
      <AppGridItem xs={12} sm={12} md={12}>
        <AppCard>
          <AppCardHeader color={CardColor.primary}>
            <h4 className={classes.cardTitleWhite}>{getSnippetText()}</h4>
            {permission.write && <p className={classes.cardCategoryWhite}>Complete all required fields</p>}
          </AppCardHeader>
          <AppCardBody>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={4}>
                <AppInput
                  touched={touched.name}
                  onTouch={() => touchSnippet({ ...touched, name: true })}
                  labelText="Snippet Name"
                  id="snippet-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: snippet.name,
                    onChange: (e: any) => updateSnippet({ ...snippet, name: e.target.value }),
                    disabled: !!match.params.snippetId || !permission.write,
                  }}
                  error={touched.name && !snippet.name}
                />
              </AppGridItem>
              <AppGridItem xs={12} sm={6} md={4} flex={true}>
                <AppSwitch
                  formControlProps={{ label: "Active" }}
                  color={BasicColor.primary}
                  onChange={(e: any) => {
                    updateSnippet({
                      ...snippet,
                      active: e.target.checked,
                    });
                  }}
                  value={snippet.active}
                  inputProps={{
                    disabled: !permission.write,
                  }}
                />
              </AppGridItem>
            </AppGridContainer>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <p className={bodyHeaderClasses}>Body</p>
                <AppTemplateEditor
                  id="snippet-body"
                  wrapperId={3000}
                  rawContent={snippet.rawBody}
                  onChange={(e: AppTemplateEditorOnChangeArgs) => updateEditor(e)}
                  editorProps={{
                    handlePastedText: isIE ? undefined : () => false,
                  }}
                  error={bodyError}
                  onTouch={() => touchSnippet({ ...touched, body: true })}
                  fields={[]}
                  readOnly={!permission.write}
                  disableToolbar={!permission.write}
                  uploadImage={(file: File) => uploadImageHandler(file, snippet)}
                  key={snippet.id}
                />
              </AppGridItem>
            </AppGridContainer>
          </AppCardBody>
          {permission.write && (
            <AppCardFooter>
              <AppGridContainer justify="flex-end" alignItems="center">
                <AppButton color={CardColor.primary} size={Size.lg} onClick={saveHandler} id="snippet-save">
                  Save
                </AppButton>
              </AppGridContainer>
            </AppCardFooter>
          )}
        </AppCard>
      </AppGridItem>
    </AppGridContainer>
  );
};

export default SnippetEditor;
