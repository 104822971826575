import { Reducer } from "redux";
import { OPEN_DIALOG, CLOSE_DIALOG, ScheduledCommsEditRequestType } from ".";
import { Dialog } from "../shared";

export interface ScheduledCommsEditState {
  requestType?: ScheduledCommsEditRequestType;
  dialog: Dialog;
}

const initialState: ScheduledCommsEditState = {
  requestType: undefined,
  dialog: { open: false }
};
export const ScheduledCommsEditReducer: Reducer<ScheduledCommsEditState> = (
  state: ScheduledCommsEditState = initialState,
  action: any
) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...initialState,
        requestType: action.requestType,
        dialog: { ...state.dialog, open: true }
      };
    case CLOSE_DIALOG:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default ScheduledCommsEditReducer;
