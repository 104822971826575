import { createStyles, Theme } from "@material-ui/core";
import { drawerWidth } from "../../views/layout/constants";

const AppDrawerStyles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7)
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",

      ...theme.mixins.toolbar
    },
    linkText: {
      textDecoration: "none"
    },
    listItem: {
      minWidth: 46
    }
  });

export default AppDrawerStyles;
