import React from "react";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppInputStyles from "./AppInputStyle";
import NumberFormat, { NumberFormatValues } from "react-number-format";

export interface AppInputProps {
  labelText?: string;
  labelProps?: object;
  id?: string;
  onChange?: (value: any) => void;
  formControlProps?: any;
  isNumeric?: boolean;
  value: string | number;
  maxLength?: number;
  disabled?: boolean;
  disableValidation?: boolean;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string | number } }) => void;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isNumericString
    />
  );
}

const Item: React.FunctionComponent<AppInputProps> = ({
  labelText,
  labelProps,
  id,
  formControlProps,
  isNumeric,
  onChange,
  value,
  maxLength,
  disabled,
  disableValidation
}: AppInputProps) => {
  const error = !value && !disableValidation;
  const success = !!value && !disableValidation;

  const classes = AppInputStyles();
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const className = formControlProps?.className
    ? `${formControlProps?.className} ${classes.formControl}`
    : `${classes.formControl}`;
  return (
    <FormControl error={error} {...formControlProps} className={className}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        disabled={disabled}
        id={id}
        inputProps={{ "data-testid": id, value: value || "", maxLength }}
        inputComponent={isNumeric && (NumberFormatCustom as any)}
        onChange={(e: any) =>
          onChange &&
          onChange(
            isNumeric
              ? e.target.value === ""
                ? undefined
                : +e.target.value
              : e.target.value?.trim() === ""
              ? undefined
              : e.target.value
          )
        }
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

export const AppInput = Item;
