import React from "react";
import { AppCardHeader, AppCardBody, AppCard } from "../../../components";
import { CardColor } from "../../../enums";
import QueryBuilder from "@comms/react-query-builder";
import { Field, Condition, transformConfigField } from "../../../store/areas/shared";
import { ViewPermission } from "../../../role";

export interface SchedulingRuleConditionProps {
  classes: any;
  fields?: Field[];
  condition: Condition;
  permission: ViewPermission;
  title: string;
  updateCondition: (condition: Condition) => void;
  store: any;
}

const SchedulingRuleCondition: React.FunctionComponent<SchedulingRuleConditionProps> = ({
  classes,
  fields,
  condition,
  permission,
  title,
  updateCondition,
  store,
}) => {
  const handleUpdate = (newCondition: Condition) => {
    updateCondition(newCondition);
  };

  const builderFields = transformConfigField(fields || []);
  const builderKey = Object.keys(builderFields).join();

  return (
    <AppCard stats="true">
      <AppCardHeader color={CardColor.primary}>
        <h4 className={classes.cardTitleWhite}>{title}</h4>
      </AppCardHeader>
      <AppCardBody>
        <div style={{ pointerEvents: permission.write ? "inherit" : "none", opacity: permission.write ? 1 : 0.7 }}>
          <QueryBuilder
            key={builderKey}
            fields={builderFields}
            rules={condition?.rawState?.rules}
            combinator={condition?.rawState?.combinator}
            onChange={(rules: any, combinator: any, logic: any) => handleUpdate({ rawState: { rules, combinator }, logic })}
            store={store}
          />
        </div>
      </AppCardBody>
    </AppCard>
  );
};

export default SchedulingRuleCondition;
