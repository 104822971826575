import TenantRequired, { TenantRequiredProps } from "./tenantRequired";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import TenantRequiredStyles from "./tenantRequiredStyles";
import { RootState } from "../../store/rootReducer";
import { connect } from "react-redux";
import { tenantIsSet, failedToGetTenants } from "../../store/areas/shared";

const mapStateToProps = (state: RootState): Partial<TenantRequiredProps> => ({
  tenantIsSet: tenantIsSet(state),
  failedToGetTenants: failedToGetTenants(state)
});

const TenantRequiredContainer = compose(
  withStyles(TenantRequiredStyles),
  connect(mapStateToProps))(TenantRequired) as React.ComponentType<any>;

export default TenantRequiredContainer;
