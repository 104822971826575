import TemplatesFilter, { TemplatesFilterProps } from "./templatesFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import templatesListStyle from "../templatesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { Filter, updateFilter, applyFilter } from "../../../store/areas/templatesList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<TemplatesFilterProps> => ({
  triggers: state.shared.triggers,
  statuses: state.templatesList.status,
  filter: state.templatesList.filter,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
  initFilter: () => {
    dispatch(applyFilter());
  },
});

const TemplatesFilterContainer = compose(
  withStyles(templatesListStyle),
  withTenant,
  connect(mapStateToProps, mapDispatchToProps)
)(TemplatesFilter) as React.ComponentType<any>;

export default TemplatesFilterContainer;
