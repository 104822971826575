import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
  ContentBlock,
  genKey,
  ContentState
} from "draft-js";
import { withStyles } from "@material-ui/styles";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toolbarOptions } from "./values";
import {
  parseStateToHTML,
  parseStateToText,
  createPlaceholderList,
  NEWLINE_REGEX,
  condenseBlocks,
  replaceNewlines
} from "./utils";
import { AppTemplateEditorOnChangeArgs, Suggestion } from "./types";
import { Field } from "../../store/areas/shared";
import SuggestionDecorator from "./suggestionDecorator";
import TemplateEditorStyles from "./appTemplateEditorStyle";
import "./styles.css";

export interface TemplateEditorProps {
  editorProps?: any;
  onChange: (e: AppTemplateEditorOnChangeArgs) => void;
  rawContent: RawDraftContentState;
  classes: any;
  touched?: boolean;
  onTouch?: () => void;
  error?: boolean;
  success?: boolean;
  id?: string;
  fields: Field[];
  disableToolbar?: boolean;
  readOnly?: boolean;
  singleLine?: boolean;
  wrapperId: number;
  uploadImage?: (file: File) => Promise<unknown>;
  snippets?: Suggestion[];
  aliasUpdate?: { old: string; new: string }[];
}

interface TemplateEditorState {
  lastAliasUpdateId: string;
  aliasHistory: string[];
  suggestions: Suggestion[];
  editorState: EditorState;
  loaded: boolean;
}

class AppEditor extends Component<TemplateEditorProps, TemplateEditorState> {
  private editor: EditorComponent | null = null;

  state: TemplateEditorState = {
    lastAliasUpdateId: "",
    aliasHistory: [],
    suggestions: createPlaceholderList(this.props.fields),
    editorState: EditorState.createEmpty(),
    loaded: false
  };

  onChangeHandler = (editorState: EditorState) => {
    if (this.props.singleLine) {
      editorState = this.amendStateToSingleLine(editorState);
    }
    const text = parseStateToText(editorState);
    const html = parseStateToHTML(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    this.setState({ editorState });
    this.props.onChange({ html, text, rawContent });
  };

  componentDidMount() {
    const newState = this.checkState();

    if (Object.keys(newState).length) {
      this.setState(newState as TemplateEditorState);
    }
  }

  componentDidUpdate(prevProps: TemplateEditorProps) {
    const newState = this.checkState(prevProps);

    if (prevProps.fields !== this.props.fields) {
      newState.suggestions = createPlaceholderList(this.props.fields);
    }

    if (Object.keys(newState).length) {
      this.setState(newState as TemplateEditorState);
    }
  }

  private checkState(prevProps?: TemplateEditorProps): Partial<TemplateEditorState> {
    const newState: Partial<TemplateEditorState> = {};

    if (prevProps?.aliasUpdate !== this.props.aliasUpdate && !!this.props.aliasUpdate?.length) {
      const editorState = newState.editorState ?? this.state.editorState;
      const contentState = ContentState.createFromText("");

      newState.editorState = EditorState.push(editorState, contentState, "remove-range");
    }

    if (!this.state.loaded) {
      const content = convertFromRaw(this.props.rawContent);
      const currentContentText = this.state.editorState
        .getCurrentContent()
        .getPlainText();

      // if current content is empty && (raw content has value)
      if (currentContentText === "" && (this.props.rawContent?.blocks[0].text !== "" || this.props.rawContent?.entityMap[0])) {
        newState.loaded = true;
        newState.editorState = EditorState.createWithContent(content);
      }
    }

    return newState;
  }

  amendStateToSingleLine(editorState: EditorState) {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();

    // If we have more than one block, compress them
    if (blocks.length > 1) {
      editorState = condenseBlocks(editorState, blocks);
    } else {
      // We only have one content block
      let contentBlock = blocks[0];
      let text = contentBlock.getText();
      const characterList = contentBlock.getCharacterList();

      if (NEWLINE_REGEX.test(text)) {
        // Replace the text stripped of its newlines. Note that we replace
        // one '\n' with one ' ' so we don't need to modify the characterList
        text = replaceNewlines(text);

        // Create a new content block based on the old one
        contentBlock = new ContentBlock({
          key: genKey(),
          text,
          type: "unstyled",
          characterList,
          depth: 0
        });

        // Update the editor state with the compressed version
        // const selection = editorState.getSelection()
        const newContentState = ContentState.createFromBlockArray([
          contentBlock
        ]);

        // Create the new state as an undoable action
        editorState = EditorState.push(
          editorState,
          newContentState,
          "insert-characters"
        );
      }
    }

    return editorState;
  }

  getWrapperRef() {
    return this.editor?.wrapper ?? null;
  }

  setWrapperRef(editor: EditorComponent) {
    this.editor = editor;
  }

  getEditorState = () => this.state?.editorState ?? null;

  getSnippetSuggestions = () => this.props.snippets ?? [];

  getPlaceholderSuggestions = () => this.state.suggestions ?? [];

  suggestionOnChange(editorState: EditorState) {
    // eslint-disable-next-line no-unused-expressions
    this.editor?.onChange(editorState);
  }

  render() {
    const {
      classes,
      disableToolbar,
      readOnly,
      singleLine,
      wrapperId,
      uploadImage,
      error
    } = this.props;
    let { editorProps } = this.props;
    const { editorState } = this.state;

    if (singleLine === true) {
      editorProps = { ...editorProps, handleReturn: () => true };
    }

    let wrapperClasses = editorProps?.wrapperClass ?? classes.defaultEditorWrapper;
    if (error) {
      wrapperClasses += ` ${classes.defaultEditorWrapperError}`;
    }

    return (
      <Editor
        ref={this.setWrapperRef.bind(this)}
        {...editorProps}
        customDecorators={SuggestionDecorator(
          [{
            separator: " ",
            trigger: "#",
            typeName: "SNIPPET",
            prefix: "{",
            suffix: "}#",
            onChange: this.suggestionOnChange.bind(this),
            getEditorState: this.getEditorState.bind(this),
            getSuggestions: this.getSnippetSuggestions.bind(this),
            getWrapperRef: this.getWrapperRef.bind(this)
          },
          {
            separator: " ",
            trigger: "@",
            typeName: "PLACEHOLDER",
            prefix: "{{",
            suffix: "}}",
            onChange: this.suggestionOnChange.bind(this),
            getEditorState: this.getEditorState.bind(this),
            getSuggestions: this.getPlaceholderSuggestions.bind(this),
            getWrapperRef: this.getWrapperRef.bind(this)
          }]
        )}
        editorState={editorState}
        wrapperId={wrapperId}
        wrapperClassName={wrapperClasses}
        editorClassName={editorProps?.editorClass ?? classes.defaultEditorContainer}
        onEditorStateChange={this.onChangeHandler}
        toolbar={{
          options: toolbarOptions,
          image: {
            urlEnabled: false,
            previewImage: true,
            uploadCallback: uploadImage,
            alt: { present: true, mandatory: true }
          }
        }}
        toolbarHidden={disableToolbar ?? false}
        readOnly={readOnly ?? false}
      />
    );
  }
}

export const AppTemplateEditor = withStyles(TemplateEditorStyles)(AppEditor);

// If needed include more properties from https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/Editor/index.js
interface EditorComponent extends Editor {
  wrapper: HTMLElement;
  onChange: (editorState: EditorState) => void;
}
