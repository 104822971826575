import { compose } from "redux";
import { connect } from "react-redux";
import JsonLogicViewer, { JsonViewerProps } from "./JsonLogicViewer";
import { QueryBuilderState } from "../../store";

export const mapStateToProps = (state: QueryBuilderState): Partial<JsonViewerProps> => ({
  rules: state.rules,
  combinator: state.combinator.value
});

const JsonLogicViewerContainer = compose(connect(mapStateToProps, null))(
  JsonLogicViewer
) as React.ComponentType<any>;
export default JsonLogicViewerContainer;
