import { AnyAction } from "redux";
import {
  GET_SNIPPETS,
  GET_SNIPPETS_SUCCESS,
  GET_SNIPPETS_FAIL,
  SELECT_SNIPPET,
  DESELECT_SNIPPET,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_TABLE_HEADERS,
  UPDATE_TABLE_HEADERS_FAIL,
  UPDATE_FILTER,
  APPLY_FILTER
} from "./actionTypes";
import { Snippet, SnippetsRequest, Filter } from "./types";
import { SortOrder } from "../../../enums";
import { UpdateTableHeadersObject } from "../shared";

export const getSnippets = (snippetsRequest: SnippetsRequest): AnyAction => {
  return {
    type: GET_SNIPPETS,
    snippetsRequest
  };
};

export const getSnippetsSuccess = (snippets: Snippet[], totalRows: number): AnyAction => {
  return {
    type: GET_SNIPPETS_SUCCESS,
    snippets,
    totalRows
  };
};

export const getSnippetsFailure = (error: Error): AnyAction => {
  return {
    type: GET_SNIPPETS_FAIL,
    message: error.message || "Unable to get list of snippets",
    error
  };
};

export const updateTableHeaders = (updateTableHeadersObject: UpdateTableHeadersObject) => {
  const { columnIndex, sortOrder } = updateTableHeadersObject;
  return {
    type: UPDATE_TABLE_HEADERS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersSuccess = (columnIndex: number, sortOrder: SortOrder) => {
  return {
    type: UPDATE_TABLE_HEADERS_SUCCESS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersFailure = (error: Error): AnyAction => {
  return {
    type: UPDATE_TABLE_HEADERS_FAIL,
    message: error.message || "Error rendering table headers. Please refresh and try again",
    error
  };
};

export const updateFilter = (filter: Filter): AnyAction => {
  return {
    type: UPDATE_FILTER,
    filter
  };
};

export const applyFilter = (): AnyAction => ({ type: APPLY_FILTER });

export const selectSnippet = (snippet: Snippet, dataIndex: number): AnyAction => {
  return {
    type: SELECT_SNIPPET,
    selectedSnippet: snippet,
    selectedDataIndex: dataIndex
  };
};

export const deselectSnippet = (snippet: Snippet, dataIndex: number): AnyAction => {
  return {
    type: DESELECT_SNIPPET,
    selectedSnippet: snippet,
    selectedDataIndex: dataIndex
  };
};