import { createStyles } from "@material-ui/core";

const ScheduledCommsListStyles = () => createStyles({
  tableCell: {
    paddingTop: 6,
    paddingBottom: 6
  },
  payloadPropertiesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  payloadProperty: {
    margin: "4px 8px",
    flexBasis: "30%",
  },
  payloadPropertyKey: {
    fontWeight: 600
  },
  payloadPropertyValue: {
    color: "#333"
  },
  buttonContainer: {
    marginTop: "25px"
  },
  activeButtonContainer: {
    justifyContent: "flex-end"
  }
});

export default ScheduledCommsListStyles;
