import TemplatePreview, { TemplatePreviewProps } from "./templatePreview";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  touchTemplatePreview,
  updateEmail,
  Touched,
  closeDialog
} from "../../store/areas/templatePreview";
import TemplatePreviewStyles from "./templatePreviewStyles";

const mapStateToProps = (state: RootState): Partial<TemplatePreviewProps> => ({
  email: state.templatePreview.email,
  userName: state.shared.account.userName,
  templateId: state.templatePreview.templateId,
  touched: state.templatePreview.touched,
  dialog: state.templatePreview.dialog
});

const mapDispatchToProps = (dispatch: any) => ({
  onTouch: (touched: Touched) => dispatch(touchTemplatePreview(touched)),
  updateEmail: (email: string) => dispatch(updateEmail(email)),
  closeDialog: () => dispatch(closeDialog())
});

const TemplatePreviewContainer = compose(
  withStyles(TemplatePreviewStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplatePreview) as React.ComponentType<any>;
export default TemplatePreviewContainer;
