import * as React from "react";
import { SharedProps } from "../shared";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FieldType } from "../../enums";
import { getOperatorByFieldType, operators } from "../../config";
import { OperatorType, Field } from "../../store";

export interface OperatorProps extends SharedProps {
  fieldType: FieldType;
  extraFields: {
    [name: string]: Field;
  };
  onChange: (newValue: string, requiredValues: number) => void;
  value: string;
}

const Operator: React.FC<OperatorProps> = ({
  classes,
  extraFields,
  fieldType,
  onChange,
  value,
}: OperatorProps) => {
  const filteredOperator = getOperatorByFieldType(fieldType, Object.values(extraFields) || []);
  return (
    <FormControl className={`${classes.formControl} ${classes.operatorControl}`}>
      <Select
        value={value}
        onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
          const newValue = e.target?.value as string;
          if (newValue && operators[newValue]) {
            onChange(newValue, operators[newValue].requiredValues);
          }
        }}
        displayEmpty
      >
        {filteredOperator.map((o: OperatorType) => (
          <MenuItem key={o.name} value={o.name}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Operator;
