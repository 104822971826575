import SnippetEditor, { SnippetEditorProps } from "./snippetEditor";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  Touched,
  updateEditor,
  Snippet,
  saveSnippet,
  initSnippet,
  updateSnippet,
  touchSnippet,
  getSnippet,
} from "../../store/areas/snippet";
import SnippetEditorStyles from "./snippetEditorStyles";
import { AppTemplateEditorOnChangeArgs } from "../../components";
import { uploadImage, EditorImage } from "../../store/areas/shared";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";
import { withTenant } from "../../withTenant";

const mapStateToProps = (state: RootState): Partial<SnippetEditorProps> => ({
  snippet: state.snippet.snippet,
  saved: state.snippet.saved,
  touched: state.snippet.touched,
  editorImage: state.shared.editorImage,
});

const mapDispatchToProps = (dispatch: any) => ({
  initSnippetPage: () => {
    dispatch(initSnippet());
  },
  updateSnippet: (snippet: Snippet) => dispatch(updateSnippet(snippet)),
  saveSnippet: (snippet: Snippet) => dispatch(saveSnippet(snippet)),
  touchSnippet: (touched: Touched) => dispatch(touchSnippet(touched)),
  getSnippet: (snippetId: string) => dispatch(getSnippet(snippetId)),
  resetState: () => dispatch(initSnippet()),
  updateEditor: (onChangeArgs: AppTemplateEditorOnChangeArgs) => dispatch(updateEditor(onChangeArgs)),
  uploadImage: (image: EditorImage) => dispatch(uploadImage(image)),
});

const SnippetEditorContainer = compose(
  withStyles(SnippetEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTenant,
  withAuthorization(View.Snippet)
)(SnippetEditor) as React.ComponentType<any>;
export default SnippetEditorContainer;
