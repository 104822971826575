import { put, takeLatest, call } from "redux-saga/effects";
import {
  remapPayload,
  Snippet,
  getSnippetSuccess,
  getSnippetFailure,
  saveSnippetFailure,
  saveSnippetSuccess,
} from ".";
import { generateUrl, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { SAVE_SNIPPET, GET_SNIPPET } from "./actionTypes";

function* saveSnippetSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.Snippets);
    const { snippet }: { snippet: Snippet } = action;

    // Removes the <body> and <table> tags which are generated when the snippet is new or reset
    let bodyHtml = /^(<body.*?><table.*?>)?((.|\n)*?)(<\/table><\/body>)?$/gi.exec(snippet.bodyHtml)?.slice(2, 3)[0];
    if (!!bodyHtml) {
      if (bodyHtml.endsWith("\n")) {
        bodyHtml = bodyHtml.substr(0, bodyHtml.length - 1);
      }

      // If the snippet is a single line, then remove the surrounding <p> tag
      if (!bodyHtml.includes("\n")) {
        bodyHtml = /^(<p.*?>)?(.*?)(<\/p>)?$/gi.exec(bodyHtml)?.slice(2, 3)[0];
      }
    }

    const body = {
      id: snippet.id,
      name: snippet.name,
      bodyHtml: bodyHtml ?? "",
      bodyText: snippet.bodyText,
      rawBody: JSON.stringify(snippet.rawBody),
      disabled: !snippet.active,
    };
    const response = yield call(fetchSecure, url, createHttpRequest(body, snippet.id ? "PUT" : "POST"));
    if (response.ok) {
      yield put(saveSnippetSuccess("Snippet saved"));
    } else {
      const error = yield call([response, "json"]);
      yield put(saveSnippetFailure(error));
    }
  } catch (error) {
    yield put(saveSnippetFailure(error));
  }
}

function* getSnippetSaga(action: any) {
  try {
    const { snippetId }: { snippetId: string } = action;
    const url = `${generateUrl(EndpointRoute.Snippets)}/${snippetId}`;
    const response = yield call(fetchSecure, url);
    const payload: { snippet: any } = yield call([response, "json"]);
    const remappedPayload: Snippet = remapPayload.getSnippet(payload.snippet);
    yield put(getSnippetSuccess(remappedPayload));
  } catch (error) {
    yield put(getSnippetFailure(error));
  }
}

export function* snippetWatcher() {
  yield takeLatest(GET_SNIPPET, getSnippetSaga);
  yield takeLatest(SAVE_SNIPPET, saveSnippetSaga);
}
