import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
} from "../../../utilities/cardStyling";
import { grayColor } from "../../../utilities/colors";
import { createStyles } from "@material-ui/core";

const AppCardIconStyles = () =>
  createStyles({
    cardIcon: {
      "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
        borderRadius: "3px",
        backgroundColor: grayColor[0],
        padding: "15px",
        marginTop: "-20px",
        marginRight: "15px",
        float: "left"
      }
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader
  });

export default AppCardIconStyles;
