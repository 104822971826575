import React from "react";
import { AppGridItem, AppGridContainer } from "../../components";
import SentCommsFilterContainer from "./sentCommsFilter/sentCommsFilterContainer";
import SentCommsTableContainer from "./sentCommsTable/sentCommsTableContainer";
import { SentCommsChip } from "./sentCommsChip/sentCommsChip";

export interface SentCommsListProps {
  history: any;
  match: any;
  classes: any;
}

const SentCommsList: React.FunctionComponent<SentCommsListProps> = ({ history, match }) => {

  return (
    <AppGridContainer>
      <AppGridItem xs={12}>
        <SentCommsFilterContainer />
      </AppGridItem>
      <AppGridContainer flex="flex" justify="center" alignItems="center">
        <SentCommsChip history={history} scheduledCommId={match.params.scheduledCommId ?? undefined} />
      </AppGridContainer>
      <AppGridItem xs={12}>
        <SentCommsTableContainer history={history} match={match} />
      </AppGridItem>
    </AppGridContainer>
  );
};

export default SentCommsList;
