import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import QueryBuilderStyle from "./QueryBuilderStyles";
import { Combinator, AddRuleButton, Rules } from "..";
import { Paper, Grid } from "@material-ui/core";

export interface QueryBuilderProps {
  classes: any;
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({
  classes
}: QueryBuilderProps) => {
  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Combinator />
          </Grid>
          <Grid item>
            <AddRuleButton />
          </Grid>
        </Grid>
        <Rules />
      </Paper>
    </div>
  );
};

export default withStyles(QueryBuilderStyle)(QueryBuilder);
