import SentCommsTable, { SentCommsTableProps } from "./sentCommsTable";
import { connect } from "react-redux";
import { compose } from "redux";
import sentCommsStyles from "../sentCommsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import {
  getSentComms,
  updateTableHeaders,
  SentCommsRequest,
  downloadSentComm,
  DownloadSentCommRequest,
  updateFilter,
  defaultFilterValues,
} from "../../../store/areas/sentCommsList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";
import { addMonths } from "date-fns";

const mapStateToProps = (state: RootState): Partial<SentCommsTableProps> => ({
  columns: state.sentCommsList.columns,
  sentComms: state.sentCommsList.sentComms,
  totalRows: state.sentCommsList.totalRows,
  itemsPerPage: state.sentCommsList.itemsPerPage,
  queries: state.sentCommsList.queries,
  loading: state.loader.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  initSentComms: (sentCommsRequest: SentCommsRequest, updateTableHeadersObject: UpdateTableHeadersObject) => {
    dispatch(
      updateFilter({
        ...defaultFilterValues,
        sentOnDate: { ...defaultFilterValues.sentOnDate, startDate: addMonths(new Date(), -1), endDate: new Date() },
      })
    );
    dispatch(getSentComms(sentCommsRequest));
    dispatch(updateTableHeaders(updateTableHeadersObject));
  },
  getSentComms: (sentCommsRequest: SentCommsRequest) => dispatch(getSentComms(sentCommsRequest)),
  downloadSentComm: (request: DownloadSentCommRequest) => dispatch(downloadSentComm(request)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
});

const SentCommsTableContainer = compose(
  withStyles(sentCommsStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(SentCommsTable) as React.ComponentType<any>;

export default SentCommsTableContainer;
