import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getTemplatesSuccess,
  getTemplatesFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getTemplates
} from "./actions";
import {
  GET_TEMPLATES,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER
} from "./actionTypes";
import { Template, TemplateType } from "./types";
import { generateUrlWithQueryString } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { generateFilterObject, getFilter, getTemplateRequest } from "./utils";
import { fetchSecure } from "../../../authProvider";

function* getTemplatesSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.Templates, {
      ...action.templatesRequest,
      ...filterObject
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        templates: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const templates: Template[] = yield payload.templates.map((i: any) => ({
        id: i.id,
        name: i.name,
        triggerName: i.triggerName,
        templateType: TemplateType[i.templateType],
        lastModified: i.modifiedOn,
        attachments: i.attachments,
        attachmentCount: i.attachments.length,
        active: !i.disabled
      }));
      yield put(getTemplatesSuccess(templates, payload.totalRecordCount));
    } else {
      const error = yield call([response, "json"]);
      yield put(getTemplatesFailure(error));
    }
  } catch (error) {
    yield put(getTemplatesFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getTemplateRequest);
  yield put(getTemplates({ ...request, pageRequested: 0 }));
}

export function* TemplatesListWatcher() {
  yield takeLatest(GET_TEMPLATES, getTemplatesSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
}
