import { createStyles } from "@material-ui/core";
import CommsBackground from "../../assets/comms.background.jpg";

const LoginStyles = () =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      minHeight: "100vh",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: `90vh`,
      backgroundImage: `url(${CommsBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100vw",
    },
    welcomeText: {
      color: "white",
      textAlign: "center",
      paddingBottom: 5,
      fontWeight: 600,
      textShadow: "1px 1px 1px black",
    },
    loginButton: {
      border: "white 1px solid",
      fontWeight: 800,
    },
    footer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
  });

export default LoginStyles;
