import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardStyles from "./appCardStyle";
import classNames from "classnames";

export interface CardProps {
  classes: any;
  plain?: boolean;
  profile?: boolean;
  chart?: boolean;
  className?: any;
}

const Item: React.FunctionComponent<CardProps | any> = props => {
  const { className, children, plain, profile, chart, classes, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

export const AppCard = withStyles(AppCardStyles)(Item);
