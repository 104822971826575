import React from "react";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppSelectStyles from "./appSelectStyle";
import { Select, Checkbox, ListItemText } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

export interface AppSelectProps {
  classes: any;
  labelText?: string;
  labelProps?: object;
  id?: string;
  formControlProps?: any;
  error?: boolean;
  success?: boolean;
  data: { id: string; name: string }[] | { [id: string]: any };
  inputProps: any;
  touched?: boolean;
  onTouch?: () => void;
  multiple?: boolean;
  includeBlank?: boolean;
}

const unifyData = (
  data: { id: string; name: string }[] | { [id: string]: any }
): { [id: string]: any } => {
  if (data instanceof Array) {
    return convertArraytoObject(data);
  }
  return data;
};

const convertArraytoObject = (array: { id: string; name: string }[]) => {
  return array.reduce((acc: { [id: string]: any }, cur: any) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
};

const getOptions = (
  data: { [id: string]: any },
  selectedValues: string[],
  multiple: boolean | undefined
) => {
  return Object.values(data).map(item => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {multiple && <Checkbox checked={selectedValues.includes(item.id)} />}
        <ListItemText primary={item.name} />
      </MenuItem>
    );
  });
};

const Item: React.FunctionComponent<AppSelectProps> = ({
  classes,
  formControlProps,
  labelText,
  id,
  labelProps,
  error,
  success,
  data,
  inputProps,
  touched,
  onTouch,
  multiple,
  includeBlank,
}: AppSelectProps) => {

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
    [" " + classes.selectPaddingFix]: !inputProps.value
  });

  const onBlur = () => {
    if (!touched && onTouch) {
      onTouch();
    }
  };

  const unifiedData = unifyData(data);

  const className = formControlProps.className
    ? `${formControlProps.className} ${classes.formControl}`
    : `${classes.formControl}`;

  const valueToRender = (selected: any) => {
    return selected
      ? multiple
        ? (selected as string[]).map(s => unifiedData[s].name).join(", ")
        : unifiedData[selected]?.name ?? inputProps.placeholder ?? ""
      : "";};

  return (
    <FormControl
      error={error}
      {...formControlProps}
      className={className}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        multiple={multiple}
        renderValue={valueToRender}
        onBlur={onBlur}
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        id={id}
        inputProps={{ "data-testid": id }}
        {...inputProps}
      >
        {includeBlank &&
          <MenuItem key="none" value="">
            <ListItemText primary="&nbsp;" />
          </MenuItem>
        }
        {data && getOptions(unifiedData, inputProps.value, multiple)}
      </Select>

      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

export const AppSelect = withStyles(AppSelectStyles)(Item);