import { Template, TemplatePayload } from "./types";
import { Field, remapAugmenterMappings, getCombinedFields } from "../shared";
import { store } from "../../../app";
import { RootState } from "../../rootReducer";
import { AugmenterOwner } from "../../../enums";

export const generatePayload = (fields: Field[]) => {
  return fields.reduce((acc: any, cur: Field) => {
    acc[cur.path] = `**${cur.path}**`;
    return acc;
  }, {});
};

export const remapPayload = {
  getTemplate: (payload: TemplatePayload): Template => {
    const state = store.getState() as RootState;

    const trigger = state.shared.triggers[payload.triggerId];
    const triggerFields = trigger?.fields ?? [];
    const triggerAugmenterMappings = trigger?.augmenterMappings ?? [];

    const templateAugmenterMappings = remapAugmenterMappings(AugmenterOwner.Template, payload.augmenterMappings);

    const combinedFields = getCombinedFields(triggerFields, [...triggerAugmenterMappings, ...templateAugmenterMappings]);

    return {
      id: payload.id,
      name: payload.name,
      subject: payload.subject,
      html: payload.html,
      text: payload.text,
      rawContent: JSON.parse(payload.rawContent),
      subjectRawContent: JSON.parse(payload.subjectRawContent),
      templateType: payload.templateType,
      trigger: { id: payload.triggerId, name: payload.triggerName },
      active: !payload.disabled,
      augmenterMappings: templateAugmenterMappings,
      combinedFields,
      templateAttachments: payload.attachments,
    };
  },
};
