import { Filter } from "./types";
import { tidyFilterStatus } from "../shared/utils";
import { RootState } from "../../rootReducer";

export const generateFilterObject = (filter: Filter): any => {
  return {
    ruleName: filter.ruleName,
    templateName: filter.templateName,
    triggers: filter.triggers,
    startDate: filter.dateModified.startDate?.toUTCString() ?? null,
    endDate: filter.dateModified.endDate?.toUTCString() ?? null,
    activeOnly: tidyFilterStatus(filter.status)
  };
};

export const getFilter = (state: RootState) => {
  return state.schedulingRulesList.filter;
};

export const getSchedulingRuleRequest = (state: RootState) => {
  return state.schedulingRulesList.queries;
};
