export const GET_TRIGGERS = "TRIGGERS_LIST/GET_TRIGGERS";
export const GET_TRIGGERS_SUCCESS = "TRIGGERS_LIST/GET_TRIGGERS_SUCCESS";
export const GET_TRIGGERS_FAIL = "TRIGGERS_LIST/GET_TRIGGERS_FAIL";

export const UPDATE_TABLE_HEADERS = "TRIGGERS_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "TRIGGERS_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "TRIGGERS_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const SELECT_TRIGGER = "TRIGGERS_LIST/SELECT_TRIGGER";
export const DESELECT_TRIGGER = "TRIGGERS_LIST/DESELECT_TRIGGER";

export const UPDATE_FILTER = "TRIGGERS_LIST/UPDATE_FILTER";
export const APPLY_FILTER = "TRIGGERS_LIST/APPLY_FILTER";
