import { createStyles } from "@material-ui/core";

const ScheduledCommsEditStyles = () => createStyles({
  pickerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 12
  }
});

export default ScheduledCommsEditStyles;
