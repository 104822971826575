const enumToObjects = (enumType: any) => {
  const keys = getKeys(enumType);
  return keys.map(k => ({ id: k, name: enumType[k] }));
};

const getKeys = (enumType: object) => {
  const members = Object.keys(enumType);
  return members.filter((x: string) => !Number.isNaN(+x));
};

export default enumToObjects;
