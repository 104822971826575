import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardFooterStyles from "./appCardFooterStyle";
import classNames from "classnames";

export interface AppCardFooterProps {
  classes: any;
  className?: any;
  plain?: boolean;
  profile?: boolean;
  stats?: boolean;
  chart?: boolean;
}

const Item: React.FunctionComponent<AppCardFooterProps> = props => {
  const { className, children, plain, profile, stats, chart, classes, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart,
    [className]: className !== undefined
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};

export const AppCardFooter = withStyles(AppCardFooterStyles)(Item);
