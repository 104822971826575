import SchedulingRulesTable, { SchedulingRulesTableProps } from "./schedulingRulesTable";
import { connect } from "react-redux";
import { compose } from "redux";
import schedulingRulesStyles from "../schedulingRulesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { getSchedulingRules, updateTableHeaders, SchedulingRulesRequest } from "../../../store/areas/schedulingRulesList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<SchedulingRulesTableProps> => ({
  columns: state.schedulingRulesList.columns,
  schedulingRules: state.schedulingRulesList.schedulingRules,
  totalRows: state.schedulingRulesList.totalRows,
  itemsPerPage: state.schedulingRulesList.itemsPerPage,
  queries: state.schedulingRulesList.queries,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSchedulingRules: (schedulingRulesRequest: SchedulingRulesRequest) => dispatch(getSchedulingRules(schedulingRulesRequest)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
});

const SchedulingRulesTableContainer = compose(
  withStyles(schedulingRulesStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.SchedulingRule)
)(SchedulingRulesTable) as React.ComponentType<any>;

export default SchedulingRulesTableContainer;
