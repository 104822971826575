import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import {
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_FILTER,
  SELECT_TEMPLATE,
  DESELECT_TEMPLATE
} from "./actionTypes";
import {
  Template,
  TemplatesRequest,
  TemplateTableFields,
  Filter,
  TemplateColumnIndex
} from "./types";
import { Column, UpdateTableHeadersObject, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { defaultStatuses } from "../shared/values";

export interface TemplatesListState {
  templates?: Template[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: TemplatesRequest;
  selectedDataIndices: number[];
  selectedTemplates: Template[];
  status: Status[];
  filter: Filter;
}

export const defaultTemplateColumns: Column[] = [
  {
    name: "name",
    label: "Template Name",
    dataType: DataType.String
  },
  { name: "triggerName", label: "Trigger", dataType: DataType.String },
  {
    name: "lastModified",
    label: "Last Modified",
    dataType: DataType.Datetime
  },
  {
    name: "attachmentCount",
    label: "Attachments",
    dataType: DataType.Number,
    disableSort: true
  },
  {
    name: "active",
    label: "Active",
    dataType: DataType.Boolean
  }
];

export const defaultFilterValues: Filter = {
  templateName: "",
  triggers: [],
  dateCreated: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All
  },
  status: ""
};

export const initialTemplateQueries: TemplatesRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  sortAscending: true,
  sortField: TemplateTableFields.LastModified
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: TemplateColumnIndex.LastModified,
  sortOrder: SortOrder.desc
};

export const initialState: TemplatesListState = {
  templates: [],
  status: defaultStatuses,
  columns: defaultTemplateColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  queries: initialTemplateQueries,
  selectedDataIndices: [],
  selectedTemplates: [],
  filter: defaultFilterValues
};

export const TemplatesListReducer: Reducer<TemplatesListState> = (
  state: TemplatesListState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_TEMPLATES:
      const { templatesRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns
      };
    case GET_TEMPLATES_SUCCESS:
      const { templates, totalRows } = action;
      return {
        ...state,
        selectedTemplate: [],
        selectedDataIndices: [],
        templates,
        totalRows
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    case SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplates: [...action.selectedTemplate],
        selectedDataIndices: [...action.selectedDataIndex]
      };
    case DESELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplates: [],
        selectedDataIndices: []
      };
    default:
      return state;
  }
};

export default TemplatesListReducer;
