import { RootState } from "./store/rootReducer";
import React from "react";
import { connect } from "react-redux";

interface WithTenantProps {
  tenantId: string;
}
export const withTenant = <BaseProps extends WithTenantProps>(BaseComponent: React.ComponentType<BaseProps>) => {
  const mapStateToProps = (state: RootState) => ({
    tenantId: state.tenant.activeTenantId,
  });

  const Wrapper = (props: any) => <BaseComponent {...props} />;

  const WrapperWithTenant = connect(mapStateToProps,undefined)(Wrapper);
  return WrapperWithTenant;
};
