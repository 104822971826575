import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardHeaderStyles from "./appCardHeaderStyle";
import { CardColor } from "../../../enums";
import classNames from "classnames";

export interface AppCardHeaderProps {
  classes: any;
  className?: any;
  color?: CardColor;
  plain?: boolean;
  stats?: boolean;
  icon?: boolean;
}

const Item: React.FunctionComponent<AppCardHeaderProps> = props => {
  const { className, children, color, plain, stats, icon, classes, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

export const AppCardHeader = withStyles(AppCardHeaderStyles)(Item);
