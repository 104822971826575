import { AnyAction } from "redux";
import {
  GET_TRIGGERS,
  GET_TRIGGERS_SUCCESS,
  GET_TRIGGERS_FAIL,
  SELECT_TRIGGER,
  DESELECT_TRIGGER,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_TABLE_HEADERS,
  UPDATE_TABLE_HEADERS_FAIL,
  UPDATE_FILTER,
  APPLY_FILTER
} from "./actionTypes";
import { Trigger, TriggersRequest, Filter } from "./types";
import { SortOrder } from "../../../enums";
import { UpdateTableHeadersObject } from "../shared";

export const getTriggers = (triggersRequest: TriggersRequest): AnyAction => {
  return {
    type: GET_TRIGGERS,
    triggersRequest
  };
};

export const getTriggersSuccess = (triggers: Trigger[], totalRows: number): AnyAction => {
  return {
    type: GET_TRIGGERS_SUCCESS,
    triggers,
    totalRows
  };
};

export const getTriggersFailure = (error: Error): AnyAction => {
  return {
    type: GET_TRIGGERS_FAIL,
    message: error.message || "Unable to get list of triggers",
    error
  };
};

export const updateTableHeaders = (updateTableHeadersObject: UpdateTableHeadersObject) => {
  const { columnIndex, sortOrder } = updateTableHeadersObject;
  return {
    type: UPDATE_TABLE_HEADERS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersSuccess = (columnIndex: number, sortOrder: SortOrder) => {
  return {
    type: UPDATE_TABLE_HEADERS_SUCCESS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersFailure = (error: Error): AnyAction => {
  return {
    type: UPDATE_TABLE_HEADERS_FAIL,
    message: error.message || "Error rendering table headers. Please refresh and try again",
    error
  };
};

export const updateFilter = (filter: Filter): AnyAction => {
  return {
    type: UPDATE_FILTER,
    filter
  };
};

export const applyFilter = (): AnyAction => ({ type: APPLY_FILTER });

export const selectTrigger = (trigger: Trigger, dataIndex: number): AnyAction => {
  return {
    type: SELECT_TRIGGER,
    selectedTriggers: trigger,
    selectedDataIndices: dataIndex
  };
};

export const deselectTrigger = (trigger: Trigger, dataIndex: number): AnyAction => {
  return {
    type: DESELECT_TRIGGER,
    selectedTriggers: trigger,
    selectedDataIndices: dataIndex
  };
};