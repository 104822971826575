import { Reducer } from "redux";
import { CLOSE_SNACKBAR } from "./actionTypes";
import { SET_TENANT } from "../shared";

export interface SnackbarState {
  open: boolean;
  variant: "success" | "warning" | "error" | "info";
  message: string;
}

const initialState: SnackbarState = {
  open: false,
  variant: "error",
  message: ""
};

export const SnackbarReducer: Reducer<SnackbarState> = (
  state = initialState,
  action: any
) => {
  const { type, message } = action;
  switch (action.type) {
    case CLOSE_SNACKBAR:
      return { ...state, open: false };
    case SET_TENANT:{
      return {
        ...state,
        open: true,
        message,
        variant: "success"
      };
    }
    default:
      if (message === undefined) {
        return state;
      }
      const matches = /(SUCCESS|FAIL)_?/.exec(type);
      if (!matches) return state;

      const [requestName] = matches;
      if (!requestName) return state;

      return {
        ...state,
        open: true,
        message,
        variant: requestName === "SUCCESS" ? "success" : "error"
      };
  }
};

export default SnackbarReducer;
