import React from "react";
import { AppGridItem, AppGridContainer, AppButton } from "../../components";
import CancellationRulesFilterContainer from "./cancellationRulesFilter/cancellationRulesFilterContainer";
import CancellationRulesTableContainer from "./cancellationRulesTable/cancellationRulesTableContainer";
import { CardColor, RouterName, Size } from "../../enums";
import { ViewPermission } from "../../role";

export interface CancellationRulesListProps {
  history: any;
  classes: any;
  permission: ViewPermission;
}

const CancellationRulesList: React.FunctionComponent<CancellationRulesListProps> = ({ history, permission }) => {
  const createNewCancellationRule = () => {
    history.push(RouterName.NewCancellationRule);
  };

  return (
    <>
      <AppGridItem xs={12}>
        <CancellationRulesFilterContainer />
      </AppGridItem>
      {permission.write && (
        <AppGridItem xs={12}>
          <AppGridContainer alignItems="flex-end" justify="flex-end">
            <AppButton color={CardColor.primary} onClick={createNewCancellationRule} size={Size.lg}>
              Create New Cancellation Rule
            </AppButton>
          </AppGridContainer>
        </AppGridItem>
      )}
      <AppGridItem xs={12}>
        <CancellationRulesTableContainer history={history} />
      </AppGridItem>
    </>
  );
};

export default CancellationRulesList;
