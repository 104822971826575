import { primaryColor, dangerColor, successColor, grayColor } from "../../utilities/colors";
import defaultFont from "../../utilities/font";
import { makeStyles } from "@material-ui/core/styles";

const AppInputStyles = makeStyles({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: primaryColor[4] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0] + " !important"
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor[0]
    }
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[1] + " !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset"
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    paddingBottom: "10px",
    paddingTop: "14px",
    position: "relative",
    verticalAlign: "unset"
  }
});

export default AppInputStyles;
