export enum IconName {
  "Dashboard" = "Dashboard",
  "Templates" = "Templates",
  "SchedulingRules" = "SchedulingRules",
  "Triggers" = "Triggers",
  "CancellationRules" = "CancellationRules",
  "Snippets" = "Snippets",
  "ScheduledComms" = "ScheduledComms",
  "SentComms" = "SentComms",
}
export enum View {
  "Template" = "Template",
  "SchedulingRule" = "SchedulingRule",
  "CancellationRule" = "CancellationRule",
  "ScheduledComm" = "ScheduledComm",
  "Snippet" = "Snippet",
  "SentComm" = "SentComm",
}

export enum RouterName {
  "Home" = "/",
  "Templates" = "/templates",
  "NewTemplate" = "/template/new",
  "EditTemplate" = "/template/:templateId",
  "NewTemplateFromExisting" = "/template/new/:existingTemplateId",
  "SchedulingRules" = "/schedulingrules",
  "NewSchedulingRule" = "/schedulingrule/new",
  "EditSchedulingRule" = "/schedulingrule/:schedulingRuleId",
  "Triggers" = "/triggers",
  "NewTrigger" = "/trigger/new",
  "EditTrigger" = "/trigger/:triggerId",
  "NewTriggerFromExisting" = "/trigger/new/:existingTriggerId",
  "CancellationRules" = "/cancellationrules",
  "NewCancellationRule" = "/cancellationRule/new",
  "EditCancellationRule" = "/cancellationrule/:cancellationRuleId",
  "Snippets" = "/snippets",
  "NewSnippet" = "/snippet/new",
  "EditSnippet" = "/snippet/:snippetId",
  "NewSnippetFromExisting" = "/snippet/new/:existingSnippetId",
  "ScheduledComms" = "/scheduledcomms",
  "SentComms" = "/sentcomms",
  "SpecificSentComms" = "/sentcomms/:scheduledCommId",
}

export enum ExternalRouterName {
  "ScheduledComms" = "/external/scheduledcomms/:key/:value",
  "SentComms" = "/external/sentcomms",
  "SpecificSentComms" = "/external/sentcomms/:scheduledCommId",
}

export enum EndpointRoute {
  "Augmenters" = "Augmenters",
  "Templates" = "Templates",
  "Tenants" = "Tenants",
  "Triggers" = "Triggers",
  "SchedulingRules" = "SchedulingRules",
  "ScheduledComms" = "ScheduledComms",
  "UploadImage" = "UploadImage",
  "CancellationRules" = "CancellationRules",
  "Snippets" = "Snippets",
  "SentComms" = "SentComms",
  "DownloadSentComm" = "DownloadSentComm",
  "UploadTemplateAttachment" = "UploadTemplateAttachment",
}

export enum TableTitle {
  "Templates" = "Templates",
  "SchedulingRules" = "Scheduling Rules",
  "Triggers" = "Triggers",
  "CancellationRules" = "Cancellation Rules",
  "Snippets" = "Snippets",
  "ScheduledComms" = "Scheduled Comms",
  "SentComms" = "Sent Comms",
}

export enum DataType {
  "String" = "",
  "Number" = "Number",
  "Datetime" = "Datetime",
  "Boolean" = "Boolean",
  "Custom" = "Custom",
}

export enum SortOrder {
  "asc" = "asc",
  "desc" = "desc",
}

export enum BooleanValue {
  "Yes" = "Yes",
  "No" = "No",
}

export enum CardColor {
  "warning" = "warning",
  "success" = "success",
  "danger" = "danger",
  "info" = "info",
  "primary" = "primary",
  "rose" = "rose",
}

export enum BasicColor {
  "primary" = "primary",
  "secondary" = "secondary",
  "default" = "default",
}

export enum Size {
  "sm" = "sm",
  "md" = "md",
  "lg" = "lg",
}

export enum SmartRange {
  "All" = "All",
  "Last month" = "Last month",
  "Last 7 days" = "Last 7 days",
  "Last 24 hours" = "Last 24 hours",
  "Next 24 hours" = "Next 24 hours",
  "Next 7 days" = "Next 7 days",
  "Next month" = "Next month",
  "Custom" = "Custom",
}

export enum RecipientSourceType {
  "FromPayload" = 1,
  "FromRecipientSource" = 2,
}

export enum ScheduledDateSourceType {
  "Immediate" = 1,
  "FromPayload" = 2,
}

export enum FieldType {
  "None" = 0,
  "Boolean" = 1,
  "Date" = 2,
  "DateTime" = 3,
  "Decimal" = 4,
  "Email" = 5,
  "Int" = 6,
  "Text" = 7,
  "Url" = 8,
  "Guid" = 9,
}

export enum AugmenterOwner {
  "None" = 0,
  "Trigger" = 1,
  "Template" = 2,
  "SchedulingRule" = 3,
}

export enum ScheduledCommsStatus {
  None = 0,
  Pending = 1,
  Dispatched = 2,
  Sent = 3,
  Failed = 4,
  DispatchFailed = 5,
  Cancelled = 6,
  NoRecipients = 7,
  SendConditionFailed = 8,
}

export enum NotificationChannelType {
  Email = 1,
  UI = 2,
}
