import { createStyles, Theme } from "@material-ui/core";

const LayoutViewStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    toolbar: {
      paddingRight: 24 // keep right padding when drawer closed
    },
    appBarSpacer: {
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      height: "100vh"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    blur: {
      opacity: 0.25,
      pointerEvents: "none",
      position: "relative"
    }
  });

export default LayoutViewStyles;
