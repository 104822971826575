export const toolbarOptions: string[] = [
  "inline",
  "blockType",
  "fontSize",
  "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  "link",
  "remove",
  "history",
  "image"
];
