import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ScheduledDateSourceType, FieldType, CardColor, BasicColor } from "../../enums";
import { AppGridContainer, AppGridItem, AppSelect, AppInput, AppCard, AppCardHeader, AppCardBody, AppSwitch } from "../../components";
import { ScheduledDateType, Field } from "../../store/areas/shared";
import { Touched, SchedulingRule } from "../../store/areas/schedulingRule/types";
import SchedulingRuleEditorStyles from "./schedulingRuleEditorStyles";
import { smartParseInt } from "../../utilities/smartParseInt";
import { ViewPermission } from "../../role";

export interface ScheduledDateProps {
  classes?: any;
  scheduledDateTypes: ScheduledDateType[];
  schedulingRule: SchedulingRule;
  touched: Touched;
  permission: ViewPermission;
  fields: Field[];
  updateSchedulingRule: (schedulingRule: SchedulingRule) => void;
  touchSchedulingRule: (touched: Touched) => void;
}

const convertArraytoObject = (array: { id: string; name: string }[]) => {
  return array.reduce((acc: { [id: string]: any }, cur: any) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
};

const convertPayload = (payload: Field[] | undefined) => {
  if (payload) {
    const data = payload.reduce((arr: any[], current: Field) => {
      return current.type === FieldType.Date || current.type === FieldType.DateTime
        ? [...arr, { id: current.path, name: current.displayName }]
        : arr;
    }, []);
    return convertArraytoObject(data);
  }
  return [];
};

const displaySchedulingDateTypeInfo = (value: number) => {
  if (value === ScheduledDateSourceType.Immediate) {
    return "Comms will be scheduled to go out immediately";
  }
  if (value === ScheduledDateSourceType.FromPayload) {
    return "Comms will be scheduled to go out based on the selected scheduled date source from the payload + any offsets";
  }
  return "";
};

const displayOffsetInfo = (value: string): string => {
  const offset = parseInt(value);
  if (offset === 1) {
    return "day after";
  }
  if (offset === -1) {
    return "day before";
  }
  if (offset > 0) {
    return "days after";
  }
  if (offset < 0) {
    return "days before";
  }
  return "";
};

const ScheduledDate: React.FunctionComponent<ScheduledDateProps> = ({
  schedulingRule,
  touched,
  scheduledDateTypes,
  updateSchedulingRule,
  touchSchedulingRule,
  classes,
  permission,
  fields,
}) => {
  return (
    <AppCard>
      <AppCardHeader color={CardColor.primary} stats={true}>
        <h4 className={classes.cardTitleWhite}>Scheduled Date Source</h4>
      </AppCardHeader>
      <AppCardBody>
        <AppGridContainer>
          <AppGridItem xs={12} sm={12} md={6}>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <AppSelect
                  data={scheduledDateTypes}
                  labelText="Scheduled Date Type"
                  id="schedulingrule-scheduling-date-type"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: schedulingRule.scheduledDateType,
                    onChange: (e: any) => {
                      updateSchedulingRule({
                        ...schedulingRule,
                        scheduledDateType: e.target.value,
                        offset: "0",
                        scheduledDateSource: "",
                      });
                    },
                    disabled: !schedulingRule.template?.id.length || !permission.write,
                  }}
                />
                <div>{displaySchedulingDateTypeInfo(schedulingRule.scheduledDateType)}</div>
              </AppGridItem>
            </AppGridContainer>
          </AppGridItem>
          <AppGridItem xs={12} sm={12} md={6}>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={8}>
                <AppSelect
                  data={convertPayload(fields)}
                  labelText="Scheduled Date Source"
                  id="schedulingrule-scheduling-date-source"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: schedulingRule.scheduledDateSource,
                    onChange: (e: any) =>
                      updateSchedulingRule({
                        ...schedulingRule,
                        scheduledDateSource: e.target.value,
                      }),
                    disabled: !(schedulingRule?.scheduledDateType === ScheduledDateSourceType.FromPayload) || !permission.write,
                  }}
                  error={
                    touched.scheduledDateSource &&
                    schedulingRule?.scheduledDateType === ScheduledDateSourceType.FromPayload &&
                    schedulingRule.scheduledDateSource.length === 0
                  }
                  onTouch={() =>
                    touchSchedulingRule({
                      ...touched,
                      scheduledDateSource: true,
                    })
                  }
                />
              </AppGridItem>
              <AppGridItem xs={12} md={4}>
                <AppInput
                  labelText="Offset in days"
                  id="schedulingrule-offset"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: schedulingRule.offset,
                    onChange: (e: any) => {
                      const { value } = e.target;
                      const asInt = smartParseInt(value);
                      if (asInt !== schedulingRule.offset) {
                        updateSchedulingRule({
                          ...schedulingRule,
                          offset: asInt,
                        });
                      }
                    },
                    disabled: !schedulingRule?.scheduledDateSource || !permission.write,
                  }}
                />
                <div>{displayOffsetInfo(schedulingRule.offset)}</div>
              </AppGridItem>
            </AppGridContainer>
          </AppGridItem>
          <AppGridItem xs={12} sm={12} md={12}>
            <AppGridContainer>
              <AppGridItem flex={true} className={classes.activeButtonContainer}>
                <AppSwitch
                  formControlProps={{ label: "If scheduled date is in past, send as immediate."}}
                  color={BasicColor.primary}
                  onChange={(e: any) => {
                    updateSchedulingRule({
                      ...schedulingRule,
                      isSendAsImmediate: e.target.checked,
                    });
                  }}
                  value={schedulingRule.isSendAsImmediate}
                  inputProps={{
                    disabled: !(schedulingRule?.scheduledDateType === ScheduledDateSourceType.FromPayload) || !permission.write,
                  }}
                />
              </AppGridItem>
            </AppGridContainer>
          </AppGridItem>
        </AppGridContainer>
      </AppCardBody>
    </AppCard>
  );
};

export default withStyles(SchedulingRuleEditorStyles)(ScheduledDate);
