import config from "./config.json";

export const ENABLE_REDUX_DEV = config.ENABLE_REDUX_DEV;
export const API_HOST = config.API_HOST;
export const ROWS_PER_TABLE_PAGE = config.ROWS_PER_TABLE_PAGE;
export const ROWS_PER_TABLE_PAGE_LARGE = config.ROWS_PER_TABLE_PAGE_LARGE;
export const NUMBER_MAX_VALUE = config.NUMBER_MAX_VALUE;
export const AUTH_CONFIG = {
  ...config.AUTH_CONFIG,
  auth: {
    ...config.AUTH_CONFIG.auth,
    redirectUri: window.location.origin + "/.auth/login/aad/callback",
  },
};
export const AUTH_PARAMS = config.AUTH_PARAMS;
export const APPINSIGHTS_INSTRUMENTATIONKEY = config.APPINSIGHTS_INSTRUMENTATIONKEY;
export const SCHEDULEDCOMMS_PROPERTY_EXCLUDE_LIST = config.SCHEDULEDCOMMS_PROPERTY_EXCLUDE_LIST;
export const TENANT_ID_HEADER_NAME = config.KLP_TENANT_ID_HEADER_NAME;
