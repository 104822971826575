import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppDialogStyles from './appDialogStyle';
import { Dialog, DialogTitle, DialogContent, Slide, DialogContentText, DialogActions } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

export interface AppDialogProps {
  classes: any;
  open: boolean;
  title?: string;
  content?: string;
  actions?: React.ReactNode;
  onCloseHandler?: () => void;
  children: any;
}

const Item: React.FunctionComponent<AppDialogProps> = ({ open, onCloseHandler, title, content, actions, children }) => {
  const Transition = React.forwardRef(
    function TransitionAnimation(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {title && <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>}
      <DialogContent>
        {content && <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>}
        {children}
      </DialogContent>

      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export const AppDialog = withStyles(AppDialogStyles)(Item);
