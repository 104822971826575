import { Snippet } from "./types";

export const remapPayload = {
  getSnippet: (payload: any): Snippet => {
    return {
      id: payload.id,
      name: payload.name,
      bodyHtml: payload.bodyHtml,
      bodyText: payload.bodyText,
      rawBody: JSON.parse(payload.rawBody),
      active: !payload.disabled
    };
  }
};
