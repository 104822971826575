import { whiteColor, dangerColor } from "../../utilities/colors";
import { createStyles } from "@material-ui/core";

const AppAugmenterStyles = () =>
  createStyles({
    cardTitleWhite: {
      color: whiteColor,
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    },
    secondaryHeadingWarning: {
      color: dangerColor[0] + " !important",
    },
  });

export default AppAugmenterStyles;
