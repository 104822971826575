import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { SharedProps } from '../shared';
import { CombinatorType } from '../../enums';

export interface CombinatorProps extends SharedProps {
  onChange: (value: string) => void;
  value: CombinatorType;
  data: string[];
  canDeselect: boolean;
  exclusive: boolean;
}

const Combinator: React.FC<CombinatorProps> = ({
  classes,
  value,
  onChange,
  data,
  canDeselect,
  exclusive,
}: CombinatorProps) => {
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment || canDeselect) {
      onChange(newAlignment || '');
    }
  };
  return (
    <>
      {data && data?.length > 0 && (
        <ToggleButtonGroup
          className={classes.buttonGroup}
          value={value}
          exclusive={exclusive}
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          {data.map(item => (
            <ToggleButton key={item} classes={{ selected: classes.selected }} value={item} aria-label={item}>
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  );
};

export default Combinator;
