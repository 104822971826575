import Rules, { RulesProps } from "./Rules";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AddRuleButtonStyle from "./RulesStyles";

import { updateRule, removeRule } from "../../store/actions";
import { QueryBuilderActionTypes } from "../../store/actionTypes";
import { Rule } from "../../store/types";
import { QueryBuilderState } from "../../store/reducer";

export const mapStateToProps = (state: QueryBuilderState): Partial<RulesProps> => ({
  rules: state.rules,
  fields: state.fields,
  extraFields: state.extraFields,
});

const mapDispatchToProps = (dispatch: Dispatch<QueryBuilderActionTypes>) => ({
  removeRule: (rule: Rule) => dispatch(removeRule(rule)),
  updateRule: (rule: Rule) => dispatch(updateRule(rule)),
});

const AddRuleButtonContainer = compose(
  withStyles(AddRuleButtonStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(Rules) as React.ComponentType<any>;
export default AddRuleButtonContainer;
