import React from "react";
import { getIcon } from "./icons";
import { IconName } from "../../enums";

export interface AppIconProps {
  iconName: IconName;
}

export const AppIcon: React.FunctionComponent<AppIconProps> = ({ iconName }) => {
  const icon = getIcon(iconName);
  return React.createElement(icon);
};
