import React, { ReactNode } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppButtonStyles from "./appButtonStyle";
import { CardColor } from "../../enums";
import classNames from "classnames";
import { Size } from "../../enums";
import { Button } from "@material-ui/core";

export interface AppButtonProps {
  classes: any;
  className?: any;
  color: CardColor;
  size: Size;
  simple?: boolean;
  round?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  thinRows?: boolean;
  muiClasses?: any;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: ReactNode;
}

const Item: React.FunctionComponent<AppButtonProps> = props => {
  const {
    size,
    color,
    round,
    children,
    disabled,
    simple,
    block,
    link,
    justIcon,
    className,
    thinRows,
    muiClasses,
    classes,
    onClick,
    startIcon,
    id,
    ...rest
  } = props;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.thinRows]: thinRows,
    [className]: className,
  });

  return (
    <Button {...rest} classes={muiClasses} className={btnClasses} onClick={onClick} id={id} data-testid={id} startIcon={startIcon}>
      {children}
    </Button>
  );
};

export const AppButton = withStyles(AppButtonStyles)(Item);
