import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppExpansionPanelStyle from "./appExpansionPanelStyle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import { AppButton } from "..";
import { Size, CardColor } from "../../enums";

export interface AppExpansionPanelProps {
  classes: any;
  title?: string | JSX.Element;
  secondaryTitle?: string | JSX.Element;
  expanded?: boolean;
  actions?: {
    name: string;
    size: Size;
    color: CardColor;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
  onExpansionChanged?: (expanded: boolean) => void;
}

const Item: React.FunctionComponent<AppExpansionPanelProps> = ({ classes, children, title, secondaryTitle, expanded, actions, onExpansionChanged }) => {
  const [expandedState, setExpanded] = useState(expanded ?? false);
  return (
    <ExpansionPanel expanded={expandedState}>
      <ExpansionPanelSummary
        onClick={() => {
          const newExpandedState = !expandedState;
          setExpanded(newExpandedState);
          if (!!onExpansionChanged) {
            onExpansionChanged(newExpandedState);
          }
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        {
          title &&
          <div className={classes.column}>
            {
              typeof title === "string"
                ? <Typography className={classes.heading}>{title}</Typography>
                : title
            }
          </div>
        }
        {
          secondaryTitle &&
          <div className={classes.column}>
            {
              typeof secondaryTitle === "string"
                ? <Typography className={classes.secondaryHeading}>{secondaryTitle}</Typography>
                : secondaryTitle
            }
          </div>
        }
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>{children}</ExpansionPanelDetails>
      <Divider />
      {
        !!actions?.length &&
        <ExpansionPanelActions>
          {
            actions.map((x, index) =>
              <AppButton key={index} size={x.size} color={x.color} onClick={x.onClick}>
                {x.name}
              </AppButton>
            )
          }
        </ExpansionPanelActions>
      }
    </ExpansionPanel>
  );
};

export const AppExpansionPanel = withStyles(AppExpansionPanelStyle)(Item);
