import { AnyAction } from "redux";
import {
  INIT_CANCELLATIONRULE,
  SAVE_CANCELLATIONRULE,
  SAVE_CANCELLATIONRULE_SUCCESS,
  SAVE_CANCELLATIONRULE_FAIL,
  TOUCH_CANCELLATIONRULE,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAIL,
  UPDATE_CANCELLATIONRULE,
  UPDATE_CONDITION,
  GET_CANCELLATIONRULE,
  GET_CANCELLATIONRULE_SUCCESS,
  GET_CANCELLATIONRULE_FAIL,
} from "./actionTypes";
import { CancellationRule, Template, Touched } from "./types";
import { Condition } from "../shared";
import { store } from "../../../app";
import { RootState } from "../../rootReducer";

export const initCancellationRule = (): AnyAction => ({ type: INIT_CANCELLATIONRULE });

export const getTemplates = (): AnyAction => ({
  type: GET_TEMPLATES,
});

export const getTemplatesSuccess = (templates: { [id: string]: Template }): AnyAction => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    templates,
  };
};

export const getTemplatesFailure = (error: Error): AnyAction => {
  return {
    type: GET_TEMPLATES_FAIL,
    error,
    message: "Unable to retrieve list of templates, please contact KLP Support",
  };
};

export const saveCancellationRule = (cancellationRule: CancellationRule): AnyAction => {
  return {
    type: SAVE_CANCELLATIONRULE,
    cancellationRule,
  };
};

export const saveCancellationRuleSuccess = (message: string): AnyAction => {
  return {
    type: SAVE_CANCELLATIONRULE_SUCCESS,
    message,
  };
};

export const saveCancellationRuleFailure = (error: Error): AnyAction => {
  return {
    type: SAVE_CANCELLATIONRULE_FAIL,
    error,
    message: error.message || "unable to save cancellation rule, please contact IT Support",
  };
};

export const updateCancellationRule = (cancellationRule: CancellationRule): AnyAction => {
  return {
    type: UPDATE_CANCELLATIONRULE,
    cancellationRule,
  };
};

export const updateCondition = (condition: Condition): AnyAction => {
  return {
    type: UPDATE_CONDITION,
    condition,
  };
};

export const touchCancellationRule = (touched: Touched): AnyAction => {
  return {
    type: TOUCH_CANCELLATIONRULE,
    touched,
  };
};

export const getCancellationRule = (cancellationRuleId: string): AnyAction => {
  return {
    type: GET_CANCELLATIONRULE,
    cancellationRuleId,
  };
};

export const getCancellationRuleSuccess = (cancellationRule: CancellationRule): AnyAction => {
  const state = store.getState() as RootState;
  const triggers = state.shared.triggers;

  if (triggers) {
    cancellationRule.templateFields = triggers[cancellationRule.template.triggerId]?.fields;
    cancellationRule.triggerFields = triggers[cancellationRule.trigger.id]?.fields;
  }

  return {
    type: GET_CANCELLATIONRULE_SUCCESS,
    cancellationRule,
  };
};

export const getCancellationRuleFailure = (error: Error): AnyAction => {
  return {
    type: GET_CANCELLATIONRULE_FAIL,
    error,
    message: "Unable to retrieve cancellation rule, please contact KLP Support",
  };
};
