import { Filter, ScheduledComm } from "./types";
import { RootState } from "../../rootReducer";
import { Condition, toISOString } from "../shared";
import { EditScheduledCommsRequest, ScheduledCommsEditRequestType } from "../scheduledCommsEdit/types";
import { CombinatorType, FieldType } from "@comms/react-query-builder/src";
import { Guid } from "guid-typescript";

export const generateFilterObject = (filter: Filter): any => {
  return {
    templateName: filter.templateName,
    triggers: filter.triggers,
    sendOnStartDate: toISOString(filter.sendOnDate.startDate),
    sendOnEndDate: toISOString(filter.sendOnDate.endDate),
    createdOnStartDate: toISOString(filter.createdOnDate.startDate),
    createdOnEndDate: toISOString(filter.createdOnDate.endDate),
    statuses: filterStatusToInt(filter.status),
    condition: filter.condition,
  };
};

export const generateEditRequest = (editRequest: EditScheduledCommsRequest): EditScheduledCommsRequest => {
  return {
    requestType: editRequest.requestType,
    scheduledCommIds: editRequest.scheduledCommIds,
    rescheduleDate: editRequest.requestType === ScheduledCommsEditRequestType.Reschedule ? editRequest.rescheduleDate : undefined,
  };
};

export const getFilter = (state: RootState) => {
  return state.scheduledCommsList.filter;
};

export const getScheduledCommRequest = (state: RootState) => {
  return state.scheduledCommsList.queries;
};

// This maps to KLP.Comms.Abstractions\Enums\ScheduledCommStatus.cs | Please ensure changes to this are reflected there too
export enum ScheduledCommStatuses {
  "Pending" = 1,
  "Dispatched" = 2,
  "Sent" = 3,
  "Failed" = 4,
  "Dispatch Failed" = 5,
  "Cancelled" = 6,
  "No Recipients" = 7,
  "Send Conditions Failed" = 8,
}

const filterStatusToInt = (filterStatuses: string[]) => {
  return filterStatuses.map((el: string) => (ScheduledCommStatuses as any)[el]);
};

export const filterStatusToString = (filterStatus: number) => {
  return ScheduledCommStatuses[filterStatus];
};

export const removeScheduledComms = (stateComms: ScheduledComm[], actionComms: ScheduledComm[]) => {
  if (!actionComms.length) {
    return [];
  }
  const actionCommsIds = actionComms.map(el => el.id);
  return stateComms.filter(el => !actionCommsIds.includes(el.id));
};

export const removeScheduledCommIndices = (stateIndices: number[], actionIndices: number[]) => {
  if (!actionIndices.length) {
    return [];
  }
  return stateIndices.filter(el => !actionIndices.includes(el));
};

export const generateKeyValueCondition = (key: string, value: string): Condition => ({
  rawState: {
    rules: [
      {
        id: Guid.raw(),
        field: {
          type: FieldType.String,
          name: key,
        },
        operatorName: "equal",
        values: [value],
        requiredValues: 1,
      },
    ],
    combinator: CombinatorType.And,
  },
  logic: {
    "==": [
      {
        var: key,
      },
      value,
    ],
  },
});
