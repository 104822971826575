import { put, takeLatest, call } from "redux-saga/effects";
import { generateUrl, generateUrlWithQueryString, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute, RecipientSourceType, AugmenterOwner, NotificationChannelType } from "../../../enums";
import {
  Template,
  getTemplatesFailure,
  getTemplatesSuccess,
  GET_TEMPLATES,
  SAVE_SCHEDULINGRULE,
  saveSchedulingRuleFailure,
  saveSchedulingRuleSuccess,
  SchedulingRule,
  SchedulingRulePayload,
  getSchedulingRuleSuccess,
  getSchedulingRuleFailure,
  GET_SCHEDULINGRULE,
  updateSchedulingRuleSuccess,
  UPDATE_SCHEDULINGRULE,
  remapPayload,
  tidyOffset,
  TemplatePayload,
} from ".";
import { NUMBER_MAX_VALUE } from "../../../config";
import { fetchSecure } from "../../../authProvider";
import { Field, getCombinedFields, GET_TRIGGERS_SUCCESS, Dictionary, remapAugmenterMappings } from "../shared";
import { RootState } from "../../rootReducer";
import { store } from "../../../app";

function* getTemplatesSaga() {
  try {
    const url = generateUrlWithQueryString(EndpointRoute.Templates, {
      itemsPerPage: NUMBER_MAX_VALUE,
      pageRequested: 0,
      sortAscending: true,
      sortField: 1,
      activeOnly: true,
    });
    const response = yield call(fetchSecure, url);
    const payload: { templates: TemplatePayload[] } = yield call([response, "json"]);

    const templates = payload.templates.reduce<Dictionary<Template>>((acc, cur) => {
      acc[cur.id] = {
        id: cur.id,
        name: cur.name,
        triggerId: cur.triggerId,
        triggerName: cur.triggerName,
        augmenterMappings: remapAugmenterMappings(AugmenterOwner.Template, cur.augmenterMappings),
      };

      return acc;
    }, {});

    const state: RootState = store.getState();

    const triggerId = state.schedulingRule.schedulingRule.template?.triggerId;
    const templateId = state.schedulingRule.schedulingRule.template?.id;

    const trigger = state.shared.triggers[triggerId];
    const triggerFields = trigger?.fields ?? [];

    const triggerMappings = trigger?.augmenterMappings ?? [];
    const templateMappings = templates[templateId]?.augmenterMappings ?? [];

    const augmenterMappings = [...triggerMappings, ...templateMappings, ...state.schedulingRule.schedulingRule.augmenterMappings];

    const combinedFields = getCombinedFields(triggerFields, augmenterMappings);

    yield put(getTemplatesSuccess(templates, combinedFields, triggerFields));
  } catch (error) {
    yield put(getTemplatesFailure(error));
  }
}

function* saveSchedulingRuleSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.SchedulingRules);
    const { schedulingRule }: { schedulingRule: SchedulingRule } = action;
    const recSource = schedulingRule.emailRecipientOverride.value || schedulingRule.emailRecipientsSource;
    const body = {
      id: schedulingRule.id,
      name: schedulingRule.name,
      templateId: schedulingRule.template.id,
      emailRecipientsSource: schedulingRule.notificationChannelTypes.includes(NotificationChannelType.Email) ? [recSource] : null,
      uiRecipientsSource: schedulingRule.notificationChannelTypes.includes(NotificationChannelType.UI) ? [schedulingRule.uiRecipientsSource] : null,
      recipientsSourceType: schedulingRule.recipientsSourceType,
      disabled: !schedulingRule.active,
      condition: schedulingRule.condition,
      sendCondition: schedulingRule.sendCondition,
      scheduledDateType: schedulingRule.scheduledDateType,
      scheduledDateSource: schedulingRule.scheduledDateSource,
      offset: tidyOffset(schedulingRule.offset),
      notificationChannelTypes: schedulingRule.notificationChannelTypes,
      isSendAsImmediate: schedulingRule.isSendAsImmediate
    };
    const response = yield call(fetchSecure, url, createHttpRequest(body, schedulingRule.id ? "PUT" : "POST"));
    if (response.ok) {
      yield put(saveSchedulingRuleSuccess("Scheduling Rule saved"));
    } else {
      const error = yield call([response, "json"]);
      yield put(saveSchedulingRuleFailure(error));
    }
  } catch (error) {
    yield put(saveSchedulingRuleFailure(error));
  }
}

function* getSchedulingRuleSaga(action: any) {
  try {
    const { schedulingRuleId }: { schedulingRuleId: string } = action;
    const url = `${generateUrl(EndpointRoute.SchedulingRules)}/${schedulingRuleId}`;
    const response = yield call(fetchSecure, url);
    const payload: { schedulingRule: SchedulingRulePayload } = yield call([response, "json"]);
    const remappedPayload: SchedulingRule = remapPayload.getSchedulingRule(payload.schedulingRule);
    yield put(getSchedulingRuleSuccess(remappedPayload));
  } catch (error) {
    yield put(getSchedulingRuleFailure(error));
  }
}

function* updateSchedulingRuleSaga(action: any) {
  const state: RootState = store.getState();
  const template = state.schedulingRule.schedulingRule.template;
  let schedulingRule: SchedulingRule = action.schedulingRule;

  let triggerFields: Field[] | undefined = state.schedulingRule.triggerFields ?? [];

  if (template && schedulingRule.template.id && template.id !== schedulingRule.template.id) {
    const trigger = state.shared.triggers[schedulingRule.template.triggerId];
    triggerFields = trigger?.fields ?? [];

    const triggerMappings = trigger?.augmenterMappings ?? [];
    const templateMappings = state.schedulingRule.templates[schedulingRule.template.id]?.augmenterMappings ?? [];

    const augmenterMappings = [...triggerMappings, ...templateMappings, ...state.schedulingRule.schedulingRule.augmenterMappings];

    const combinedFields = getCombinedFields(triggerFields, augmenterMappings);

    schedulingRule = {
      ...schedulingRule,
      combinedFields,
      emailRecipientsSource: "",
      uiRecipientsSource: "",
      recipientsSourceType: RecipientSourceType.FromPayload,
      emailRecipientOverride: { value: "", isValid: false },
    };
  }

  if (schedulingRule.emailRecipientsSource.includes("Override ")) {
    schedulingRule.recipientsSourceType = RecipientSourceType.FromRecipientSource;
  }

  yield put(updateSchedulingRuleSuccess(schedulingRule, triggerFields));
}

function* getTriggersSuccess(action: any) {
  const state: RootState = store.getState() as RootState;

  const schedulingRule = state.schedulingRule.schedulingRule;

  if (!schedulingRule?.template?.triggerId) {
    return;
  }

  const trigger = action.triggers[schedulingRule.template.triggerId];
  const triggerFields = trigger?.fields ?? [];

  const triggerMappings = trigger?.augmenterMappings ?? [];
  const templateMappings = schedulingRule.template?.augmenterMappings ?? [];
  const schedulingRuleMappings = schedulingRule.augmenterMappings ?? [];
  const augmenterMappings = [...triggerMappings, ...templateMappings, ...schedulingRuleMappings];

  const combinedFields = getCombinedFields(triggerFields, augmenterMappings);

  yield put(updateSchedulingRuleSuccess({ ...schedulingRule, combinedFields }, triggerFields));
}

export function* schedulingRuleWatcher() {
  yield takeLatest(GET_TEMPLATES, getTemplatesSaga);
  yield takeLatest(SAVE_SCHEDULINGRULE, saveSchedulingRuleSaga);
  yield takeLatest(GET_SCHEDULINGRULE, getSchedulingRuleSaga);
  yield takeLatest(UPDATE_SCHEDULINGRULE, updateSchedulingRuleSaga);
  yield takeLatest(GET_TRIGGERS_SUCCESS, getTriggersSuccess);
}
