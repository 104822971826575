import * as React from "react";
import { SharedProps } from "../shared";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

export interface AddRuleButtonProps extends SharedProps {
  OnClick: () => void;
}

const AddRuleButton: React.FC<AddRuleButtonProps> = ({
  classes,
  OnClick
}: AddRuleButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      className={classes.button}
      startIcon={<AddIcon />}
      onClick={OnClick}
    >
      Add rule
    </Button>
  );
};

export default AddRuleButton;
