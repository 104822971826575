import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
export interface NoMatchProps {
  classes?: any;
}

const NoMatch: React.FunctionComponent<NoMatchProps> = ({ classes }) => (
  <Paper className={classes.paper}>
    <div className={classes.container}>
      <Typography variant="h4">404</Typography>
      <Typography variant="subtitle1">We are sorry but the page you are looking for does not exist.</Typography>
    </div>
  </Paper>
);

export default NoMatch;
