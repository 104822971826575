import { omit } from "lodash";
import { ScheduledCommStatuses } from "../../store/areas/scheduledCommsList/utils";
import { ScheduledComm, RawPayload } from "../../store/areas/scheduledCommsList";
import { ScheduledCommsEditRequestType } from "../../store/areas/scheduledCommsEdit/types";
import { uniqueValues } from "../../utilities/arrayUtilities";
import { SCHEDULEDCOMMS_PROPERTY_EXCLUDE_LIST } from "../../config";
import { formatUTCDate } from "../../utilities/dateUtilities";

// If any sent/error rows selected then both buttons disabled.
// If cancelled rows selected then only 're-schedule' action button enabled.
// If cancelled and pending rows selected then only 're-schedule' will be enabled.
// If pending only rows selected then both action buttons enabled.

const sentOrDispatchedStatuses = [
  ScheduledCommStatuses[ScheduledCommStatuses.Sent],
  ScheduledCommStatuses[ScheduledCommStatuses.Dispatched],
  ScheduledCommStatuses[ScheduledCommStatuses["Dispatch Failed"]],
  ScheduledCommStatuses[ScheduledCommStatuses["No Recipients"]]];

export const cancelButtonDisabled = (selectedScheduledComms: ScheduledComm[]) => {
  if (!selectedScheduledComms.length) {
    return true;
  }
  if (
    selectedScheduledComms.some(el => el.status === ScheduledCommStatuses[ScheduledCommStatuses.Cancelled])) {
    return true;
  }
  if (
    selectedScheduledComms.some(el => sentOrDispatchedStatuses.includes(el.status))) {
    return true;
  }
  if (selectedScheduledComms.every(el => el.status === ScheduledCommStatuses[ScheduledCommStatuses.Pending])) {
    return false;
  };
  return true;
};

export const rescheduleButtonDisabled = (selectedScheduledComms: ScheduledComm[]) => {
  if (!selectedScheduledComms.length) {
    return true;
  }
  if (
    selectedScheduledComms.some(el => sentOrDispatchedStatuses.includes(el.status))) {
    return true;
  }
  if (selectedScheduledComms.every(el =>
    el.status === ScheduledCommStatuses[ScheduledCommStatuses.Failed] ||
    el.status === ScheduledCommStatuses[ScheduledCommStatuses.Pending] ||
    el.status === ScheduledCommStatuses[ScheduledCommStatuses.Cancelled])
  ) {
    return false;
  }
  return true;
};

export const getTitleText = (requestType: ScheduledCommsEditRequestType) => {
  switch (requestType) {
    case ScheduledCommsEditRequestType.Cancel:
      return "Cancel all selected emails";
    case ScheduledCommsEditRequestType.Reschedule:
      return "Reschedule all selected emails";
    default:
      return "";
  }
};

export const getContentText = (requestType: ScheduledCommsEditRequestType, numberOfSelectedRows: number) => {
  const sentenceFragment = numberOfSelectedRows > 1 ? `all ${numberOfSelectedRows} selected scheduled comms` : "the selected scheduled comm";
  switch (requestType) {
    case ScheduledCommsEditRequestType.Cancel:
      return `By pressing confirm, you will cancel ${sentenceFragment} from going out. Cancelled comms can be re-scheduled if needed.`;
    case ScheduledCommsEditRequestType.Reschedule:
      return `By pressing confirm, you will re-schedule ${sentenceFragment} to the date and time you choose below. Are you sure you wish to do this?`;
    default:
      return "";
  }
};

export const getPayloadFields = (rawPayload: RawPayload): { path: string; value: string }[] => {
  const payload = omit(rawPayload, SCHEDULEDCOMMS_PROPERTY_EXCLUDE_LIST);
  const properties = createPlaceholderList(payload);
  const uniqueOnly = uniqueValues(properties);
  return uniqueOnly;
};

const isObject = (arg: any): boolean => {
  if (arg === null) { // needed to prevent a recursive loop as typeof null returns "object"
    return false;
  }
  if (typeof arg === "object" && !Array.isArray(arg)) {
    return true;
  }
  return false;
};

const isDate = (arg: any): boolean => {
  const iso8601DateRegex = new RegExp(/\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\dZ/);
  return iso8601DateRegex.test(arg);
};

const userReadableValue = (nodeValue: any) => {
  if (nodeValue === true) return "True";
  if (nodeValue === false) return "False";
  if (isDate(nodeValue)) return formatUTCDate(nodeValue);
  return nodeValue;
};

export const createPlaceholderList = (obj: any, pathString = ""): any => {
  const keys = Object.keys(obj);
  return keys.flatMap(key => {
    const nodeValue = obj[key];
    while (isObject(nodeValue)) {
      const partialPath = key;
      return createPlaceholderList(nodeValue, partialPath);
    }
    return { path: `${pathString}.${key}`, value: userReadableValue(nodeValue) };
  });
};