export const GET_TEMPLATES = "SCHEDULINGRULE/GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "SCHEDULINGRULE/GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAIL = "SCHEDULINGRULE/GET_TEMPLATES_FAIL";

export const SAVE_SCHEDULINGRULE = "SCHEDULINGRULE/SAVE_SCHEDULINGRULE";
export const SAVE_SCHEDULINGRULE_SUCCESS = "SCHEDULINGRULE/SAVE_SCHEDULINGRULE_SUCCESS";
export const SAVE_SCHEDULINGRULE_FAIL = "SCHEDULINGRULE/SAVE_SCHEDULINGRULE_FAIL";

export const INIT_SCHEDULINGRULE = "SCHEDULINGRULE/INIT_SCHEDULINGRULE";

export const TOUCH_SCHEDULINGRULE = "SCHEDULINGRULE/TOUCH_SCHEDULINGRULE";

export const UPDATE_SCHEDULINGRULE = "SCHEDULINGRULE/UPDATE_SCHEDULINGRULE";
export const UPDATE_SCHEDULINGRULE_SUCCESS = "SCHEDULINGRULE/UPDATE_SCHEDULINGRULE_SUCCESS";
export const UPDATE_CONDITION = "SCHEDULINGRULE/UPDATE_CONDITION";
export const UPDATE_SENDCONDITION = "SCHEDULINGRULE/UPDATE_SENDCONDITION";

export const GET_SCHEDULINGRULE = "SCHEDULINGRULE/GET_SCHEDULINGRULE";
export const GET_SCHEDULINGRULE_SUCCESS = "SCHEDULINGRULE/GET_SCHEDULINGRULE_SUCCESS";
export const GET_SCHEDULINGRULE_FAIL = "SCHEDULINGRULE/GET_SCHEDULINGRULE_FAIL";
