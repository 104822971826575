import CancellationRulesList, { CancellationRulesListProps } from "./cancellationRulesList";
import { connect } from "react-redux";
import { compose } from "redux";
import CancellationRulesListStyles from "./cancellationRulesListStyles";
import { withStyles } from "@material-ui/styles";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (): Partial<CancellationRulesListProps> => ({});

const mapDispatchToProps = () => ({});

const CancellationRulesListContainer = compose(
  withStyles(CancellationRulesListStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.CancellationRule)
)(CancellationRulesList) as React.ComponentType<any>;

export default CancellationRulesListContainer;
