/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/camelcase */
import * as React from "react";
import { FieldType } from "./enums";
import { OperatorType, Field } from "./store/types";
import { AppInput, AppSwitch, AppDynamicDatePicker, AppAutoComplete } from "./controls";

export const getOperatorByFieldType = (fieldType: FieldType, fields: Field[]) => {
  const hasFields = fields.some((f: Field) => f.type === fieldType);
  return Object.values(operators).filter((o: OperatorType) => o.supportedTypes.includes(fieldType) && (o.name !== "field" || hasFields));
};

const handleDynamicDate = (values: any[]) => {
  if (values[0]?.isToday) {
    if (values[0]?.offset) {
      return `{{date:today + ${values[0]?.offset}}}`;
    }
    return "{{date:today}}";
  }
  if (values[0].date) {
    const date = new Date(Date.parse(values[0]?.date));
    if (date) {
      return `{{date:${new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toUTCString()}}}`;
    }
    return `{{date:${values[0].date}}}`;
  }

  return values[0];
};

export const operators: { [name: string]: OperatorType } = {
  equal: {
    name: "equal",
    requiredValues: 1,
    label: "==",
    labelForFormat: "equal to",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "==": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.String, FieldType.Number, FieldType.Date],
    inputs: {
      String: [(key: number, props: any) => <AppInput key={key} {...props} />],
      Number: [(key: number, props: any) => <AppInput key={key} isNumeric={true} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  not_equal: {
    name: "not_equal",
    requiredValues: 1,
    label: "!=",
    labelForFormat: "not equal to",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "!=": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.String, FieldType.Number, FieldType.Date],
    inputs: {
      String: [(key: number, props: any) => <AppInput key={key} {...props} />],
      Number: [(key: number, props: any) => <AppInput key={key} isNumeric={true} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  less: {
    name: "less",
    requiredValues: 1,
    label: "<",
    labelForFormat: "less than",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "<": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.Number, FieldType.Date],
    inputs: {
      Number: [(key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  less_or_equal: {
    name: "less_or_equal",
    requiredValues: 1,
    label: "<=",
    labelForFormat: "less or equal than",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "<=": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.Number, FieldType.Date],
    inputs: {
      Number: [(key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  greater: {
    name: "greater",
    requiredValues: 1,
    label: ">",
    labelForFormat: "greater than",
    jsonLogic: (fieldName: any, values: any[]) => ({
      ">": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.Number, FieldType.Date],
    inputs: {
      Number: [(key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  greater_or_equal: {
    name: "greater_or_equal",
    requiredValues: 1,
    label: ">=",
    labelForFormat: "greater or equal than",
    jsonLogic: (fieldName: any, values: any[]) => ({
      ">=": [{ var: fieldName }, handleDynamicDate(values)],
    }),
    supportedTypes: [FieldType.Number, FieldType.Date],
    inputs: {
      Number: [(key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />],
      Date: [(key: number, props: any) => <AppDynamicDatePicker key={key} {...props} />],
    },
  },
  like: {
    name: "like",
    requiredValues: 1,
    label: "Like",
    labelForFormat: "Like",
    jsonLogic: (fieldName: any, values: any[]) => ({
      in: [values[0], { var: fieldName }],
    }),
    supportedTypes: [FieldType.String],
    inputs: {
      String: [(key: number, props: any) => <AppInput key={key} {...props} />],
    },
  },
  not_like: {
    name: "not_like",
    requiredValues: 1,
    label: "Not like",
    labelForFormat: "Not Like",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "!": { in: [values[0], { var: fieldName }] },
    }),
    supportedTypes: [FieldType.String],
    inputs: {
      String: [(key: number, props: any) => <AppInput key={key} {...props} />],
    },
  },
  between: {
    name: "between",
    requiredValues: 2,
    label: "Between",
    labelForFormat: "BETWEEN",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "<=": [values[0], { var: fieldName }, values[1]],
    }),
    supportedTypes: [FieldType.Number],
    inputs: {
      Number: [
        (key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />,
        (key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />,
      ],
    },
  },
  not_between: {
    name: "not_between",
    requiredValues: 2,
    label: "Not Between",
    labelForFormat: "NOT BETWEEN",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "!": {
        "<=": [values[0], { var: fieldName }, values[1]],
      },
    }),
    supportedTypes: [FieldType.Number],
    inputs: {
      Number: [
        (key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />,
        (key: number, props: any) => <AppInput isNumeric={true} key={key} {...props} />,
      ],
    },
  },
  is_empty: {
    name: "is_empty",
    requiredValues: 0,
    label: "Is empty",
    labelForFormat: "IS EMPTY",
    jsonLogic: (fieldName: any) => ({
      "!": { var: fieldName },
    }),
    supportedTypes: [FieldType.String, FieldType.Number, FieldType.Date],
    inputs: {},
  },
  is_not_empty: {
    name: "is_not_empty",
    requiredValues: 0,
    label: "Is not empty",
    labelForFormat: "IS NOT EMPTY",
    jsonLogic: (fieldName: any) => ({
      "!!": { var: fieldName },
    }),
    supportedTypes: [FieldType.String, FieldType.Number, FieldType.Date],
    inputs: {},
  },
  is: {
    name: "is",
    requiredValues: 1,
    label: "Is",
    labelForFormat: "IS",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "==": [{ var: fieldName }, values[0]],
    }),
    supportedTypes: [FieldType.Boolean],
    inputs: {
      Boolean: [(key: number, props: any) => <AppSwitch key={key} {...props} />],
    },
  },
  is_not: {
    name: "is_not",
    requiredValues: 1,
    label: "Is not",
    labelForFormat: "IS NOT",
    jsonLogic: (fieldName: any, values: any[]) => ({
      "!=": [{ var: fieldName }, values[0]],
    }),
    supportedTypes: [FieldType.Boolean],
    inputs: {
      Boolean: [(key: number, props: any) => <AppSwitch key={key} {...props} />],
    },
  },
  field: {
    name: "field",
    requiredValues: 1,
    label: "Field",
    labelForFormat: "FIELD",
    jsonLogic: (fieldName: any) => ({
      "!!": { var: fieldName },
    }),
    supportedTypes: [FieldType.String, FieldType.Number, FieldType.Date, FieldType.Boolean],
    inputs: {
      String: [(key: number, props: any) => <AppAutoComplete key={key} {...props} />],
      Number: [(key: number, props: any) => <AppAutoComplete key={key} {...props} />],
      Date: [(key: number, props: any) => <AppAutoComplete key={key} {...props} />],
      Boolean: [(key: number, props: any) => <AppAutoComplete key={key} {...props} />],
    },
  },
};
