import { dangerColor } from "../../../utilities/colors";
import { createStyles } from "@material-ui/core";

const AliasDialogStyles = () =>
  createStyles({
    errorMessage: {
      color: dangerColor[0],
    }
  });

export default AliasDialogStyles;
