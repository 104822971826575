import React, { useEffect } from "react";
import { CardColor, Size } from "../../../enums";
import {
  AppGridItem,
  AppGridContainer,
  AppExpansionPanel,
  AppInput,
  AppSelect,
  AppDateRangeButton
} from "../../../components";
import { DateRange, Status } from "../../../store/areas/shared";
import { Filter } from "../../../store/areas/snippetsList/types";
import { defaultFilterValues } from "../../../store/areas/snippetsList/reducer";

export interface SnippetsFilterProps {
  classes: any;
  statuses: Status[];
  filter: Filter;
  tenantId: string;
  updateFilter: (filter: Filter) => void;
  applyFilter: () => void;
  initFilter: () => void;
}
const SnippetsFilter: React.FunctionComponent<SnippetsFilterProps> = ({
  classes,
  statuses,
  filter,
  tenantId,
  updateFilter,
  applyFilter,
  initFilter
}) => {
  useEffect(() => {
    initFilter();
  }, [initFilter, tenantId]);

  const clearFilter = () => {
    updateFilter(defaultFilterValues);
    applyFilter();
  };

  const actions = [
    { name: "Clear", color: CardColor.rose, size: Size.sm, onClick: clearFilter },
    { name: "Apply", color: CardColor.primary, size: Size.sm, onClick: applyFilter },
  ];

  return (
    <AppGridItem xs={12}>
      <AppExpansionPanel title="Filters" actions={actions}>
        <AppGridContainer>
          <AppGridItem xs={12} md={6} lg={6}>
            <AppInput
              labelText="Snippet Name"
              id="snippets-snippet-name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: filter.snippetName,
                onChange: (e: any) => updateFilter({ ...filter, snippetName: e.target.value })
              }}
            />
          </AppGridItem>
          <AppGridItem xs={12} md={6} lg={6}>
            <AppSelect
              includeBlank={true}
              data={statuses}
              labelText="Status"
              id="snippets-status"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: filter.status,
                onChange: (e: any) => updateFilter({ ...filter, status: e.target.value })
              }}
            />
          </AppGridItem>
          <AppGridItem xs={12} md={6} lg={6}>
            <div className={classes.dateRangeButton}>
              <AppDateRangeButton
                caption={"Date Modified"}
                disableFutureProps={{ startDate: true, endDate: true }}
                dateRange={filter.dateCreated}
                id="snippets-date-modified"
                onUpdate={(dateRange: DateRange) =>
                  updateFilter({
                    ...filter,
                    dateCreated: dateRange
                  })
                }
              />
            </div>
          </AppGridItem>
        </AppGridContainer>
      </AppExpansionPanel>
    </AppGridItem>
  );
};

export default SnippetsFilter;
