import { makeStyles, Theme } from "@material-ui/core/styles";

const AppSwitchStyles = makeStyles((theme: Theme) => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  labelRootError: {
    color: theme.palette.error.main + " !important"
  },
  labelRootSuccess: {
    color: theme.palette.success.main + " !important"
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    margin: "8px",
    top: "20px",
    position: "relative",
    verticalAlign: "unset"
  }
}));

export default AppSwitchStyles;
