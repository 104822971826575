import TemplateAttachment, { TemplateAttachmentProps } from "./templateAttachment";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/rootReducer";
import TemplateAttachmentStyles from "./templateAttachmentStyles";
import { Template, updateTemplate, TemplateAttachmentRequest, uploadTemplateAttachment } from "../../../store/areas/template";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<TemplateAttachmentProps> => ({
  attachments: state.template.template.templateAttachments,
  isUploading: state.template.isUploadingAttachment,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateTemplate: (template: Template) => dispatch(updateTemplate(template)),
  uploadTemplateAttachment: (request: TemplateAttachmentRequest) => dispatch(uploadTemplateAttachment(request)),
});

const TemplateAttachmentContainer = compose(
  withStyles(TemplateAttachmentStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Template)
)(TemplateAttachment) as React.ComponentType<any>;

export default TemplateAttachmentContainer;
