import { AnyAction } from "redux";
import {
  GET_SCHEDULED_COMMS,
  GET_SCHEDULED_COMMS_SUCCESS,
  GET_SCHEDULED_COMMS_FAIL,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_TABLE_HEADERS_FAIL,
  UPDATE_TABLE_HEADERS,
  UPDATE_FILTER,
  UPDATE_FILTER_CONDITION,
  APPLY_FILTER,
  SELECT_SCHEDULED_COMM,
  DESELECT_SCHEDULED_COMM,
  EDIT_SCHEDULED_COMMS,
  EDIT_SCHEDULED_COMMS_SUCCESS,
  EDIT_SCHEDULED_COMMS_FAIL,
  ScheduledComm,
  ScheduledCommsRequest,
  Filter
} from ".";
import { SortOrder, FieldType } from "../../../enums";
import { UpdateTableHeadersObject, Condition, Field, TriggerDetails, Dictionary } from "../shared";
import { EditScheduledCommsRequest, ScheduledCommsEditRequestType } from "../scheduledCommsEdit";
import { store } from "../../../app";
import { RootState } from "../../rootReducer";

export const getScheduledComms = (scheduledCommsRequest: ScheduledCommsRequest): AnyAction => {
  const request = {
    ...scheduledCommsRequest,
    sortField: scheduledCommsRequest.sortField,
  };

  return {
    type: GET_SCHEDULED_COMMS,
    scheduledCommsRequest: request,
  };
};

export const getScheduledCommsSuccess = (scheduledComms: ScheduledComm[], totalRows: number): AnyAction => {
  return {
    type: GET_SCHEDULED_COMMS_SUCCESS,
    scheduledComms,
    totalRows,
  };
};

export const getScheduledCommsFailure = (error: Error): AnyAction => {
  return {
    type: GET_SCHEDULED_COMMS_FAIL,
    message: error.message || "Unable to get list of scheduled comms, please contact KLP Support",
    error,
  };
};

export const updateFilter = (filter: Filter, triggers: Dictionary<TriggerDetails> | undefined = undefined): AnyAction => {
  const state = store.getState() as RootState;

  if (triggers !== undefined || filter?.triggers?.length !== state.scheduledCommsList.filter?.triggers?.length) {
    if (triggers === undefined) {
      triggers = state.shared.triggers;
    }

    const triggerFields: Field[] = [];

    const filterOutDates = (x: Field) => x.type !== FieldType.Date && x.type !== FieldType.DateTime;

    if (!filter?.triggers?.length) {
      for (const triggerId in triggers) {
        const trigger = triggers[triggerId];
        triggerFields.push(...trigger.fields.filter(filterOutDates));
      }
    } else {
      for (const triggerId of filter.triggers) {
        const trigger = triggers[triggerId];
        triggerFields.push(...trigger.fields.filter(filterOutDates));
      }
    }

    const sortedFields = triggerFields.sort((a, b) => {
      const aPath = a.path.toLowerCase();
      const bPath = b.path.toLowerCase();
      return aPath > bPath ? 1 : aPath < bPath ? -1 : 0;
    });

    filter.triggerFields = sortedFields;
  }

  return {
    type: UPDATE_FILTER,
    filter,
  };
};

export const updateFilterCondition = (condition: Condition): AnyAction => {
  return {
    type: UPDATE_FILTER_CONDITION,
    condition,
  };
};

export const applyFilter = (): AnyAction => ({ type: APPLY_FILTER });

export const updateTableHeaders = (updateTableHeadersObject: UpdateTableHeadersObject) => {
  const { columnIndex, sortOrder } = updateTableHeadersObject;
  return {
    type: UPDATE_TABLE_HEADERS,
    columnIndex,
    sortOrder,
  };
};

export const updateTableHeadersSuccess = (columnIndex: number, sortOrder: SortOrder) => {
  return {
    type: UPDATE_TABLE_HEADERS_SUCCESS,
    columnIndex,
    sortOrder,
  };
};

export const updateTableHeadersFailure = (error: Error): AnyAction => {
  return {
    type: UPDATE_TABLE_HEADERS_FAIL,
    message: error.message || "Error rendering table headers. Please refresh and try again",
    error,
  };
};

export const selectScheduledComm = (scheduledComm: ScheduledComm[], dataIndex: number[]): AnyAction => {
  return {
    type: SELECT_SCHEDULED_COMM,
    selectedScheduledComm: scheduledComm,
    selectedDataIndex: dataIndex,
  };
};

export const deselectScheduledComm = (scheduledComm: ScheduledComm[], dataIndex: number[]): AnyAction => {
  return {
    type: DESELECT_SCHEDULED_COMM,
    selectedScheduledComm: scheduledComm,
    selectedDataIndex: dataIndex,
  };
};

export const editScheduledComms = (editRequest: EditScheduledCommsRequest): AnyAction => {
  return {
    type: EDIT_SCHEDULED_COMMS,
    editRequest,
  };
};

export const editScheduledCommsSuccess = (editRequest: EditScheduledCommsRequest): AnyAction => {
  const { requestType, scheduledCommIds } = editRequest;
  const count = scheduledCommIds.length;
  let message;
  switch (requestType) {
    case ScheduledCommsEditRequestType.Cancel:
      message = `${count} selected scheduled comms have been cancelled.`;
      break;
    case ScheduledCommsEditRequestType.Reschedule:
      message = `${count} selected scheduled comms have been re-scheduled.`;
      break;
    default:
      message = `${count} selected scheduled comms have been updated`;
  }
  return {
    type: EDIT_SCHEDULED_COMMS_SUCCESS,
    message,
  };
};

export const editScheduledCommsFailure = (error: Error): AnyAction => {
  return {
    type: EDIT_SCHEDULED_COMMS_FAIL,
    error,
    message: error.message || "Unable to edit the selected scheduled comms, please contact IT Support",
  };
};
