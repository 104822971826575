import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppGridItemStyles from "./appGridItemStyle";

const Item: React.FunctionComponent<any> = props => {
  const { children, classes, flex, className, ...rest } = props;

  const itemClasses = classNames({
    [classes.grid]: true,
    [classes.flex]: flex,
    [className]: className
  });

  return (
    <Grid item {...rest} className={itemClasses}>
      {children}
    </Grid>
  );
};

export const AppGridItem = withStyles(AppGridItemStyles)(Item);
