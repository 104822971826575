import React, { useEffect } from "react";
import { AppTable, AppButton, AppTableCell, AppTableRow } from "../../../components";
import { Column, UpdateTableHeadersObject } from "../../../store/areas/shared";
import { ScheduledComm, ScheduledCommsRequest, parseMUIColumnToEnum, Filter } from "../../../store/areas/scheduledCommsList/types";
import { SortOrder, TableTitle, CardColor, Size, RouterName, ExternalRouterName } from "../../../enums";
import { ROWS_PER_TABLE_PAGE_LARGE } from "../../../config";
import { getPayloadFields as getPayloadProperties } from "../utils";
import { generateKeyValueCondition, emptyFilter } from "../../../store/areas/scheduledCommsList";
import { ViewPermission } from "../../../role";

export interface ScheduledCommsTableProps {
  classes: any;
  columns: Column[];
  deselectScheduledComm: (scheduledComm: ScheduledComm[], dataIndex: number[]) => void;
  getScheduledComms: (scheduledCommsRequest: ScheduledCommsRequest) => void;
  history: any;
  itemsPerPage: number;
  initScheduledComms: () => void;
  updateFilter: (filter: Filter) => void;
  queries: ScheduledCommsRequest;
  scheduledComms: ScheduledComm[];
  selectedDataIndices?: number[];
  selectedScheduledComms?: ScheduledComm[];
  selectScheduledComm: (scheduledComm: ScheduledComm[], dataIndex: number[]) => void;
  totalRows: number;
  updateTableHeaders: (updateTableHeaders: UpdateTableHeadersObject) => void;
  loading: boolean;
  match: any;
  permission: ViewPermission;
}

const ScheduledCommsTable: React.FunctionComponent<ScheduledCommsTableProps> = ({
  columns,
  deselectScheduledComm,
  getScheduledComms,
  history,
  initScheduledComms,
  itemsPerPage,
  queries,
  scheduledComms,
  totalRows,
  updateTableHeaders,
  selectedDataIndices,
  selectedScheduledComms,
  selectScheduledComm,
  updateFilter,
  classes,
  match,
  loading,
  permission,
}) => {
  const { key, value } = match?.params ?? {};

  const external = Boolean(key && value);

  useEffect(() => {
    if (external) {
      updateFilter({ ...emptyFilter, condition: generateKeyValueCondition(key, value) });
    }
  }, [match, key, value, external, updateFilter]);

  useEffect(() => {
    initScheduledComms();
  }, [initScheduledComms]);

  const changePage = (pageRequested: number) => {
    const changePageRequest: ScheduledCommsRequest = {
      ...queries,
      itemsPerPage,
      pageRequested,
    };
    getScheduledComms(changePageRequest);
  };

  const enableViewComm = (status: string): boolean => {
    const viewStatuses = ["Dispatched", "Sent", "Failed", "Dispatch Failed"];
    return viewStatuses.includes(status) ? true : false;
  };

  const customCellNode = (scheduledComm: ScheduledComm) => {
    return enableViewComm(scheduledComm.status) ? (
      <AppButton
        color={CardColor.primary}
        onClick={() => history.push(`${key ? ExternalRouterName.SentComms : RouterName.SentComms}/${scheduledComm.id}`)}
        round
        size={Size.sm}
        thinRows
      >
        View Audit
      </AppButton>
    ) : (
      ""
    );
  };

  const sortChange = (changedColumn: string) => {
    const { pageRequested } = queries;
    const sortField = parseMUIColumnToEnum(changedColumn);
    let sortAscending: boolean;
    if (sortField === queries.sortField) {
      sortAscending = !queries.sortAscending;
    } else {
      sortAscending = true;
    }
    const sortChangeRequest: ScheduledCommsRequest = {
      itemsPerPage,
      sortAscending,
      sortField,
      pageRequested,
    };
    getScheduledComms(sortChangeRequest);
  };

  const onTableChange = (action: string, tableState: any) => {
    switch (action) {
      case "sort":
        const { activeColumn: columnIndex } = tableState;
        const { sortDirection } = tableState.columns[columnIndex];
        const mapStringToEnum: any = {
          desc: SortOrder.asc,
          asc: SortOrder.desc,
        };
        const sortOrder: SortOrder = mapStringToEnum[sortDirection];
        const updateTableHeadersObject: UpdateTableHeadersObject = {
          columnIndex,
          sortOrder,
        };
        updateTableHeaders(updateTableHeadersObject);
        break;
      default:
        return;
    }
  };

  const onRowSelect = (data?: any[], index?: any, currentRowsSelected?: number) => {
    if (selectedScheduledComms!.length < currentRowsSelected!) {
      selectScheduledComm(data!, index);
    } else {
      deselectScheduledComm(data!, index);
    }
  };

  const renderExpandableRow = (rowData: any, rowMeta: any) => {
    const payloadForCurrentRow = scheduledComms[rowMeta.rowIndex].payload;
    const properties = getPayloadProperties(payloadForCurrentRow);
    return (
      <AppTableRow shadingOnHover id={`payload-properties-row-${rowMeta.rowIndex}`}>
        <AppTableCell className={classes.tableCell} id={`payload-property-${rowMeta.rowIndex}`} colSpan={rowData.length + 1}>
          <div className={classes.payloadPropertiesContainer}>
            {properties.map((el, idx) => {
              return (
                <div className={classes.payloadProperty} key={idx}>
                  <span className={classes.payloadPropertyKey}>{el.path}</span>:{" "}
                  <span className={classes.payloadPropertyValue}>{JSON.stringify(el.value)}</span>
                </div>
              );
            })}
          </div>
        </AppTableCell>
      </AppTableRow>
    );
  };

  return (
    <>
      {external && loading ? (
        "Loading"
      ) : (
        <AppTable
          rowsPerPage={ROWS_PER_TABLE_PAGE_LARGE}
          columns={columns}
          title={TableTitle.ScheduledComms}
          data={scheduledComms}
          count={totalRows}
          selectableRows={permission.write ? "multiple" : "none"}
          onSelect={onRowSelect}
          rowsSelected={selectedDataIndices}
          selectableRowsHeader={true}
          serverSide={true}
          sort={true}
          thinRows
          onChangePage={(pageNumber: number) => changePage(pageNumber)}
          onTableChange={(action: string, tableState: any) => onTableChange(action, tableState)}
          pageRequested={queries.pageRequested}
          customCellNode={customCellNode}
          // eslint-disable-next-line
          onColumnSortChange={(changedColumn: string) => sortChange(changedColumn)}
          makeRowsExpandable={true}
          renderExpandableRow={renderExpandableRow}
        />
      )}
    </>
  );
};

export default ScheduledCommsTable;
