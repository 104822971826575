import { RawDraftContentState } from "draft-js";
import { Field, Trigger, AugmenterMappings, AugmenterInputMappings, Dictionary } from "../shared";

export interface Snippet {
  name: string;
}

export interface Template {
  id: string;
  name: string;
  subject: string;
  html: string;
  text: string;
  subjectRawContent: RawDraftContentState;
  rawContent: RawDraftContentState;
  templateType: TemplateType | 0;
  trigger: Trigger;
  active: boolean;
  augmenterMappings: AugmenterMappings[];
  combinedFields: Field[];
  templateAttachments: TemplateAttachment[];
}

export interface TemplatePayload {
  id: string;
  name: string;
  subject: string;
  text: string;
  html: string;
  subjectRawContent: string;
  rawContent: string;
  templateType: TemplateType | 0;
  triggerId: string;
  triggerName: string;
  disabled: boolean;
  augmenterMappings: Dictionary<AugmenterInputMappings>;
  attachments: TemplateAttachment[];
}

export interface Touched {
  name: boolean;
  subject: boolean;
  text: boolean;
  templateType: boolean;
  trigger: boolean;
  augmenterMappings: boolean;
}

export enum TemplateType {
  "HTML" = 1,
  "Text" = 2,
}

export interface PreviewInfo {
  queued: boolean;
  email: string;
}

export interface TemplateAttachment {
  name: string;
  templateAttachmentId: string;
  size: string;
}

export interface TemplateAttachmentRequest {
  templateAttachment: TemplateAttachment;
  templateAttachmentData: any;
}
