import { makeStyles, Theme } from "@material-ui/core";

const AppDatePickerStyles = makeStyles((theme: Theme) => ({
  dateInput: {
    width: "100px",
    padding: "6px 4px 7px 0px",
  },
  labelRoot: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset",
  },
  labelRootError: {
    color: theme.palette.error.main + " !important",
  },
  labelRootSuccess: {
    color: theme.palette.success.main + " !important",
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none",
  },
  marginTop: {
    marginTop: "16px",
  },
  formControl: {
    margin: "8px",
    position: "relative",
  },
}));

export default AppDatePickerStyles;
