import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import ExtensionIcon from "@material-ui/icons/Extension";
import EmailIcon from "@material-ui/icons/Email";
import Schedule from "@material-ui/icons/Schedule";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { IconName } from "../../enums";

export const getIcon = (iconName: IconName) => {
  switch (iconName) {
    case "Templates":
      return AssignmentIcon;
    case "SchedulingRules":
      return Schedule;
    case "CancellationRules":
      return CancelScheduleSendIcon;
    case "Snippets":
      return ExtensionIcon;
    case "ScheduledComms":
      return EmailIcon;
    case "SentComms":
      return VisibilityIcon;
    default:
      return DashboardIcon;
  }
};
