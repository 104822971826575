import React from "react";
import { CardColor, NotificationChannelType, RecipientSourceType } from "../../enums";
import { AppGridContainer, AppGridItem, AppCardHeader, AppCardBody, AppCard, AppAutocomplete, AppInput } from "../../components";
import { Touched, SchedulingRule, RecipientOverride } from "../../store/areas/schedulingRule/types";
import { Field } from "../../store/areas/shared";
import { ViewPermission } from "../../role";

export interface RecipientProps {
  classes: any;
  emailFields: Field[];
  uiFields: Field[];
  emailRecipient: string;
  uiRecipient: string;
  onChange: (e: any) => void;
  onTouch: (touched: Touched) => void;
  touched: Touched;
  schedulingRule: SchedulingRule;
  permission: ViewPermission;
}

const Recipient: React.FunctionComponent<RecipientProps> = ({
  classes,
  emailFields,
  uiFields,
  emailRecipient,
  uiRecipient,
  onChange,
  onTouch,
  touched,
  schedulingRule,
  permission,
}) => {
  const checkRecipientOverride = (value: string): RecipientOverride => {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = emailRegex.exec(value) ? true : false;
    return {
      value,
      isValid,
    };
  };

  return (
    <AppCard>
      <AppCardHeader color={CardColor.primary} stats={true}>
        <h4 className={classes.cardTitleWhite}>Recipient Source</h4>
      </AppCardHeader>
      <AppCardBody>
        <AppGridContainer>
          {emailFields && (
            <AppGridItem xs={12} sm={12} md={6}>
              <AppAutocomplete
                id={`schedulingrule-recipient`}
                formControlProps={{
                  fullWidth: true,
                }}
                labelText="Choose Email Recipient Source"
                options={emailFields?.map(x => x.path) ?? []}
                inputProps={{
                  value: emailRecipient,
                  onChange: (e: any) => {
                    onChange({
                      ...schedulingRule,
                      emailRecipientsSource: e.target.textContent,
                      emailRecipientOverride: { value: "", isValid: false },
                      recipientsSourceType: RecipientSourceType.FromPayload,
                    });
                  },
                  disabled:
                    !schedulingRule.template.id.length ||
                    !permission.write ||
                    (schedulingRule.notificationChannelTypes.length === 1 &&
                      schedulingRule.notificationChannelTypes.includes(NotificationChannelType.UI)) ||
                    !schedulingRule.notificationChannelTypes.length,
                }}
                touched={touched?.emailRecipient}
                onTouch={() => {
                  onTouch({ ...touched, emailRecipient: true });
                }}
                error={
                  touched.emailRecipient &&
                  schedulingRule.notificationChannelTypes.includes(NotificationChannelType.Email) && !schedulingRule.emailRecipientsSource
                }
              />
            </AppGridItem>
          )}
          {emailRecipient.includes("Override ") && (
            <AppGridItem xs={12} sm={12} md={6}>
              <AppInput
                touched={touched.emailRecipientOverride}
                onTouch={() => onTouch({ ...touched, emailRecipientOverride: true })}
                labelText="Email Recipient Override"
                id="schedulingrule-recipientOverride"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: schedulingRule.emailRecipientOverride.value,
                  onChange: (e: any) =>
                    onChange({
                      ...schedulingRule,
                      emailRecipientOverride: checkRecipientOverride(e.target.value),
                      recipientsSourceType: RecipientSourceType.FromRecipientSource,
                    }),
                  disabled:
                    schedulingRule.notificationChannelTypes.length === 1 &&
                    schedulingRule.notificationChannelTypes.includes(NotificationChannelType.UI),
                }}
                error={touched.emailRecipientOverride && !schedulingRule.emailRecipientOverride.isValid}
              />
            </AppGridItem>
          )}
        </AppGridContainer>
        <AppGridContainer>
          {uiFields && (
            <AppGridItem xs={12} sm={12} md={6}>
              <AppAutocomplete
                id={`schedulingrule-recipient`}
                formControlProps={{
                  fullWidth: true,
                }}
                labelText="Choose UI Recipient Source"
                options={uiFields?.map(x => x.path) ?? []}
                inputProps={{
                  value: uiRecipient,
                  onChange: (e: any) => {
                    onChange({
                      ...schedulingRule,
                      uiRecipientsSource: e.target.textContent,
                      recipientsSourceType: RecipientSourceType.FromPayload,
                    });
                  },
                  disabled:
                    !schedulingRule.template.id.length ||
                    !permission.write ||
                    (schedulingRule.notificationChannelTypes.length === 1 &&
                      schedulingRule.notificationChannelTypes.includes(NotificationChannelType.Email)) ||
                    !schedulingRule.notificationChannelTypes.length,
                }}
                touched={touched?.uiRecipient}
                onTouch={() => {
                  onTouch({ ...touched, uiRecipient: true });
                }}
                error={
                  touched.uiRecipient &&
                  schedulingRule.notificationChannelTypes.includes(NotificationChannelType.UI) && !schedulingRule.uiRecipientsSource
                }
              />
            </AppGridItem>
          )}
        </AppGridContainer>
      </AppCardBody>
    </AppCard>
  );
};
export default Recipient;
