import { EditorState, Modifier } from 'draft-js';
import { getSelectedBlock } from 'draftjs-utils';
import { Suggestion } from './configProps';

export default function addSuggestion(
  editorState: EditorState,
  onChange: Function,
  separator: string,
  trigger: string,
  suggestion: Suggestion,
  typeName: string,
  prefix: string,
  suffix: string
): void {

  const value = `${trigger}${prefix ?? ""}${suggestion.value}${suffix ?? ""}`;
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(typeName, "IMMUTABLE", { text: value, value, url: null })
    .getLastCreatedEntityKey();

  const selectedBlock = getSelectedBlock(editorState);
  const selectedBlockText: string = selectedBlock.getText();
  let focusOffset = editorState.getSelection().get("focusOffset");
  const regex = new RegExp(` ${trigger}(?!${prefix})\\w*`, "g");

  const match = regex.exec(selectedBlockText);

  const suggestionIndex = match ? (match.index) + 1 : 0;
  let spaceAlreadyPresent = false;

  if (match !== null) {
    focusOffset = match.index + match[0].length + trigger.length;
  }

  if (selectedBlockText[focusOffset] === ' ') {
    spaceAlreadyPresent = true;
  }

  let updatedSelection: any = editorState.getSelection().merge({
    anchorOffset: suggestionIndex,
    focusOffset,
  });
  let newEditorState = EditorState.acceptSelection(editorState, updatedSelection);
  let contentState = Modifier.replaceText(
    newEditorState.getCurrentContent(),
    updatedSelection,
    value,
    newEditorState.getCurrentInlineStyle(),
    entityKey
  );

  newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

  if (!spaceAlreadyPresent) {
    // insert a blank space after suggestion
    updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: suggestionIndex + value.length,
      focusOffset: suggestionIndex + value.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, updatedSelection);
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      updatedSelection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined,
    );
  }

  newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

  onChange(newEditorState);
}
