import React, { useEffect } from "react";
import { AppButton, AppGridContainer, AppGridItem, AppInput } from "../../components";
import { CardColor, Size } from "../../enums";
import { Touched } from "../../store/areas/templatePreview";
import { Dialog } from "../../store/areas/shared";
import { emailIsValid } from "./utils";

export interface TemplatePreviewProps {
  email: string;
  userName: string;
  touched: Touched;
  templateId: string;
  dialog: Dialog;
  updateEmail: (email: string) => void;
  queueEmail: (email: string) => void;
  onTouch: (touched: Touched) => void;
  closeDialog: () => void;
}

const TemplatePreview: React.FunctionComponent<TemplatePreviewProps> = ({
  email,
  userName,
  touched,
  updateEmail,
  queueEmail,
  onTouch,
  closeDialog,
}) => {
  useEffect(() => {
    updateEmail(userName);
    // eslint-disable-next-line
  }, []);

  const queueHandler = () => {
    if (emailIsValid(email)) {
      queueEmail(email);
    }
    onTouch({ ...touched, email: true });
  };

  return (
    <>
      <AppGridItem xs={12} sm={12} md={12}>
        <AppInput
          touched={touched.email}
          onTouch={() => onTouch({ ...touched, email: true })}
          labelText="Email Address"
          id="template-preview-email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "email",
            value: email,
            onChange: (e: any) => updateEmail(e.target.value),
          }}
          error={touched.email && !emailIsValid(email)}
        />
      </AppGridItem>
      <AppGridContainer justify="flex-end" alignItems="center">
        <AppButton color={CardColor.info} onClick={queueHandler} size={Size.md}>
          Queue Email
        </AppButton>
        <AppButton color={CardColor.rose} onClick={closeDialog} size={Size.md}>
          Go Back
        </AppButton>
      </AppGridContainer>
    </>
  );
};

export default TemplatePreview;
