import { createStyles } from "@material-ui/core";

const LoaderViewStyles = () =>
  createStyles({
    loaderContainer: {
      position: "fixed",
      right: "0px",
      left: "0px",
      zIndex: 10000
    },
    root: {
      flexGrow: 1
    },
    barColorPrimary: {
      backgroundColor: "#00348d"
    },
    colorPrimary: {
      backgroundColor: "#fafafa"
    },
    barRoot: {
      height: "7px"
    }
  });

export default LoaderViewStyles;
