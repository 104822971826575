import * as React from "react";
import { SharedProps } from "../shared";
import { Rule, Field } from "../../store";
import RuleItem from "./RuleItem";
import { getRulesError } from "../../utils";

export interface RulesProps extends SharedProps {
  removeRule: (rule: Rule) => void;
  updateRule: (rule: Rule) => void;
  rules: Rule[];
  fields: { [name: string]: Field };
  extraFields: { [name: string]: Field };
}

const Rules: React.FC<RulesProps> = ({
  classes,
  rules,
  fields,
  extraFields,
  removeRule,
  updateRule,
}: RulesProps) => {
  const errors = getRulesError(rules);
  return (
    <div className={classes.groupChildren}>
      {rules.map((r: Rule, index: number) => (
        <RuleItem
          classes={classes}
          key={index}
          rule={r}
          fields={fields}
          removeRule={removeRule}
          updateRule={updateRule}
          ruleError={errors[r.id]}
          extraFields={extraFields}
        />
      ))}
    </div>
  );
};

export default Rules;
