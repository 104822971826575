import React from "react";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import AppSecure from "./appSecure";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import withAppInsights from "./appInsights";
import LoginContainer from "./views/login/loginContainer";

export const store = configureStore();

export class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={LoginContainer} exact />
            <Route path="/logout/:reason" component={LoginContainer} exact />
            <AppSecure store={store} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withAppInsights(App);
