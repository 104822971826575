import { MUIColumnNames, TableRequest, DateRange, Trigger } from "../shared";
import { TemplateAttachment } from "../template/types";

export interface Template {
  id: string;
  name: string;
  triggerName: string;
  lastModified: Date;
  attachments?: TemplateAttachment[];
  active?: boolean;
}

// namespace KLP.Comms.Enums.TemplateSearchSortFields for back end mapping
export enum TemplateTableFields {
  "Name" = 1,
  "TriggerName" = 2,
  "LastModified" = 3,
  "Active" = 4
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum TemplateColumnIndex {
  "Name" = TemplateTableFields.Name - 1,
  "TriggerName" = TemplateTableFields.TriggerName - 1,
  "LastModified" = TemplateTableFields.LastModified - 1,
  "Active" = TemplateTableFields.Active - 1
}

// namespace KLP.Comms.Enums.TemplateType for back end mapping
export enum TemplateType {
  "HTML" = 1,
  "Text" = 2
}

export const parseMUIColumnToEnum = (columnName: string): TemplateTableFields => {
  switch (columnName) {
    case MUIColumnNames.name:
      return TemplateTableFields.Name;
    case MUIColumnNames.triggerName:
      return TemplateTableFields.TriggerName;
    case MUIColumnNames.lastModified:
      return TemplateTableFields.LastModified;
    case MUIColumnNames.active:
      return TemplateTableFields.Active;
    default:
      return TemplateTableFields.Name;
  }
};

export interface TemplatesRequest extends TableRequest {
  sortField?: TemplateTableFields;
}

export interface Filter {
  templateName: string;
  triggers: Trigger[];
  dateCreated: DateRange;
  status: string;
}
