export const GET_CANCELLATION_RULES = "CANCELLATION_RULES_LIST/GET_CANCELLATION_RULES";
export const GET_CANCELLATION_RULES_SUCCESS = "CANCELLATION_RULES_LIST/GET_CANCELLATION_RULES_SUCCESS";
export const GET_CANCELLATION_RULES_FAIL = "CANCELLATION_RULES_LIST/GET_CANCELLATION_RULES_FAIL";

export const UPDATE_TABLE_HEADERS = "CANCELLATION_RULES_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "CANCELLATION_RULES_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "CANCELLATION_RULES_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const UPDATE_FILTER = "CANCELLATION_RULES_LIST/UPDATE_FILTER";
export const APPLY_FILTER = "CANCELLATION_RULES_LIST/APPLY_FILTER";
