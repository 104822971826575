import { createStyles } from "@material-ui/core/styles";
import { whiteColor } from "../../utilities/colors";

const TenantPickerStyles = () => createStyles({
  TenantPicker: {
    padding:"8px 15px !important",
    "& .MuiInputLabel-root":{
      color: whiteColor + "!important"
    },
    "& .MuiInputBase-root":{
      color: whiteColor + "!important"
    },
    "& .MuiInputBase-input":{
      padding: "4px 0 5px"
    },
    "& .MuiFormControl-root":{
      paddingBottom: 0
    },
    "& .MuiSelect-icon":{
      color: whiteColor + "!important"
    },
    "& .MuiInput-underline:after":{
      borderBottom: "2px solid " + whiteColor
    },
    "& .MuiInput-underline:before":{
      borderBottom: "1px solid " + whiteColor
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
      borderBottom: "2px solid " + whiteColor
    }
  }
});

export default TenantPickerStyles;
