import React, { useEffect } from "react";
import { AppTable, AppButton } from "../../../components";
import { Column, UpdateTableHeadersObject } from "../../../store/areas/shared";
import { SchedulingRule, SchedulingRulesRequest, parseMUIColumnToEnum } from "../../../store/areas/schedulingRulesList/types";
import { SortOrder, TableTitle, CardColor, Size } from "../../../enums";
import { initialSchedulingRuleQueries, defaultHeaders } from "../../../store/areas/schedulingRulesList/reducer";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { ViewPermission } from "../../../role";

export interface SchedulingRulesTableProps {
  history: any;
  classes: any;
  columns: Column[];
  schedulingRules: SchedulingRule[];
  totalRows: number;
  itemsPerPage: number;
  getSchedulingRules: (schedulingRulesRequest: SchedulingRulesRequest) => void;
  queries: SchedulingRulesRequest;
  updateTableHeaders: (updateTableHeaders: UpdateTableHeadersObject) => void;
  permission: ViewPermission;
}

const SchedulingRulesTable: React.FunctionComponent<SchedulingRulesTableProps> = ({
  columns,
  schedulingRules,
  getSchedulingRules,
  totalRows,
  queries,
  updateTableHeaders,
  itemsPerPage,
  history,
  permission,
}) => {
  useEffect(() => {
    getSchedulingRules(initialSchedulingRuleQueries);
    updateTableHeaders(defaultHeaders);
  }, [getSchedulingRules, updateTableHeaders]);

  const changePage = (pageRequested: number) => {
    const changePageRequest: SchedulingRulesRequest = {
      ...queries,
      itemsPerPage,
      pageRequested,
    };
    getSchedulingRules(changePageRequest);
  };

  const sortChange = (changedColumn: string) => {
    const { pageRequested } = queries;
    const sortField = parseMUIColumnToEnum(changedColumn);
    let sortAscending: boolean;
    if (sortField === queries.sortField) {
      sortAscending = !queries.sortAscending;
    } else {
      sortAscending = true;
    }
    const sortChangeRequest: SchedulingRulesRequest = {
      itemsPerPage,
      sortAscending,
      sortField,
      pageRequested,
    };
    getSchedulingRules(sortChangeRequest);
  };

  const onTableChange = (action: string, tableState: any) => {
    switch (action) {
      case "sort":
        const { activeColumn: columnIndex } = tableState;
        const { sortDirection } = tableState.columns[columnIndex];
        const mapStringToEnum: any = {
          desc: SortOrder.asc,
          asc: SortOrder.desc,
        };
        const sortOrder: SortOrder = mapStringToEnum[sortDirection];
        const updateTableHeadersObject: UpdateTableHeadersObject = {
          columnIndex,
          sortOrder,
        };
        updateTableHeaders(updateTableHeadersObject);
        break;
      default:
        return;
    }
  };

  const customCellNode = (schedulingRule: SchedulingRule) => {
    return (
      <AppButton color={CardColor.primary} onClick={() => history.push(`/schedulingrule/${schedulingRule.id}`)} size={Size.sm} round>
        {permission.write ? "Edit" : "View"}
      </AppButton>
    );
  };

  return (
    <AppTable
      rowsPerPage={ROWS_PER_TABLE_PAGE}
      columns={columns}
      title={TableTitle.SchedulingRules}
      data={schedulingRules}
      count={totalRows}
      serverSide={true}
      sort={true}
      onChangePage={(pageNumber: number) => changePage(pageNumber)}
      onTableChange={(action: string, tableState: any) => onTableChange(action, tableState)}
      pageRequested={queries.pageRequested}
      customCellNode={customCellNode}
      // eslint-disable-next-line
      onColumnSortChange={(changedColumn: string, direction: string) => sortChange(changedColumn)}
    />
  );
};

export default SchedulingRulesTable;
