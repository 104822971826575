export const primaryColor = ["#00348d", "#00348d", "#1869e1", "#af2cc5"];
export const warningColor = ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"];
export const dangerColor = ["#f44336", "#ef5350", "#e53935", "#f55a4e"];
export const successColor = ["#4caf50", "#66bb6a", "#43a047", "#5cb860"];
export const infoColor = ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"];
export const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573"];
export const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7"
];
export const blackColor = "#000";
export const whiteColor = "#FFF";
