import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import logo from "../../assets/kpmg.logo.png";
import { AppBar, withStyles } from "@material-ui/core";
import AppHeaderStyles from "./appHeaderStyle";

export interface AppHeaderProps {
  classes: any;
  toggleDrawer: () => void;
  drawerOpen: boolean;
  children: any;
}

const Item: React.FunctionComponent<AppHeaderProps> = ({ classes, toggleDrawer, drawerOpen, children }) => {
  return (
    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.title}>
          <img src={logo} alt="Logo" />
        </div>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export const AppHeader = withStyles(AppHeaderStyles)(Item);
