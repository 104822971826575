import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardIconStyles from "./appCardIconStyle";
import classNames from "classnames";
import { CardColor } from "../../../enums";

export interface AppCardIconProps {
  classes: any;
  className: string;
  color: CardColor;
}

const Item: React.FunctionComponent<AppCardIconProps> = props => {
  const { className, children, color, classes, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
};

export const AppCardIcon = withStyles(AppCardIconStyles)(Item);
