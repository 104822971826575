import { Reducer } from "redux";
import {
  INIT_CANCELLATIONRULE,
  SAVE_CANCELLATIONRULE_SUCCESS,
  TOUCH_CANCELLATIONRULE,
  GET_TEMPLATES_SUCCESS,
  UPDATE_CONDITION,
  UPDATE_CANCELLATIONRULE,
  GET_CANCELLATIONRULE_SUCCESS,
} from "./actionTypes";
import { Template, CancellationRule, Touched } from "./types";
import { initialCondition, Dictionary } from "../shared";

export interface CancellationRuleState {
  templates: Dictionary<Template>;
  cancellationRule: CancellationRule;
  saved: boolean;
  touched: Touched;
}

const initialState: CancellationRuleState = {
  templates: {},
  cancellationRule: {
    id: "",
    name: "",
    template: {
      id: "",
      name: "",
      triggerId: "",
      triggerName: "",
    },
    trigger: { id: "", name: "" },
    active: true,
    condition: initialCondition,
    templateFields: [],
    triggerFields: [],
    hasMultipleTemplates: false,
  },
  saved: false,
  touched: {
    name: false,
    trigger: false,
    template: false,
    condition: false,
  },
};

export const CancellationRuleReducer: Reducer<CancellationRuleState> = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_CANCELLATIONRULE:
      return {
        ...initialState,
        ...action.state,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
      };
    case UPDATE_CANCELLATIONRULE:
      return {
        ...state,
        cancellationRule: action.cancellationRule,
      };
    case UPDATE_CONDITION:
      return {
        ...state,
        cancellationRule: { ...state.cancellationRule, condition: action.condition },
      };
    case SAVE_CANCELLATIONRULE_SUCCESS:
      return {
        ...state,
        saved: true,
      };
    case TOUCH_CANCELLATIONRULE:
      return {
        ...state,
        touched: { ...state.touched, ...action.touched },
      };
    case GET_CANCELLATIONRULE_SUCCESS:
      return {
        ...state,
        cancellationRule: action.cancellationRule,
      };
    default:
      return state;
  }
};
