import CancellationRulesFilter, { CancellationRulesFilterProps } from "./cancellationRulesFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import CancellationRulesListStyles from "../cancellationRulesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/styles";
import {
  updateFilter,
  Filter,
  applyFilter
} from "../../../store/areas/cancellationRulesList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<CancellationRulesFilterProps> => ({
  filter: state.cancellationRulesList.filter,
  triggers: state.shared.triggers,
  status: state.cancellationRulesList.status
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
  initFilter: () => {
    dispatch(applyFilter());
  },
});

const CancellationRulesFilterContainer = compose(
  withStyles(CancellationRulesListStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTenant
)(CancellationRulesFilter) as React.ComponentType<any>;

export default CancellationRulesFilterContainer;
