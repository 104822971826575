import React from "react";
import { variantIcon } from "./snackbarViewStyle";
import * as MUISnackBar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export interface SnackbarViewProps {
  className?: string;
  message?: string;
  closeSnackbar: () => void;
  variant: keyof typeof variantIcon;
  classes: any;
  open: boolean;
}

const SnackbarView: React.FunctionComponent<SnackbarViewProps> = ({
  className,
  message,
  closeSnackbar,
  variant,
  classes,
  open,
  ...other
}) => {
  const Icon = variantIcon[variant];

  return (
    <MUISnackBar.default
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3500}
      onClose={closeSnackbar}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={closeSnackbar}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </MUISnackBar.default>
  );
};

export default SnackbarView;
