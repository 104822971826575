import React, { useEffect } from "react";
import { CardColor, Size } from "../../../enums";
import {
  AppGridItem,
  AppGridContainer,
  AppExpansionPanel,
  AppInput,
  AppSelect,
  AppDateRangeButton
} from "../../../components";
import { DateRange, Status, Trigger, Dictionary } from "../../../store/areas/shared";
import {
  Filter,
  defaultFilterValues
} from "../../../store/areas/schedulingRulesList";

export interface SchedulingRulesFilterProps {
  classes: any;
  triggers: Dictionary<Trigger>;
  status: Status[];
  filter: Filter;
  tenantId: string;
  initFilter: () => void;
  updateFilter: (filter: Filter) => void;
  applyFilter: () => void;
}

const SchedulingRulesFilter: React.FunctionComponent<SchedulingRulesFilterProps> = ({
  classes,
  triggers,
  status,
  filter,
  tenantId,
  updateFilter,
  applyFilter,
  initFilter
}) => {

  useEffect(() => {
    initFilter();
  }, [initFilter, tenantId]);

  const clearFilter = () => {
    updateFilter(defaultFilterValues);
    applyFilter();
  };

  const actions = [
    { name: "Clear", color: CardColor.rose, size: Size.sm, onClick: clearFilter },
    { name: "Apply", color: CardColor.primary, size: Size.sm, onClick: applyFilter },
  ];

  return (
    <AppExpansionPanel title="Filters" actions={actions}>
      <AppGridContainer>
        <AppGridItem xs={12} md={6} lg={6}>
          <AppInput
            labelText="Rule Name"
            id="schedulingrule-rule-name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: filter.ruleName,
              onChange: (e: any) => updateFilter({ ...filter, ruleName: e.target.value })
            }}
          />
        </AppGridItem>

        <AppGridItem xs={12} md={6} lg={6}>
          <AppInput
            labelText="Template Name"
            id="schedulingrule-template-name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: filter.templateName,
              onChange: (e: any) => updateFilter({ ...filter, templateName: e.target.value })
            }}
          />
        </AppGridItem>

        <AppGridItem xs={12} md={6} lg={6}>
          <AppSelect
            data={triggers}
            multiple={true}
            labelText="Trigger"
            id="schedulingrule-triggers"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: filter.triggers,
              onChange: (e: any) => updateFilter({ ...filter, triggers: e.target.value })
            }}
          />
        </AppGridItem>
        <AppGridItem xs={12} md={6} lg={6}>
          <AppSelect
            includeBlank={true}
            data={status}
            labelText="Status"
            id="schedulingrule-status"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: filter.status,
              onChange: (e: any) => updateFilter({ ...filter, status: e.target.value })
            }}
          />
        </AppGridItem>
        <AppGridItem xs={12} md={6} lg={6}>
          <div className={classes.dateRangeButton}>
            <AppDateRangeButton
              caption={"Date Modified"}
              disableFutureProps={{ startDate: true, endDate: true }}
              dateRange={filter.dateModified}
              id="schedulingrule-date-modified"
              onUpdate={(dateRange: DateRange) =>
                updateFilter({
                  ...filter,
                  dateModified: dateRange
                })
              }
            />
          </div>
        </AppGridItem>
      </AppGridContainer>
    </AppExpansionPanel>
  );
};

export default SchedulingRulesFilter;
