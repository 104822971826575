import React, { useEffect } from "react";
import { AppButton, AppCopyright } from "../../components";
import { Size, CardColor, RouterName } from "../../enums";
import { Typography } from "@material-ui/core";
import { MenuItem } from "../../store/areas/layout";
import { Account } from "../../store/areas/shared";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../app";

export interface LoginProps {
  classes: any;
  history: any;
  match: any;
  menuItems: MenuItem[];
  account: Account;
  loaded: boolean;
}

const Login: React.FunctionComponent<LoginProps> = ({ classes, history, match, menuItems, account, loaded }: LoginProps) => {
  const logoutReason = match.params.reason;

  useEffect(() => {
    if (account.idToken) {
      const item = menuItems.find(m => m.to && account.permission[m.view]?.read);
      history.push(item?.to || RouterName.Templates);
    }
  }, [account, history, menuItems]);

  const loginButton = () => (
    <>
      <Typography className={classes.welcomeText} variant="subtitle1">
        {logoutReason === "inactive" ? "You have been logged out due to inactivity" : "Please login to proceed"}
      </Typography>
      <AppButton className={classes.loginButton} size={Size.md} color={CardColor.primary} onClick={() => authProvider.login()}>
        Login
      </AppButton>
    </>
  );

  return (
    <div className={classes.main}>
      <div className={classes.body}>
        <Typography className={classes.welcomeText} variant="h4">
          KPMG Learning Platform Communications Portal
        </Typography>

        {!loaded && <CircularProgress title="Loading.." thickness={5} size={80} color="primary" />}
        <AzureAD provider={authProvider} reduxStore={store}>
          {({ authenticationState }: { authenticationState: AuthenticationState }) => {
            switch (authenticationState) {
              case AuthenticationState.InProgress:
                return (
                  <Typography className={classes.welcomeText} variant="subtitle1">
                    Redirecting to login...
                  </Typography>
                );
              case AuthenticationState.Authenticated: {
                return (
                  <Typography className={classes.welcomeText} variant="subtitle1">
                    Redirecting to comms...
                  </Typography>
                );
              }
              case AuthenticationState.Unauthenticated:
              default:
                return loginButton();
            }
          }}
        </AzureAD>
      </div>
      <div className={classes.footer}>
        <AppCopyright />
      </div>
    </div>
  );
};

export default Login;
