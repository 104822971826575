import { Status, ScheduledDateType, Condition } from "./types";
import { CombinatorType } from "@comms/react-query-builder/src";

export const defaultStatuses: Status[] = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
];

export const defaultSentCommStatuses: Status[] = [
  { id: "sent", name: "Sent" },
  { id: "failed", name: "Failed" },
];

export const defaultScheduledDateTypes: ScheduledDateType[] = [
  { id: 1, name: "Immediate" },
  { id: 2, name: "From Trigger" },
];

export const initialCondition: Condition = { rawState: { combinator: CombinatorType.And, rules: [] }, logic: {} };
