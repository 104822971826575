import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getCancellationRulesSuccess,
  getCancellationRulesFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getCancellationRules
} from "./actions";
import {
  GET_CANCELLATION_RULES,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER
} from "./actionTypes";
import { CancellationRule } from "./types";
import { generateUrlWithQueryString } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { generateFilterObject, getCancellationRuleRequest, getFilter } from "./utils";

function* getCancellationRulesSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.CancellationRules, {
      ...action.cancellationRulesRequest,
      ...filterObject
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        cancellationRules: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const cancellationRules: CancellationRule[] = yield payload.cancellationRules.map(
        (i: any) => ({
          id: i.id,
          name: i.name,
          templateName: i.templateName,
          triggerName: i.triggerName,
          lastModified: i.modifiedOn,
          active: !i.disabled,
        })
      );
      yield put(
        getCancellationRulesSuccess(cancellationRules, payload.totalRecordCount)
      );
    } else {
      const error = yield call([response, "json"]);
      yield put(getCancellationRulesFailure(error));
    }
  } catch (error) {
    yield put(getCancellationRulesFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getCancellationRuleRequest);
  yield put(getCancellationRules({ ...request, pageRequested: 0 }));
}

export function* CancellationRulesListWatcher() {
  yield takeLatest(GET_CANCELLATION_RULES, getCancellationRulesSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
}
