import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Login, { LoginProps } from "./login";
import LoginStyles from "./loginStyle";
import { RootState } from "../../store/rootReducer";
import React from "react";

export const mapStateToProps = (state: RootState): Partial<LoginProps> => ({
  menuItems: state.layout.mainList,
  account: state.shared.account,
  loaded: state.shared.loginLoaded,
});

const mapDispatchToProps = () => ({});
const LoginContainer = compose(withStyles(LoginStyles), connect(mapStateToProps, mapDispatchToProps))(Login) as React.ComponentType<any>;
export default LoginContainer;
