import { MUIColumnNames, TableRequest, DateRange } from "../shared";

export interface Trigger {
  id: string;
  name: string;
  lastModified: Date;
  active: boolean;
}

// namespace KLP.Comms.Enums.TriggerSearchSortFields for back end mapping
export enum TriggerTableFields {
  "Name" = 1,
  "LastModified" = 2,
  "Active" = 3
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum TriggerColumnIndex {
  "Name" = TriggerTableFields.Name - 1,
  "LastModified" = TriggerTableFields.LastModified - 1,
  "Active" = TriggerTableFields.Active - 1
}

export const parseMUIColumnToEnum = (columnName: string): TriggerTableFields => {
  switch (columnName) {
    case MUIColumnNames.lastModified:
      return TriggerTableFields.LastModified;
    case MUIColumnNames.active:
      return TriggerTableFields.Active;
    case MUIColumnNames.name:
    default:
      return TriggerTableFields.Name;
  }
};

export interface TriggersRequest extends TableRequest {
  sortField?: TriggerTableFields;
}

export interface Filter {
  triggerName: string;
  dateCreated: DateRange;
  status: string;
}