import { Filter } from "./types";
import { RootState } from "../../rootReducer";

export const generateFilterObject = (filter: Filter): any => {
  return {
    sentOnStartDate: filter.sentOnDate.startDate?.toUTCString() ?? null,
    sentOnEndDate: filter.sentOnDate.endDate?.toUTCString() ?? null,
    recipient: filter.recipient,
    subject: filter.subject,
    isSuccess: convertStatus(filter.status),
  };
};

export const convertStatus = (filterStatus: string) => {
  switch (filterStatus) {
    case "sent":
      return true;
    case "failed":
      return false;
    default:
      return null;
  }
};

export const getFilter = (state: RootState) => {
  return state.sentCommsList.filter;
};

export const getSentCommsRequest = (state: RootState) => {
  return state.sentCommsList.queries;
};
