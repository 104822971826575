import SnippetsFilter, { SnippetsFilterProps } from "./snippetsFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import snippetsListStyle from "../snippetsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import {
  Filter,
  updateFilter,
  applyFilter
} from "../../../store/areas/snippetsList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<SnippetsFilterProps> => ({
  statuses: state.snippetsList.status,
  filter: state.snippetsList.filter
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
  initFilter: () => dispatch(applyFilter())
});

const SnippetsFilterContainer = compose(
  withStyles(snippetsListStyle),
  withTenant,
  connect(mapStateToProps, mapDispatchToProps)
)(SnippetsFilter) as React.ComponentType<any>;

export default SnippetsFilterContainer;
