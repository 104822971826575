import { Reducer } from "redux";
import { IconName, RouterName, View } from "../../../enums";
import { MenuItem, TOGGLE_DRAWER } from ".";

export interface LayoutState {
  drawerOpen: boolean;
  mainList: MenuItem[];
  secondaryList: MenuItem[];
}

const initialState: LayoutState = {
  drawerOpen: false,
  mainList: [
    {
      caption: "Templates",
      iconName: IconName.Templates,
      to: RouterName.Templates,
      view: View.Template,
    },
    {
      caption: "Scheduling Rules",
      iconName: IconName.SchedulingRules,
      to: RouterName.SchedulingRules,
      view: View.SchedulingRule,
    },
    {
      caption: "Cancellation Rules",
      iconName: IconName.CancellationRules,
      to: RouterName.CancellationRules,
      view: View.CancellationRule,
    },
    {
      caption: "Snippets",
      iconName: IconName.Snippets,
      to: RouterName.Snippets,
      view: View.Snippet,
    },
    {
      caption: "Scheduled Comms",
      iconName: IconName.ScheduledComms,
      to: RouterName.ScheduledComms,
      view: View.ScheduledComm,
    },
    {
      caption: "Sent Comms",
      iconName: IconName.SentComms,
      to: RouterName.SentComms,
      view: View.SentComm,
    },
  ],
  secondaryList: [],
};

export const LayoutReducer: Reducer<LayoutState> = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    default:
      return state;
  }
};
