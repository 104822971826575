import SentCommsList, { SentCommsListProps } from "./sentCommsList";
import { connect } from "react-redux";
import { compose } from "redux";
import SentCommsListStyles from "./sentCommsListStyles";
import { withStyles } from "@material-ui/styles";

const mapStateToProps = (): Partial<SentCommsListProps> => ({});

const mapDispatchToProps = () => ({});

const SentCommsListContainer = compose(
  withStyles(SentCommsListStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(SentCommsList) as React.ComponentType<any>;

export default SentCommsListContainer;
