import AddRuleButton from "./AddRuleButton";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AddRuleButtonStyle from "./AddRuleButtonStyles";
import { QueryBuilderActionTypes }  from "../../store/actionTypes";
import { addRule } from "../../store/actions";

const mapDispatchToProps = (dispatch: Dispatch<QueryBuilderActionTypes>) => ({
  OnClick: () => dispatch(addRule()),
});

const AddRuleButtonContainer = compose(
  withStyles(AddRuleButtonStyle),
  connect(null, mapDispatchToProps)
)(AddRuleButton) as React.ComponentType<any>;
export default AddRuleButtonContainer;
