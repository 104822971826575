export const GET_SNIPPETS = "SNIPPETS_LIST/GET_SNIPPETS";
export const GET_SNIPPETS_SUCCESS = "SNIPPETS_LIST/GET_SNIPPETS_SUCCESS";
export const GET_SNIPPETS_FAIL = "SNIPPETS_LIST/GET_SNIPPETS_FAIL";

export const UPDATE_TABLE_HEADERS = "SNIPPETS_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "SNIPPETS_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "SNIPPETS_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const SELECT_SNIPPET = "SNIPPETS_LIST/SELECT_SNIPPET";
export const DESELECT_SNIPPET = "SNIPPETS_LIST/DESELECT_SNIPPET";

export const UPDATE_FILTER = "SNIPPETS_LIST/UPDATE_FILTER";
export const APPLY_FILTER = "SNIPPETS_LIST/APPLY_FILTER";
