import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getTriggersSuccess,
  getTriggersFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getTriggers
} from "./actions";
import {
  GET_TRIGGERS,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER
} from "./actionTypes";
import { Trigger } from "./types";
import { generateUrlWithQueryString } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { generateFilterObject, getFilter, getTriggerRequest } from "./utils";
import { fetchSecure } from "../../../authProvider";

function* getTriggersSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.Triggers, {
      ...action.triggersRequest,
      ...filterObject
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        triggers: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const triggers: Trigger[] = yield payload.triggers.map((i: any) => ({
        id: i.triggerId,
        name: i.name,
        lastModified: i.modifiedOn,
        active: !i.disabled
      }));
      yield put(getTriggersSuccess(triggers, payload.totalRecordCount));
    } else {
      const error = yield call([response, "json"]);
      yield put(getTriggersFailure(error));
    }
  } catch (error) {
    yield put(getTriggersFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getTriggerRequest);
  yield put(getTriggers({ ...request, pageRequested: 0 }));
}

export function* TriggersListWatcher() {
  yield takeLatest(GET_TRIGGERS, getTriggersSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
}
