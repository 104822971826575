import { AnyAction } from "redux";
import {
  GET_SENT_COMMS,
  GET_SENT_COMMS_SUCCESS,
  GET_SENT_COMMS_FAIL,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_TABLE_HEADERS_FAIL,
  UPDATE_TABLE_HEADERS,
  UPDATE_FILTER,
  APPLY_FILTER,
  DOWNLOAD_SENT_COMM,
  DOWNLOAD_SENT_COMM_SUCCESS,
  DOWNLOAD_SENT_COMM_FAIL,
} from "./actionTypes";
import { SentComm, SentCommsRequest, Filter, DownloadSentCommRequest } from "./types";
import { SortOrder } from "../../../enums";
import { UpdateTableHeadersObject } from "../shared";

export const getSentComms = (sentCommsRequest: SentCommsRequest): AnyAction => {
  return {
    type: GET_SENT_COMMS,
    sentCommsRequest,
  };
};

export const getSentCommsSuccess = (sentComms: SentComm[], totalRows: number): AnyAction => {
  return {
    type: GET_SENT_COMMS_SUCCESS,
    sentComms,
    totalRows,
  };
};

export const getSentCommsFailure = (error: Error): AnyAction => {
  return {
    type: GET_SENT_COMMS_FAIL,
    message: error.message || "Unable to get list of sent comms, please contact KLP Support",
    error,
  };
};

export const downloadSentComm = (request: DownloadSentCommRequest): AnyAction => {
  return {
    type: DOWNLOAD_SENT_COMM,
    request,
  };
};

export const downloadSentCommSuccess = (blobContent: string): AnyAction => {
  return {
    type: DOWNLOAD_SENT_COMM_SUCCESS,
    blobContent,
  };
};

export const downloadSentCommFailure = (error: Error): AnyAction => {
  return {
    type: DOWNLOAD_SENT_COMM_FAIL,
    error,
    message: "Unable to retrieve email content, please contact KLP Support",
  };
};

export const updateFilter = (filter: Filter): AnyAction => {
  return {
    type: UPDATE_FILTER,
    filter,
  };
};

export const applyFilter = (): AnyAction => ({ type: APPLY_FILTER });

export const updateTableHeaders = (updateTableHeadersObject: UpdateTableHeadersObject) => {
  const { columnIndex, sortOrder } = updateTableHeadersObject;
  return {
    type: UPDATE_TABLE_HEADERS,
    columnIndex,
    sortOrder,
  };
};

export const updateTableHeadersSuccess = (columnIndex: number, sortOrder: SortOrder) => {
  return {
    type: UPDATE_TABLE_HEADERS_SUCCESS,
    columnIndex,
    sortOrder,
  };
};

export const updateTableHeadersFailure = (error: Error): AnyAction => {
  return {
    type: UPDATE_TABLE_HEADERS_FAIL,
    message: error.message || "Error rendering table headers. Please refresh and try again",
    error,
  };
};
