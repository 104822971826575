/* eslint-disable @typescript-eslint/camelcase */
import { Rule } from "./types";
import {
  Add_RULE,
  REMOVE_RULE,
  UPDATE_RULE,
  INIT_RULES,
  INIT_FIELDS,
  QueryBuilderActionTypes,
  UPDATE_COMBINATOR,
} from "./actionTypes";
import { Field } from "..";
import { CombinatorType } from "../enums";

export function addRule(): QueryBuilderActionTypes {
  return {
    type: Add_RULE,
  };
}

export function removeRule(rule: Rule): QueryBuilderActionTypes {
  return {
    type: REMOVE_RULE,
    rule,
  };
}

export function updateRule(rule: Rule): QueryBuilderActionTypes {
  return {
    type: UPDATE_RULE,
    rule,
  };
}

export function initRules(rules: Rule[]): QueryBuilderActionTypes {
  return {
    type: INIT_RULES,
    rules,
  };
}
export function initFields(
  fields: { [name: string]: Field },
  extraFields: { [name: string]: Field }
): QueryBuilderActionTypes {
  return {
    type: INIT_FIELDS,
    fields,
    extraFields,
  };
}

export function updateCombinator(value: CombinatorType): QueryBuilderActionTypes {
  return {
    type: UPDATE_COMBINATOR,
    value,
  };
}
