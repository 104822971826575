import { createStyles } from "@material-ui/core";

const AppTableStyles = () =>
  createStyles({
    cellContainer: {
      textAlign: "right",
      paddingRight: 10,
    },
  });

export default AppTableStyles;
