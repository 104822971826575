import CancellationRuleEditor, { CancellationRuleEditorProps } from "./cancellationRuleEditor";
import CancellationRuleEditorStyles from "./cancellationRuleEditorStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  initCancellationRule,
  getTemplates,
  updateCancellationRule,
  saveCancellationRule,
  touchCancellationRule,
  getCancellationRule,
} from "../../store/areas/cancellationRule/actions";
import { CancellationRule, Touched } from "../../store/areas/cancellationRule/types";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";
import { withTenant } from "../../withTenant";

const mapStateToProps = (state: RootState): Partial<CancellationRuleEditorProps> => ({
  cancellationRule: state.cancellationRule.cancellationRule,
  triggers: state.shared.triggers,
  templates: state.cancellationRule.templates,
  saved: state.cancellationRule.saved,
  touched: state.cancellationRule.touched,
});

const mapDispatchToProps = (dispatch: any) => ({
  initCancellationRulePage: () => {
    dispatch(initCancellationRule());
  },
  getTemplates: () => dispatch(getTemplates()),
  updateCancellationRule: (cancellationRule: CancellationRule) => dispatch(updateCancellationRule(cancellationRule)),
  saveCancellationRule: (template: CancellationRule) => dispatch(saveCancellationRule(template)),
  touchCancellationRule: (touched: Touched) => dispatch(touchCancellationRule(touched)),
  getCancellationRule: (cancellationRuleId: string) => dispatch(getCancellationRule(cancellationRuleId)),
  resetState: () => dispatch(initCancellationRule()),
});

const CancellationRuleEditorContainer = compose(
  withStyles(CancellationRuleEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.CancellationRule),
  withTenant
)(CancellationRuleEditor) as React.ComponentType<any>;
export default CancellationRuleEditorContainer;
