import { createStyles } from "@material-ui/core";

const SnippetsListStyles = () =>
  createStyles({
    dateRangeButton: {
      marginTop: "15px"
    }
  });

export default SnippetsListStyles;
