import { createStyles } from "@material-ui/core";

const AppDynamicDatePickerStyles = () =>
  createStyles({
    datePickerContainer: {
      marginTop: "8px",
      marginLeft: "8px",
      width: "165px",
    },
    switchContainer: {
      margin: "5px",
      marginTop: "25px",
    },
  });

export default AppDynamicDatePickerStyles;
