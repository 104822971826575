import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getScheduledCommsSuccess,
  getScheduledCommsFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getScheduledComms,
  editScheduledCommsSuccess,
  editScheduledCommsFailure,
  updateTableHeaders,
  updateFilter
} from "./actions";
import {
  GET_SCHEDULED_COMMS,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER,
  EDIT_SCHEDULED_COMMS
} from "./actionTypes";
import { ScheduledComm } from "./types";
import { generateUrl, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { generateFilterObject, getScheduledCommRequest, getFilter, filterStatusToString, generateEditRequest } from "./utils";
import { initialScheduledCommQueries, defaultHeaders } from "./reducer";
import { GET_TRIGGERS_SUCCESS } from "../shared";

function* getScheduledCommsSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = `${generateUrl(EndpointRoute.ScheduledComms)}/search`;
    const searchRequest = {
      ...action.scheduledCommsRequest,
      ...filterObject
    };
    const response: Response = yield call(fetchSecure, url, createHttpRequest(searchRequest, "POST"));
    if (response.ok) {
      const payload: {
        scheduledComms: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const scheduledComms: ScheduledComm[] = yield payload.scheduledComms.map(
        (i: any) => ({
          id: i.id,
          templateName: i.templateName,
          triggerName: i.triggerName,
          sendOn: i.sendOn,
          createdOn: i.createdOn,
          status: filterStatusToString(i.status),
          payload: i.payload,
          externalSessionId:i.externalSessionId
        })
      );
      yield put(
        getScheduledCommsSuccess(scheduledComms, payload.totalRecordCount)
      );
    } else {
      const error = response.headers.get("content-type")?.startsWith("application/json")
        ? yield call([response, "json"])
        : "An error has occured, please contact KLP Support";
      yield put(getScheduledCommsFailure(error));
    }
  } catch (error) {
    yield put(getScheduledCommsFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getScheduledCommRequest);
  yield put(getScheduledComms({ ...request, pageRequested: 0 }));
}

function* editScheduledCommsSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.ScheduledComms);
    const editRequest = generateEditRequest(action.editRequest);
    const response = yield call(fetchSecure, url, createHttpRequest(editRequest, "PUT"));
    if (response.ok) {
      yield put(editScheduledCommsSuccess(editRequest));
      yield put(getScheduledComms(initialScheduledCommQueries));
      yield put(updateTableHeaders(defaultHeaders));
    } else {
      const error = yield call([response, "json"]);
      yield put(editScheduledCommsFailure(error));
    }
  } catch (error) {
    yield put(editScheduledCommsFailure(error));
  }
}

function* getTriggersSuccess(action: any) {
  yield put(updateFilter({} as any, action.triggers));
}

export function* ScheduledCommsListWatcher() {
  yield takeLatest(GET_SCHEDULED_COMMS, getScheduledCommsSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
  yield takeLatest(EDIT_SCHEDULED_COMMS, editScheduledCommsSaga);
  yield takeLatest(GET_TRIGGERS_SUCCESS, getTriggersSuccess);
}
