import { Middleware } from "redux";
import { RootState } from "../rootReducer";

/**
 * Token Expiration middleware doesn't add any extra types to dispatch, just check if the token expired and logout user
 */

export default function createTokenExpirationMiddleware() {
  const tokenExpirationMiddleware: Middleware = store => next => action => {
    const state: RootState = store.getState();
    if (state?.shared?.account.exp) {
      if (state.shared.account.exp <= Date.now() / 1000) {
        window.location.replace(`${window.location.origin}/logout/inactive`);
      }
    }
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);
    return returnValue;
  };
  return tokenExpirationMiddleware;
}
