import React, { useEffect } from "react";
import { CancellationRule, Template, Touched } from "../../store/areas/cancellationRule/types";
import { CardColor, Size, RouterName, BasicColor } from "../../enums";
import {
  AppGridContainer,
  AppGridItem,
  AppCardHeader,
  AppCardBody,
  AppCard,
  AppInput,
  AppSelect,
  AppCardFooter,
  AppButton,
  AppSwitch,
} from "../../components";
import { Divider } from "@material-ui/core";
import CancellationRuleConditionContainer from "./cancellationRuleCondition/cancellationRuleConditionContainer";
import { initialCondition, Dictionary, Trigger, TriggerDetails } from "../../store/areas/shared";
import { ViewPermission } from "../../role";

export interface CancellationRuleEditorProps {
  classes: any;
  triggers: Dictionary<TriggerDetails>;
  templates: Dictionary<Template>;
  cancellationRule: CancellationRule;
  trigger: Trigger;
  saved: boolean;
  history: any;
  match: any;
  touched: Touched;
  permission: ViewPermission;
  tenantId: string;
  initCancellationRulePage: () => void;
  getTemplates: () => void;
  updateCancellationRule: (cancellationRule: CancellationRule) => void;
  saveCancellationRule: (cancellationRule: CancellationRule) => void;
  getCancellationRule: (cancellationRuleId: string) => void;
  touchCancellationRule: (touched: Touched) => void;
  resetState: () => void;
}

const validCancellationRule = (cancellationRule: CancellationRule) => {
  return cancellationRule.name && (cancellationRule.template?.id || cancellationRule.hasMultipleTemplates) && cancellationRule.trigger?.id;
};

const CancellationRuleEditor: React.FunctionComponent<CancellationRuleEditorProps> = ({
  classes,
  initCancellationRulePage,
  cancellationRule,
  templates,
  triggers,
  updateCancellationRule,
  saveCancellationRule,
  touched,
  touchCancellationRule,
  history,
  saved,
  resetState,
  getTemplates,
  match,
  getCancellationRule,
  permission,
  tenantId,
}) => {
  useEffect(() => {
    initCancellationRulePage();
  }, [initCancellationRulePage, tenantId]);

  useEffect(() => {
    if (match.params.cancellationRuleId) {
      getCancellationRule(match.params.cancellationRuleId);
    }
  }, [getCancellationRule, match, tenantId]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates, tenantId]);

  useEffect(() => {
    if (saved) {
      resetState();
      history.push(RouterName.CancellationRules);
    }
  }, [history, resetState, saved]);

  const getCancellationRuleText = (templateId: string) =>
    !permission.write ? "View Cancellation Rule" : templateId ? "Edit Cancellation Rule" : "New Cancellation Rule";

  const saveHandler = () => {
    if (validCancellationRule(cancellationRule)) {
      saveCancellationRule(cancellationRule);
    }
    touchCancellationRule({
      name: true,
      template: true,
      trigger: true,
      condition: true,
    });
  };

  return (
    <AppGridContainer>
      <AppGridItem xs={12} sm={12} md={12}>
        <AppCard>
          <AppCardHeader color={CardColor.primary}>
            <h4 className={classes.cardTitleWhite}>{getCancellationRuleText(match?.params?.cancellationRuleId)}</h4>
            {permission.write && <p className={classes.cardCategoryWhite}>Complete all required fields</p>}
          </AppCardHeader>
          <AppCardBody>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <AppGridContainer>
                  <AppGridItem xs={12} sm={12} md={6}>
                    <AppInput
                      touched={touched.name}
                      onTouch={() => touchCancellationRule({ ...touched, name: true })}
                      labelText="Cancellation Rule Name"
                      id="cancellationrule-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: cancellationRule.name,
                        onChange: (e: any) =>
                          updateCancellationRule({
                            ...cancellationRule,
                            name: e.target.value,
                          }),
                        disabled: !permission.write,
                      }}
                      error={touched.name && !cancellationRule.name}
                    />
                  </AppGridItem>
                  <AppGridItem xs={12} sm={6} flex={true} className={classes.activeButtonContainer}>
                    <AppSwitch
                      formControlProps={{ label: "Active" }}
                      color={BasicColor.primary}
                      onChange={(e: any) => {
                        updateCancellationRule({
                          ...cancellationRule,
                          active: e.target.checked,
                        });
                      }}
                      value={cancellationRule.active}
                      inputProps={{
                        disabled: !permission.write,
                      }}
                    />
                  </AppGridItem>
                </AppGridContainer>
                <AppGridContainer>
                  <AppGridItem xs={12}>
                    <AppGridContainer>
                      <AppGridItem xs={12} sm={12} md={6}>
                        <AppSelect
                          data={triggers}
                          labelText="Cancellation Trigger"
                          id="cancellationrule-trigger"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: cancellationRule.trigger.id,
                            onChange: (e: any) => {
                              updateCancellationRule({
                                ...cancellationRule,
                                trigger: triggers[e.target.value] || cancellationRule.trigger,
                                triggerFields: triggers[e.target.value || cancellationRule.trigger.id].fields,
                                condition: initialCondition,
                              });
                            },
                            disabled: !permission.write,
                          }}
                          touched={touched.trigger}
                          error={touched.trigger && !cancellationRule.trigger?.id}
                          onTouch={() => touchCancellationRule({ ...touched, trigger: true })}
                        />
                      </AppGridItem>
                    </AppGridContainer>
                  </AppGridItem>
                  {!cancellationRule.hasMultipleTemplates && (
                    <AppGridItem xs={12}>
                      <AppGridContainer>
                        <AppGridItem xs={12} sm={12} md={6}>
                          <AppSelect
                            data={templates}
                            labelText="Template"
                            id="cancellationrule-template"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: cancellationRule.template?.id || "",
                              onChange: (e: any) =>
                                updateCancellationRule({
                                  ...cancellationRule,
                                  template: templates[e.target.value] || cancellationRule.template,
                                  templateFields:
                                    triggers[templates[e.target.value].triggerId || cancellationRule.template.triggerId].fields,
                                  condition: initialCondition,
                                }),
                              disabled: !permission.write,
                            }}
                            touched={touched.template}
                            error={touched.template && !cancellationRule.template?.id}
                            onTouch={() => touchCancellationRule({ ...touched, template: true })}
                          />
                        </AppGridItem>
                      </AppGridContainer>
                    </AppGridItem>
                  )}
                  <AppGridItem xs={12} sm={6} md={4} flex={true}>
                    <AppSwitch
                      formControlProps={{ label: "All Scheduled Comms" }}
                      color={BasicColor.primary}
                      onChange={(e: any) => {
                        updateCancellationRule({
                          ...cancellationRule,
                          hasMultipleTemplates: e.target.checked,
                          template: {} as Template,
                        });
                      }}
                      inputProps={{
                        disabled: !permission.write,
                      }}
                      value={cancellationRule.hasMultipleTemplates}
                    />
                  </AppGridItem>
                </AppGridContainer>
              </AppGridItem>
            </AppGridContainer>
            <Divider></Divider>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                {!!cancellationRule?.triggerFields?.length &&
                  (!!cancellationRule?.templateFields?.length || cancellationRule.hasMultipleTemplates) && (
                  <CancellationRuleConditionContainer key={cancellationRule.trigger?.id} />
                )}
              </AppGridItem>
            </AppGridContainer>
          </AppCardBody>
          {permission.write && (
            <AppCardFooter>
              <AppGridContainer direction="row-reverse" justify="flex-start" alignItems="center">
                <AppButton
                  color={CardColor.primary}
                  size={Size.lg}
                  className={classes.saveButton}
                  onClick={saveHandler}
                  id="cancellationrule-save"
                >
                  Save
                </AppButton>
              </AppGridContainer>
            </AppCardFooter>
          )}
        </AppCard>
      </AppGridItem>
    </AppGridContainer>
  );
};

export default CancellationRuleEditor;
