import { createStyles, Theme } from '@material-ui/core/styles';

const queryBuilderStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      padding: theme.spacing(2),
      backgroundColor: 'rgba(250,240,210,0.5)',
      border: '1px solid #DCC896',
    },
  });

export default queryBuilderStyles;
