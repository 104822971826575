import React from "react";
import { AppGridItem, AppGridContainer, AppButton, AppDialog } from "../../components";
import ScheduledCommsFilterContainer from "./scheduledCommsFilter/scheduledCommsFilterContainer";
import ScheduledCommsTableContainer from "./scheduledCommsTable/scheduledCommsTableContainer";
import { CardColor, Size } from "../../enums";
import { ScheduledComm } from "../../store/areas/scheduledCommsList";
import { cancelButtonDisabled, rescheduleButtonDisabled, getTitleText, getContentText } from "./utils";
import ScheduledCommsEditContainer from "../scheduledCommsEdit/scheduledCommsEditContainer";
import { ScheduledCommsEditRequestType, EditScheduledCommsRequest } from "../../store/areas/scheduledCommsEdit";
import { Dialog } from "../../store/areas/shared/types";
import { setSeconds } from "date-fns";
import { ViewPermission } from "../../role";

export interface ScheduledCommsListProps {
  history: any;
  classes: any;
  dialog: Dialog;
  selectedScheduledComms: ScheduledComm[];
  requestType: ScheduledCommsEditRequestType;
  permission: ViewPermission;
  editScheduledComms: (editRequest: EditScheduledCommsRequest) => void;
  updateRescheduleDate: (date: Date) => void;
  openDialog: (type: ScheduledCommsEditRequestType) => void;
  closeDialog: () => void;
}

const ScheduledCommsList: React.FunctionComponent<ScheduledCommsListProps> = ({
  history,
  selectedScheduledComms,
  editScheduledComms,
  openDialog,
  requestType,
  closeDialog,
  dialog,
  permission,
}) => {
  const cancelScheduledComms = () => {
    openDialog(ScheduledCommsEditRequestType.Cancel);
    return;
  };

  const rescheduleScheduledComms = () => {
    openDialog(ScheduledCommsEditRequestType.Reschedule);
    return;
  };

  const editRequestHandler = (type: ScheduledCommsEditRequestType, date: Date) => {
    const rescheduleDate = setSeconds(date, 0);
    const scheduledCommsIds = selectedScheduledComms.map(el => el.id);
    const editRequest: EditScheduledCommsRequest = {
      requestType: type,
      scheduledCommIds: scheduledCommsIds,
      rescheduleDate,
    };
    editScheduledComms(editRequest);
    closeDialog();
  };

  return (
    <>
      <AppGridItem xs={12}>
        <ScheduledCommsFilterContainer />
      </AppGridItem>
      {permission.write && (
        <AppGridItem xs={12}>
          <AppGridContainer alignItems="flex-end" justify="flex-end">
            <AppButton
              color={CardColor.danger}
              onClick={cancelScheduledComms}
              size={Size.lg}
              disabled={cancelButtonDisabled(selectedScheduledComms)}
            >
              Cancel
            </AppButton>
            <AppButton
              color={CardColor.primary}
              onClick={rescheduleScheduledComms}
              size={Size.lg}
              disabled={rescheduleButtonDisabled(selectedScheduledComms)}
            >
              Re-schedule
            </AppButton>
          </AppGridContainer>
        </AppGridItem>
      )}

      <AppGridItem xs={12}>
        <ScheduledCommsTableContainer permission={permission} history={history} />
      </AppGridItem>
      {dialog.open && (
        <AppDialog
          open={dialog.open}
          title={getTitleText(requestType)}
          content={getContentText(requestType, selectedScheduledComms.length)}
        >
          <ScheduledCommsEditContainer submitEditRequest={editRequestHandler} />
        </AppDialog>
      )}
    </>
  );
};

export default ScheduledCommsList;
