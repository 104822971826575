import { Reducer } from "redux";
import { EditorState, convertToRaw } from "draft-js";
import { Field } from "../shared";
import {
  Template,
  UPDATE_TEMPLATE,
  INIT_TEMPLATE,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  Touched,
  TOUCH_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  UPDATE_EDITOR,
  PreviewInfo,
  Snippet,
  GET_SNIPPETS_SUCCESS,
  UPLOAD_TEMPLATE_ATTACHMENT,
  UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS,
  UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
} from ".";

export interface TemplateState {
  triggerFields: Field[];
  snippets: Snippet[];
  template: Template;
  saved: boolean;
  previewInfo: PreviewInfo;
  touched: Touched;
  isUploadingAttachment: boolean;
  aliasUpdates: { old: string; new: string }[];
}

export const initialState: TemplateState = {
  triggerFields: [],
  snippets: [],
  template: {
    id: "",
    name: "",
    subject: "",
    html: "",
    text: "",
    subjectRawContent: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    rawContent: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    templateType: 0,
    trigger: { id: "", name: "" },
    active: true,
    augmenterMappings: [],
    combinedFields: [],
    templateAttachments: [],
  },
  aliasUpdates: [],
  saved: false,
  previewInfo: { queued: false, email: "" },
  touched: {
    name: false,
    subject: false,
    text: false,
    templateType: false,
    trigger: false,
    augmenterMappings: false,
  },
  isUploadingAttachment: false,
};

export const TemplateReducer: Reducer<TemplateState> = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_TEMPLATE:
      return {
        ...initialState,
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.template,
        triggerFields: action.triggerFields,
        aliasUpdates: action.aliasUpdates
      };
    case UPDATE_EDITOR:
      const { templatePartial } = action;
      return {
        ...state,
        template: { ...state.template, ...templatePartial },
      };
    case SAVE_TEMPLATE:
      return {
        ...state,
        previewInfo: action.previewInfo,
      };
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        saved: true,
      };
    case TOUCH_TEMPLATE:
      return {
        ...state,
        touched: { ...state.touched, ...action.touched },
      };
    case GET_SNIPPETS_SUCCESS:
      return {
        ...state,
        snippets: action.snippets,
      };
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.template,
        triggerFields: action.triggerFields,
      };
    case UPLOAD_TEMPLATE_ATTACHMENT:
      return {
        ...state,
        isUploadingAttachment: true,
      };
    case UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isUploadingAttachment: false,
        template: {
          ...state.template,
          templateAttachments: [...state.template.templateAttachments, action.templateAttachment],
        },
      };
    case UPLOAD_TEMPLATE_ATTACHMENT_FAIL:
      return {
        ...state,
        isUploadingAttachment: false,
      };
    default:
      return state;
  }
};
