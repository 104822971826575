import SchedulingRulesList, { SchedulingRulesListProps } from "./schedulingRulesList";
import { connect } from "react-redux";
import { compose } from "redux";
import SchedulingRulesListStyles from "./schedulingRulesListStyles";
import { withStyles } from "@material-ui/styles";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (): Partial<SchedulingRulesListProps> => ({});

const mapDispatchToProps = () => ({});

const SchedulingRulesListContainer = compose(
  withStyles(SchedulingRulesListStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.SchedulingRule)
)(SchedulingRulesList) as React.ComponentType<any>;

export default SchedulingRulesListContainer;
