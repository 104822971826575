import { createStyles } from "@material-ui/core";

const TemplatesListStyles = () =>
  createStyles({
    dateRangeButton: {
      marginTop: "15px"
    },
    attachmentPropertiesContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    attachmentProperty: {
      margin: "4px 8px",
      flexBasis: "30%",
    },
    attachmentPropertyKey: {
      fontWeight: 600
    }
  });

export default TemplatesListStyles;
