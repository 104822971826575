import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import appDateRangeButtonStyles from "./appDateRangeButtonStyle";
import { DateRange } from "../../store/areas/shared";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Size, CardColor, SmartRange } from "../../enums";
import { AppButton } from "..";
import { Divider } from "@material-ui/core";
import { addHours, addDays, addMonths } from "date-fns";

import { AppDatePicker } from "../appDatePicker/appDatePicker";

export interface AppDateRangeButtonProps {
  classes: any;
  dateRange: DateRange;
  onUpdate: (dateRange: DateRange) => void;
  caption: string;
  id?: string;
  disableFutureProps?: DisableTimePeriodProps;
  disablePastProps?: DisableTimePeriodProps;
}

interface DisableTimePeriodProps {
  [key: string]: boolean;
}

const SmartRangeValues = [
  {
    value: SmartRange.All,
    future: false,
  },
  {
    value: SmartRange["Last month"],
    future: false,
  },
  {
    value: SmartRange["Last 7 days"],
    future: false,
  },
  {
    value: SmartRange["Last 24 hours"],
    future: false,
  },
  {
    value: SmartRange["Next 24 hours"],
    future: true,
  },
  {
    value: SmartRange["Next 7 days"],
    future: true,
  },
  {
    value: SmartRange["Next month"],
    future: true,
  },
  {
    value: SmartRange.Custom,
    future: false,
  },
];

const Item: React.FunctionComponent<AppDateRangeButtonProps> = ({
  dateRange,
  caption,
  onUpdate,
  classes,
  id,
  disableFutureProps,
  disablePastProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(mouseEvent.currentTarget);
  };

  const getDateRange = (smartRange: SmartRange): DateRange => {
    let from;
    let to;
    const now = new Date();
    switch (smartRange) {
      case "All":
        break;
      case "Last 24 hours":
        from = addHours(now, -24);
        to = now;
        break;
      case "Last 7 days":
        from = addDays(now, -7);
        to = now;
        break;
      case "Last month":
        from = addMonths(now, -1);
        to = now;
        break;
      case "Next 24 hours":
        from = now;
        to = addHours(now, 24);
        break;
      case "Next 7 days":
        from = now;
        to = addDays(now, 7);
        break;
      case "Next month":
        from = now;
        to = addMonths(now, 1);
        break;
      default:
        return dateRange;
    }
    return {
      startDate: from,
      endDate: to,
      smartRange,
    };
  };

  const handleClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (e.target) {
      const liItem = e.target as HTMLLIElement;
      if (liItem) {
        const smartRange = liItem.textContent as SmartRange;
        if (smartRange) {
          if (smartRange === SmartRange.Custom) {
            onUpdate({ ...dateRange, smartRange });
            e.preventDefault();
          } else {
            setAnchorEl(null);
            const newRange = getDateRange(smartRange);
            onUpdate(newRange);
          }
        }
      }
    }
  };

  const HandleCustomApply = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      <AppButton color={CardColor.info} onClick={handleClick} size={Size.md} round>
        {`${caption} : ${dateRange.smartRange || "All"} `}
      </AppButton>
      <Menu
        variant="selectedMenu"
        anchorEl={anchorEl}
        data-testid={id}
        open={Boolean(anchorEl)}
        classes={{ paper: classes.menu }}
        onClose={handleClose}
      >
        {SmartRangeValues.filter(k => {
          if (disableFutureProps?.startDate || disableFutureProps?.endDate) {
            return !k.future;
          }
          return k;
        }).map(k => (
          <MenuItem key={k.value} selected={dateRange.smartRange === k.value} value={k.value} onClick={handleClose}>
            {k.value}
          </MenuItem>
        ))}

        {dateRange.smartRange === "Custom" && [
          <Divider key="divider" />,
          <AppDatePicker
            date={dateRange.startDate}
            onChange={(date?: Date) => {
              if (date) {
                onUpdate({ ...dateRange, startDate: date });
              }
            }}
            labelText="From"
            id="startDate"
            key="startDate"
            disableFuture={disableFutureProps?.startDate}
            disablePast={disablePastProps?.startDate}
          />,
          <AppDatePicker
            date={dateRange.endDate}
            onChange={(date?: Date) => {
              if (date) {
                onUpdate({ ...dateRange, endDate: date });
              }
            }}
            labelText="To"
            id="endDate"
            key="endDate"
            disableFuture={disableFutureProps?.endDate}
            disablePast={disablePastProps?.endDate}
          />,
          <div key="submit" className={classes.applyButton}>
            <AppButton color={CardColor.info} onClick={HandleCustomApply} size={Size.md} round key="submit">
              Apply
            </AppButton>
          </div>,
        ]}
      </Menu>
    </div>
  );
};

export const AppDateRangeButton = withStyles(appDateRangeButtonStyles)(Item);
