import SentCommsFilter, { SentCommsFilterProps } from "./sentCommsFilter";
import { connect } from "react-redux";
import { compose } from "redux";
import SentCommsListStyles from "../sentCommsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/styles";
import { updateFilter, Filter, applyFilter } from "../../../store/areas/sentCommsList";
import { withTenant } from "../../../withTenant";

const mapStateToProps = (state: RootState): Partial<SentCommsFilterProps> => ({
  filter: state.sentCommsList.filter,
  status: state.sentCommsList.status,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
  applyFilter: () => dispatch(applyFilter()),
  initFilter: () => {
    dispatch(applyFilter());
  },
});

const SentCommsFilterContainer = compose(
  withStyles(SentCommsListStyles),
  withTenant,
  connect(mapStateToProps, mapDispatchToProps)
)(SentCommsFilter) as React.ComponentType<any>;

export default SentCommsFilterContainer;
