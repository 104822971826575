import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppDrawerStyles from "./appDrawerStyle";
import Drawer from "@material-ui/core/Drawer";
import { IconButton, Divider, List } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import { MenuItem } from "../../store/areas/layout";
import ListItemLink from "./listItemLink";

export interface AppDrawerProps {
  classes: any;
  toggleDrawer: () => void;
  drawerOpen: boolean;
  mainList: MenuItem[];
  secondaryList: MenuItem[];
}

const Item: React.FunctionComponent<AppDrawerProps> = (props: AppDrawerProps) => {
  const { classes, drawerOpen, toggleDrawer, mainList, secondaryList } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)
      }}
      open={drawerOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {mainList && mainList.length > 0 && (
        <List>
          {mainList.map(i => {
            return <ListItemLink key={i.caption} classes={classes} {...i} />;
          })}
        </List>
      )}
      {secondaryList && secondaryList.length > 0 && (
        <>
          <Divider />
          <List>
            {secondaryList.map(i => {
              return <ListItemLink key={i.caption} classes={classes} {...i} />;
            })}
          </List>
        </>
      )}
    </Drawer>
  );
};

export const AppDrawer = withStyles(AppDrawerStyles)(Item);
