import React, { useState } from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { CardColor, Size, AugmenterOwner } from "../../enums";
import { Augmenter, AugmenterMappings, Field, UndefinedMapping, Dictionary, mapOutputFieldsWithAlias } from "../../store/areas/shared";
import {
  AppButton,
  AppCard,
  AppCardBody,
  AppCardFooter,
  AppCardHeader,
  AppDialog,
  AppExpansionPanel,
  AppGridContainer,
  AppGridItem,
  AppInput,
  AppSelect,
} from "../../components";
import AliasDialog from "./AliasDialog/aliasDialog";
import AppAugmenterStyle from "./appAugmenterStyle";
import { ViewPermission } from "../../role";

export interface AppAugmenterProps {
  classes: any;
  /** All the available augmenters in the system. */
  augmenters: Dictionary<Augmenter>;
  triggerFields: Field[];
  /** Mappings used by the parent entities (i.e. Trigger or Template). This is used to identify the used augmenters. */
  inheritedMappings?: AugmenterMappings[];
  /** Mappings used by the current entity. */
  ownMappings: AugmenterMappings[];
  /** The onChange callback will be invoked with the all the updated augmenter mappings including inherited ones. */
  onChange: (augmenterMappings: AugmenterMappings[]) => void;
  permission: ViewPermission;
}

const AppAugmenterItem: React.FunctionComponent<AppAugmenterProps> = ({
  classes,
  triggerFields,
  augmenters,
  inheritedMappings,
  ownMappings,
  onChange,
  permission,
}) => {
  const [expandedStates, setExpansion] = useState<{ [x: string]: boolean }>({});
  const [dialogData, setDialogData] = useState<DialogData | undefined>();

  if (!augmenters) {
    return <></>;
  }

  let outputFields: Field[] = [...triggerFields];

  if (!inheritedMappings) {
    inheritedMappings = [];
  }

  const allMappings = [...inheritedMappings, ...(ownMappings ?? [])];
  const availableAugmenters: AugmenterSelectItem[] = Object.values(augmenters).map(x => ({ id: x.id, name: x.id }));

  return (
    <AppCard>
      <AppCardHeader color={CardColor.primary} stats={true}>
        <h4 className={classes.cardTitleWhite}>Additional Data Sources</h4>
      </AppCardHeader>
      <AppCardBody>
        {allMappings.map((x, index) => {
          let augmenter = augmenters[x.augmenterId];
          if (!augmenter) {
            augmenter = {
              id: "",
              description: "",
              outputFields: [],
              requiredInputs: [],
            };
          }

          // used when updating the `ownMappings` array
          const ownIndex = index - (inheritedMappings?.length ?? 0);

          const render =
            x.augmenterOwner !== AugmenterOwner.None
              ? renderExistingMappings(index, x, augmenters)
              : renderAugmenter(
                index,
                allMappings,
                augmenter,
                augmenters,
                availableAugmenters,
                outputFields,
                expandedStates,
                setExpansion,
                setDialogData,
                (x: AugmenterMappings) => onChange(updateMappings(ownMappings, x, ownIndex)),
                () => onChange(removeMappings(ownMappings, ownIndex)),
                () => onChange(createMappings(ownMappings, ownIndex)),
                () => onChange(createMappings(ownMappings, ownIndex + 1)),
                classes,
                permission
              );

          outputFields = [...outputFields, ...mapOutputFieldsWithAlias(x.alias, augmenter.id, augmenter.outputFields)];

          return render;
        })}
        {!!dialogData && (
          <AppDialog
            open={true}
            title={`Update Alias for '${allMappings[dialogData.index].augmenterId}'`}
            content={"By pressing Update, the 'Subject' and the 'Template Body' will be cleared"}
          >
            <AliasDialog
              allMappings={allMappings}
              mappingIndex={dialogData.index}
              onAliasUpdate={x => {
                setDialogData(undefined);
                if (!!x) {
                  onChange(updateMappings(ownMappings, x, dialogData.index));
                }
              }}
            />
          </AppDialog>
        )}
      </AppCardBody>
      {permission.write && (
        <AppCardFooter>
          <AppGridContainer>
            <AppGridItem xs={12} sm={12} md={12}>
              <AppButton
                color={CardColor.primary}
                size={Size.sm}
                onClick={() => onChange(createMappings(ownMappings))}
                disabled={!availableAugmenters.length}
              >
                Add
              </AppButton>
            </AppGridItem>
          </AppGridContainer>
        </AppCardFooter>
      )}
    </AppCard>
  );
};

function renderExistingMappings(index: number, mappings: AugmenterMappings, augmenters: Dictionary<Augmenter>) {
  const augmenter = augmenters[mappings.augmenterId];

  if (!augmenter) {
    return <React.Fragment key={index}></React.Fragment>;
  }

  let augmenterId = `${augmenter.id}`;
  if (!!mappings.alias) {
    augmenterId += ` as ${mappings.alias}`;
  }

  return (
    <AppGridContainer key={`${index}-${augmenter.id}-${mappings.alias}`} alignItems="flex-end">
      <AppGridItem xs={11} sm={11} md={11}>
        <AppInput
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: `${augmenterId} - Created by the ${AugmenterOwner[mappings.augmenterOwner]}`,
            disabled: true,
          }}
        />
      </AppGridItem>
      <AppGridItem xs={1} sm={1} md={1}>
        <Tooltip title={augmenter.description} arrow={true}>
          <InfoIcon />
        </Tooltip>
      </AppGridItem>
    </AppGridContainer>
  );
}

function renderAugmenter(
  index: number,
  allMappings: AugmenterMappings[],
  augmenter: Augmenter,
  augmenters: Dictionary<Augmenter>,
  availableAugmenters: AugmenterSelectItem[],
  outputFields: Field[],
  expandedStates: any,
  setExpansion: React.Dispatch<React.SetStateAction<{ [x: string]: boolean }>>,
  setShowDialog: SetDialogDataFunc,
  onChange: OnAugmenterChange,
  onRemove: () => void,
  onAddAbove: () => void,
  onAddBelow: () => void,
  classes: any,
  permission: ViewPermission
) {
  const actions = permission.write
    ? [
      { name: "Remove", color: CardColor.rose, size: Size.sm, onClick: onRemove },
      { name: "Add Above", color: CardColor.primary, size: Size.sm, onClick: onAddAbove },
      { name: "Add Below", color: CardColor.primary, size: Size.sm, onClick: onAddBelow },
    ]
    : [];

  const mappings = allMappings[index];

  let secondaryHeadingWarning = "";
  let secondaryTitle = augmenter.id;

  if (!mappings.augmenterId) {
    secondaryHeadingWarning = `${classes.secondaryHeadingWarning}`;
    secondaryTitle = "Please select a data source";
  } else if (Object.values(mappings.mappings).some(x => x && x === UndefinedMapping)) {
    secondaryHeadingWarning = `${classes.secondaryHeadingWarning}`;
    secondaryTitle = "Incomplete input mappings";
  } else if (allMappings.some((x, i) => i !== index && x.augmenterId === mappings.augmenterId && x.alias === mappings.alias)) {
    secondaryHeadingWarning = `${classes.secondaryHeadingWarning}`;
    secondaryTitle = "Additional data source with the same id and alias already exists";
  }

  return (
    <AppExpansionPanel
      key={`${index}-${augmenter.id}`}
      title="New Data Source"
      secondaryTitle={secondaryTitle}
      classes={{ secondaryHeading: secondaryHeadingWarning }}
      actions={actions}
      expanded={expandedStates[index]}
      onExpansionChanged={x => setExpansion({ ...expandedStates, [index]: x })}
    >
      <AppGridContainer>
        <AppGridItem xs={12} sm={12} md={12}>
          {renderAugmenterSelector(index, augmenter, mappings, augmenters, availableAugmenters, onChange, setShowDialog)}
        </AppGridItem>
        {augmenter.id && !!augmenter.requiredInputs?.length && (
          <>
            <AppGridItem xs={12} sm={12} md={12}>
              <Typography>Map Inputs</Typography>
            </AppGridItem>
            {augmenter && mappings && renderAugmenterInputFields(augmenter, mappings, outputFields, onChange)}
          </>
        )}
      </AppGridContainer>
    </AppExpansionPanel>
  );
}

function renderAugmenterSelector(
  index: number,
  augmenter: Augmenter,
  mappings: AugmenterMappings,
  augmenters: Dictionary<Augmenter>,
  selectData: AugmenterSelectItem[],
  onChange: OnAugmenterChange,
  setShowDialog: SetDialogDataFunc
) {
  return (
    <AppGridContainer alignItems="baseline">
      <AppGridItem xs={11} sm={11} md={5}>
        <AppSelect
          data={selectData}
          labelText="Select Data Source Retriever"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: augmenter.id,
            onChange: (e: any) => {
              const selectedAugmenter = augmenters[e.target.value];
              if (!selectedAugmenter) {
                return;
              }

              const mappingInputs = selectedAugmenter.requiredInputs.reduce((obj, cur) => ({ ...obj, [cur.path]: UndefinedMapping }), {});

              onChange({
                augmenterId: selectedAugmenter.id,
                alias: "",
                augmenterOwner: AugmenterOwner.None,
                mappings: mappingInputs,
              });
            },
          }}
        />
      </AppGridItem>
      <AppGridItem xs={1} sm={1} md={1}>
        {augmenter.id && (
          <Tooltip title={augmenter.description} arrow={true}>
            <InfoIcon />
          </Tooltip>
        )}
      </AppGridItem>
      <AppGridItem xs={6} sm={6} md={4}>
        {augmenter.id && (
          <AppInput
            labelText="Alias"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: mappings.alias || "No alias defined",
              disabled: true,
            }}
          />
        )}
      </AppGridItem>
      <AppGridItem xs={6} sm={6} md={2}>
        {augmenter.id && (
          <AppButton color={CardColor.primary} size={Size.sm} onClick={() => setShowDialog({ index })} id="apply-alias">
            Update
          </AppButton>
        )}
      </AppGridItem>
    </AppGridContainer>
  );
}

function renderAugmenterInputFields(augmenter: Augmenter, mappings: AugmenterMappings, outputFields: Field[], onChange: OnAugmenterChange) {
  return augmenter.requiredInputs.map((x, index) => {
    const selectData = outputFields.reduce(
      (arr: { id?: string; name: string }[], field: Field) => {
        if (x.type === field.type && x.isArray === field.isArray) {
          arr.push({ id: field.path, name: field.displayName });
        }

        return arr;
      },
      [{ id: UndefinedMapping, name: "Select field" }]
    );

    return (
      <AppGridItem key={index} xs={12} sm={12} md={12}>
        <AppGridContainer>
          <AppGridItem xs={12} sm={6} md={6}>
            <AppInput
              labelText={x.displayName}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: x.path,
                disabled: true,
              }}
            />
          </AppGridItem>
          <AppGridItem xs={12} sm={6} md={6}>
            <AppSelect
              data={selectData}
              labelText="Select field"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: mappings.mappings[x.path] ?? UndefinedMapping,
                onChange: (e: any) => {
                  onChange({
                    ...mappings,
                    mappings: {
                      ...mappings.mappings,
                      [x.path]: e.target.value,
                    },
                  });
                },
              }}
              error={!mappings.mappings[x.path] || mappings.mappings[x.path] === UndefinedMapping}
            />
          </AppGridItem>
        </AppGridContainer>
      </AppGridItem>
    );
  });
}

function createMappings(ownMappings: AugmenterMappings[], index: number | null = null) {
  const newAugmenter = { augmenterId: "", alias: "", augmenterOwner: AugmenterOwner.None, mappings: {} };

  if (index === null) {
    ownMappings = [...ownMappings, newAugmenter];
  } else {
    ownMappings = [...ownMappings];
    ownMappings.splice(index, 0, newAugmenter);
  }

  return ownMappings;
}

function updateMappings(ownMappings: AugmenterMappings[], mappingToUpdate: AugmenterMappings, index: number) {
  ownMappings = [...ownMappings];
  ownMappings.splice(index, 1, mappingToUpdate);

  return ownMappings;
}

function removeMappings(ownMappings: AugmenterMappings[], index: number) {
  ownMappings = [...ownMappings];
  ownMappings.splice(index, 1);

  return ownMappings;
}

const AppAugmenter = withStyles(AppAugmenterStyle)(AppAugmenterItem);
export default AppAugmenter;

type OnAugmenterChange = (augmenterMappings: AugmenterMappings) => void;

type AugmenterSelectItem = { id: string; name: string };

type DialogData = { index: number };

type SetDialogDataFunc = React.Dispatch<React.SetStateAction<DialogData | undefined>>;
