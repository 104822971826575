import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor
} from "../../utilities/colors";
import defaultFont from "../../utilities/font";
import { createStyles } from "@material-ui/core/styles";

const AppSelectStyles  = () =>
createStyles({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: primaryColor[4] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[1] + " !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset"
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "15px",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    paddingBottom: "10px",
    position: "relative",
    verticalAlign: "unset"
  },
  selectPaddingFix: {
    paddingBottom: 21
  }
});

export default AppSelectStyles;
