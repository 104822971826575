import TenantPicker, { TenantPickerProps } from "./tenantPicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import TenantPickerStyles from "./tenantPickerStyles";
import { RootState } from "../../store/rootReducer";
import { getTenants, setTenant } from "../../store/areas/shared";

const mapStateToProps = (state: RootState): Partial<TenantPickerProps> => ({
  activeTenantId: state.tenant.activeTenantId,
  tenants: state.tenant.tenants,
  status: state.tenant.status
});

const mapDispatchToProps = (dispatch: any) => ({
  getTenants: () => dispatch(getTenants()),
  setTenant: (id: string) => {
    dispatch(setTenant(id));
  }
});

const TenantPickerContainer = compose(
  withStyles(TenantPickerStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(TenantPicker) as React.ComponentType<any>;
export default TenantPickerContainer;
