import { grayColor, dangerColor } from "../../utilities/colors";
import defaultFont from "../../utilities/font";
import { createStyles } from "@material-ui/core";

const TemplateEditorStyles = () =>
  createStyles({
    saveButton: { marginRight: "18px" },
    editorHeader: {
      ...defaultFont,
      color: grayColor[1] + " !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset"
    },
    editorHeaderError: {
      color: dangerColor[0] + " !important"
    },
    activeButton: {
      display: "flex"
    },
    subjectWrapper: {
      width: "100%",
      display: "block",
      height: 80,
      border: "2px solid black",
      boxShadow: "2px 3px #00348d",
      overflow: "auto"
    },
    subjectEditor: {
      height: "auto",
      overflow: "visible",
      padding: "0 15px",
      borderRadius: 2,
      position: "absolute",
      width: "95%",
      "& .DraftEditor-root": {
        height: "95%"
      }
    }
  });

export default TemplateEditorStyles;
