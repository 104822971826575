import { AnyAction } from "redux";
import {
  UPDATE_TEMPLATE,
  INIT_TEMPLATE,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAIL,
  TOUCH_TEMPLATE,
  Template,
  Touched,
  Snippet,
  PreviewInfo,
  GET_SNIPPETS,
  GET_SNIPPETS_SUCCESS,
  GET_SNIPPETS_FAIL,
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAIL,
  UPDATE_EDITOR,
  QUEUE_TEMPLATE,
  QUEUE_TEMPLATE_SUCCESS,
  QUEUE_TEMPLATE_FAIL,
  UPLOAD_TEMPLATE_ATTACHMENT,
  UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS,
  UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
} from ".";
import { AppTemplateEditorOnChangeArgs } from "../../../components";
import { Field, getCombinedFields } from "../shared";
import { store } from "../../../app";
import { RootState } from "../../rootReducer";
import { TemplateAttachmentRequest, TemplateAttachment } from "./types";
import { EditorState, convertToRaw } from "draft-js";

export const updateTemplate = (template: Template): AnyAction => {
  const state: RootState = store.getState();

  const trigger = state.shared.triggers[template.trigger.id];
  const triggerFields = trigger?.fields ?? [];
  const aliasUpdates = [];

  if (state.template.template.augmenterMappings !== template.augmenterMappings) {
    const triggerMappings = trigger?.augmenterMappings ?? [];
    const templateMappings = template.augmenterMappings ?? [];
    const augmenterMappings = [...triggerMappings, ...templateMappings];
    template.combinedFields = getCombinedFields(triggerFields, augmenterMappings);

    for (const mappingIndex in template.augmenterMappings) {
      const mapping = template.augmenterMappings[mappingIndex];
      const existingMapping = state.template.template.augmenterMappings[mappingIndex];

      if (!existingMapping || mapping.augmenterId !== existingMapping.augmenterId) {
        continue;
      }

      if (mapping.alias !== existingMapping.alias) {
        const id = mapping.alias || mapping.augmenterId;
        const existingId = existingMapping.alias || existingMapping.augmenterId;
        aliasUpdates.push({ old: existingId, new: id });

        template.subjectRawContent = convertToRaw(EditorState.createEmpty().getCurrentContent());
        template.subject = "";
        template.rawContent = convertToRaw(EditorState.createEmpty().getCurrentContent());
        template.text = "";
        template.html = "";
      }
    }
  }

  return {
    type: UPDATE_TEMPLATE,
    template,
    triggerFields,
    aliasUpdates,
  };
};

export const updateEditor = (onChangeArgs: AppTemplateEditorOnChangeArgs, source: string): AnyAction => {
  let templatePartial;
  switch (source) {
    case "body":
      templatePartial = {
        html: onChangeArgs.html,
        text: onChangeArgs.text,
        rawContent: onChangeArgs.rawContent,
      };
      break;
    case "subject":
      templatePartial = {
        subject: onChangeArgs.text,
        subjectRawContent: onChangeArgs.rawContent,
      };
      break;
    default:
      templatePartial = {};
  }
  return {
    type: UPDATE_EDITOR,
    templatePartial,
  };
};

export const initTemplate = (): AnyAction => ({ type: INIT_TEMPLATE });

export const saveTemplate = (template: Template, previewInfo: PreviewInfo): AnyAction => {
  return {
    type: SAVE_TEMPLATE,
    template,
    previewInfo,
  };
};

export const saveTemplateSuccess = (message: string): AnyAction => {
  return {
    type: SAVE_TEMPLATE_SUCCESS,
    message,
  };
};

export const saveTemplateFailure = (error: Error): AnyAction => {
  return {
    type: SAVE_TEMPLATE_FAIL,
    error,
    message: error.message || "unable to save template, please contact IT Support",
  };
};

export const touchTemplate = (touched: Touched): AnyAction => {
  return {
    type: TOUCH_TEMPLATE,
    touched,
  };
};

export const getSnippets = (): AnyAction => ({ type: GET_SNIPPETS });

export const getSnippetsSuccess = (snippets: Snippet[]): AnyAction => {
  return {
    type: GET_SNIPPETS_SUCCESS,
    snippets,
  };
};

export const getSnippetsFailure = (error: Error): AnyAction => {
  return {
    type: GET_SNIPPETS_FAIL,
    error,
    message: "Unable to retrieve list of snippets, please contact KLP Support",
  };
};

export const getTemplate = (templateId: string): AnyAction => {
  return {
    type: GET_TEMPLATE,
    templateId,
  };
};

export const getTemplateSuccess = (template: Template): AnyAction => {
  const state = store.getState() as RootState;
  const triggerFields = state.shared.triggers[template.trigger.id]?.fields ?? [];

  return {
    type: GET_TEMPLATE_SUCCESS,
    template,
    triggerFields
  };
};

export const getTemplateFailure = (error: Error): AnyAction => {
  return {
    type: GET_TEMPLATE_FAIL,
    error,
    message: "Unable to retrieve template, please contact KLP Support",
  };
};

export const queueTemplate = (template: Template, email: string, fields: Field[]): AnyAction => {
  return {
    type: QUEUE_TEMPLATE,
    template,
    email,
    fields
  };
};

export const queueTemplateSuccess = (message: string): AnyAction => {
  return {
    type: QUEUE_TEMPLATE_SUCCESS,
    message,
  };
};

export const queueTemplateFailure = (error: Error): AnyAction => {
  return {
    type: QUEUE_TEMPLATE_FAIL,
    error,
    message: error.message || "unable to save and queue template, please contact IT Support",
  };
};

export const uploadTemplateAttachment = (request: TemplateAttachmentRequest): AnyAction => {
  return {
    type: UPLOAD_TEMPLATE_ATTACHMENT,
    request,
  };
};

export const uploadTemplateAttachmentSuccess = (templateAttachment: TemplateAttachment): AnyAction => {
  return {
    type: UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS,
    templateAttachment,
  };
};

export const uploadTemplateAttachmentFailure = (error: Error): AnyAction => {
  return {
    type: UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
    error,
    message: error.message || "unable to upload attachment, please contact IT Support",
  };
};
