import React from "react";
import { AppGridContainer, AppGridItem, AppButton } from "../../components";
import { CardColor, Size, RouterName } from "../../enums";
import { Snippet } from "../../store/areas/snippetsList";
import SnippetsTableContainer from "./snippetsTable/snippetsTableContainer";
import SnippetFilterContainer from "./snippetsFilter/snippetsFilterContainer";
import { ViewPermission } from "../../role";

export interface SnippetsListProps {
  history: any;
  selectedSnippets: Snippet[];
  permission: ViewPermission;
}
const SnippetsList: React.FunctionComponent<SnippetsListProps> = ({ history, selectedSnippets, permission }) => {
  const createNewSnippet = () => {
    history.push(RouterName.NewSnippet);
  };

  const createFromExistingNewSnippet = () => {
    if (selectedSnippets.length) {
      history.push(`${RouterName.NewSnippet}/${selectedSnippets[0].id}`);
    }
  };

  return (
    <>
      <SnippetFilterContainer />
      {permission.write && (
        <AppGridItem xs={12}>
          <AppGridContainer alignItems="flex-end" justify="flex-end">
            <AppButton color={CardColor.primary} onClick={createNewSnippet} size={Size.lg} id="create-new-snippet">
              Create New Snippet
            </AppButton>
            <AppButton color={CardColor.primary} disabled={!selectedSnippets.length} onClick={createFromExistingNewSnippet} size={Size.lg}>
              Create From Existing Snippet
            </AppButton>
          </AppGridContainer>
        </AppGridItem>
      )}
      <AppGridItem xs={12}>
        <SnippetsTableContainer history={history} />
      </AppGridItem>
    </>
  );
};

export default SnippetsList;
