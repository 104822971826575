import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { AppGridContainer, AppCopyright, AppHeader, AppDrawer } from "../../components";
import { MenuItem } from "../../store/areas/layout";
import SnackbarContainer from "../snackbar/snackbarViewContainer";
import { Permission } from "../../role";
import TenantPickerContainer from "../tenantPicker/tenantPickerContainer";
import TenantRequiredContainer from "../tenantRequired/tenantRequiredContainer";

export interface LayoutViewProps {
  classes: any;
  drawerOpen: boolean;
  mainList: MenuItem[];
  secondaryList: MenuItem[];
  loading: boolean;
  permission: Permission;
  toggleDrawer: () => void;
  getAugmenters: () => void;
  getTriggers: () => void;
  tenantIsSet: boolean;
}

const LayoutView: React.FunctionComponent<LayoutViewProps> = ({
  classes,
  drawerOpen,
  toggleDrawer,
  children,
  mainList,
  secondaryList,
  loading,
  permission,
  getAugmenters,
  getTriggers,
  tenantIsSet,
}) => {
  useEffect(() => {
    if (tenantIsSet) {
      getAugmenters();
      getTriggers();
    }
  }, [getAugmenters, getTriggers, tenantIsSet]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SnackbarContainer />
      <AppHeader drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}>
        <TenantPickerContainer />
      </AppHeader>
      <AppDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        mainList={mainList.filter(m => permission[m.view]?.read)}
        secondaryList={secondaryList}
      />
      <div className={loading ? classes.blur : undefined}>
        <main
          className={classes.content}
          onClick={() => {
            drawerOpen && toggleDrawer();
          }}
        >
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <AppGridContainer container spacing={3}>
              <TenantRequiredContainer>{children}</TenantRequiredContainer>
            </AppGridContainer>
          </Container>
          <AppCopyright />
        </main>
      </div>
    </div>
  );
};

export default LayoutView;
