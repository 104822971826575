import { compose } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { updateTemplate, Template } from "../../../store/areas/template";
import TemplateAugmenter, { TemplateAugmenterProps } from "./templateAugmenter";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<TemplateAugmenterProps> => ({
  augmenters: state.shared.augmenters,
  triggerFields: state.template.triggerFields,
  inheritedMappings: state.shared.triggers[state.template?.template?.trigger.id]?.augmenterMappings ?? [],
  ownMappings: state.template?.template?.augmenterMappings ?? [],
});

const mapDispatchToProps = (dispatch: any) => ({
  onTemplateChange: (template: Template) => dispatch(updateTemplate(template)),
});

const AugmenterContainerFunction = compose(connect(mapStateToProps, mapDispatchToProps), withAuthorization(View.Template)) as any;
const AugmenterContainer = AugmenterContainerFunction(TemplateAugmenter) as React.ComponentType<Partial<TemplateAugmenterProps>>;
export default AugmenterContainer;
