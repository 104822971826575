import SnippetsList, { SnippetsListProps } from "./snippetsList";
import { connect } from "react-redux";
import { compose } from "redux";
import snippetsListStyle from "./snippetsListStyles";
import { RootState } from "../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (state: RootState): Partial<SnippetsListProps> => ({
  selectedSnippets: state.snippetsList.selectedSnippets,
});

const mapDispatchToProps = () => ({});

const SnippetsListContainer = compose(
  withStyles(snippetsListStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Snippet)
)(SnippetsList) as React.ComponentType<any>;

export default SnippetsListContainer;
