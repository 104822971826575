import ScheduledCommsList, { ScheduledCommsListProps } from "./scheduledCommsList";
import { connect } from "react-redux";
import { compose } from "redux";
import ScheduledCommsListStyles from "./scheduledCommsListStyles";
import { withStyles } from "@material-ui/styles";
import { RootState } from "../../store/rootReducer";
import { openDialog, closeDialog, ScheduledCommsEditRequestType, EditScheduledCommsRequest } from "../../store/areas/scheduledCommsEdit";
import { editScheduledComms } from "../../store/areas/scheduledCommsList";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (state: RootState): Partial<ScheduledCommsListProps> => ({
  selectedScheduledComms: state.scheduledCommsList.selectedScheduledComms,
  dialog: state.scheduledCommsEdit.dialog,
  requestType: state.scheduledCommsEdit.requestType,
});

const mapDispatchToProps = (dispatch: any) => ({
  openDialog: (requestType: ScheduledCommsEditRequestType) => dispatch(openDialog(requestType)),
  closeDialog: () => dispatch(closeDialog()),
  editScheduledComms: (editRequest: EditScheduledCommsRequest) => dispatch(editScheduledComms(editRequest)),
});

const ScheduledCommsListContainer = compose(
  withStyles(ScheduledCommsListStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.ScheduledComm)
)(ScheduledCommsList) as React.ComponentType<any>;

export default ScheduledCommsListContainer;
