import { Theme, createStyles } from "@material-ui/core/styles";

const AddRuleButtonStyles = (theme: Theme) =>
  createStyles({
    groupChildren: {
      paddingLeft: "24px",
      marginTop: "10px",
      marginBottom: "10px",
      "&::first-child": {
        marginTop: "0px !important",
      },
      "&::after": {
        boxSizing: "border-box",
      },
    },
    button: {
      margin: theme.spacing(1),
      display: "flex",
    },
    ruleContainer: {
      marginBottom: "20px",
      paddingRight: "25px",

      margin: theme.spacing(1),
      "&::after": {
        boxSizing: "border-box",
      },
      "&::before": {
        boxSizing: "border-box",
      },
    },
    rule: {
      marginLeft: "10px",
      margin: "auto",
      position: "relative",
      display: "flex",
      "&::after": {
        content: "''",
        position: "absolute",
        left: "-40px",
        width: "30px",
        height: "calc(127%)",
        borderColor: "#CCC",
        borderStyle: "solid",
        top: "-76%",
        borderWidth: "0 0 2px 2px",
        boxSizing: "border-box",
      },
      "&::before": {
        top: "-4px",
        borderWidth: "0 0 2px 2px",
      },
    },
    buttonsContainer: {
      position: "absolute",
      right: "0px",
      display: "inherit",
    },
    deleteButton: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 220,
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
    },
    operatorControl: {
      top: "16px",
      minWidth: "120px",
    },
    operatorContainer: {
      position: "relative",
    },
    ruleCaption: {
      top: "14px",
      position: "relative",
    },
  });

export default AddRuleButtonStyles;
