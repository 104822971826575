import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
export interface UnauthorizedProps {
  classes?: any;
}

const Unauthorized: React.FunctionComponent<UnauthorizedProps> = ({ classes }) => (
  <Paper className={classes.paper}>
    <div className={classes.container}>
      <Typography variant="h4">Unauthorized</Typography>
      <Typography variant="subtitle1">We are sorry but you do not have access to this page.</Typography>
    </div>
  </Paper>
);

export default Unauthorized;
