import { name } from "msal/lib-commonjs/packageMetadata";
import { AnyAction, Reducer } from "redux";
import {
  AAD_ACQUIRED_ID_TOKEN_SUCCESS,
  GET_AUGMENTERS_SUCCESS,
  Account,
  Augmenter,
  EditorImage,
  UPLOAD_IMAGE_SUCCESS,
  GET_TRIGGERS_SUCCESS,
  Dictionary,
  TriggerDetails,
  Tenant
} from ".";
import { getPermission } from "../../../role";
import { AAD_INITIALIZED, GET_TENANTS_SUCCESS, GET_TENANTS_FAIL, LOGIN_SUCCESS, SET_TENANT } from "./actionTypes";
import { TenantStatus } from "./types";

export const TENANT_ID = "tenantId";
export const TENANT_NAME = "tenantName";

export interface SharedState {
  account: Account;
  loginLoaded: boolean;
  augmenters: Dictionary<Augmenter>;
  triggers: Dictionary<TriggerDetails>;
  editorImage: EditorImage;
}

const initialState: SharedState = {
  account: { userName: "", exp: 0, idToken: "", roles: [], permission: {} },
  loginLoaded: false,
  augmenters: {},
  triggers: {},
  editorImage: { name: "", imageData: "" },
};

export const SharedReducer: Reducer<SharedState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AAD_ACQUIRED_ID_TOKEN_SUCCESS:
      if (action.payload.idToken) {
        return {
          ...state,
          account: {
            ...state.account,
            userName: action.payload.idToken.preferredName,
            exp: action.payload.idToken.expiration,
            idToken: action.payload.idToken.rawIdToken,
            roles: action.payload.idToken.claims.roles,
            permission: getPermission(action.payload.idToken.claims.roles),
          },
          loginLoaded: true,
        };
      }
      return state;
    case AAD_INITIALIZED:
      return { ...state, loginLoaded: true };
    case LOGIN_SUCCESS:
      const account = action.accountInfo.account;
      return {
        ...state,
        account: {
          ...state.account,
          userName: account.idToken.preferred_username,
          roles: account.idToken.roles,
          permission: getPermission(account.idToken.roles),
          idToken: action.accountInfo.jwtIdToken ?? state.account.idToken,
        },
      };
    case GET_AUGMENTERS_SUCCESS:
      return {
        ...state,
        augmenters: action.augmenters,
      };
    case GET_TRIGGERS_SUCCESS:
      return {
        ...state,
        triggers: action.triggers,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        editorImage: action.image,
      };
    default:
      return state;
  }
};

export interface TenantState
{
  activeTenantId: string;
  tenants: Tenant[];
  status: TenantStatus;
}

const initialTenantState: TenantState = {
  activeTenantId: sessionStorage.getItem(TENANT_ID) ?? "",
  tenants: [],
  status: TenantStatus.LOADING
};

export const TenantReducer: Reducer<TenantState> = (state = initialTenantState, action: AnyAction) => {

  switch(action.type){
    case GET_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: action.tenants,
        status: TenantStatus.SUCCESS
      };

    case GET_TENANTS_FAIL:
      return {
        ...state,
        status: TenantStatus.FAILED,
      };

    case SET_TENANT:
      const tenant = state.tenants.find(tenant => tenant.id === action.tenantId);
      const tenantId = tenant?.id ?? "";
      const tenantName = tenant?.name ?? "";
      sessionStorage.setItem(TENANT_ID, tenantId);
      sessionStorage.setItem(TENANT_NAME, tenantName);
      return {
        ...state,
        activeTenantId: tenantId
      };

    default:
      return state;
  }
};