import { createStyles } from "@material-ui/core";

const AppDateRangeButtonStyles = () =>
  createStyles({
    menu: {
      width: "250px",
    },
    applyButton: {
      padding: "8px 0px 0px 59px",
    },
    container: {
      position: "relative",
      width: "%100",
    },
  });

export default AppDateRangeButtonStyles;
