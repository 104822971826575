import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { RootState } from "../../../store/rootReducer";
import { Condition } from "../../../store/areas/shared";
import { updateFilterCondition } from "../../../store/areas/scheduledCommsList";
import ScheduledCommsListStyles from "../scheduledCommsListStyles";
import scheduledCommsConditionsFilter, { ScheduledCommsConditionsFilterProps } from "./scheduledCommsConditionsFilter";
import { configureStore } from "@comms/react-query-builder/src";

const store = configureStore();

const mapStateToProps = (state: RootState): Partial<ScheduledCommsConditionsFilterProps> => ({
  condition: state.scheduledCommsList.filter.condition,
  triggerFields: state.scheduledCommsList.filter.triggerFields,
  store,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFilterCondition: (condition: Condition) => dispatch(updateFilterCondition(condition)),
});

const scheduledCommsConditionsFilterContainer = compose(
  withStyles(ScheduledCommsListStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(scheduledCommsConditionsFilter) as React.ComponentType<any>;

export default scheduledCommsConditionsFilterContainer;
