import { combineReducers, Reducer } from "redux";
import { LayoutReducer, LayoutState } from "./areas/layout";
import { LoaderReducer, LoaderState } from "./areas/loader";
import { SchedulingRulesListReducer, SchedulingRulesListState } from "./areas/schedulingRulesList";
import { SchedulingRuleReducer, SchedulingRuleState } from "./areas/schedulingRule";
import { SnackbarReducer, SnackbarState } from "./areas/snackbar";
import { TemplateReducer, TemplateState } from "./areas/template";
import { TemplatesListReducer, TemplatesListState } from "./areas/templatesList";
import { TemplatePreviewReducer, TemplatePreviewState } from "./areas/templatePreview";
import { SharedState, SharedReducer, TenantState, TenantReducer } from "./areas/shared";
import { TriggersListReducer, TriggersListState } from "./areas/triggersList";
import { CancellationRuleReducer, CancellationRuleState } from "./areas/cancellationRule";
import { CancellationRulesListReducer, CancellationRulesListState } from "./areas/cancellationRulesList";
import { SnippetReducer, SnippetState } from "./areas/snippet";
import { SnippetsListReducer, SnippetsListState } from "./areas/snippetsList";
import { ScheduledCommsListState, ScheduledCommsListReducer } from "./areas/scheduledCommsList";
import { ScheduledCommsEditState, ScheduledCommsEditReducer } from "./areas/scheduledCommsEdit";
import { SentCommsListState, SentCommsListReducer } from "./areas/sentCommsList";

export interface RootState {
  layout: LayoutState;
  loader: LoaderState;
  schedulingRule: SchedulingRuleState;
  schedulingRulesList: SchedulingRulesListState;
  snackbar: SnackbarState;
  template: TemplateState;
  templatesList: TemplatesListState;
  templatePreview: TemplatePreviewState;
  triggersList: TriggersListState;
  shared: SharedState;
  cancellationRule: CancellationRuleState;
  cancellationRulesList: CancellationRulesListState;
  snippet: SnippetState;
  snippetsList: SnippetsListState;
  scheduledCommsList: ScheduledCommsListState;
  scheduledCommsEdit: ScheduledCommsEditState;
  sentCommsList: SentCommsListState;
  tenant: TenantState;
}

const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  layout: LayoutReducer,
  loader: LoaderReducer,
  schedulingRule: SchedulingRuleReducer,
  schedulingRulesList: SchedulingRulesListReducer,
  snackbar: SnackbarReducer,
  templatesList: TemplatesListReducer,
  template: TemplateReducer,
  templatePreview: TemplatePreviewReducer,
  triggersList: TriggersListReducer,
  cancellationRule: CancellationRuleReducer,
  cancellationRulesList: CancellationRulesListReducer,
  shared: SharedReducer,
  snippet: SnippetReducer,
  snippetsList: SnippetsListReducer,
  scheduledCommsList: ScheduledCommsListReducer,
  scheduledCommsEdit: ScheduledCommsEditReducer,
  sentCommsList: SentCommsListReducer,
  tenant: TenantReducer
});

export default rootReducer;
