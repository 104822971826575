import React, { useState } from "react";
import { AppButton, AppGridContainer, AppGridItem } from "../../components";
import { CardColor, Size } from "../../enums";
import { Dialog } from "../../store/areas/shared";
import { ScheduledCommsEditRequestType } from "../../store/areas/scheduledCommsEdit/types";
import { AppDatePicker } from "../../components/appDatePicker/appDatePicker";

export interface ScheduledCommsEditProps {
  classes?: any;
  requestType: ScheduledCommsEditRequestType;
  dialog: Dialog;
  submitEditRequest: (type: ScheduledCommsEditRequestType, date?: Date) => void;
  closeDialog: () => void;
}

const ScheduledCommsEdit: React.FunctionComponent<ScheduledCommsEditProps> = ({
  requestType,
  submitEditRequest,
  closeDialog,
  classes
}) => {

  const [rescheduleDate, setRescheduleDate] = useState(new Date());

  const submitScheduledCommsEditRequest = () => {
    submitEditRequest(requestType, rescheduleDate);
  };

  return (
    <>
      <AppGridContainer justify="flex-end" alignItems="center">
        {requestType === ScheduledCommsEditRequestType.Reschedule &&
          <AppGridContainer>
            <AppGridItem xs={12} className={classes.pickerContainer}>
              <AppDatePicker
                date={rescheduleDate}
                onChange={(date?: Date) => {
                  if (date) {
                    setRescheduleDate(date);
                  }
                }}
                labelText="New Send On Date (local time)"
                id="sendOnDate"
                key="sendOnDate"
                disablePast={true}
              />
            </AppGridItem>
          </AppGridContainer>
        }
        <AppButton color={CardColor.rose} onClick={closeDialog} size={Size.md}>
          Go Back
        </AppButton>
        <AppButton color={CardColor.info} onClick={submitScheduledCommsEditRequest} size={Size.md}>
          Confirm
        </AppButton>
      </AppGridContainer>
    </>
  );
};

export default ScheduledCommsEdit;
