import { AnyAction } from "redux";
import {
  Template,
  GET_TEMPLATES,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_SUCCESS,
  INIT_SCHEDULINGRULE,
  SAVE_SCHEDULINGRULE,
  SchedulingRule,
  SAVE_SCHEDULINGRULE_SUCCESS,
  SAVE_SCHEDULINGRULE_FAIL,
  Touched,
  TOUCH_SCHEDULINGRULE,
  UPDATE_SCHEDULINGRULE,
  GET_SCHEDULINGRULE,
  GET_SCHEDULINGRULE_SUCCESS,
  GET_SCHEDULINGRULE_FAIL,
} from ".";
import { UPDATE_CONDITION, UPDATE_SCHEDULINGRULE_SUCCESS, UPDATE_SENDCONDITION } from "./actionTypes";
import { Field, Condition } from "../shared";

export const getTemplates = (): AnyAction => ({ type: GET_TEMPLATES });

export const getTemplatesSuccess = (templates: { [id: string]: Template }, combinedFields: Field[], triggerFields: Field[]): AnyAction => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    templates,
    combinedFields,
    triggerFields,
  };
};

export const getTemplatesFailure = (error: Error): AnyAction => {
  return {
    type: GET_TEMPLATES_FAIL,
    error,
    message: "Unable to retrieve list of templates, please contact KLP Support",
  };
};

export const initSchedulingRule = (): AnyAction => ({ type: INIT_SCHEDULINGRULE });

export const saveSchedulingRule = (schedulingRule: SchedulingRule): AnyAction => {
  return {
    type: SAVE_SCHEDULINGRULE,
    schedulingRule,
  };
};

export const saveSchedulingRuleSuccess = (message: string): AnyAction => {
  return {
    type: SAVE_SCHEDULINGRULE_SUCCESS,
    message,
  };
};

export const saveSchedulingRuleFailure = (error: Error): AnyAction => {
  return {
    type: SAVE_SCHEDULINGRULE_FAIL,
    error,
    message: error.message || "unable to save template, please contact IT Support",
  };
};

export const touchSchedulingRule = (touched: Touched): AnyAction => {
  return {
    type: TOUCH_SCHEDULINGRULE,
    touched,
  };
};

export const updateSchedulingRule = (schedulingRule: SchedulingRule): AnyAction => {
  return {
    type: UPDATE_SCHEDULINGRULE,
    schedulingRule,
  };
};

export const updateSchedulingRuleSuccess = (schedulingRule: SchedulingRule, triggerFields?: Field[]): AnyAction => {
  return {
    type: UPDATE_SCHEDULINGRULE_SUCCESS,
    schedulingRule,
    triggerFields,
  };
};

export const updateCondition = (condition: Condition): AnyAction => {
  return {
    type: UPDATE_CONDITION,
    condition,
  };
};

export const updateSendCondition = (sendCondition: Condition): AnyAction => {
  return {
    type: UPDATE_SENDCONDITION,
    sendCondition,
  };
};

export const getSchedulingRule = (schedulingRuleId: string): AnyAction => {
  return {
    type: GET_SCHEDULINGRULE,
    schedulingRuleId,
  };
};

export const getSchedulingRuleSuccess = (schedulingRule: SchedulingRule): AnyAction => {
  return {
    type: GET_SCHEDULINGRULE_SUCCESS,
    schedulingRule,
  };
};

export const getSchedulingRuleFailure = (error: Error): AnyAction => {
  return {
    type: GET_SCHEDULINGRULE_FAIL,
    error,
    message: "Unable to retrieve scheduling rule, please contact KLP Support",
  };
};
