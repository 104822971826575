export const UPDATE_TEMPLATE = "TEMPLATE/UPDATE_TEMPLATE";

export const UPDATE_EDITOR = "TEMPLATE/UPDATE_EDITOR";

export const INIT_TEMPLATE = "TEMPLATE/INIT_TEMPLATE";

export const SAVE_TEMPLATE = "TEMPLATE/SAVE_TEMPLATE";
export const SAVE_TEMPLATE_SUCCESS = "TEMPLATE/SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_FAIL = "TEMPLATE/SAVE_TEMPLATE_FAIL";

export const TOUCH_TEMPLATE = "TEMPLATE/TOUCH_TEMPLATE";

export const GET_SNIPPETS = "TEMPLATE/GET_SNIPPETS";
export const GET_SNIPPETS_SUCCESS = "TEMPLATE/GET_SNIPPETS_SUCCESS";
export const GET_SNIPPETS_FAIL = "TEMPLATE/GET_SNIPPETS_FAIL";

export const GET_TEMPLATE = "TEMPLATE/GET_TEMPLATE";
export const GET_TEMPLATE_SUCCESS = "TEMPLATE/GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAIL = "TEMPLATE/GET_TEMPLATE_FAIL";

export const QUEUE_TEMPLATE = "TEMPLATE/QUEUE_TEMPLATE";
export const QUEUE_TEMPLATE_SUCCESS = "TEMPLATE/QUEUE_TEMPLATE_SUCCESS";
export const QUEUE_TEMPLATE_FAIL = "TEMPLATE/QUEUE_TEMPLATE_FAIL";

export const UPLOAD_TEMPLATE_ATTACHMENT = "TEMPLATE/UPLOAD_TEMPLATE_ATTACHMENT";
export const UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS = "TEMPLATE/UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS";
export const UPLOAD_TEMPLATE_ATTACHMENT_FAIL = "TEMPLATE/UPLOAD_TEMPLATE_ATTACHMENT_FAIL";
