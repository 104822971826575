import React from "react";
import { AppGridItem, AppGridContainer, AppButton } from "../../components";
import SchedulingRulesFilterContainer from "./schedulingRulesFilter/schedulingRulesFilterContainer";
import SchedulingRulesTableContainer from "./schedulingRulesTable/schedulingRulesTableContainer";
import { CardColor, RouterName, Size } from "../../enums";
import { ViewPermission } from "../../role";

export interface SchedulingRulesListProps {
  history: any;
  classes: any;
  permission: ViewPermission;
}

const SchedulingRulesList: React.FunctionComponent<SchedulingRulesListProps> = ({ history, permission }) => {
  const createNewSchedulingRule = () => {
    history.push(RouterName.NewSchedulingRule);
  };

  return (
    <>
      <AppGridItem xs={12}>
        <SchedulingRulesFilterContainer />
      </AppGridItem>
      {permission.write && (
        <AppGridItem xs={12}>
          <AppGridContainer alignItems="flex-end" justify="flex-end">
            <AppButton color={CardColor.primary} onClick={createNewSchedulingRule} size={Size.lg}>
              Create New Scheduling Rule
            </AppButton>
          </AppGridContainer>
        </AppGridItem>
      )}

      <AppGridItem xs={12}>
        <SchedulingRulesTableContainer history={history} />
      </AppGridItem>
    </>
  );
};

export default SchedulingRulesList;
