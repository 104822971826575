export const GET_SENT_COMMS = "SENT_COMMS_LIST/GET_SENT_COMMS";
export const GET_SENT_COMMS_SUCCESS = "SENT_COMMS_LIST/GET_SENT_COMMS_SUCCESS";
export const GET_SENT_COMMS_FAIL = "SENT_COMMS_LIST/GET_SENT_COMMS_FAIL";

export const UPDATE_TABLE_HEADERS = "SENT_COMMS_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "SENT_COMMS_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "SENT_COMMS_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const UPDATE_FILTER = "SENT_COMMS_LIST/UPDATE_FILTER";
export const APPLY_FILTER = "SENT_COMMS_LIST/APPLY_FILTER";

export const DOWNLOAD_SENT_COMM = "SENT_COMMS_LIST/DOWNLOAD_SENT_COMM";
export const DOWNLOAD_SENT_COMM_SUCCESS = "SENT_COMMS_LIST/DOWNLOAD_SENT_COMM_SUCCESS";
export const DOWNLOAD_SENT_COMM_FAIL = "SENT_COMMS_LIST/DOWNLOAD_SENT_COMM_FAIL";
