import { ContentBlock } from "react-draft-wysiwyg";
import { Suggestion, Config } from "../configProps";
import { getSuggestionListComponent } from "./SuggestionListComponent";

class SuggestionDecorator {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  findSuggestionEntities = (contentBlock: ContentBlock, callback: (index: number, length: number) => void) => {
    const { separator, trigger, getSuggestions, getEditorState } = this.config;
    const editorState = getEditorState();

    if (!editorState) { return; }

    const selection = editorState.getSelection();

    if (selection.get("anchorKey") !== contentBlock.get("key") || selection.get("anchorKey") !== selection.get("focusKey")) {
      return;
    }

    let text = contentBlock.getText();
    const textSubstrLength = selection.get("focusOffset") === text.length - 1 ? text.length : selection.get("focusOffset") + 1;
    text = text.substr(0, textSubstrLength);
    let index = text.lastIndexOf(separator + trigger);
    let preText = separator + trigger;
    if ((index === undefined || index < 0) && text.startsWith(trigger)) {
      index = 0;
      preText = trigger;
    }

    if (index < 0) { return; }

    let suggestionText = text.substr(index + preText.length, text.length);

    if (!this.config.caseSensitive && !!suggestionText) {
      suggestionText = suggestionText.toLowerCase();
    }

    const suggestionPresent = getSuggestions().some((suggestion: Suggestion) => {
      if (!suggestion.value) { return false; }
      const value = this.config.caseSensitive ? suggestion.value : suggestion.value.toLowerCase();
      return value.includes(suggestionText);
    });

    if (suggestionPresent) {
      callback(index === 0 ? 0 : index + 1, text.length);
    }
  };

  getDecorator = () => ({
    strategy: this.findSuggestionEntities.bind(this),
    component: getSuggestionListComponent(this.config),
  });
}

export default SuggestionDecorator;
