import { Status } from "../shared/types";

export const scheduledCommStatuses: Status[] = [
  { id: "Pending", name: "Pending" },
  { id: "Dispatched", name: "Dispatched" },
  { id: "Sent", name: "Sent" },
  { id: "Failed", name: "Failed" },
  { id: "Dispatch Failed", name: "Dispatch Failed" },
  { id: "Cancelled", name: "Cancelled" },
  { id: "No Recipients", name: "No Recipients" }
];