import { Filter } from "./types";
import { tidyFilterStatus } from "../shared/utils";
import { RootState } from "../../rootReducer";

export const generateFilterObject = (filter: Filter): any => {
  return {
    triggerName: filter.triggerName,
    startDate: filter.dateCreated.startDate?.toUTCString() ?? null,
    endDate: filter.dateCreated.endDate?.toUTCString() ?? null,
    activeOnly: tidyFilterStatus(filter.status)
  };
};

export const getFilter = (state: RootState) => {
  return state.triggersList.filter;
};

export const getTriggerRequest = (state: RootState) => {
  return state.triggersList.queries;
};
