import { store } from "../../../app";
import { RootState } from "../../rootReducer";
import { remapAugmenterMappings, getCombinedFields } from "../shared";
import { SchedulingRule, RecipientOverride, SchedulingRulePayload } from ".";
import { RecipientSourceType, AugmenterOwner } from "../../../enums";
import { overrideField } from "../../../views/schedulingRuleEditor/values";

const generateEmailRecipientSourceInfo = (payload: any): { emailRecipientsSource: string; emailRecipientOverride: RecipientOverride } => {
  if (payload.recipientsSourceType === RecipientSourceType.FromRecipientSource) {
    return {
      emailRecipientsSource: overrideField.path,
      emailRecipientOverride: { value: payload.emailRecipientsSource ? payload.emailRecipientsSource[0] : "", isValid: true },
    };
  } else {
    return {
      emailRecipientsSource: payload.emailRecipientsSource ? payload.emailRecipientsSource[0] : "",
      emailRecipientOverride: { value: "", isValid: false },
    };
  }
};

export const remapPayload = {
  getSchedulingRule: (payload: SchedulingRulePayload): SchedulingRule => {
    const state = store.getState() as RootState;

    const trigger = state.shared.triggers[payload.triggerId];
    const triggerFields = trigger?.fields ?? [];
    const triggerAugmenterMappings = trigger?.augmenterMappings ?? [];

    const templateAugmenterMappings = state.schedulingRule.templates[payload.templateId]?.augmenterMappings ?? [];

    const schedulingRuleAugmenterMappings = remapAugmenterMappings(AugmenterOwner.SchedulingRule, payload.augmenterMappings ?? []);

    const combinedFields = getCombinedFields(triggerFields, [
      ...triggerAugmenterMappings,
      ...templateAugmenterMappings,
      ...schedulingRuleAugmenterMappings,
    ]);

    return {
      id: payload.id,
      name: payload.name,
      emailRecipientsSource: generateEmailRecipientSourceInfo(payload).emailRecipientsSource,
      uiRecipientsSource:  payload.uiRecipientsSource ? payload.uiRecipientsSource[0] :"",
      recipientsSourceType: payload.recipientsSourceType,
      emailRecipientOverride: generateEmailRecipientSourceInfo(payload).emailRecipientOverride,
      template: {
        id: payload.templateId,
        name: payload.templateName,
        triggerId: payload.triggerId,
        triggerName: payload.triggerName,
        augmenterMappings: templateAugmenterMappings,
      },
      active: !payload.disabled,
      condition: payload.condition,
      sendCondition: payload.sendCondition,
      scheduledDateSource: payload.scheduledDateSource,
      scheduledDateType: payload.scheduledDateType,
      offset: payload.offset,
      augmenterMappings: schedulingRuleAugmenterMappings,
      combinedFields,
      notificationChannelTypes: payload.notificationChannelTypes,
      isSendAsImmediate: payload.isSendAsImmediate
    };
  },
};

export const tidyOffset = (input: string) => {
  switch (input) {
    case "-":
    case "0":
    case undefined:
    case null:
      return undefined;
    default:
      return parseInt(input);
  }
};
