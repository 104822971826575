import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import {
  GET_SNIPPETS_SUCCESS,
  GET_SNIPPETS,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_FILTER,
  SELECT_SNIPPET,
  DESELECT_SNIPPET
} from "./actionTypes";
import {
  Snippet,
  SnippetsRequest,
  SnippetTableFields,
  Filter,
  SnippetColumnIndex
} from "./types";
import { Column, UpdateTableHeadersObject, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { defaultStatuses } from "../shared/values";

export interface SnippetsListState {
  snippets?: Snippet[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: SnippetsRequest;
  selectedDataIndices: number[];
  selectedSnippets: Snippet[];
  status: Status[];
  filter: Filter;
}

export const defaultSnippetColumns: Column[] = [
  {
    name: "name",
    label: "Snippet Name",
    dataType: DataType.String
  },
  {
    name: "lastModified",
    label: "Last Modified",
    dataType: DataType.Datetime
  },
  {
    name: "active",
    label: "Active",
    dataType: DataType.Boolean
  }
];

export const defaultFilterValues: Filter = {
  snippetName: "",
  dateCreated: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All
  },
  status: ""
};

export const initialSnippetQueries: SnippetsRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  sortAscending: false,
  sortField: SnippetTableFields.LastModified
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: SnippetColumnIndex.LastModified,
  sortOrder: SortOrder.desc
};

export const initialState: SnippetsListState = {
  snippets: [],
  status: defaultStatuses,
  columns: defaultSnippetColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  queries: initialSnippetQueries,
  selectedDataIndices: [],
  selectedSnippets: [],
  filter: defaultFilterValues
};

export const SnippetsListReducer: Reducer<SnippetsListState> = (
  state: SnippetsListState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_SNIPPETS:
      const { snippetsRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns
      };
    case GET_SNIPPETS_SUCCESS:
      const { snippets, totalRows } = action;
      return {
        ...state,
        selectedSnippet: [],
        selectedDataIndices: [],
        snippets,
        totalRows
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    case SELECT_SNIPPET:
      return {
        ...state,
        selectedSnippets: [...action.selectedSnippet],
        selectedDataIndices: [...action.selectedDataIndex]
      };
    case DESELECT_SNIPPET:
      return {
        ...state,
        selectedSnippets: [],
        selectedDataIndices: []
      };
    default:
      return state;
  }
};

export default SnippetsListReducer;
