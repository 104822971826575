import { AnyAction } from "redux";
import { OPEN_DIALOG, CLOSE_DIALOG, ScheduledCommsEditRequestType } from ".";

export const openDialog = (requestType: ScheduledCommsEditRequestType): AnyAction => {
  return {
    type: OPEN_DIALOG,
    requestType
  };
};

export const closeDialog = (): AnyAction => ({ type: CLOSE_DIALOG });
