import React, { useEffect } from "react";
import { SchedulingRule, Template, Touched } from "../../store/areas/schedulingRule";
import { CardColor, Size, RouterName, ScheduledDateSourceType, FieldType, RecipientSourceType, BasicColor } from "../../enums";
import {
  AppGridContainer,
  AppGridItem,
  AppCardHeader,
  AppCardBody,
  AppCard,
  AppInput,
  AppSelect,
  AppCardFooter,
  AppButton,
  AppSwitch,
} from "../../components";
import Recipient from "./recipient";
import ScheduledDate from "./scheduledDate";
import { Divider } from "@material-ui/core";
import { ScheduledDateType, Field, Condition, initialCondition, Dictionary, Trigger } from "../../store/areas/shared";
import { notificationChannelTypes, overrideField } from "./values";
import SchedulingRuleConditionContainer from "./schedulingRuleCondition/schedulingRuleConditionContainer";
import SchedulingRuleSendConditionContainer from "./schedulingRuleCondition/schedulingRuleSendConditionContainer";
import { AreRulesValid } from "@comms/react-query-builder";
import { ViewPermission } from "../../role";

export interface SchedulingRuleEditorProps {
  classes: any;
  triggers: Dictionary<Trigger>;
  templates: Dictionary<Template>;
  schedulingRule: SchedulingRule;
  trigger: Trigger;
  saved: boolean;
  history: any;
  match: any;
  touched: Touched;
  scheduledDateTypes: ScheduledDateType[];
  permission: ViewPermission;
  fields: Field[];
  tenantId: string;
  initSchedulingRulePage: () => void;
  getTemplates: () => void;
  updateSchedulingRule: (schedulingRule: SchedulingRule) => void;
  saveSchedulingRule: (schedulingRule: SchedulingRule) => void;
  getSchedulingRule: (schedulingRuleId: string) => void;
  touchSchedulingRule: (touched: Touched) => void;
  resetState: () => void;
}

const validSchedulingRule = (schedulingRule: SchedulingRule) => {
  return (
    schedulingRule.name &&
    schedulingRule.template?.id &&
    recipientSourceIsValid(schedulingRule) &&
    scheduledDateIsValid(schedulingRule) &&
    conditionIsValid(schedulingRule.condition) &&
    conditionIsValid(schedulingRule.sendCondition)
  );
};

const recipientSourceIsValid = (schedulingRule: SchedulingRule) => {
  if (schedulingRule.recipientsSourceType === RecipientSourceType.FromPayload) {
    return schedulingRule.emailRecipientsSource || schedulingRule.uiRecipientsSource;
  }

  if (schedulingRule.recipientsSourceType === RecipientSourceType.FromRecipientSource) {
    return schedulingRule.emailRecipientOverride?.isValid;
  }
  return false;
};

const scheduledDateIsValid = (schedulingRule: SchedulingRule) => {
  if (schedulingRule.scheduledDateType === ScheduledDateSourceType.FromPayload) {
    return schedulingRule.scheduledDateSource;
  }
  return true;
};

const conditionIsValid = (condition: Condition) => {
  if (condition?.rawState?.rules === undefined) {
    return true;
  }
  return AreRulesValid(condition.rawState.rules);
};

const SchedulingRuleEditor: React.FunctionComponent<SchedulingRuleEditorProps> = ({
  classes,
  initSchedulingRulePage,
  schedulingRule,
  templates,
  updateSchedulingRule,
  saveSchedulingRule,
  match,
  getSchedulingRule,
  touched,
  touchSchedulingRule,
  history,
  saved,
  resetState,
  getTemplates,
  scheduledDateTypes,
  permission,
  fields,
  tenantId,
}) => {
  useEffect(() => {
    initSchedulingRulePage();
  }, [initSchedulingRulePage]);

  useEffect(() => {
    if (match.params.schedulingRuleId) {
      getSchedulingRule(match.params.schedulingRuleId);
    }
  }, [getSchedulingRule, match, tenantId]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates, tenantId]);

  useEffect(() => {
    if (saved) {
      resetState();
      history.push(RouterName.SchedulingRules);
    }
  }, [history, resetState, saved]);

  const saveHandler = () => {
    if (validSchedulingRule(schedulingRule)) {
      saveSchedulingRule(schedulingRule);
    }
    touchSchedulingRule({
      name: true,
      emailRecipient: true,
      emailRecipientOverride: true,
      uiRecipient: true,
      template: true,
      scheduledDateSource: true,
      notificationChannelTypes: true,
    });
  };

  const emailRecipientSources =
    schedulingRule.combinedFields?.reduce(
      (arr: Field[], current: Field) => {
        return current.isRecipientSource && current.type === FieldType.Email ? [...arr, current] : arr;
      },
      [overrideField]
    ) ?? [];

  const uiRecipientSources =
    schedulingRule.combinedFields
      ?.reduce(
        (arr: Field[], current: Field) => {
          return current.isRecipientSource && (current.type === FieldType.Text || current.type === FieldType.Guid)
            ? [...arr, current]
            : arr;
        },
        [overrideField]
      )
      .filter(x => x.path !== "Override (Email)") ?? [];

  const getTitleText = () =>
    !permission.write ? "View Scheduling Rule" : match.params.schedulingRuleId ? "Edit Scheduling Rule" : "New Scheduling Rule";

  return (
    <AppGridContainer>
      <AppGridItem xs={12} sm={12} md={12}>
        <AppCard>
          <AppCardHeader color={CardColor.primary}>
            <h4 className={classes.cardTitleWhite}>{getTitleText()}</h4>
            {permission.write && <p className={classes.cardCategoryWhite}>Complete all required fields</p>}
          </AppCardHeader>
          <AppCardBody>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <AppGridContainer>
                  <AppGridItem xs={12} sm={12} md={6}>
                    <AppInput
                      touched={touched.name}
                      onTouch={() => touchSchedulingRule({ ...touched, name: true })}
                      labelText="Scheduling Rule Name"
                      id="schedulingrule-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: schedulingRule.name,
                        onChange: (e: any) =>
                          updateSchedulingRule({
                            ...schedulingRule,
                            name: e.target.value,
                          }),
                        disabled: !permission.write,
                      }}
                      error={touched.name && !schedulingRule.name}
                    />
                  </AppGridItem>
                  <AppGridItem xs={12} sm={6} flex={true} className={classes.activeButtonContainer}>
                    <AppSwitch
                      formControlProps={{ label: "Active" }}
                      color={BasicColor.primary}
                      onChange={(e: any) => {
                        updateSchedulingRule({
                          ...schedulingRule,
                          active: e.target.checked,
                        });
                      }}
                      value={schedulingRule.active}
                      inputProps={{
                        disabled: !permission.write,
                      }}
                    />
                  </AppGridItem>
                </AppGridContainer>
                <AppGridContainer>
                  <AppGridItem xs={12} sm={12} md={6}>
                    <AppSelect
                      data={templates}
                      labelText="Template"
                      id="schedulingrule-template"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: schedulingRule.template?.id || "",
                        onChange: (e: any) =>
                          updateSchedulingRule({
                            ...schedulingRule,
                            template: templates[e.target.value] || schedulingRule.template,
                            scheduledDateType: ScheduledDateSourceType.Immediate,
                            scheduledDateSource: "",
                            offset: "0",
                            condition: initialCondition,
                            sendCondition: initialCondition,
                          }),
                        disabled: !permission.write,
                      }}
                      touched={touched.template}
                      error={touched.template && !schedulingRule.template?.id}
                      onTouch={() => touchSchedulingRule({ ...touched, template: true })}
                    />
                  </AppGridItem>
                </AppGridContainer>
                <AppGridContainer>
                  <AppGridItem xs={12} sm={6}>
                    <AppSelect
                      data={notificationChannelTypes}
                      multiple={true}
                      labelText="Notification Channel Type"
                      id="schedulingrule-notification-channel-type"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: schedulingRule.notificationChannelTypes,
                        onChange: (e: any) => {
                          updateSchedulingRule({
                            ...schedulingRule,
                            notificationChannelTypes: e.target.value,
                          });
                        },
                        disabled: !permission.write,
                      }}
                      touched={touched.notificationChannelTypes}
                      error={touched.notificationChannelTypes && !schedulingRule.notificationChannelTypes.length}
                      onTouch={() => touchSchedulingRule({ ...touched, notificationChannelTypes: true })}
                    />
                  </AppGridItem>
                </AppGridContainer>
                <AppGridContainer>
                  <AppGridItem xs={12} sm={12} md={6}>
                    <AppInput
                      labelText="Trigger"
                      id="schedulingrule-trigger"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        value: schedulingRule.template.triggerName,
                      }}
                    />
                  </AppGridItem>
                </AppGridContainer>
              </AppGridItem>
              <AppGridItem xs={12} sm={12} md={6}></AppGridItem>
            </AppGridContainer>
            <Divider></Divider>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <Recipient
                  key={schedulingRule.template?.triggerId}
                  emailFields={emailRecipientSources}
                  uiFields={uiRecipientSources}
                  emailRecipient={schedulingRule.emailRecipientsSource}
                  uiRecipient={schedulingRule.uiRecipientsSource}
                  onChange={(e: any) => {
                    updateSchedulingRule(e);
                  }}
                  classes={classes}
                  schedulingRule={schedulingRule}
                  touched={touched}
                  onTouch={(touched: Touched) => touchSchedulingRule(touched)}
                  permission={permission}
                />
              </AppGridItem>
            </AppGridContainer>
            <Divider></Divider>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <ScheduledDate
                  schedulingRule={schedulingRule}
                  scheduledDateTypes={scheduledDateTypes}
                  touched={touched}
                  touchSchedulingRule={touchSchedulingRule}
                  updateSchedulingRule={updateSchedulingRule}
                  permission={permission}
                  fields={fields}
                />
              </AppGridItem>
            </AppGridContainer>
            <Divider></Divider>
            {!!schedulingRule?.combinedFields?.length && (
              <AppGridContainer>
                <AppGridItem xs={12} sm={12} md={12}>
                  <SchedulingRuleConditionContainer key={schedulingRule.template?.triggerId} />
                </AppGridItem>
              </AppGridContainer>
            )}
            <Divider></Divider>
            {!!schedulingRule?.combinedFields?.length && (
              <AppGridContainer>
                <AppGridItem xs={12} sm={12} md={12}>
                  <SchedulingRuleSendConditionContainer key={schedulingRule.template?.triggerId} />
                </AppGridItem>
              </AppGridContainer>
            )}
          </AppCardBody>
          {permission.write && (
            <AppCardFooter>
              <AppGridContainer direction="row-reverse" justify="flex-start" alignItems="center">
                <AppButton
                  color={CardColor.primary}
                  size={Size.lg}
                  className={classes.saveButton}
                  onClick={saveHandler}
                  id="schedulingrule-save"
                >
                  Save
                </AppButton>
              </AppGridContainer>
            </AppCardFooter>
          )}
        </AppCard>
      </AppGridItem>
    </AppGridContainer>
  );
};

export default SchedulingRuleEditor;
