export enum CombinatorType {
  "And" = "and",
  "Or" = "or",
}

export enum FieldType {
  "None" = "",
  "String" = "String",
  "Number" = "Number",
  "Boolean" = "Boolean",
  "Date" = "Date",
}
