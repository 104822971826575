import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppSwitchStyles from "./appSwitchStyle";
import { Switch, FormControlLabel } from "@material-ui/core";
import { BasicColor } from "../../enums";

export interface AppSwitchProps {
  classes: any;
  value: boolean;
  onChange: (e: any) => void;
  formControlProps: any;
  color?: BasicColor;
  inputProps?: any;
}

const Item: React.FunctionComponent<AppSwitchProps> = ({
  classes,
  value,
  onChange,
  formControlProps,
  color,
  inputProps,
}: AppSwitchProps) => {
  const changeHandler = (e: any) => {
    onChange(e);
  };

  return (
    <FormControlLabel
      classes={classes}
      control={<Switch checked={value} value={value} onChange={changeHandler} color={color ?? BasicColor.default} {...inputProps} />}
      label={formControlProps.label}
    ></FormControlLabel>
  );
};

export const AppSwitch = withStyles(AppSwitchStyles)(Item);
