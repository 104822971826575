import { fork } from "redux-saga/effects";
import { TemplatesListWatcher } from "./areas/templatesList";
import { SchedulingRulesListWatcher } from "./areas/schedulingRulesList";
import { sharedWatcher } from "./areas/shared";
import { TriggersListWatcher } from "./areas/triggersList";
import { templateWatcher } from "./areas/template";
import { schedulingRuleWatcher } from "./areas/schedulingRule";
import { cancellationRuleWatcher } from "./areas/cancellationRule";
import { CancellationRulesListWatcher } from "./areas/cancellationRulesList";
import { snippetWatcher } from "./areas/snippet";
import { SnippetsListWatcher } from "./areas/snippetsList";
import { ScheduledCommsListWatcher } from "./areas/scheduledCommsList";
import { SentCommsListWatcher } from "./areas/sentCommsList";

export default function* rootSaga() {
  yield fork(TemplatesListWatcher);
  yield fork(SchedulingRulesListWatcher);
  yield fork(sharedWatcher);
  yield fork(TriggersListWatcher);
  yield fork(templateWatcher);
  yield fork(schedulingRuleWatcher);
  yield fork(cancellationRuleWatcher);
  yield fork(CancellationRulesListWatcher);
  yield fork(snippetWatcher);
  yield fork(SnippetsListWatcher);
  yield fork(ScheduledCommsListWatcher);
  yield fork(SentCommsListWatcher);
}
