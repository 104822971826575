import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardBodyStyles from "./appCardBodyStyle";
import classNames from "classnames";

export interface AppCardHeaderProps {
  classes: any;
  className?: any;
  plain?: boolean;
  profile?: boolean;
}

const Item: React.FunctionComponent<AppCardHeaderProps> = props => {
  const { className, children, plain, profile, classes, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};

export const AppCardBody = withStyles(AppCardBodyStyles)(Item);
