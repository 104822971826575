import React from "react";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppInputStyles from "./appInputStyle";

export interface AppInputProps {
  labelText?: string;
  labelProps?: object;
  id?: string;
  inputProps?: object;
  formControlProps?: any;
  error?: boolean;
  success?: boolean;
  touched?: boolean;
  onTouch?: () => void;
}
const item: React.FunctionComponent<AppInputProps> = ({
  labelText,
  labelProps,
  id,
  inputProps,
  formControlProps,
  error,
  success,
  touched,
  onTouch
}: AppInputProps) => {
  const classes = AppInputStyles();
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const onBlur = () => {
    if (!touched && onTouch) {
      onTouch();
    }
  };

  const className = formControlProps.className
    ? `${formControlProps.className} ${classes.formControl}`
    : `${classes.formControl}`;

  return (
    <FormControl
      error={error}
      {...formControlProps}
      className={className}
    >
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        onBlur={onBlur}
        id={id}
        inputProps={{ "data-testid": id }}
        {...inputProps}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

export const AppInput = item;
