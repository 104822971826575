import TemplatesTable, { TemplatesTableProps } from "./templatesTable";
import { connect } from "react-redux";
import { compose } from "redux";
import templatesStyle from "../templatesListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import { TemplatesRequest, Template } from "../../../store/areas/templatesList/types";
import { getTemplates, updateTableHeaders, selectTemplate, deselectTemplate } from "../../../store/areas/templatesList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";

const mapStateToProps = (state: RootState): Partial<TemplatesTableProps> => ({
  columns: state.templatesList.columns,
  templates: state.templatesList.templates,
  totalRows: state.templatesList.totalRows,
  itemsPerPage: state.templatesList.itemsPerPage,
  queries: state.templatesList.queries,
  selectedTemplates: state.templatesList.selectedTemplates,
  selectedDataIndices: state.templatesList.selectedDataIndices,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTemplates: (templatesRequest: TemplatesRequest) => dispatch(getTemplates(templatesRequest)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
  selectTemplate: (template: Template, dataIndex: number) => dispatch(selectTemplate(template, dataIndex)),
  deselectTemplate: (template: Template, dataIndex: number) => dispatch(deselectTemplate(template, dataIndex)),
});

const TemplatesTableContainer = compose(
  withStyles(templatesStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Template)
)(TemplatesTable) as React.ComponentType<any>;

export default TemplatesTableContainer;
