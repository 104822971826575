import SnackbarView, { SnackbarViewProps } from "./snackbarView";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import SnackbarViewStyles from "./snackbarViewStyle";
import { RootState } from "../../store/rootReducer";
import { closeSnackbar } from "../../store/areas/snackbar";

const mapStateToProps = (state: RootState): Partial<SnackbarViewProps> => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
  variant: state.snackbar.variant
});

const mapDispatchToProps = (dispatch: any) => ({
  closeSnackbar: () => dispatch(closeSnackbar())
});

const SnackbarViewContainer = compose(
  withStyles(SnackbarViewStyles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SnackbarView) as React.ComponentType<any>;
export default SnackbarViewContainer;
