// This maps to KLP.Comms.Abstractions\Enums\ScheduledCommStatus.cs
// Reschedule === Pending, Cancel === Cancelled
export enum ScheduledCommsEditRequestType {
  "Reschedule" = 1,
  "Cancel" = 6
}

export type EditScheduledCommsRequest = {
  scheduledCommIds: string[];
  requestType: ScheduledCommsEditRequestType;
  rescheduleDate?: Date;
};