import { AnyAction } from "redux";
import { UPDATE_EMAIL, TOUCH_TEMPLATE_PREVIEW, OPEN_DIALOG, CLOSE_DIALOG } from ".";
import { Touched } from "./types";

export const updateEmail = (email: string): AnyAction => {
  return {
    type: UPDATE_EMAIL,
    email
  };
};

export const touchTemplatePreview = (touched: Touched): AnyAction => {
  return {
    type: TOUCH_TEMPLATE_PREVIEW,
    touched
  };
};

export const openDialog = (): AnyAction => ({ type: OPEN_DIALOG });

export const closeDialog = (): AnyAction => ({ type: CLOSE_DIALOG });
