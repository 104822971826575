import LayoutView, { LayoutViewProps } from "./layoutView";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import LayoutViewStyle from "./layoutViewStyles";
import { RootState } from "../../store/rootReducer";
import { toggleDrawer } from "../../store/areas/layout";
import { getAugmenters, getTriggers, tenantIsSet } from "../../store/areas/shared";

const mapStateToProps = (state: RootState): Partial<LayoutViewProps> => ({
  drawerOpen: state.layout.drawerOpen,
  mainList: state.layout.mainList,
  secondaryList: state.layout.secondaryList,
  loading: state.loader.loading,
  permission: state.shared.account.permission,
  tenantIsSet: tenantIsSet(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleDrawer()),
  getAugmenters: () => dispatch(getAugmenters()),
  getTriggers: () => dispatch(getTriggers()),
});

const LayoutViewContainer = compose(
  withStyles(LayoutViewStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(LayoutView) as React.ComponentType<any>;
export default LayoutViewContainer;
