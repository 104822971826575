import { createStyles } from "@material-ui/core";

const TenantRequiredStyles = () =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: `100%`,
      with: `100%`,
    },
    paper: {
      margin: 0,
      height: `100vh`,
      width: `100vw`,
    },
  });

export default TenantRequiredStyles;
