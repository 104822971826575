import TemplateEditor, { TemplateEditorProps } from "./templateEditor";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  updateTemplate,
  Template,
  initTemplate,
  saveTemplate,
  touchTemplate,
  Touched,
  getTemplate,
  updateEditor,
  queueTemplate,
  PreviewInfo,
  getSnippets,
} from "../../store/areas/template";
import TemplateEditorStyles from "./templateEditorStyles";
import { AppTemplateEditorOnChangeArgs } from "../../components";
import { openDialog, closeDialog } from "../../store/areas/templatePreview";
import { Field, EditorImage, uploadImage } from "../../store/areas/shared";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";

const mapStateToProps = (state: RootState): Partial<TemplateEditorProps> => ({
  triggers: state.shared.triggers,
  fields: state.template.template.combinedFields,
  template: state.template.template,
  saved: state.template.saved,
  previewInfo: state.template.previewInfo,
  touched: state.template.touched,
  dialog: state.templatePreview.dialog,
  editorImage: state.shared.editorImage,
  snippets: state.template.snippets,
  aliasUpdates: state.template.aliasUpdates,
});

const mapDispatchToProps = (dispatch: any) => ({
  initTemplatePage: () => {
    dispatch(initTemplate());
    dispatch(getSnippets());
  },
  updateTemplate: (template: Template) => dispatch(updateTemplate(template)),
  saveTemplate: (template: Template, previewInfo: PreviewInfo) => dispatch(saveTemplate(template, previewInfo)),
  touchTemplate: (touched: Touched) => dispatch(touchTemplate(touched)),
  getTemplate: (templateId: string) => dispatch(getTemplate(templateId)),
  resetState: () => dispatch(initTemplate()),
  updateEditor: (onChangeArgs: AppTemplateEditorOnChangeArgs, source: string) => dispatch(updateEditor(onChangeArgs, source)),
  openDialog: () => dispatch(openDialog()),
  closeDialog: () => dispatch(closeDialog()),
  queueEmail: (template: Template, email: string, fields: Field[]) =>
    dispatch(queueTemplate(template, email, fields)),
  uploadImage: (image: EditorImage) => dispatch(uploadImage(image)),
});

const TemplateEditorContainer = compose(
  withStyles(TemplateEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.Template)
)(TemplateEditor) as React.ComponentType<any>;
export default TemplateEditorContainer;
