export const GET_SCHEDULED_COMMS = "SCHEDULED_COMMS_LIST/GET_SCHEDULED_COMMS";
export const GET_SCHEDULED_COMMS_SUCCESS = "SCHEDULED_COMMS_LIST/GET_SCHEDULED_COMMS_SUCCESS";
export const GET_SCHEDULED_COMMS_FAIL = "SCHEDULED_COMMS_LIST/GET_SCHEDULED_COMMS_FAIL";

export const UPDATE_TABLE_HEADERS = "SCHEDULED_COMMS_LIST/UPDATE_TABLE_HEADERS";
export const UPDATE_TABLE_HEADERS_SUCCESS = "SCHEDULED_COMMS_LIST/UPDATE_TABLE_HEADERS_SUCCESS";
export const UPDATE_TABLE_HEADERS_FAIL = "SCHEDULED_COMMS_LIST/UPDATE_TABLE_HEADERS_FAIL";

export const UPDATE_FILTER = "SCHEDULED_COMMS_LIST/UPDATE_FILTER";
export const UPDATE_FILTER_CONDITION = "SCHEDULED_COMMS_LIST/UPDATE_FILTER_CONDITION";
export const APPLY_FILTER = "SCHEDULED_COMMS_LIST/APPLY_FILTER";

export const SELECT_SCHEDULED_COMM = "SCHEDULED_COMMS_LIST/SELECT_SCHEDULED_COMM";
export const DESELECT_SCHEDULED_COMM = "SCHEDULED_COMMS_LIST/DESELECT_SCHEDULED_COMM";

export const EDIT_SCHEDULED_COMMS = "SCHEDULED_COMMS_LIST/EDIT_SCHEDULED_COMMS";
export const EDIT_SCHEDULED_COMMS_SUCCESS = "SCHEDULED_COMMS_LIST/EDIT_SCHEDULED_COMMS_SUCCESS";
export const EDIT_SCHEDULED_COMMS_FAIL = "SCHEDULED_COMMS_LIST/EDIT_SCHEDULED_COMMS_FAIL";
