import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getSnippetsSuccess,
  getSnippetsFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getSnippets
} from "./actions";
import {
  GET_SNIPPETS,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER
} from "./actionTypes";
import { Snippet } from "./types";
import { generateUrlWithQueryString } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { generateFilterObject, getFilter, getSnippetRequest } from "./utils";
import { fetchSecure } from "../../../authProvider";

function* getSnippetsSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.Snippets, {
      ...action.snippetsRequest,
      ...filterObject
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        snippets: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const snippets: Snippet[] = yield payload.snippets.map((i: any) => ({
        id: i.id,
        name: i.name,
        lastModified: i.modifiedOn,
        active: !i.disabled
      }));
      yield put(getSnippetsSuccess(snippets, payload.totalRecordCount));
    } else {
      const error = yield call([response, "json"]);
      yield put(getSnippetsFailure(error));
    }
  } catch (error) {
    yield put(getSnippetsFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getSnippetRequest);
  yield put(getSnippets({ ...request, pageRequested: 0 }));
}

export function* SnippetsListWatcher() {
  yield takeLatest(GET_SNIPPETS, getSnippetsSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
}
