import SchedulingRuleEditor, { SchedulingRuleEditorProps } from "./schedulingRuleEditor";
import SchedulingRuleEditorStyles from "./schedulingRuleEditorStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/rootReducer";
import {
  initSchedulingRule,
  getTemplates,
  SchedulingRule,
  Touched,
  saveSchedulingRule,
  updateSchedulingRule,
  touchSchedulingRule,
  getSchedulingRule,
} from "../../store/areas/schedulingRule";
import { withAuthorization } from "../../withAuthorization";
import { View } from "../../enums";
import { withTenant } from "../../withTenant";

const mapStateToProps = (state: RootState): Partial<SchedulingRuleEditorProps> => ({
  triggers: state.shared.triggers,
  templates: state.schedulingRule.filteredTemplates,
  schedulingRule: state.schedulingRule.schedulingRule,
  saved: state.schedulingRule.saved,
  touched: state.schedulingRule.touched,
  scheduledDateTypes: state.schedulingRule.scheduledDateTypes,
  fields: state.schedulingRule.triggerFields
});

const mapDispatchToProps = (dispatch: any) => ({
  initSchedulingRulePage: () => {
    dispatch(initSchedulingRule());
  },
  getTemplates: () => dispatch(getTemplates()),
  updateSchedulingRule: (schedulingRule: SchedulingRule) => dispatch(updateSchedulingRule(schedulingRule)),
  saveSchedulingRule: (template: SchedulingRule) => dispatch(saveSchedulingRule(template)),
  touchSchedulingRule: (touched: Touched) => dispatch(touchSchedulingRule(touched)),
  getSchedulingRule: (schedulingRuleId: string) => dispatch(getSchedulingRule(schedulingRuleId)),
  resetState: () => dispatch(initSchedulingRule()),
});

const SchedulingRuleEditorContainer = compose(
  withStyles(SchedulingRuleEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTenant,
  withAuthorization(View.SchedulingRule)
)(SchedulingRuleEditor) as React.ComponentType<any>;
export default SchedulingRuleEditorContainer;
