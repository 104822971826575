import { grayColor, dangerColor } from "../../utilities/colors";
import defaultFont from "../../utilities/font";
import { createStyles } from "@material-ui/core";

const SnippetEditorStyles = () =>
  createStyles({
    saveButton: { marginRight: "18px" },
    editorHeader: {
      ...defaultFont,
      color: grayColor[1] + " !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset"
    },
    editorHeaderError: {
      color: dangerColor[0] + " !important"
    },
    activeButton: {
      display: "flex"
    }
  });

export default SnippetEditorStyles;
