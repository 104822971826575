import React, { useEffect } from "react";
import { AppTable, AppButton, AppTableRow, AppTableCell } from "../../../components";
import { Column, UpdateTableHeadersObject } from "../../../store/areas/shared";
import { SentComm, SentCommsRequest, parseMUIColumnToEnum, DownloadSentCommRequest } from "../../../store/areas/sentCommsList/types";
import { SortOrder, TableTitle, CardColor, Size, ExternalRouterName } from "../../../enums";
import { initialSentCommQueries, defaultHeaders } from "../../../store/areas/sentCommsList/reducer";
import { ROWS_PER_TABLE_PAGE_LARGE } from "../../../config";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export interface SentCommsTableProps {
  classes: any;
  columns: Column[];
  downloadSentComm: (request: DownloadSentCommRequest) => void;
  getSentComms: (sentCommsRequest: SentCommsRequest) => void;
  history: any;
  itemsPerPage: number;
  initSentComms: (sentCommsRequest: SentCommsRequest, updateTableHeadersObject: UpdateTableHeadersObject) => void;
  match: any;
  queries: SentCommsRequest;
  sentComms: SentComm[];
  totalRows: number;
  loading: boolean;
  updateTableHeaders: (updateTableHeaders: UpdateTableHeadersObject) => void;
}

const SentCommsTable: React.FunctionComponent<SentCommsTableProps> = ({
  classes,
  columns,
  downloadSentComm,
  getSentComms,
  itemsPerPage,
  initSentComms,
  match,
  queries,
  sentComms,
  totalRows,
  updateTableHeaders,
  history,
  loading,
}) => {
  const external = history.location.pathname.includes(ExternalRouterName.SentComms);
  useEffect(() => {
    initSentComms(initialSentCommQueries, defaultHeaders);
  }, [initSentComms, match]);

  useEffect(() => {
    const { scheduledCommId } = match.params;
    if (scheduledCommId) {
      const queriesWithScheduledCommId: SentCommsRequest = { ...initialSentCommQueries, scheduledCommId };
      initSentComms(queriesWithScheduledCommId, defaultHeaders);
    }
  }, [match, initSentComms]);

  const changePage = (pageRequested: number) => {
    const changePageRequest: SentCommsRequest = {
      ...queries,
      itemsPerPage,
      pageRequested,
    };
    getSentComms(changePageRequest);
  };

  const sortChange = (changedColumn: string) => {
    const { pageRequested } = queries;
    const sortField = parseMUIColumnToEnum(changedColumn);
    let sortAscending: boolean;
    if (sortField === queries.sortField) {
      sortAscending = !queries.sortAscending;
    } else {
      sortAscending = true;
    }
    const sortChangeRequest: SentCommsRequest = {
      itemsPerPage,
      sortAscending,
      sortField,
      pageRequested,
    };
    getSentComms(sortChangeRequest);
  };

  const onTableChange = (action: string, tableState: any) => {
    switch (action) {
      case "sort":
        const { activeColumn: columnIndex } = tableState;
        const { sortDirection } = tableState.columns[columnIndex];
        const mapStringToEnum: any = {
          desc: SortOrder.asc,
          asc: SortOrder.desc,
        };
        const sortOrder: SortOrder = mapStringToEnum[sortDirection];
        const updateTableHeadersObject: UpdateTableHeadersObject = {
          columnIndex,
          sortOrder,
        };
        updateTableHeaders(updateTableHeadersObject);
        break;
      default:
        return;
    }
  };

  const customCellNode = (sentComm: SentComm) => {
    const request: DownloadSentCommRequest = {
      blobPath: sentComm.downloadUri,
      fileType: sentComm.fileType,
      sentOn: new Date(sentComm.sentOn),
      recipient: sentComm.recipient,
    };
    return (
      <AppButton
        color={CardColor.primary}
        onClick={() => downloadSentComm(request)}
        round
        size={Size.sm}
        startIcon={<CloudDownloadIcon />}
        thinRows
      >
        Download
      </AppButton>
    );
  };

  const renderExpandableRow = (rowData: any, rowMeta: any) => {
    const attachmentsForCurrentRow = sentComms[rowMeta.rowIndex].attachments;
    if (attachmentsForCurrentRow?.length === 0) {
      return (
        <AppTableRow shadingOnHover id={`attachment-properties-row-${rowMeta.rowIndex}`}>
          <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={rowData.length + 1}>
            <span>This template currently has no attachments.</span>
          </AppTableCell>
        </AppTableRow>
      );
    }
    return (
      <AppTableRow shadingOnHover id={`attachment-properties-row-${rowMeta.rowIndex}`}>
        <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={1}>
          <span>Attachments:</span>
        </AppTableCell>
        <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={rowData.length}>
          <div className={classes.attachmentsContainer}>
            {attachmentsForCurrentRow?.map((el, idx) => {
              const lastIndex = el.lastIndexOf(".");
              const fileName = el.substring(0, lastIndex);
              const extension = el.substring(lastIndex, el.length);
              return (
                <div className={classes.attachment} key={idx}>
                  <span className={classes.attachmentName}>{fileName}</span>
                  <span className={classes.attachmentExtension}>{extension}</span>
                </div>
              );
            })}
          </div>
        </AppTableCell>
      </AppTableRow>
    );
  };

  return (
    <>
      {loading && external ? (
        "Loading"
      ) : (
        <AppTable
          rowsPerPage={ROWS_PER_TABLE_PAGE_LARGE}
          columns={columns}
          title={TableTitle.SentComms}
          data={sentComms}
          count={totalRows}
          serverSide={true}
          sort={true}
          customCellNode={customCellNode}
          thinRows
          onChangePage={(pageNumber: number) => changePage(pageNumber)}
          onTableChange={(action: string, tableState: any) => onTableChange(action, tableState)}
          pageRequested={queries.pageRequested}
          onColumnSortChange={(changedColumn: string) => sortChange(changedColumn)}
          makeRowsExpandable={true}
          renderExpandableRow={renderExpandableRow}
        />
      )}
    </>
  );
};

export default SentCommsTable;
