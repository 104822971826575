import React, { useEffect } from "react";
import {
  AppGridContainer,
  AppGridItem,
  AppCard,
  AppCardHeader,
  AppCardBody,
  AppCardFooter,
  AppButton,
  AppSelect,
  AppInput,
  AppTemplateEditor,
  AppSwitch,
  AppDialog,
  AppTemplateEditorOnChangeArgs,
  Suggestion,
} from "../../components";
import { CardColor, Size, RouterName, BasicColor } from "../../enums";
import { Template, Touched, TemplateType, PreviewInfo, Snippet } from "../../store/areas/template";
import { Dialog, Field, UndefinedMapping, EditorImage, Trigger, Dictionary } from "../../store/areas/shared";
import enumToObjects from "../../utilities/enumToObjects";
import TemplatePreviewContainer from "../templatePreview/templatePreviewContainer";
import TemplateAugmenterContainer from "./templateAugmenter/templateAugmenterContainer";
import TemplateAttachmentContainer from "./templateAttachment/templateAttachmentContainer";
import { ViewPermission } from "../../role";

export interface TemplateEditorProps {
  classes: any;
  triggers: Dictionary<Trigger>;
  fields: Field[];
  snippets: Snippet[];
  template: Template;
  editorImage: EditorImage;
  saved: boolean;
  previewInfo: PreviewInfo;
  history: any;
  match: any;
  touched: Touched;
  dialog: Dialog;
  aliasUpdates: { old: string; new: string }[];
  initTemplatePage: () => void;
  updateTemplate: (template: Template) => void;
  saveTemplate: (template: Template, previewInfo: PreviewInfo) => void;
  saveTemplateFromExisting: (template: Template) => void;
  getTemplate: (templateId: string) => void;
  touchTemplate: (touched: Touched) => void;
  resetState: () => void;
  updateEditor: (onChangeArgs: AppTemplateEditorOnChangeArgs, source: string) => void;
  openDialog: () => void;
  closeDialog: () => void;
  queueEmail: (template: Template, email: string, fields: Field[]) => void;
  uploadImage: (image: EditorImage) => void;
  permission: ViewPermission;
}

const validTemplate = (template: Template) => {
  return (
    template.name &&
    template.templateType &&
    template.trigger.id &&
    template.subject &&
    template.text &&
    template.augmenterMappings?.every(
      (x, xIndex) =>
        !!x.augmenterId &&
        !template.augmenterMappings.some((y, yIndex) => yIndex !== xIndex && y.augmenterId === x.augmenterId && y.alias === x.alias) &&
        Object.values(x.mappings).every(y => y !== UndefinedMapping)
    )
  );
};

const TemplateEditor: React.FunctionComponent<TemplateEditorProps> = ({
  classes,
  initTemplatePage,
  template,
  editorImage,
  triggers,
  updateTemplate,
  updateEditor,
  saveTemplate,
  saved,
  previewInfo,
  history,
  match,
  touched,
  touchTemplate,
  fields,
  resetState,
  getTemplate,
  openDialog,
  closeDialog,
  dialog,
  queueEmail,
  uploadImage,
  snippets,
  aliasUpdates,
  permission,
}) => {
  useEffect(() => {
    initTemplatePage();
  }, [initTemplatePage]);

  useEffect(() => {
    const templateId = match.params.templateId ?? match.params.existingTemplateId;
    if (templateId) {
      getTemplate(templateId);
    }
  }, [getTemplate, match]);

  useEffect(() => {
    if (saved) {
      if (previewInfo.queued) {
        queueEmail(template, previewInfo.email, fields);
      }
      resetState();
      history.push(RouterName.Templates);
    }
  }, [saved, template, queueEmail, fields, previewInfo, history, resetState]);

  const saveHandler = (previewInfo: PreviewInfo) => {
    if (validTemplate(template)) {
      saveTemplate({ ...template, id: match.params.templateId }, previewInfo);
    }

    touchTemplate({
      ...touched,
      name: true,
      subject: true,
      text: true,
      templateType: true,
      trigger: true,
      augmenterMappings: true,
    });
  };

  const queueHandler = (email: string) => {
    saveHandler({ ...previewInfo, queued: true, email });
    closeDialog();
  };

  const previewHandler = () => {
    if (validTemplate(template)) {
      openDialog();
    }
    touchTemplate({
      ...touched,
      name: true,
      subject: true,
      text: true,
      templateType: true,
      trigger: true,
    });
  };

  const handleTriggerChange = (e: any) => {
    const trigger = triggers[e.target.value];

    if (trigger) {
      updateTemplate({
        ...template,
        trigger,
        augmenterMappings: [],
      });
    }
  };

  const uploadImageHandler = (file: File, template: Template) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        uploadImage({
          ...editorImage,
          name: file.name,
          imageData: reader.result,
          promiseResolver: { resolve, reject },
        });
      };
    });
    return promise;
  };

  const getTemplateText = () =>
    !permission.write
      ? "View Template"
      : match.params.templateId
        ? "Edit Template"
        : match.params.existingTemplateId
          ? "Create From Existing Template"
          : "New Template";

  const subjectError = touched.subject && !template.subject;
  const subjectHeaderClasses = `${classes.editorHeader} ${subjectError ? classes.editorHeaderError : ""}`;

  const bodyError = touched.text && !template.text;
  const bodyHeaderClasses = `${classes.editorHeader} ${bodyError ? classes.editorHeaderError : ""}`;

  const isIE = /* @cc_on!@*/ false || !!(document as any).documentMode;

  const suggestions = snippets.reduce((arr: Suggestion[], cur) => {
    arr.push({ text: cur.name, value: cur.name });
    return arr;
  }, []);

  return (
    <AppGridContainer>
      <AppGridItem xs={12} sm={12} md={12}>
        <AppCard>
          <AppCardHeader color={CardColor.primary}>
            <h4 className={classes.cardTitleWhite}>{getTemplateText()}</h4>
            {permission.write && <p className={classes.cardCategoryWhite}>Complete all required fields</p>}
          </AppCardHeader>
          <AppCardBody>
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={4}>
                <AppInput
                  touched={touched.name}
                  onTouch={() => touchTemplate({ ...touched, name: true })}
                  labelText="Template Name"
                  id="template-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: template.name,
                    onChange: (e: any) => updateTemplate({ ...template, name: e.target.value }),
                    disabled: !permission.write,
                  }}
                  error={touched.name && !template.name}
                />
              </AppGridItem>
              <AppGridItem xs={12} sm={6} md={4}>
                <AppSelect
                  data={enumToObjects(TemplateType)}
                  labelText="Template Type"
                  id="template-templatetype"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: template.templateType || "",
                    onChange: (e: any) =>
                      updateTemplate({
                        ...template,
                        templateType: e.target.value,
                      }),
                    disabled: !permission.write,
                  }}
                  touched={touched.templateType}
                  error={touched.templateType && !template.templateType}
                  onTouch={() => touchTemplate({ ...touched, templateType: true })}
                />
              </AppGridItem>
              <AppGridItem xs={12} sm={6} md={4}>
                <AppSelect
                  data={triggers}
                  labelText="Trigger"
                  id="template-trigger"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: template.trigger.id ?? "",
                    onChange: handleTriggerChange,
                    disabled: !!match.params.templateId || !!match.params.existingTemplateId || !permission.write,
                  }}
                  touched={touched.trigger}
                  error={touched.trigger && !template.trigger.id}
                  onTouch={() => touchTemplate({ ...touched, trigger: true })}
                />
              </AppGridItem>
              <AppGridItem xs={12} sm={6} md={4} flex={true}>
                <AppSwitch
                  formControlProps={{ label: "Active" }}
                  color={BasicColor.primary}
                  onChange={(e: any) => {
                    updateTemplate({
                      ...template,
                      active: e.target.checked,
                    });
                  }}
                  inputProps={{
                    disabled: !permission.write,
                  }}
                  value={template.active}
                />
              </AppGridItem>
            </AppGridContainer>
            {!!template.trigger.id && (
              <AppGridContainer>
                <AppGridItem xs={12} sm={12} md={12}>
                  <TemplateAugmenterContainer />
                </AppGridItem>
              </AppGridContainer>
            )}
            {
              <AppGridContainer>
                <AppGridItem xs={12} sm={12} md={12}>
                  <TemplateAttachmentContainer />
                </AppGridItem>
              </AppGridContainer>
            }
            <AppGridContainer>
              <AppGridItem xs={12} sm={12} md={12}>
                <p className={subjectHeaderClasses}>Subject</p>
                <AppTemplateEditor
                  id="template-subject"
                  wrapperId={1000}
                  rawContent={template.subjectRawContent}
                  editorProps={{
                    wrapperClass: classes.subjectWrapper,
                    editorClass: classes.subjectEditor,
                    handlePastedText: isIE ? undefined : () => false,
                  }}
                  singleLine={true}
                  onChange={(e: AppTemplateEditorOnChangeArgs) => updateEditor(e, "subject")}
                  error={subjectError}
                  onTouch={() => touchTemplate({ ...touched, subject: true })}
                  fields={fields}
                  disableToolbar={true}
                  readOnly={fields.length <= 0 || !permission.write}
                  snippets={suggestions}
                  aliasUpdate={aliasUpdates}
                  key={`${template.id}${template.trigger.id}`}
                />
              </AppGridItem>
              <AppGridItem xs={12} sm={12} md={12}>
                <p className={bodyHeaderClasses}>Body</p>
                <AppTemplateEditor
                  id="template-body"
                  wrapperId={2000}
                  rawContent={template.rawContent}
                  onChange={(e: AppTemplateEditorOnChangeArgs) => updateEditor(e, "body")}
                  editorProps={{
                    handlePastedText: isIE ? undefined : () => false,
                  }}
                  error={bodyError}
                  onTouch={() => touchTemplate({ ...touched, text: true })}
                  fields={fields}
                  readOnly={fields.length <= 0 || !permission.write}
                  disableToolbar={fields.length <= 0 || !permission.write}
                  uploadImage={(file: File) => uploadImageHandler(file, template)}
                  snippets={suggestions}
                  aliasUpdate={aliasUpdates}
                  key={`${template.id}${template.trigger.id}`}
                />
              </AppGridItem>
            </AppGridContainer>
          </AppCardBody>
          {permission.write && (
            <AppCardFooter>
              <AppGridContainer justify="flex-end" alignItems="center">
                <AppButton
                  color={CardColor.primary}
                  size={Size.lg}
                  onClick={() => saveHandler({ ...previewInfo, queued: false })}
                  id="template-save"
                >
                  Save
                </AppButton>
                {template.id && (
                  <AppButton
                    color={CardColor.primary}
                    size={Size.lg}
                    className={classes.saveButton}
                    onClick={previewHandler}
                    id="template-preview"
                  >
                    Save &amp; Preview
                  </AppButton>
                )}

                {dialog.open && (
                  <AppDialog
                    open={dialog.open}
                    title={"Queue test email"}
                    content={"By pressing Queue, test email will be queued and sent to the entered email"}
                  >
                    <TemplatePreviewContainer queueEmail={queueHandler} />
                  </AppDialog>
                )}
              </AppGridContainer>
            </AppCardFooter>
          )}
        </AppCard>
      </AppGridItem>
    </AppGridContainer>
  );
};

export default TemplateEditor;
