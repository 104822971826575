import * as React from "react";
import { Rule } from "../../store";
import { CombinatorType } from "../../enums";
import { operators } from "../../config";
import { getRulesError } from "../../utils";

export interface JsonViewerProps {
  rules: Rule[];
  combinator: CombinatorType;
}

const getJsonLogic = (rules: Rule[], combinator: CombinatorType): object => {
  const errors = getRulesError(rules);
  let logicObjects: any = rules
    .filter(r => errors[r.id] === undefined)
    .map(r => generateRuleJsonLogic(r));
  if (logicObjects.length === 1) {
    return logicObjects[0];
  } else if (logicObjects.length > 1) {
    return (logicObjects = { [combinator]: logicObjects });
  }
  return {};
};

const generateRuleJsonLogic = (rule: Rule): object => {
  return operators[rule.operatorName].jsonLogic(rule.field.name, [...rule.values]);
};

const AddRuleButton: React.FC<JsonViewerProps> = ({ rules, combinator }: JsonViewerProps) => {
  return <div>{JSON.stringify(getJsonLogic(rules, combinator), undefined, 2)}</div>;
};

export default AddRuleButton;
