import React from "react";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppAutoCompleteStyles from "./AppAutoCompleteStyle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export interface AppAutoCompleteProps {
  labelText?: string;
  labelProps?: object;
  id?: string;
  onChange?: (value: any) => void;
  formControlProps?: any;
  value: string;
  options?: string[];
}

const Item: React.FunctionComponent<AppAutoCompleteProps> = ({
  formControlProps,
  onChange,
  value,
  options,
}: AppAutoCompleteProps) => {
  const success = options?.some((o: string) => o === value);
  const error = !success;
  if (error) {
    value = "";
  }

  const classes = AppAutoCompleteStyles();

  const className = formControlProps?.className
    ? `${formControlProps?.className} ${classes.formControl} ${classes.selectControl}`
    : `${classes.formControl} ${classes.selectControl}`;

  return (
    <FormControl error={error} {...formControlProps} className={className}>
      <Autocomplete
        options={options || []}
        value={value}
        onChange={(_event: any, newValue: string | null) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        renderInput={(params: any) => <TextField {...params} label={"Field"} fullWidth />}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

export const AppAutoComplete = Item;
