import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppChipStyles from "./appChipStyle";
import { Chip } from "@material-ui/core";

export interface AppChipProps {
  className?: any;
  color?: "default" | "primary" | "secondary";
  label: string;
  size: "small" | "medium";
  disabled?: boolean;
  clickable?: boolean;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLDivElement>) => void;
  variant?: "default" | "outlined";
  chipProps?: object;
}

const Item: React.FunctionComponent<AppChipProps> = props => {
  const { className, clickable, color, disabled, id, label, onClick, onDelete, size, variant, chipProps } = props;

  return (
    <Chip
      label={label}
      disabled={disabled ?? false}
      clickable={clickable ?? true}
      className={className}
      onClick={onClick}
      onDelete={onDelete}
      id={id}
      data-testid={id}
      color={color ?? "default"}
      variant={variant ?? "default"}
      size={size}
      {...chipProps}
    />
  );
};

export const AppChip = withStyles(AppChipStyles)(Item);
