import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ContentBlock, ContentState } from "draft-js";
import "../../styles.css";

class SuggestionDecorator {
  private typeName: string;
  private className?: string;

  constructor(typeName: string, className?: string) {
    this.typeName = typeName;
    this.className = className;
  }

  findEntities(contentBlock: ContentBlock, callback: () => void, contentState: ContentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === this.typeName
      );
    }, callback);
  }

  getComponent() {
    const className = this.className;
    const Component = ({ entityKey, children, contentState }: { entityKey: string; children: React.ReactChildren; contentState: ContentState }) => {
      const { value } = contentState.getEntity(entityKey).getData();
      return (
        <span data-value={value} className={classNames("rdw-mention-link", className)}>
          {children}
        </span>
      );
    };

    Component.propTypes = {
      entityKey: PropTypes.string,
      children: PropTypes.array,
      contentState: PropTypes.object
    };

    return Component;
  }

  getDecorator() {
    return {
      strategy: this.findEntities.bind(this),
      component: this.getComponent()
    };
  }
}

export default SuggestionDecorator;
