import { CancellationRule } from "./types";

export const remapPayload = {
  getCancellationRule: (payload: any): CancellationRule => {
    return {
      id: payload.id,
      name: payload.name,
      template: {
        id: payload.templateId,
        name: payload.templateName,
        triggerId: payload.templateTriggerId,
        triggerName: payload.triggerName,
      },
      trigger: {
        id: payload.triggerId,
        name: payload.triggerName,
      },
      templateFields: [],
      triggerFields: [],
      active: !payload.disabled,
      condition: payload.condition,
      hasMultipleTemplates: payload.hasMultipleTemplates,
    };
  },
};
