import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import { GET_SENT_COMMS_SUCCESS, GET_SENT_COMMS, UPDATE_TABLE_HEADERS_SUCCESS, UPDATE_FILTER } from "./actionTypes";
import { SentComm, SentCommsRequest, SentCommsTableFields, SentCommsColumnIndex, Filter } from "./types";
import { Column, UpdateTableHeadersObject, defaultSentCommStatuses, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE_LARGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { addMonths } from "date-fns";

export interface SentCommsListState {
  sentComms?: SentComm[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: SentCommsRequest;
  filter: Filter;
  status: Status[];
}

export const defaultSentCommColumns: Column[] = [
  {
    name: "sentOn",
    label: "Sent Date",
    dataType: DataType.Datetime,
  },
  {
    name: "recipient",
    label: "Recipient",
    dataType: DataType.String,
  },
  {
    name: "subject",
    label: "Subject",
    dataType: DataType.String,
  },
  {
    name: "sent",
    label: "Sent",
    dataType: DataType.Boolean,
    disableSort: true,
  },
];

export const defaultFilterValues: Filter = {
  sentOnDate: {
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
    smartRange: SmartRange["Last month"],
  },
  recipient: "",
  subject: "",
  status: "",
};

export const initialSentCommQueries: SentCommsRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE_LARGE,
  sortAscending: false,
  sortField: SentCommsTableFields.SentOn,
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: SentCommsColumnIndex.SentOn,
  sortOrder: SortOrder.desc,
};

export const initialState: SentCommsListState = {
  sentComms: [],
  columns: defaultSentCommColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE_LARGE,
  queries: initialSentCommQueries,
  filter: defaultFilterValues,
  status: defaultSentCommStatuses,
};

export const SentCommsListReducer: Reducer<SentCommsListState> = (state: SentCommsListState = initialState, action: any) => {
  switch (action.type) {
    case GET_SENT_COMMS:
      const { sentCommsRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns,
      };
    case GET_SENT_COMMS_SUCCESS:
      const { sentComms, totalRows } = action;
      return {
        ...state,
        sentComms,
        totalRows,
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      };

    default:
      return state;
  }
};

export default SentCommsListReducer;
