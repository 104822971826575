import { TableRequest, MUIColumnNames, DateRange } from "../shared";

export interface SentComm {
  id: string;
  subject: string;
  recipient: string;
  sentOn: Date;
  status: boolean;
  downloadUri: string;
  fileType: FileType | 0;
  attachments?: string[];
}

export enum FileType {
  "HTML" = 1,
  "Text" = 2,
}

export enum SentCommsTableFields {
  "SentOn" = 1,
  "Recipient" = 2,
  "Subject" = 3,
  "Sent" = 4,
}

export enum SentCommsColumnIndex {
  "SentOn" = SentCommsTableFields.SentOn - 1,
  "Recipient" = SentCommsTableFields.Recipient - 1,
  "Subject" = SentCommsTableFields.Subject - 1,
  "Sent" = SentCommsTableFields.Sent - 1,
}

export const parseMUIColumnToEnum = (columnName: string): SentCommsTableFields => {
  switch (columnName) {
    case MUIColumnNames.sentOn:
      return SentCommsTableFields.SentOn;
    case MUIColumnNames.recipient:
      return SentCommsTableFields.Recipient;
    case MUIColumnNames.subject:
      return SentCommsTableFields.Subject;
    case MUIColumnNames.sent:
      return SentCommsTableFields.Sent;
    default:
      return SentCommsTableFields.SentOn;
  }
};

export interface SentCommsRequest extends TableRequest {
  sortField?: SentCommsTableFields;
  scheduledCommId?: string;
}

export interface Filter {
  sentOnDate: DateRange;
  recipient: string;
  subject: string;
  status: string;
}

export interface DownloadSentCommRequest {
  blobPath: string;
  fileType: number;
  recipient: string;
  sentOn: Date;
}
