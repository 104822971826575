import { Reducer } from 'redux';
import { DataType, SortOrder, SmartRange } from '../../../enums';
import {
  GET_TRIGGERS_SUCCESS,
  GET_TRIGGERS,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_FILTER,
  SELECT_TRIGGER,
  DESELECT_TRIGGER,
} from './actionTypes';
import { Trigger, TriggersRequest, TriggerTableFields, Filter, TriggerColumnIndex } from './types';
import { Column, UpdateTableHeadersObject, Status } from '../shared';
import { ROWS_PER_TABLE_PAGE } from '../../../config';
import { updateColumns } from '../shared/utils';
import { defaultStatuses } from '../shared/values';

export interface TriggersListState {
  triggers?: Trigger[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: TriggersRequest;
  selectedDataIndices: number[];
  selectedTriggers: Trigger[];
  status: Status[];
  filter: Filter;
}

export const defaultTriggerColumns: Column[] = [
  {
    name: 'name',
    label: 'Trigger Name',
    dataType: DataType.String,
  },
  {
    name: 'lastModified',
    label: 'Last Modified',
    dataType: DataType.Datetime,
  },
  {
    name: 'active',
    label: 'Active',
    dataType: DataType.Boolean,
  },
];

export const defaultFilterValues: Filter = {
  triggerName: '',
  dateCreated: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All
  },
  status: '',
};

export const initialTriggerQueries: TriggersRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  sortAscending: true,
  sortField: TriggerTableFields.Name,
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: TriggerColumnIndex.Name,
  sortOrder: SortOrder.asc,
};

export const initialState: TriggersListState = {
  triggers: [],
  status: defaultStatuses,
  columns: defaultTriggerColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  queries: initialTriggerQueries,
  selectedDataIndices: [],
  selectedTriggers: [],
  filter: defaultFilterValues,
};

export const TriggersListReducer: Reducer<TriggersListState> = (
  state: TriggersListState = initialState,
  action: any,
) => {
  switch (action.type) {
    case GET_TRIGGERS:
      const { triggersRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns,
      };
    case GET_TRIGGERS_SUCCESS:
      const { triggers, totalRows } = action;
      return {
        ...state,
        selectedTriggers: [],
        selectedDataIndices: [],
        triggers,
        totalRows,
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      };
    case SELECT_TRIGGER:
      return {
        ...state,
        selectedTriggers: [...state.selectedTriggers, action.selectedScheduledComm],
        selectedDataIndices: [...state.selectedDataIndices, action.selectedDataIndex]
      };
    case DESELECT_TRIGGER:
      return {
        ...state,
        selectedTriggers: state.selectedTriggers?.filter(el => el.id !== action.selectedTrigger.id),
        selectedDataIndices: state.selectedDataIndices?.filter(el => el !== action.selectedDataIndex)
      };
    default:
      return state;
  }
};

export default TriggersListReducer;
