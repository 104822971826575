/* tslint:disable:*/
import rootReducer from "./rootReducer";
import { createStore, applyMiddleware, compose, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import { ENABLE_REDUX_DEV } from "../config";
import createTokenExpirationMiddleware from "./middlewares/tokenExpiration";
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const expireTokenMiddleware = createTokenExpirationMiddleware();

  let composeEnhancers: any;
  let store: Store;
  if (ENABLE_REDUX_DEV) {
    composeEnhancers = composeWithDevTools({
      trace: true,
      traceLimit: 25
    });

    store = createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(expireTokenMiddleware, sagaMiddleware))
    );
  } else {
    store = createStore(
      rootReducer,
      compose(applyMiddleware(expireTokenMiddleware, sagaMiddleware))
    );
  }

  sagaMiddleware.run(rootSaga);

  return store;
}
