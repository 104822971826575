import { createStyles } from "@material-ui/core";
import { whiteColor, dangerColor, primaryColor } from "../../../utilities/colors";

const TemplateAttachmentStyles = () =>
  createStyles({
    cardTitleWhite: {
      color: whiteColor,
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    },
    fileInput: {
      display: "none",
    },
    progressItem: {
      justifyContent: "center",
      display: "flex",
    },
    circularProgress: {
      color: primaryColor[1],
    },
    uploadText: {
      color: primaryColor[1],
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "13px",
    },
    deleteIcon: {
      color: dangerColor[0],
    },
  });

export default TemplateAttachmentStyles;
