import { createStyles } from "@material-ui/core";

const CancellationRuleEditorStyles = () =>
  createStyles({
    buttonContainer: {
      marginTop: "25px",
    },
    activeButtonContainer: {
      justifyContent: "flex-end",
    },
    readonlyContainer: {
      pointerEvents: "none",
      opacity: 0.7,
    },
  });

export default CancellationRuleEditorStyles;
