import { createStyles } from "@material-ui/core";
import defaultFont from "../../utilities/font";
import { dangerColor, successColor, grayColor } from "../../utilities/colors";

const AppDatePickerStyles = () =>
  createStyles({
    dateInput: {
      width: "100px",
      padding: "6px 4px 7px 0px",
    },
    labelRoot: {
      ...defaultFont,
      color: grayColor[3] + " !important",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset",
    },
    labelRootError: {
      color: dangerColor[0],
    },
    labelRootSuccess: {
      color: successColor[0],
    },
    disabled: {
      "&:before": {
        backgroundColor: "transparent !important",
      },
    },
    feedback: {
      position: "absolute",
      top: "18px",
      right: "0",
      zIndex: 2,
      display: "block",
      width: "24px",
      height: "24px",
      textAlign: "center",
      pointerEvents: "none",
    },
    marginTop: {
      marginTop: "16px",
    },
    container: {
      padding: "6px 16px 0px",
    },
  });

export default AppDatePickerStyles;
