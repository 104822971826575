import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ContentState } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import addSuggestionFunction from '../addSuggestion';
import { Config, Suggestion } from "../configProps";

interface SuggestionListProps {
  children: string;
  blockKey: string;
  contentState: ContentState;
  decoratedText: string;
  dir: string | null;
  end: number;
  entityKey: string | null;
  key: string;
  offsetKey: string;
  start: number;
}

type Style = { left?: number; right?: number; bottom?: number };
type SetActiveOptionFunc = React.Dispatch<React.SetStateAction<number>>;
type SetFilteredSuggestionsFunc = React.Dispatch<React.SetStateAction<Suggestion[]>>;

export function getSuggestionListComponent(config: Config) {
  const initStyle: Style = { left: 15 };

  const addSuggestion = (selectedSuggestion: Suggestion) => {
    const editorState = config.getEditorState();
    const { onChange, separator, trigger, prefix, suffix } = config;

    if (selectedSuggestion) {
      addSuggestionFunction(editorState!, onChange, separator, trigger, selectedSuggestion, config.typeName, prefix, suffix);
    }
  };

  const onOptionMouseEnter = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, setActiveOption: SetActiveOptionFunc) => {
    // eslint-disable-next-line
    // @ts-ignore
    const index = event.target?.getAttribute('data-index');
    setActiveOption(parseInt(index));
  };

  const onOptionMouseLeave = (setActiveOption: SetActiveOptionFunc) => {
    setActiveOption(-1);
  };

  const filterSuggestions = (text: string, setFilteredSuggestions: SetFilteredSuggestionsFunc) => {
    const suggestions = config.getSuggestions();
    let suggestionText = text.substr(1);
    if (!suggestionText || suggestionText.length === 0) {
      setFilteredSuggestions(suggestions);
      return;
    }

    if (!config.caseSensitive && !!suggestionText) {
      suggestionText = suggestionText?.toLowerCase();
    }

    const filteredSuggestions = suggestions?.filter(suggestion => {
      const value = config.caseSensitive ? suggestion.value : suggestion.value.toLowerCase();
      return value.includes(suggestionText);
    }) ?? [];

    setFilteredSuggestions(filteredSuggestions);
  };

  const renderSuggestions = (activeOption: number, setActiveOption: SetActiveOptionFunc, filteredSuggestions: Suggestion[]) => {
    const { optionClassName } = config;

    const className = (index: number) => {
      return classNames('rdw-suggestion-option',
        optionClassName,
        { "rdw-suggestion-option-active": index === activeOption });
    };

    return filteredSuggestions.map((suggestion, index) => (
      <span
        key={index}
        data-index={index}
        spellCheck={false}
        className={className(index)}
        onClick={() => addSuggestion(filteredSuggestions[activeOption])}
        onMouseEnter={x => onOptionMouseEnter(x, setActiveOption)}
        onMouseLeave={() => onOptionMouseLeave(setActiveOption)}
      >
        {suggestion.text}
      </span>
    ));
  };

  // eslint-disable-next-line react/display-name
  return (props: SuggestionListProps) => {
    const [activeOption, setActiveOption] = useState(-1);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [style, setStyle] = useState(initStyle);
    const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>([]);

    const dropdown = useRef<HTMLSpanElement | null>(null);
    const suggestion = useRef<HTMLSpanElement | null>(null);

    const wrapperRef = config.getWrapperRef();

    const onKeydownHandler = useCallback((event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        setActiveOption(-1);
        setShowSuggestions(false);
        setStyle(initStyle);

        addSuggestion(filteredSuggestions[activeOption]);
        return;
      }

      if (event.key === 'ArrowDown' || event.key === 'Down') {
        event.preventDefault();
        if (activeOption === filteredSuggestions.length - 1) {
          setActiveOption(0);
        } else {
          setActiveOption(activeOption + 1);
        }
      } else if (event.key === 'ArrowUp' || event.key === 'Up') {
        event.preventDefault();
        if (activeOption <= 0) {
          setActiveOption(filteredSuggestions.length - 1);
        } else {
          setActiveOption(activeOption - 1);
        }
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        setShowSuggestions(false);
      }
    }, [activeOption, filteredSuggestions]);

    useEffect(() => {
      if (wrapperRef === null) { return; }

      const editorRect = wrapperRef.getBoundingClientRect();
      const suggestionRect = suggestion.current!.getBoundingClientRect();
      const dropdownRect = dropdown.current!.getBoundingClientRect();
      let left;
      let right;
      let bottom;
      if (editorRect.width < suggestionRect.left - editorRect.left + dropdownRect.width) {
        right = 15;
      } else {
        left = 15;
      }
      if (editorRect.bottom < dropdownRect.bottom) {
        bottom = 0;
      }

      setStyle({ left, right, bottom });
    }, [wrapperRef, suggestion, dropdown]);

    useEffect(() => {
      if (wrapperRef === null) { return; }
      wrapperRef.addEventListener('keydown', onKeydownHandler, { capture: true });

      // Cleanup
      return () => {
        wrapperRef.removeEventListener('keydown', onKeydownHandler, { capture: true });
      };
    }, [wrapperRef, onKeydownHandler]);

    useEffect(() => {
      filterSuggestions(props.decoratedText, setFilteredSuggestions);
      setShowSuggestions(true);
    }, [props.decoratedText]);

    const { dropdownClassName } = config;
    const className = classNames("rdw-suggestion-dropdown", dropdownClassName);

    return (
      <span
        className="rdw-suggestion-wrapper"
        ref={suggestion}
        aria-haspopup="true"
        aria-label="rdw-suggestion-popup"
      >
        <span>{props.children}</span>
        {showSuggestions && (
          <span className={className}
            contentEditable="false"
            suppressContentEditableWarning
            style={style}
            ref={dropdown}
          >
            {renderSuggestions(activeOption, setActiveOption, filteredSuggestions)}
          </span>
        )}
      </span>
    );
  };
}