import React, { FunctionComponent, useEffect } from "react";
import { AppGridItem, AppSelect } from "../../components";
import { Tenant, TENANT_NAME, TenantStatus } from "../../store/areas/shared";

export interface TenantPickerProps {
  activeTenantId: string;
  tenants: Tenant[];
  status: TenantStatus;
  getTenants: () => void;
  setTenant: (id: string) => void;
  classes: any;
}

const TenantPicker: FunctionComponent<TenantPickerProps> = ({
  activeTenantId,
  tenants,
  status,
  getTenants,
  setTenant,
  classes
}) => {
  useEffect(() => {
    getTenants();
  }, [getTenants]);


  switch(status){
    case TenantStatus.SUCCESS:
      return (
        <AppGridItem xs={4} m={3} lg={2} className={classes.TenantPicker}>
          <AppSelect
            data={tenants}
            multiple={false}
            labelText="Tenant"
            id="header-tenants"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: activeTenantId,
              placeholder: sessionStorage.getItem(TENANT_NAME) ?? "",
              onChange: (e: any) => setTenant(e.target.value)
            }}/>
        </AppGridItem>
      );
    case TenantStatus.FAILED:
      return <div>Could not load tenants</div>;
    default:
      return <div>Loading tenants...</div>;
  }
};

export default TenantPicker;