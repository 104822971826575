import React from "react";
import { AppGridContainer, AppGridItem, AppButton } from "../../components";
import { CardColor, Size, RouterName } from "../../enums";
import { Template } from "../../store/areas/templatesList";
import TemplatesTableContainer from "./templatesTable/templatesTableContainer";
import TemplateFilterContainer from "./templatesFilter/templatesFilterContainer";
import { ViewPermission } from "../../role";

export interface TemplatesListProps {
  history: any;
  selectedTemplates: Template[];
  permission: ViewPermission;
}
const TemplatesList: React.FunctionComponent<TemplatesListProps> = ({ history, selectedTemplates, permission }) => {
  const createNewTemplate = () => {
    history.push(RouterName.NewTemplate);
  };

  const createFromExistingNewTemplate = () => {
    if (selectedTemplates.length) {
      history.push(`${RouterName.NewTemplate}/${selectedTemplates[0].id}`);
    }
  };

  return (
    <>
      <TemplateFilterContainer />
      {permission.write && (
        <AppGridItem xs={12}>
          <AppGridContainer alignItems="flex-end" justify="flex-end">
            <AppButton color={CardColor.primary} onClick={createNewTemplate} size={Size.lg} id="create-new-template">
              Create New Template
            </AppButton>
            <AppButton
              color={CardColor.primary}
              disabled={!selectedTemplates.length}
              onClick={createFromExistingNewTemplate}
              size={Size.lg}
            >
              Create From Existing Template
            </AppButton>
          </AppGridContainer>
        </AppGridItem>
      )}

      <AppGridItem xs={12}>
        <TemplatesTableContainer history={history} />
      </AppGridItem>
    </>
  );
};

export default TemplatesList;
