import { createStyles } from "@material-ui/core";

const SchedulingRuleEditorStyles = () =>
  createStyles({
    buttonContainer: {
      marginTop: "25px",
    },
    activeButtonContainer: {
      justifyContent: "flex-end",
    },
  });

export default SchedulingRuleEditorStyles;
