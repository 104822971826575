import { Theme, createStyles } from "@material-ui/core/styles";

const AddRuleButtonStyles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  });

export default AddRuleButtonStyles;
