import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export interface LoaderViewProps {
  classes: any;
  loading: boolean;
}

const LoaderView: React.FunctionComponent<LoaderViewProps> = ({
  classes,
  loading
}: LoaderViewProps) => {
  const GetLoader = () => {
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.root}>
          <LinearProgress
            color="primary"
            classes={{
              barColorPrimary: classes.barColorPrimary,
              colorPrimary: classes.colorPrimary,
              root: classes.barRoot
            }}
          />
        </div>
      </div>
    );
  };

  return <div>{loading && <GetLoader />}</div>;
};
export default LoaderView;
