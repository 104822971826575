import { TableRequest, MUIColumnNames, DateRange, Trigger } from "../shared";

export interface CancellationRule {
  id: string;
  name: string;
  templateName: string;
  lastModified: Date;
  active?: boolean;
}

// namespace KLP.Comms.Enums.CancellationRulesSearchSortFields for back end mapping
export enum CancellationRulesTableFields {
  "Name" = 1,
  "TriggerName" = 2,
  "TemplateName" = 3,
  "LastModified" = 4,
  "Active" = 5
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum CancellationRulesColumnIndex {
  "Name" = CancellationRulesTableFields.Name - 1,
  "TriggerName" = CancellationRulesTableFields.TriggerName - 1,
  "TemplateName" = CancellationRulesTableFields.TemplateName - 1,
  "LastModified" = CancellationRulesTableFields.LastModified - 1,
  "Active" = CancellationRulesTableFields.Active - 1
}

export const parseMUIColumnToEnum = (columnName: string): CancellationRulesTableFields => {
  switch (columnName) {
    case MUIColumnNames.name:
      return CancellationRulesTableFields.Name;
    case MUIColumnNames.triggerName:
      return CancellationRulesTableFields.TriggerName;
    case MUIColumnNames.lastModified:
      return CancellationRulesTableFields.LastModified;
    case MUIColumnNames.templateName:
      return CancellationRulesTableFields.TemplateName;
    case MUIColumnNames.active:
      return CancellationRulesTableFields.Active;
    default:
      return CancellationRulesTableFields.Name;
  }
};

export interface CancellationRulesRequest extends TableRequest {
  sortField?: CancellationRulesTableFields;
}

export interface Filter {
  ruleName: string;
  templateName: string;
  triggers: Trigger[];
  dateModified: DateRange;
  status: string;
}
