import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppDynamicDatePickerStyles from "./AppDynamicDatePickerStyle";
import { Grid } from "@material-ui/core";
import { AppInput } from "..";
import { DatePicker } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

interface DynamicValue {
  date: Date;
  isToday: boolean;
  offset: number;
}

export interface AppDynamicDatePickerProps {
  classes: any;
  value: DynamicValue;
  id?: string;
  dynamic?: boolean;
  onChange: (value: DynamicValue) => void;
}

const OffsetInputControl = (value: DynamicValue, onChange: (value: DynamicValue) => void) => (
  <Grid item>
    <AppInput
      formControlProps={{ style: { width: "165px" } }}
      value={value?.offset || 0}
      isNumeric={true}
      onChange={(offset: any) => onChange({ date: value.date, isToday: value.isToday, offset })}
      maxLength={3}
      labelText={"Offset in days from today"}
      disableValidation={true}
    />
  </Grid>
);

const DatePickerControl = (
  classes: any,
  value: DynamicValue,
  onChange: (value: DynamicValue) => void
) => (
  <Grid item>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        className={classes.datePickerContainer}
        margin="normal"
        id="date-picker-dialog"
        label="Select Date"
        value={value?.date || new Date(Date.now())}
        onChange={(pDate: MaterialUiPickersDate) => {
          onChange({
            date: pDate
              ? new Date(pDate.getFullYear(), pDate.getMonth(), pDate.getDate())
              : value.date,
            isToday: value.isToday,
            offset: value.offset,
          });
        }}
        variant="inline"
        format="dd/MM/yyyy"
        disableToolbar
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  </Grid>
);

const Item: React.FunctionComponent<AppDynamicDatePickerProps> = ({
  classes,
  value,
  id,
  onChange,
}) => {
  useEffect(() => {
    if (value?.date === undefined && onChange) {
      const now = new Date(Date.now());
      onChange({
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        isToday: value?.isToday,
        offset: value?.offset,
      });
    }
  }, [onChange, value]);

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item className={classes.switchContainer}>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Static</Grid>
          <Grid item>
            <Switch
              classes={{
                disabled: classes.disabled,
              }}
              id={id}
              onChange={(_: any, checked) =>
                onChange &&
                onChange({
                  date: value.date,
                  isToday: checked,
                  offset: value.offset,
                })
              }
              size="small"
              checked={!!value?.isToday}
            />
          </Grid>
          <Grid item>Dynamic</Grid>
        </Grid>
      </Grid>
      {value?.isToday
        ? OffsetInputControl(value, onChange)
        : DatePickerControl(classes, value, onChange)}
    </Grid>
  );
};

export const AppDynamicDatePicker = withStyles(AppDynamicDatePickerStyles)(Item);
