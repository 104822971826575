import { makeStyles } from "@material-ui/core/styles";
import { dangerColor, successColor, grayColor } from "../../utilities/colors";
import defaultFont from "../../utilities/font";

const AppAutocompleteStyles = makeStyles({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },

  labelRoot: {
    ...defaultFont,
    color: grayColor[1] + " !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset"
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  feedback: {
    position: "absolute",
    right: "15px",
    top: "30px",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    paddingBottom: "10px",
    position: "relative",
    verticalAlign: "unset"
  },
  option: {
    fontSize: "15",
    "& > span": {
      marginRight: "10",
      fontSize: "18"
    }
  },
  buttonContainer: {
    marginTop: "7px"
  },
  removeDottedBorder: {
    "&::before": {
      borderBottom: "2px solid transparent"
    }
  },
  customAutocompleteError: {
    "& > div:nth-child(1)": {
      "&::before": {
        left: "0",
        right: "0",
        bottom: "0",
        content: '"\\00a0"',
        position: "absolute",
        transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottom: "2px solid rgba(0, 0, 0, 1)",
        pointerEvents: "none",
        transform: "scaleX(1)",
        borderBottomColor: "#f44336"
      }
    },
    "& label": {
      color: dangerColor[0] + " !important",
      borderBottom: dangerColor[0] + " !important"
    }
  }
});

export default AppAutocompleteStyles;
