import { AnyAction } from "redux";
import {
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAIL,
  SELECT_TEMPLATE,
  DESELECT_TEMPLATE,
  UPDATE_TABLE_HEADERS_SUCCESS,
  UPDATE_TABLE_HEADERS,
  UPDATE_TABLE_HEADERS_FAIL,
  UPDATE_FILTER,
  APPLY_FILTER
} from "./actionTypes";
import { Template, TemplatesRequest, Filter } from "./types";
import { SortOrder } from "../../../enums";
import { UpdateTableHeadersObject } from "../shared";

export const getTemplates = (templatesRequest: TemplatesRequest): AnyAction => {
  return {
    type: GET_TEMPLATES,
    templatesRequest
  };
};

export const getTemplatesSuccess = (templates: Template[], totalRows: number): AnyAction => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    templates,
    totalRows
  };
};

export const getTemplatesFailure = (error: Error): AnyAction => {
  return {
    type: GET_TEMPLATES_FAIL,
    message: error.message || "Unable to get list of templates",
    error
  };
};

export const updateTableHeaders = (updateTableHeadersObject: UpdateTableHeadersObject) => {
  const { columnIndex, sortOrder } = updateTableHeadersObject;
  return {
    type: UPDATE_TABLE_HEADERS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersSuccess = (columnIndex: number, sortOrder: SortOrder) => {
  return {
    type: UPDATE_TABLE_HEADERS_SUCCESS,
    columnIndex,
    sortOrder
  };
};

export const updateTableHeadersFailure = (error: Error): AnyAction => {
  return {
    type: UPDATE_TABLE_HEADERS_FAIL,
    message: error.message || "Error rendering table headers. Please refresh and try again",
    error
  };
};

export const updateFilter = (filter: Filter): AnyAction => {
  return {
    type: UPDATE_FILTER,
    filter
  };
};

export const applyFilter = (): AnyAction => ({ type: APPLY_FILTER });


export const selectTemplate = (template: Template, dataIndex: number): AnyAction => {
  return {
    type: SELECT_TEMPLATE,
    selectedTemplate: template,
    selectedDataIndex: dataIndex
  };
};

export const deselectTemplate = (template: Template, dataIndex: number): AnyAction => {
  return {
    type: DESELECT_TEMPLATE,
    selectedTemplate: template,
    selectedDataIndex: dataIndex
  };
};