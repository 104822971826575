import { createStyles, Theme } from "@material-ui/core";

const AppGridContainerStyles = (theme: Theme) =>
  createStyles({
    grid: {
      [theme.breakpoints.up("md")]: {
        margin: "0 -15px !important"
      },
      width: "100%"
    }
  });

export default AppGridContainerStyles;
