/**
 * @class QueryBuilderComponent
 */
import "core-js";
import * as React from "react";
import { FieldType, CombinatorType } from "./enums";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import JsonLogicViewerContainer from "./components/JsonLogicViewer/JsonLogicViewerContainer";
import { getJsonLogic, AreRulesValid } from "./utils";
import { ThemeProvider } from "@material-ui/core";
import defaultTheme from "./theme/defaultTheme";
import _ from "lodash";
import QueryBuilder from "./components/QueryBuilder/QueryBuilder";
import { initFields, initRules, updateCombinator } from "./store/actions";
import { QueryBuilderState } from "./store/reducer";
import { Field, Rule } from "./store/types";

interface AppProps {
  store: any;
  fields: {
    [name: string]: Field;
  };
  extraFields?: {
    [name: string]: Field;
  };
  displayJsonLogic?: boolean;
  rules?: Rule[];
  combinator?: CombinatorType;
  onChange?: (rules: Rule[], combinator: CombinatorType, jsonLogic: any) => void;
}

const App: React.FC<AppProps> = ({
  store,
  fields,
  extraFields,
  displayJsonLogic,
  rules,
  combinator,
  onChange,
}: AppProps) => {
  React.useEffect(() => {
    if (fields || extraFields) {
      store.dispatch(initFields(fields, extraFields || {}));
    }
  }, []);

  React.useEffect(() => {
    const rootState: QueryBuilderState = store.getState();
    if (rules && !_.isEqual(rootState.rules, rules)) {
      if (rules) {
        store.dispatch(initRules(rules));
      }
    }
    if (combinator && !_.isEqual(rootState.combinator.value, combinator)) {
      if (combinator) {
        store.dispatch(updateCombinator(combinator));
      }
    }
  }, []);

  React.useEffect(() => {
    if (onChange) {
      store.subscribe(() => {
        const state: QueryBuilderState = store.getState();
        onChange(
          state.rules,
          state.combinator.value,
          getJsonLogic(state.rules, state.combinator.value)
        );
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <QueryBuilder />
        {displayJsonLogic && <JsonLogicViewerContainer />}
      </ThemeProvider>
    </Provider>
  );
};

export default App;

export { FieldType, CombinatorType, AreRulesValid, configureStore, AppProps };