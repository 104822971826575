import { TableRequest, MUIColumnNames, DateRange, Trigger } from "../shared";

export interface SchedulingRule {
  id: string;
  name: string;
  triggerName: string;
  templateName: string;
  lastModified: Date;
  active?: boolean;
}

// namespace KLP.Comms.Enums.SchedulingRulesSearchSortFields for back end mapping
export enum SchedulingRulesTableFields {
  "Name" = 1,
  "TriggerName" = 2,
  "TemplateName" = 3,
  "LastModified" = 4,
  "Active" = 5
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum SchedulingRulesColumnIndex {
  "Name" = SchedulingRulesTableFields.Name - 1,
  "TriggerName" = SchedulingRulesTableFields.TriggerName - 1,
  "TemplateName" = SchedulingRulesTableFields.TemplateName - 1,
  "LastModified" = SchedulingRulesTableFields.LastModified - 1,
  "Active" = SchedulingRulesTableFields.Active - 1
}

export const parseMUIColumnToEnum = (columnName: string): SchedulingRulesTableFields => {
  switch (columnName) {
    case MUIColumnNames.name:
      return SchedulingRulesTableFields.Name;
    case MUIColumnNames.triggerName:
      return SchedulingRulesTableFields.TriggerName;
    case MUIColumnNames.lastModified:
      return SchedulingRulesTableFields.LastModified;
    case MUIColumnNames.templateName:
      return SchedulingRulesTableFields.TemplateName;
    case MUIColumnNames.active:
      return SchedulingRulesTableFields.Active;
    default:
      return SchedulingRulesTableFields.Name;
  }
};

export interface SchedulingRulesRequest extends TableRequest {
  sortField?: SchedulingRulesTableFields;
}

export interface Filter {
  ruleName: string;
  templateName: string;
  triggers: Trigger[];
  dateModified: DateRange;
  status: string;
}
