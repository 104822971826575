import { Reducer } from "redux";
import { Touched } from "./types";
import { UPDATE_EMAIL, TOUCH_TEMPLATE_PREVIEW, OPEN_DIALOG, CLOSE_DIALOG } from ".";
import { Dialog } from "../shared";

export interface TemplatePreviewState {
  email: string;
  templateId: string;
  touched: Touched;
  dialog: Dialog;
}

const initialState: TemplatePreviewState = {
  email: "",
  templateId: "",
  touched: { email: false },
  dialog: { open: false }
};
export const TemplatePreviewReducer: Reducer<TemplatePreviewState> = (
  state: TemplatePreviewState = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_EMAIL:
      return { ...state, email: action.email };
    case TOUCH_TEMPLATE_PREVIEW:
      return {
        ...state,
        touched: { ...state.touched, ...action.touched }
      };
    case OPEN_DIALOG:
      return {
        ...initialState,
        dialog: { ...state.dialog, open: true }
      };
    case CLOSE_DIALOG:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default TemplatePreviewReducer;
