import React, { useEffect } from "react";
import { CardColor, Size } from "../../../enums";
import { AppGridItem, AppGridContainer, AppExpansionPanel, AppInput, AppSelect, AppDateRangeButton } from "../../../components";
import { DateRange, Status } from "../../../store/areas/shared";
import { Filter, defaultFilterValues } from "../../../store/areas/sentCommsList";

export interface SentCommsFilterProps {
  classes: any;
  filter: Filter;
  status: Status[];
  tenantId: string;
  updateFilter: (filter: Filter) => void;
  applyFilter: () => void;
  initFilter: () => void;
}

const SentCommsFilter: React.FunctionComponent<SentCommsFilterProps> = ({
  classes,
  filter,
  status,
  tenantId,
  updateFilter,
  applyFilter,
  initFilter
}) => {

  useEffect(() => {
    initFilter();
  }, [initFilter, tenantId]);

  const clearFilter = () => {
    updateFilter(defaultFilterValues);
    applyFilter();
  };

  const actions = [
    { name: "Clear", color: CardColor.rose, size: Size.sm, onClick: clearFilter },
    { name: "Apply", color: CardColor.primary, size: Size.sm, onClick: applyFilter },
  ];

  return (
    <AppExpansionPanel title="Filters" actions={actions}>
      <AppGridContainer>
        <AppGridItem xs={12} md={6} lg={6}>
          <AppInput
            labelText="Recipient"
            id="sentcomm-recipient"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: filter.recipient,
              onChange: (e: any) => updateFilter({ ...filter, recipient: e.target.value }),
            }}
          />
        </AppGridItem>
        <AppGridItem xs={12} md={6} lg={6}>
          <AppInput
            labelText="Subject"
            id="sentcomm-subject"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: filter.subject,
              onChange: (e: any) => updateFilter({ ...filter, subject: e.target.value }),
            }}
          />
        </AppGridItem>
        <AppGridItem xs={12} md={6} lg={6}>
          <AppSelect
            data={status}
            labelText="Status"
            id="sentcomm-status"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: filter.status,
              onChange: (e: any) => updateFilter({ ...filter, status: e.target.value }),
            }}
          />
        </AppGridItem>
        <AppGridItem xs={12} md={6} lg={6}>
          <div className={classes.dateRangeButton}>
            <AppDateRangeButton
              caption={"Date Sent"}
              disableFutureProps={{ startDate: true, endDate: true }}
              dateRange={filter.sentOnDate}
              id="sentcomm-date-sent"
              onUpdate={(dateRange: DateRange) =>
                updateFilter({
                  ...filter,
                  sentOnDate: dateRange,
                })
              }
            />
          </div>
        </AppGridItem>
      </AppGridContainer>
    </AppExpansionPanel>
  );
};

export default SentCommsFilter;
