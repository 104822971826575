import React, { useEffect } from "react";
import { AppTable, AppButton } from "../../../components";
import { Column, UpdateTableHeadersObject } from "../../../store/areas/shared";
import { Snippet, SnippetsRequest, parseMUIColumnToEnum } from "../../../store/areas/snippetsList";
import { SortOrder, CardColor, Size, TableTitle } from "../../../enums";
import { initialSnippetQueries, defaultHeaders } from "../../../store/areas/snippetsList/reducer";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { ViewPermission } from "../../../role";

export interface SnippetsTableProps {
  classes: any;
  columns: Column[];
  history: any;
  itemsPerPage: number;
  queries: SnippetsRequest;
  selectedDataIndices?: number[];
  selectedSnippets?: Snippet[];
  snippets: Snippet[];
  totalRows: number;
  permission: ViewPermission;
  getSnippets: (snippetsRequest: SnippetsRequest) => void;
  selectSnippet: (snippet: Snippet, dataIndex: number) => void;
  deselectSnippet: (snippet: Snippet, dataIndex: number) => void;
  updateTableHeaders: (updateTableHeaders: UpdateTableHeadersObject) => void;
}

const SnippetsTable: React.FunctionComponent<SnippetsTableProps> = ({
  columns,
  history,
  itemsPerPage,
  queries,
  selectedDataIndices,
  selectedSnippets,
  snippets,
  totalRows,
  permission,
  deselectSnippet,
  getSnippets,
  selectSnippet,
  updateTableHeaders,
}) => {
  useEffect(() => {
    getSnippets(initialSnippetQueries);
    updateTableHeaders(defaultHeaders);
  }, [getSnippets, updateTableHeaders]);

  const changePage = (pageRequested: number) => {
    const changePageRequest: SnippetsRequest = {
      ...queries,
      pageRequested,
    };
    getSnippets(changePageRequest);
  };

  const customCellNode = (snippet: Snippet) => {
    return (
      <AppButton color={CardColor.primary} onClick={() => history.push(`/snippet/${snippet.id}`)} size={Size.sm} round>
        {permission.write ? "Edit" : "View"}
      </AppButton>
    );
  };

  const sortChange = (changedColumn: string) => {
    const { pageRequested } = queries;
    const sortField = parseMUIColumnToEnum(changedColumn);
    let sortAscending: boolean;
    if (sortField === queries.sortField) {
      sortAscending = !queries.sortAscending;
    } else {
      sortAscending = true;
    }
    const sortChangeRequest: SnippetsRequest = {
      itemsPerPage,
      sortAscending,
      sortField,
      pageRequested,
    };
    getSnippets(sortChangeRequest);
  };

  const onTableChange = (action: string, tableState: any) => {
    switch (action) {
      case "sort":
        const { activeColumn: columnIndex } = tableState;
        const { sortDirection } = tableState.columns[columnIndex];
        const mapStringToEnum: any = {
          desc: SortOrder.asc,
          asc: SortOrder.desc,
        };
        const sortOrder: SortOrder = mapStringToEnum[sortDirection];
        const updateTableHeadersObject: UpdateTableHeadersObject = {
          columnIndex,
          sortOrder,
        };
        updateTableHeaders(updateTableHeadersObject);
        break;
      default:
        return;
    }
  };

  const onRowSelect = (data?: any, index?: any) => {
    const isSelect = data && index !== undefined && !selectedSnippets?.includes(data[0]);
    if (isSelect) {
      selectSnippet(data, index);
    } else {
      deselectSnippet(data, index);
    }
  };

  return (
    <AppTable
      selectableRows={permission.write ? "single" : "none"}
      rowsSelected={selectedDataIndices}
      onSelect={onRowSelect}
      rowsPerPage={ROWS_PER_TABLE_PAGE}
      columns={columns}
      title={TableTitle.Snippets}
      data={snippets}
      count={totalRows}
      serverSide={true}
      sort={true}
      onChangePage={(pageNumber: number) => changePage(pageNumber)}
      onTableChange={(action: string, tableState: any) => onTableChange(action, tableState)}
      customCellNode={customCellNode}
      pageRequested={queries.pageRequested}
      // eslint-disable-next-line
      onColumnSortChange={(changedColumn: string, direction: string) => sortChange(changedColumn)}
    />
  );
};

export default SnippetsTable;
