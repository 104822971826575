import React from "react";
import { AppCard, AppCardHeader, AppCardBody, AppCardFooter, AppGridContainer, AppGridItem, AppButton, AppList } from "../../../components";
import { CardColor, Size } from "../../../enums";
import { Template } from "../../../store/areas/template";
import { TemplateAttachment, TemplateAttachmentRequest } from "../../../store/areas/template/types";
import { store } from "../../../app";
import { RootState } from "../../../store/rootReducer";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ListData } from "../../../components/appList/types";
import { ViewPermission } from "../../../role";

export interface TemplateAttachmentProps {
  classes: any;
  attachments: TemplateAttachment[];
  fileInput: any;
  isUploading: boolean;
  permission: ViewPermission;
  uploadTemplateAttachment: (request: TemplateAttachmentRequest) => void;
  updateTemplate: (template: Template) => void;
}

const Attachment: React.FunctionComponent<TemplateAttachmentProps> = ({
  classes,
  attachments,
  fileInput,
  isUploading,
  uploadTemplateAttachment,
  updateTemplate,
  permission,
}) => {
  const removeAttachment = (templateAttachment: ListData) => {
    const state = store.getState() as RootState;
    const filteredAttachments = state.template.template.templateAttachments.filter(
      item => item.templateAttachmentId !== templateAttachment.id
    );
    updateTemplate({ ...state.template.template, templateAttachments: filteredAttachments });
  };

  const addAttachment = (event: any) => {
    const state = store.getState() as RootState;
    const file = event.target.files[0];
    const attachment: TemplateAttachment = {
      name: file.name,
      templateAttachmentId: "",
      size: file.size,
    };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      uploadTemplateAttachment({
        templateAttachment: attachment,
        templateAttachmentData: reader.result
      });
    };
  };

  const getFileSize = (sizeInBytes: string) => {
    const kiloByte = 1024;
    const megaByte = kiloByte * kiloByte;
    const convertedSize = parseInt(sizeInBytes);

    if (convertedSize < megaByte) {
      return `${(convertedSize / kiloByte).toFixed(2)} KB`;
    }
    return `${(convertedSize / megaByte).toFixed(2)} MB`;
  };

  const remapAttachments = (attachments: TemplateAttachment[]) => {
    const mappedAttachments: ListData[] = attachments.map(item => ({
      id: item.templateAttachmentId,
      title: item.name,
      subTitle: getFileSize(item.size),
      icon: { name: <AttachmentIcon /> },
    }));
    return mappedAttachments;
  };

  return (
    <AppCard>
      <AppCardHeader color={CardColor.primary} stats={true}>
        <h4 className={classes.cardTitleWhite}>Attachments</h4>
      </AppCardHeader>
      <AppCardBody>
        <AppList
          data={remapAttachments(attachments)}
          secondaryAction={
            permission.write ? { icon: { name: <DeleteIcon />, style: classes.deleteIcon }, onClick: removeAttachment } : undefined
          }
        />
        {isUploading && (
          <div>
            <div className={classes.progressItem}>
              <CircularProgress className={classes.circularProgress} size={30} />
            </div>
            <div className={classes.progressItem}>
              <p className={classes.uploadText}>Uploading</p>
            </div>
          </div>
        )}
      </AppCardBody>
      {permission.write && (
        <AppCardFooter>
          <AppGridContainer>
            <AppGridItem xs={12} sm={12} md={12}>
              <input
                className={classes.fileInput}
                type="file"
                onChange={addAttachment}
                ref={input => (fileInput = input)}
                onClick={(e: any) => (e.target.value = "")}
              />
              <AppButton color={CardColor.primary} size={Size.sm} onClick={() => fileInput.click()}>
                Add
              </AppButton>
            </AppGridItem>
          </AppGridContainer>
        </AppCardFooter>
      )}
    </AppCard>
  );
};

export default Attachment;
