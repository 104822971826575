import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

export interface TenantRequiredProps {
  classes?: any;
  tenantIsSet: boolean;
  failedToGetTenants: boolean;
  children: any;
}

const TenantRequired: React.FunctionComponent<TenantRequiredProps> = ({ classes, tenantIsSet, failedToGetTenants, children }) => {

  if (!failedToGetTenants && tenantIsSet){
    return children;
  }

  const messages = failedToGetTenants
    ? { title: "You do not have access to any tenants", subTitle: "Please raise an access request or new user account ticket on SNOW." }
    : { title: "Tenant Required", subTitle: "Please select a tenant before continuing." };

  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <Typography variant="h4">{messages.title}</Typography>
        <Typography variant="subtitle1">{messages.subTitle}</Typography>
      </div>
    </Paper>
  );
};

export default TenantRequired;
