import { TableRequest, MUIColumnNames, DateRange, Condition, Field } from "../shared";

export interface RawPayload {
  key: string;
  value: RawPayload | string;
};

export interface ScheduledComm {
  id: string;
  triggerName: string;
  templateName: string;
  sendOn: Date;
  createdOn: Date;
  status: string;
  payload: RawPayload;
  externalSessionId: string;
}

// namespace KLP.Comms.Enums.ScheduledCommsSearchSortFields for back end mapping
export enum ScheduledCommsTableFields {
  "TemplateName" = 1,
  "SendOn" = 2,
  "CreatedOn" = 3,
  "TriggerName" = 4
}

// Column index is a 0-based MUI Datatables property, so doesn't map to a back end enum
export enum ScheduledCommsColumnIndex {
  "TemplateName" = ScheduledCommsTableFields.TemplateName - 1,
  "SendOn" = ScheduledCommsTableFields.SendOn - 1,
  "CreatedOn" = ScheduledCommsTableFields.CreatedOn - 1,
  "TriggerName" = ScheduledCommsTableFields.TriggerName - 1
}

export const parseMUIColumnToEnum = (columnName: string): ScheduledCommsTableFields => {
  switch (columnName) {
    case MUIColumnNames.templateName:
      return ScheduledCommsTableFields.TemplateName;
    case MUIColumnNames.sendOn:
      return ScheduledCommsTableFields.SendOn;
    case MUIColumnNames.createdOn:
      return ScheduledCommsTableFields.CreatedOn;
    case MUIColumnNames.triggerName:
      return ScheduledCommsTableFields.TriggerName;
    default:
      return ScheduledCommsTableFields.TemplateName;
  }
};

export interface ScheduledCommsRequest extends TableRequest {
  sortField?: ScheduledCommsTableFields;
}

export interface Filter {
  templateName: string;
  triggers: string[];
  sendOnDate: DateRange;
  createdOnDate: DateRange;
  status: string[];
  condition: Condition;
  triggerFields: Field[];
}
