import { Reducer } from "redux";
import {
  Template,
  SchedulingRule,
  Touched,
  GET_TEMPLATES_SUCCESS,
  INIT_SCHEDULINGRULE,
  TOUCH_SCHEDULINGRULE,
  SAVE_SCHEDULINGRULE_SUCCESS,
  GET_SCHEDULINGRULE_SUCCESS,
  UPDATE_SENDCONDITION,
} from ".";
import { ScheduledDateType, Field, Dictionary } from "../shared";
import { UPDATE_CONDITION, UPDATE_SCHEDULINGRULE_SUCCESS } from "./actionTypes";
import { defaultScheduledDateTypes, initialCondition } from "../shared/values";
import { RecipientSourceType } from "../../../enums";

export interface SchedulingRuleState {
  triggerFields: Field[];
  templates: Dictionary<Template>;
  filteredTemplates: Dictionary<Template>;
  schedulingRule: SchedulingRule;
  saved: boolean;
  touched: Touched;
  scheduledDateTypes: ScheduledDateType[];
}

const initialState: SchedulingRuleState = {
  triggerFields: [],
  templates: {},
  schedulingRule: {
    id: "",
    name: "",
    emailRecipientsSource: "",
    uiRecipientsSource: "",
    recipientsSourceType: RecipientSourceType.FromPayload,
    emailRecipientOverride: { value: "", isValid: false },
    template: {
      id: "",
      name: "",
      triggerId: "",
      triggerName: "",
      augmenterMappings: [],
    },
    active: true,
    condition: initialCondition,
    sendCondition: initialCondition,
    scheduledDateType: 1,
    scheduledDateSource: "",
    offset: "0",
    augmenterMappings: [],
    combinedFields: [],
    notificationChannelTypes: [],
    isSendAsImmediate: false
  },
  filteredTemplates: {},
  saved: false,
  touched: {
    name: false,
    emailRecipient: false,
    emailRecipientOverride: false,
    uiRecipient: false,
    template: false,
    scheduledDateSource: false,
    notificationChannelTypes: false,
  },
  scheduledDateTypes: defaultScheduledDateTypes,
};

export const SchedulingRuleReducer: Reducer<SchedulingRuleState> = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_SCHEDULINGRULE:
      return {
        ...initialState,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        filteredTemplates: action.templates,
        schedulingRule: {
          ...state.schedulingRule,
          combinedFields: action.combinedFields,
        },
        triggerFields: action.triggerFields
      };
    case UPDATE_SCHEDULINGRULE_SUCCESS:
      return {
        ...state,
        schedulingRule: action.schedulingRule,
        triggerFields: action.triggerFields
      };
    case UPDATE_CONDITION:
      return {
        ...state,
        schedulingRule: { ...state.schedulingRule, condition: action.condition },
      };
    case UPDATE_SENDCONDITION:
      return {
        ...state,
        schedulingRule: { ...state.schedulingRule, sendCondition: action.sendCondition },
      };
    case SAVE_SCHEDULINGRULE_SUCCESS:
      return {
        ...state,
        saved: true,
      };
    case TOUCH_SCHEDULINGRULE:
      return {
        ...state,
        touched: { ...state.touched, ...action.touched },
      };
    case GET_SCHEDULINGRULE_SUCCESS:
      return {
        ...state,
        schedulingRule: action.schedulingRule,
      };
    default:
      return state;
  }
};
