import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppTableCellStyles from "./appTableCellStyle";
import { TableCell } from "@material-ui/core";

export interface AppTableCellProps {
  align?: "center" | "inherit" | "justify" | "left" | "right";
  children: any;
  className?: any;
  classes?: any;
  colSpan?: number;
  id: string;
  size?: "small" | "medium";
  sortDirection?: "asc" | "desc" | false;
  variant?: "body" | "footer" | "head";
}

const Item: React.FunctionComponent<AppTableCellProps> = props => {
  const {
    align,
    children,
    className,
    classes,
    colSpan,
    id,
    size,
    sortDirection,
    variant,
  } = props;

  return (
    <TableCell
      align={align ?? "inherit"}
      className={className}
      classes={classes}
      colSpan={colSpan}
      data-testid={id}
      id={id}
      size={size}
      sortDirection={sortDirection ?? false}
      variant={variant ?? "body"}
    >
      {children}
    </TableCell>
  );
};

export const AppTableCell = withStyles(AppTableCellStyles)(Item);
