import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AppSwitchStyles from "./AppSwitchStyle";

export interface AppSwitchProps {
  id?: string;
  onChange?: (value: any) => void;
  formControlProps?: any;
  error?: boolean;
  success?: boolean;
  touched?: boolean;
  onTouch?: () => void;
  isNumeric?: boolean;
  value: boolean;
}

const Item: React.FunctionComponent<AppSwitchProps> = ({
  id,
  onChange,
  formControlProps,
  error,
  success,
  touched,
  onTouch,
  value
}: AppSwitchProps) => {
  const classes = AppSwitchStyles();

  const onBlur = () => {
    if (!touched && onTouch) {
      onTouch();
    }
  };

  React.useEffect(() => {
    if (value === undefined && onChange) {
      onChange(false);
    }
  }, [onChange, value]);

  const className = formControlProps?.className
    ? `${formControlProps?.className} ${classes.formControl}`
    : `${classes.formControl}`;

  return (
    <FormControl error={error} {...formControlProps} className={className}>
      <Switch
        classes={{
          disabled: classes.disabled
        }}
        onBlur={onBlur}
        id={id}
        onChange={(_: any, checked) => onChange && onChange(checked)}
        size="small"
        checked={!!value}
      />

      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

export const AppSwitch = Item;
