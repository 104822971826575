import { Reducer } from "redux";
import { DataType, SortOrder, SmartRange } from "../../../enums";
import { GET_SCHEDULING_RULES_SUCCESS, GET_SCHEDULING_RULES, UPDATE_TABLE_HEADERS_SUCCESS, UPDATE_FILTER } from "./actionTypes";
import { SchedulingRule, SchedulingRulesRequest, SchedulingRulesTableFields, SchedulingRulesColumnIndex, Filter } from "./types";
import { Column, UpdateTableHeadersObject, Status } from "../shared";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { updateColumns } from "../shared/utils";
import { defaultStatuses } from "../shared/values";

export interface SchedulingRulesListState {
  schedulingRules?: SchedulingRule[];
  columns: Column[];
  totalRows: number;
  itemsPerPage: number;
  queries: SchedulingRulesRequest;
  status: Status[];
  filter: Filter;
}

export const defaultSchedulingRuleColumns: Column[] = [
  {
    name: "name",
    label: "Rule Name",
    dataType: DataType.String,
  },
  {
    name: "templateName",
    label: "Template Name",
    dataType: DataType.String,
  },
  { name: "triggerName", label: "Trigger", dataType: DataType.String },
  {
    name: "lastModified",
    label: "Last Modified",
    dataType: DataType.Datetime,
  },
  {
    name: "active",
    label: "Active",
    dataType: DataType.Boolean,
  },
];

export const defaultFilterValues: Filter = {
  ruleName: "",
  templateName: "",
  triggers: [],
  dateModified: {
    startDate: undefined,
    endDate: undefined,
    smartRange: SmartRange.All,
  },
  status: "",
};

export const initialSchedulingRuleQueries: SchedulingRulesRequest = {
  pageRequested: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  sortAscending: true,
  sortField: SchedulingRulesTableFields.LastModified,
};

export const defaultHeaders: UpdateTableHeadersObject = {
  columnIndex: SchedulingRulesColumnIndex.LastModified,
  sortOrder: SortOrder.asc,
};

export const initialState: SchedulingRulesListState = {
  schedulingRules: [],
  columns: defaultSchedulingRuleColumns,
  totalRows: 0,
  itemsPerPage: ROWS_PER_TABLE_PAGE,
  queries: initialSchedulingRuleQueries,
  status: defaultStatuses,
  filter: defaultFilterValues,
};

export const SchedulingRulesListReducer: Reducer<SchedulingRulesListState> = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SCHEDULING_RULES:
      const { schedulingRulesRequest: queries } = action;
      return { ...state, queries };
    case UPDATE_TABLE_HEADERS_SUCCESS:
      const updatedColumns = updateColumns(action, state.columns);
      return {
        ...state,
        columns: updatedColumns,
      };
    case GET_SCHEDULING_RULES_SUCCESS:
      const { schedulingRules, totalRows } = action;
      return { ...state, schedulingRules, totalRows };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      };
    default:
      return state;
  }
};

export default SchedulingRulesListReducer;
