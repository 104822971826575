import React, { useEffect } from "react";
import { AppTable, AppButton, AppTableRow, AppTableCell } from "../../../components";
import { Column, UpdateTableHeadersObject } from "../../../store/areas/shared";
import { Template, TemplatesRequest, parseMUIColumnToEnum } from "../../../store/areas/templatesList";
import { SortOrder, CardColor, Size, TableTitle } from "../../../enums";
import { initialTemplateQueries, defaultHeaders } from "../../../store/areas/templatesList/reducer";
import { ROWS_PER_TABLE_PAGE } from "../../../config";
import { ViewPermission } from "../../../role";

export interface TemplatesTableProps {
  classes: any;
  columns: Column[];
  history: any;
  itemsPerPage: number;
  queries: TemplatesRequest;
  selectedDataIndices?: number[];
  selectedTemplates?: Template[];
  templates: Template[];
  totalRows: number;
  permission: ViewPermission;
  getTemplates: (templatesRequest: TemplatesRequest) => void;
  selectTemplate: (template: Template, dataIndex: number) => void;
  deselectTemplate: (template: Template, dataIndex: number) => void;
  updateTableHeaders: (updateTableHeaders: UpdateTableHeadersObject) => void;
}

const TemplatesTable: React.FunctionComponent<TemplatesTableProps> = ({
  columns,
  history,
  itemsPerPage,
  queries,
  selectedDataIndices,
  selectedTemplates,
  templates,
  totalRows,
  deselectTemplate,
  getTemplates,
  selectTemplate,
  updateTableHeaders,
  classes,
  permission,
}) => {
  useEffect(() => {
    getTemplates(initialTemplateQueries);
    updateTableHeaders(defaultHeaders);
  }, [getTemplates, updateTableHeaders]);

  const changePage = (pageRequested: number) => {
    const changePageRequest: TemplatesRequest = {
      ...queries,
      pageRequested,
    };
    getTemplates(changePageRequest);
  };

  const customCellNode = (template: Template) => {
    return (
      <AppButton color={CardColor.primary} onClick={() => history.push(`/template/${template.id}`)} size={Size.sm} round>
        {permission.write ? "Edit" : "View"}
      </AppButton>
    );
  };

  const sortChange = (changedColumn: string) => {
    const { pageRequested } = queries;
    const sortField = parseMUIColumnToEnum(changedColumn);
    let sortAscending: boolean;
    if (sortField === queries.sortField) {
      sortAscending = !queries.sortAscending;
    } else {
      sortAscending = true;
    }
    const sortChangeRequest: TemplatesRequest = {
      itemsPerPage,
      sortAscending,
      sortField,
      pageRequested,
    };
    getTemplates(sortChangeRequest);
  };

  const onTableChange = (action: string, tableState: any) => {
    switch (action) {
      case "sort":
        const { activeColumn: columnIndex } = tableState;
        const { sortDirection } = tableState.columns[columnIndex];
        const mapStringToEnum: any = {
          desc: SortOrder.asc,
          asc: SortOrder.desc,
        };
        const sortOrder: SortOrder = mapStringToEnum[sortDirection];
        const updateTableHeadersObject: UpdateTableHeadersObject = {
          columnIndex,
          sortOrder,
        };
        updateTableHeaders(updateTableHeadersObject);
        break;
      default:
        return;
    }
  };

  const onRowSelect = (data?: any, index?: any) => {
    const isSelect = data && index !== undefined && !selectedTemplates?.includes(data[0]);
    if (isSelect) {
      selectTemplate(data, index);
    } else {
      deselectTemplate(data, index);
    }
  };

  const renderExpandableRow = (rowData: any, rowMeta: any) => {
    const attachmentsForCurrentRow = templates[rowMeta.rowIndex].attachments;
    if (attachmentsForCurrentRow?.length === 0) {
      return (
        <AppTableRow shadingOnHover id={`attachment-properties-row-${rowMeta.rowIndex}`}>
          <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={rowData.length + 1}>
            <span>This template currently has no attachments.</span>
          </AppTableCell>
        </AppTableRow>
      );
    }
    return (
      <AppTableRow shadingOnHover id={`attachment-properties-row-${rowMeta.rowIndex}`}>
        <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={1}>
          <span>Attachments:</span>
        </AppTableCell>
        <AppTableCell className={classes.tableCell} id={`attachment-property-${rowMeta.rowIndex}`} colSpan={rowData.length}>
          <div className={classes.attachmentPropertiesContainer}>
            {attachmentsForCurrentRow?.map((el, idx) => {
              return (
                <div className={classes.attachmentProperty} key={idx}>
                  <span className={classes.attachmentPropertyKey}>{el.name}</span>
                </div>
              );
            })}
          </div>
        </AppTableCell>
      </AppTableRow>
    );
  };

  return (
    <AppTable
      selectableRows={permission.write ? "single" : "none"}
      rowsSelected={selectedDataIndices}
      onSelect={onRowSelect}
      rowsPerPage={ROWS_PER_TABLE_PAGE}
      columns={columns}
      title={TableTitle.Templates}
      data={templates}
      count={totalRows}
      serverSide={true}
      sort={true}
      onChangePage={(pageNumber: number) => changePage(pageNumber)}
      onTableChange={(action: string, tableState: any) => onTableChange(action, tableState)}
      customCellNode={customCellNode}
      pageRequested={queries.pageRequested}
      makeRowsExpandable={true}
      renderExpandableRow={renderExpandableRow}
      // eslint-disable-next-line
      onColumnSortChange={(changedColumn: string, direction: string) => sortChange(changedColumn)}
    />
  );
};

export default TemplatesTable;
