import SchedulingRuleCondition, { SchedulingRuleConditionProps } from "./schedulingRuleCondition";
import SchedulingRuleEditorStyles from "../schedulingRuleEditorStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/rootReducer";
import { updateCondition } from "../../../store/areas/schedulingRule";
import { Condition } from "../../../store/areas/shared";
import { withAuthorization } from "../../../withAuthorization";
import { View } from "../../../enums";
import { configureStore } from "@comms/react-query-builder/src";

const store = configureStore();

const mapStateToProps = (state: RootState): Partial<SchedulingRuleConditionProps> => ({
  fields: state.schedulingRule.triggerFields,
  condition: state.schedulingRule.schedulingRule?.condition,
  title: "Scheduling Conditions",
  store,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCondition: (condition: Condition) => dispatch(updateCondition(condition)),
});

const SchedulingRuleConditionContainer = compose(
  withStyles(SchedulingRuleEditorStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(View.SchedulingRule)
)(SchedulingRuleCondition) as React.ComponentType<any>;
export default SchedulingRuleConditionContainer;
