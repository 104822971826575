import SuggestionDecorator from "./Suggestion";
import SuggestionListDecorator from "./SuggestionList";
import { Config } from "./configProps";

const getDecorators = (config: Config) => [
  new SuggestionDecorator(config.typeName, config.suggestionClassName).getDecorator(),
  new SuggestionListDecorator(config).getDecorator()
];

export const multipleDecoratorHelper = (configs: Config[]) => configs.flatMap(config => getDecorators(config));

export default multipleDecoratorHelper;