import * as React from "react";
import { SharedProps } from "../shared";
import { Grid, Paper, withStyles, Tooltip, Theme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import Operator from "./Operator";
import { operators, getOperatorByFieldType } from "../../config";
import { Field, Rule } from "../../store";
import { getRuleError, getRuleInfo } from "../../utils";

export interface RuleItemProps extends SharedProps {
  rule: Rule;
  fields: {
    [name: string]: Field;
  };
  extraFields: {
    [name: string]: Field;
  };
  ruleError: string;
  removeRule: (rule: Rule) => void;
  updateRule: (rule: Rule) => void;
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RuleItem: React.FC<RuleItemProps> = ({
  classes,
  rule,
  fields,
  extraFields,
  ruleError,
  removeRule,
  updateRule,
}: RuleItemProps) => {
  ruleError = getRuleError(rule) || ruleError;
  const ruleInfo = getRuleInfo(rule);
  return (
    <Paper elevation={3} className={classes.ruleContainer}>
      <Grid
        container
        direction="row"
        className={classes.rule}
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.fieldContainer}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              options={Object.values(fields).map((f: Field) => f.name)}
              value={rule.field.name}
              onChange={(_event: any, newValue: string | null) => {
                if (newValue) {
                  const operator = getOperatorByFieldType(
                    fields[newValue].type,
                    Object.values(extraFields)
                  )[0];
                  updateRule({
                    ...rule,
                    field: fields[newValue],
                    values: [],
                    operatorName: operator.name,
                    requiredValues: operator.requiredValues,
                  });
                }
              }}
              renderInput={(params: any) => <TextField {...params} label={"Field"} fullWidth />}
            />
          </FormControl>
        </Grid>

        {rule.field?.type && (
          <Grid item>
            <Operator
              extraFields={extraFields}
              classes={classes}
              fieldType={rule.field.type}
              value={rule.operatorName}
              onChange={(operatorName: string, requiredValues: number) =>
                updateRule({
                  ...rule,
                  operatorName,
                  requiredValues,
                  values: [],
                })
              }
            />
          </Grid>
        )}
        <div className={classes.operatorContainer}>
          {operators[rule.operatorName]?.inputs[rule.field.type]?.map(
            (oInput: (key: number, props: any) => JSX.Element, index: number) => {
              return (
                <Grid item key={index}>
                  {oInput(index, {
                    labelText: `Enter ${rule.field.type}`,
                    value: rule.values[index],

                    onChange: (value: any) => {
                      const newValues = rule.values.slice(0);
                      newValues[index] = value;
                      updateRule({ ...rule, values: newValues });
                    },
                    options: Object.values(extraFields)
                      .filter((f: Field) => f.type === rule.field.type)
                      .map((f: Field) => f.name),
                  })}
                </Grid>
              );
            }
          )}
        </div>

        <div className={classes.buttonsContainer}>
          {ruleError && (
            <HtmlTooltip title={<React.Fragment>{ruleError}</React.Fragment>}>
              <InfoIcon className={classes.ruleCaption} fontSize="large" color="error" />
            </HtmlTooltip>
          )}
          {ruleInfo && (
            <HtmlTooltip title={<React.Fragment>{ruleInfo}</React.Fragment>}>
              <InfoIcon className={classes.ruleCaption} fontSize="large" color="primary" />
            </HtmlTooltip>
          )}
          <IconButton aria-label="delete" onClick={() => removeRule(rule)} color="secondary">
            <DeleteIcon fontSize="large" />
          </IconButton>
        </div>
      </Grid>
    </Paper>
  );
};

export default RuleItem;
