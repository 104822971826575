import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppTableRowStyles from "./appTableRowStyle";
import { TableRow } from "@material-ui/core";

export interface AppTableRowProps {
  children: any;
  className?: any;
  classes?: any;
  shadingOnHover?: boolean;
  shadingAlwaysOn?: boolean;
  id: string;
}

const Item: React.FunctionComponent<AppTableRowProps> = props => {
  const {
    children,
    className,
    classes,
    shadingOnHover,
    shadingAlwaysOn,
    id,
  } = props;

  return (
    <TableRow
      className={className}
      classes={classes}
      data-testid={id}
      id={id}
      hover={shadingOnHover ?? undefined}
      selected={shadingAlwaysOn ?? undefined}
    >
      {children}
    </TableRow>
  );
};

export const AppTableRow = withStyles(AppTableRowStyles)(Item);
