import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  getSchedulingRulesSuccess,
  getSchedulingRulesFailure,
  updateTableHeadersSuccess,
  updateTableHeadersFailure,
  getSchedulingRules
} from "./actions";
import {
  GET_SCHEDULING_RULES,
  UPDATE_TABLE_HEADERS,
  APPLY_FILTER
} from "./actionTypes";
import { SchedulingRule } from "./types";
import { generateUrlWithQueryString } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { fetchSecure } from "../../../authProvider";
import { generateFilterObject, getSchedulingRuleRequest, getFilter } from "./utils";

function* getSchedulingRulesSaga(action: any) {
  try {
    const filter = yield select(getFilter);
    const filterObject = generateFilterObject(filter);
    const url = generateUrlWithQueryString(EndpointRoute.SchedulingRules, {
      ...action.schedulingRulesRequest,
      ...filterObject
    });
    const response = yield call(fetchSecure, url);
    if (response.ok) {
      const payload: {
        schedulingRules: any[];
        totalRecordCount: number;
      } = yield call([response, "json"]);
      const schedulingRules: SchedulingRule[] = yield payload.schedulingRules.map(
        (i: any) => ({
          id: i.id,
          name: i.name,
          templateName: i.templateName,
          triggerName: i.triggerName,
          lastModified: i.modifiedOn,
          active: !i.disabled,
          scheduledDateType: i.scheduledDateType,
          scheduledDateSource: i.scheduledDateSource,
          offset: i.offset
        })
      );
      yield put(
        getSchedulingRulesSuccess(schedulingRules, payload.totalRecordCount)
      );
    } else {
      const error = yield call([response, "json"]);
      yield put(getSchedulingRulesFailure(error));
    }
  } catch (error) {
    yield put(getSchedulingRulesFailure(error));
  }
}

function* updateTableHeadersSaga(action: any) {
  try {
    const { columnIndex, sortOrder } = action;
    yield put(updateTableHeadersSuccess(columnIndex, sortOrder));
  } catch (error) {
    yield put(updateTableHeadersFailure(error));
  }
}

function* applyFilterSaga() {
  const request = yield select(getSchedulingRuleRequest);
  yield put(getSchedulingRules({ ...request, pageRequested: 0 }));
}

export function* SchedulingRulesListWatcher() {
  yield takeLatest(GET_SCHEDULING_RULES, getSchedulingRulesSaga);
  yield takeLatest(UPDATE_TABLE_HEADERS, updateTableHeadersSaga);
  yield takeLatest(APPLY_FILTER, applyFilterSaga);
}
