import { put, takeLatest, call } from "redux-saga/effects";
import { generateUrl, generateUrlWithQueryString, createHttpRequest } from "../../../utilities/httpUtils";
import { EndpointRoute } from "../../../enums";
import { NUMBER_MAX_VALUE } from "../../../config";
import { fetchSecure } from "../../../authProvider";
import { Template, CancellationRule } from "./types";
import {
  getTemplatesSuccess,
  getTemplatesFailure,
  saveCancellationRuleSuccess,
  saveCancellationRuleFailure,
  getCancellationRuleSuccess,
  getCancellationRuleFailure,
} from "./actions";
import { GET_TEMPLATES, SAVE_CANCELLATIONRULE, GET_CANCELLATIONRULE } from "./actionTypes";
import { remapPayload } from "./utils";

function* getTemplatesSaga() {
  try {
    const url = generateUrlWithQueryString(EndpointRoute.Templates, {
      itemsPerPage: NUMBER_MAX_VALUE,
      pageRequested: 0,
      sortAscending: true,
      sortField: 1,
      activeOnly: true,
    });
    const response = yield call(fetchSecure, url);
    const payload: { templates: Template[] } = yield call([response, "json"]);

    const templates: { [id: string]: Template } = payload.templates.reduce((acc: { [id: string]: Template }, cur: Template) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    yield put(getTemplatesSuccess(templates));
  } catch (error) {
    yield put(getTemplatesFailure(error));
  }
}

function* getCancellationRuleSaga(action: any) {
  try {
    const { cancellationRuleId }: { cancellationRuleId: string } = action;
    const url = `${generateUrl(EndpointRoute.CancellationRules)}/${cancellationRuleId}`;
    const response = yield call(fetchSecure, url);
    const payload: { cancellationRule: CancellationRule } = yield call([response, "json"]);
    const remappedPayload: CancellationRule = remapPayload.getCancellationRule(payload.cancellationRule);
    yield put(getCancellationRuleSuccess(remappedPayload));
  } catch (error) {
    yield put(getCancellationRuleFailure(error));
  }
}

function* saveCancellationRuleSaga(action: any) {
  try {
    const url = generateUrl(EndpointRoute.CancellationRules);
    const { cancellationRule }: { cancellationRule: CancellationRule } = action;
    const body = {
      id: cancellationRule.id,
      name: cancellationRule.name,
      templateId: cancellationRule.template.id,
      triggerId: cancellationRule.trigger.id,
      disabled: !cancellationRule.active,
      condition: cancellationRule.condition,
      hasMultipleTemplates: cancellationRule.hasMultipleTemplates,
    };
    const response = yield call(fetchSecure, url, createHttpRequest(body, cancellationRule.id ? "PUT" : "POST"));
    if (response.ok) {
      yield put(saveCancellationRuleSuccess("Cancellation Rule saved"));
    } else {
      const error = yield call([response, "json"]);
      yield put(saveCancellationRuleFailure(error));
    }
  } catch (error) {
    yield put(saveCancellationRuleFailure(error));
  }
}

export function* cancellationRuleWatcher() {
  yield takeLatest(GET_TEMPLATES, getTemplatesSaga);
  yield takeLatest(SAVE_CANCELLATIONRULE, saveCancellationRuleSaga);
  yield takeLatest(GET_CANCELLATIONRULE, getCancellationRuleSaga);
}
