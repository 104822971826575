import { AUTH_CONFIG, AUTH_PARAMS, TENANT_ID_HEADER_NAME } from "./config";
import { IdTokenResponse, LoginType, MsalAuthProvider } from "react-aad-msal";
import { store } from "./app";
import { RootState } from "./store/rootReducer";
import { Configuration } from "msal";

export const authProvider = new MsalAuthProvider(AUTH_CONFIG as Configuration, AUTH_PARAMS, {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "auth.html",
});

export const fetchSecure = function*(url: string, data: RequestInit = {}) {
  const state: RootState = store.getState();
  data.headers =  getHeadersWithTenantId(data.headers || {}, state.tenant.activeTenantId);
  let rawIdToken = state.shared.account.idToken;
  if (!rawIdToken) {
    const token: IdTokenResponse = yield authProvider.getIdToken();
    rawIdToken = token.idToken.rawIdToken;
  }

  const cacheControl = { "AuthResponse-Control": "no-cache", pragma: "no-cache" };
  data = {
    ...data,
    headers: { ...cacheControl, Authorization: `bearer ${rawIdToken}`, ...data.headers },
  };
  return yield fetch(url, data);
};

const getHeadersWithTenantId = (headers: HeadersInit, activeTenantId: string): HeadersInit => {
  return activeTenantId === ""
    ? headers
    : { ...headers, [TENANT_ID_HEADER_NAME]: activeTenantId };
};

