import { AnyAction } from "redux";
import {
  UPDATE_SNIPPET,
  INIT_SNIPPET,
  SAVE_SNIPPET,
  SAVE_SNIPPET_SUCCESS,
  SAVE_SNIPPET_FAIL,
  TOUCH_SNIPPET,
  Snippet,
  Touched,
  GET_SNIPPET,
  GET_SNIPPET_SUCCESS,
  GET_SNIPPET_FAIL,
  UPDATE_EDITOR
} from ".";
import { AppTemplateEditorOnChangeArgs } from "../../../components";

export const updateSnippet = (snippet: Snippet): AnyAction => {
  return {
    type: UPDATE_SNIPPET,
    snippet
  };
};

export const updateEditor = (onChangeArgs: AppTemplateEditorOnChangeArgs): AnyAction => {
  const snippetPartial = {
    bodyHtml: onChangeArgs.html,
    bodyText: onChangeArgs.text,
    rawBody: onChangeArgs.rawContent
  };

  return {
    type: UPDATE_EDITOR,
    snippetPartial
  };
};

export const initSnippet = (): AnyAction => ({ type: INIT_SNIPPET });

export const saveSnippet = (snippet: Snippet): AnyAction => {
  return {
    type: SAVE_SNIPPET,
    snippet
  };
};

export const saveSnippetSuccess = (message: string): AnyAction => {
  return {
    type: SAVE_SNIPPET_SUCCESS,
    message
  };
};

export const saveSnippetFailure = (error: Error): AnyAction => {
  return {
    type: SAVE_SNIPPET_FAIL,
    error,
    message:
      error.message || "unable to save snippet, please contact IT Support"
  };
};

export const touchSnippet = (touched: Touched): AnyAction => {
  return {
    type: TOUCH_SNIPPET,
    touched
  };
};

export const getSnippet = (snippetId: string): AnyAction => {
  return {
    type: GET_SNIPPET,
    snippetId
  };
};

export const getSnippetSuccess = (snippet: Snippet): AnyAction => {
  return {
    type: GET_SNIPPET_SUCCESS,
    snippet
  };
};

export const getSnippetFailure = (error: Error): AnyAction => {
  return {
    type: GET_SNIPPET_FAIL,
    error,
    message: "Unable to retrieve snippet, please contact KLP Support"
  };
};
