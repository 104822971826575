import ScheduledCommsTable, { ScheduledCommsTableProps } from "./scheduledCommsTable";
import { connect } from "react-redux";
import { compose } from "redux";
import scheduledCommsStyles from "../scheduledCommsListStyles";
import { RootState } from "../../../store/rootReducer";
import { withStyles } from "@material-ui/core/styles";
import {
  getScheduledComms,
  updateTableHeaders,
  ScheduledCommsRequest,
  ScheduledComm,
  selectScheduledComm,
  deselectScheduledComm,
  initialScheduledCommQueries,
  defaultHeaders,
  updateFilter,
  Filter,
} from "../../../store/areas/scheduledCommsList";
import { UpdateTableHeadersObject } from "../../../store/areas/shared";

const mapStateToProps = (state: RootState): Partial<ScheduledCommsTableProps> => ({
  columns: state.scheduledCommsList.columns,
  scheduledComms: state.scheduledCommsList.scheduledComms,
  totalRows: state.scheduledCommsList.totalRows,
  itemsPerPage: state.scheduledCommsList.itemsPerPage,
  queries: state.scheduledCommsList.queries,
  selectedScheduledComms: state.scheduledCommsList.selectedScheduledComms,
  selectedDataIndices: state.scheduledCommsList.selectedDataIndices,
  loading: state.loader.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  initScheduledComms: () => {
    dispatch(getScheduledComms(initialScheduledCommQueries));
    dispatch(updateTableHeaders(defaultHeaders));
  },
  getScheduledComms: (scheduledCommsRequest: ScheduledCommsRequest) => dispatch(getScheduledComms(scheduledCommsRequest)),
  updateTableHeaders: (updateTableHeadersObject: UpdateTableHeadersObject) => dispatch(updateTableHeaders(updateTableHeadersObject)),
  selectScheduledComm: (scheduledComm: ScheduledComm[], dataIndex: number[]) => dispatch(selectScheduledComm(scheduledComm, dataIndex)),
  deselectScheduledComm: (scheduledComm: ScheduledComm[], dataIndex: number[]) => dispatch(deselectScheduledComm(scheduledComm, dataIndex)),
  updateFilter: (filter: Filter) => dispatch(updateFilter(filter)),
});

const ScheduledCommsTableContainer = compose(
  withStyles(scheduledCommsStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScheduledCommsTable) as React.ComponentType<any>;

export default ScheduledCommsTableContainer;
